import { parseISO } from 'date-fns';

import { PaymentStatus } from 'graphql/generated-types';
import { formatPrice } from 'utilities/string';

export class Payment {
  constructor(props) {
    this.hasPaymentDetails = props !== null;

    this.amount = Number(props?.amount ?? 0);
    this.created = props?.created;
    this.paymentMethod = props?.paymentMethod;
    this.status = props?.status;
    this.isRefunded = props?.isRefunded ?? false;
    this.processor = props?.processor;

    this.date = this.hasPaymentDetails ? parseISO(this.created) : null;
  }

  get formattedAmount() {
    return formatPrice(this.amount);
  }

  get statusLabel() {
    if (this.isRefunded) return 'Refunded';

    switch (this.status) {
      case PaymentStatus.RequiresAction:
        return 'Declined';
      case PaymentStatus.Processing:
      case PaymentStatus.RequiresCapture:
      case PaymentStatus.RequiresConfirmation:
        return 'Pending';
      case PaymentStatus.Canceled:
        return 'Cancelled';
      case PaymentStatus.Succeeded:
      case PaymentStatus.Completed:
        return 'Paid';
      default:
        return 'Unknown';
    }
  }

  get isPaymentExempt() {
    return this.amount === 0;
  }
}
