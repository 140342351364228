import classNames from 'classnames';

import { IconCheck, IconTimes } from 'icons';
import { ChildStatusEnum } from 'graphql/constants';

import styles from './ChildStatusLabel.module.scss';

const statusToLabelMap = {
  [ChildStatusEnum.APPROVED]: 'Approved',
  [ChildStatusEnum.PENDING]: 'Pending',
  [ChildStatusEnum.UNRECOGNIZED]: 'Unrecognized',
  [ChildStatusEnum.DEACTIVATED]: 'Deactivated',
};

export const ChildStatusLabel = ({ className, status }) => (
  <div className={classNames(styles.container, styles[status.toLowerCase()], className)}>
    {status === ChildStatusEnum.APPROVED && <IconCheck size={10} className={styles.icon} />}

    {status === ChildStatusEnum.UNRECOGNIZED && <IconTimes size={14} className={styles.icon} />}
    {statusToLabelMap[status]}
  </div>
);
