import classNames from 'classnames';

import styles from './Icon.module.scss';

export const WhitePebbles = ({ className, color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 32 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.91377 13.5316C4.91377 13.5316 7.48022 11.4083 13.3685 11.3212C19.2568 11.2341 25.7087 12.1313 28.028 14.4637C30.3473 16.7962 28.8873 18.7627 28.8873 18.7627C28.8873 18.7627 27.7993 20.5202 25.6902 20.9775C23.5812 21.4349 21.1556 21.9358 17.8361 21.9554C14.5166 21.975 7.51256 21.6156 5.92556 20.3198C4.33856 19.024 3.27826 17.9591 3.28519 16.5087C3.29212 15.0583 4.91377 13.5316 4.91377 13.5316Z"
      fill="white"
    />
    <path
      d="M9.36913 1.54228C11.0406 0.519454 12.9945 -0.0168013 14.9871 0.000401267C18.2628 0.0548461 19.3277 0.668986 19.3277 0.668986C19.3277 0.668986 21.5315 1.96042 22.1252 2.12593C22.7188 2.29144 23.763 2.63335 24.1303 3.63732C24.4976 4.64128 25.0173 5.77156 24.1949 6.44232C23.3726 7.11308 23.1115 7.4746 21.2566 7.94064C19.6988 8.30558 18.0898 8.43713 16.4887 8.33047C15.2482 8.27602 12.319 7.99291 11.3003 7.99073C10.2816 7.98855 8.38737 6.9541 8.15174 5.73671C7.91612 4.51932 7.9577 2.11722 9.36913 1.54228Z"
      fill="white"
    />
    <path
      d="M1.37492 29.4321C1.37492 29.4321 2.52994 27.1237 6.73652 25.7952C10.1312 24.7456 13.752 24.5209 17.2634 25.1419C19.8044 25.5665 23.5905 26.1001 27.3097 27.3458C31.0289 28.5915 32.0337 29.345 32.0083 31.0481C31.9829 32.7511 30.1441 34.097 29.5782 34.3147C27.4693 35.2496 25.1744 35.7528 22.8444 35.7913C19.0051 35.9002 10.3402 36.0091 8.86637 35.9045C7.39257 35.8 3.78429 35.6868 2.34052 33.8857C0.896744 32.0847 0.668048 30.521 1.37492 29.4321Z"
      fill="white"
    />
  </svg>
);
