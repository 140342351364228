import { formatTimeString } from 'utilities/date';
import { AllBookingsForGuardianType, Maybe, SessionType, Status } from 'graphql/generated-types';
import { UTagType } from 'ui/Tag';

const sessionTypeMap: { [key in SessionType]: string } = {
  AM: 'AM',
  PM: 'PM',
  FULL: 'Full day',
  NONE: 'None',
};

const getFormattedTimes = (start: string, end?: string): string => {
  if (!end) {
    return formatTimeString(start, 'HH:mm');
  }
  return `${formatTimeString(start, 'HH:mm')}-${formatTimeString(end, 'HH:mm')}`;
};

export const getBookingDetailsHref = (session: AllBookingsForGuardianType): string => {
  if (
    session.bookingType === 'MARKETPLACE_ACTIVITY_BOOKING' &&
    session.sessionId &&
    session.marketplaceActivity?.bookingType !== 'SUBSCRIPTION'
  ) {
    return `/marketplace/details?bookingId=${session.bookingId}&childId=${session.child?.id}&sessionId=${session.sessionId}`;
  }

  if (session.bookingType === 'MARKETPLACE_ACTIVITY_BOOKING') {
    return `/marketplace/details?bookingId=${session.bookingId}&childId=${session.child?.id}`;
  }

  return `/session/?sessionIds=${session.bookingId}`;
};

export const formatSessionType = (
  session: AllBookingsForGuardianType,
  hideEndTimes: boolean,
): string | null => {
  if (session.customSessionType?.id) {
    return session.showCustomSessionTimes
      ? getFormattedTimes(session.customSessionType.startTime, session.customSessionType.endTime)
      : session.customSessionType.name;
  }

  if (session.sessionType) {
    return sessionTypeMap[session.sessionType];
  }

  if (session.subscriptionStartTime && session.subscriptionEndTime) {
    return getFormattedTimes(
      `${session.subscriptionStartTime}:00`,
      hideEndTimes ? undefined : `${session.subscriptionEndTime}:00`,
    );
  }

  if (session.startTime && session.endTime) {
    return getFormattedTimes(session.startTime, hideEndTimes ? undefined : session.endTime);
  }

  return null;
};

export const mapBookingStatusToTagType = (status?: Maybe<Status>): UTagType => {
  switch (status) {
    case Status.Confirmed:
      return 'positive';
    case Status.Rejected:
      return 'negative';
    case Status.OnHold:
    case Status.Pending:
      return 'outline';
    case Status.Cancelled:
    case Status.Moved:
      return 'disabled';
    default:
      return 'outline';
  }
};

export const mapBookingStatusToText = (status?: Maybe<Status>): string => {
  switch (status) {
    case Status.Confirmed:
      return 'Confirmed';
    case Status.Rejected:
      return 'Declined';
    case Status.Cancelled:
    case Status.Moved:
      return 'Cancelled';
    case Status.OnHold:
      return 'On hold';
    case Status.Pending:
    default:
      return 'Pending';
  }
};
