import { FC } from 'react';

import classNames from 'classnames';

import styles from './Tag.module.scss';

export type UTagType = 'default' | 'outline' | 'positive' | 'negative' | 'disabled';

interface ITagProps {
  className?: string;
  type?: UTagType;
}

export const Tag: FC<ITagProps> = ({ children, className, type = 'default' }) => (
  <div className={classNames(styles.container, styles[type], className)}>{children}</div>
);
