import { Link, useHistory } from 'react-router-dom';
import { logButtonEvent } from 'app/amplitude';
import { useProfile } from 'app/profile';
import { IconBaby, IconExclamationTriangle, IconUser } from 'icons';
import { ChildStatusEnum } from 'graphql/constants';
import { SettingsPage } from 'layouts';
import { Button } from 'ui';

import { Tile } from '../../components/Tile';
import { BookingButton } from '../../../Home/components/BookingButton';
import { MarketingPreferences } from './components/MarketingPreferences';
import styles from './ProfileHome.module.scss';
import { usePageViewTracker } from 'hooks';

export const ProfileHome = () => {
  const { profile, isInitializing: loading } = useProfile();
  const history = useHistory();
  const children = profile?.children ?? [];
  const hasNoBookableChildren = profile?.childrenEligibleForBooking.length === 0;


  usePageViewTracker('profile/home');

  /**
   * Handles what happens whent he user clicks the Request session button
   *
   * @param {MouseEvent} e The click event from the button
   */
  const handleRequestSessionClick = (e) => {
    e.preventDefault();

    if (hasNoBookableChildren) return;

    if (profile.children.length === 1 && !profile.children[0].hasBirthDate) {
      setShowBirthDateError(true);
      return;
    }

    logButtonEvent('make_a_booking_selected');

    if (!profile.canBookChildcare && profile.canBookMarketplaceActivities) {
      window.location.href = `https://activities.bookpebble.co.uk/`;
    }

    if (profile.canBookChildcare && profile.canBookMarketplaceActivities) {
      return history.push(`/session-or-marketplace`);
    }
  };

  return (
    <SettingsPage
      title="Profile"
      useHistoryBack={false}
      useResponsiveCentered={false}
      onBack={() => history.push('/home')}
    >
      <div className={styles.tiles}>
        <Tile
          icon={<IconUser size={18} />}
          isLoading={loading}
          href="/profile/detail"
          onClick={() => logButtonEvent('guardian_profile_selected')}
        >
          <strong>{profile?.fullName}</strong>
          <p>{profile?.phoneNumber}</p>
          {profile?.email && <p>{profile.email}</p>}
        </Tile>

        {children.length > 0 && (
          <section className={styles.children}>
            <h2 className={styles.sectionTitle}>Attendee(s)</h2>

            {children.map((child) => (
              <Tile
                icon={<IconBaby size={20} />}
                isLoading={loading}
                className={styles.childTile}
                data-testid={`childTile`}
                key={child.id}
                href={`/profile/child/${child.id}`}
                onClick={() => logButtonEvent('child_profile_next')}
              >
                <div className={styles.childInfo}>
                  <strong>{child?.fullName}</strong>
                  {child?.primaryNursery?.name && <p>{child?.primaryNursery?.name}</p>}
                  {child.isAdult && <p>Adult</p>}
                </div>
                {child.isRegisteredWithNursery && (
                  <div className={styles.childStatus}>
                    {child.status === ChildStatusEnum.PENDING && (
                      <div className={styles.pendingStatus}>Pending</div>
                    )}

                    {child.status === ChildStatusEnum.UNRECOGNIZED && (
                      <IconExclamationTriangle size={24} className={styles.unrecognizedStatus} />
                    )}
                  </div>
                )}
              </Tile>
            ))}

            <Tile
              href="/profile/add-child"
              className={styles.addChildLink}
              onClick={() => logButtonEvent('add_child')}
            >
              <Link to="/profile/add-child">Add Attendee</Link>
            </Tile>
          </section>
        )}

        <MarketingPreferences />
        <div className={styles.bookingButtonWrapper}>
          <BookingButton
            profile={profile}
            onClick={handleRequestSessionClick}
            expand={false}
            className={styles.bookingButton}
          />
        </div>
      </div>

      <div className={styles.support}>
        <div className={styles.supportContent}>
          <p>
            Do you need help?
            <br />
            Our support team is here.
          </p>

          <Button
            onClick={() => logButtonEvent('guardian_contact_us')}
            href="mailto:support@bookpebble.co.uk"
          >
            Contact us
          </Button>
        </div>
      </div>

      <footer>
        <ul className={styles.footerLinks}>
          <li>
            <a
              href="https://www.bookpebble.co.uk/privacy-policy"
              onClick={() => logButtonEvent('privacy_policy_selected')}
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.bookpebble.co.uk/terms"
              onClick={() => logButtonEvent('T&C_selected')}
              target="_blank"
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <Link onClick={() => logButtonEvent('logout_selected')} to="/logout">
              Log out
            </Link>
          </li>
        </ul>
      </footer>
    </SettingsPage>
  );
};
