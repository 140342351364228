import { gql } from '@apollo/client';

const submitSessionBooking = gql`
  mutation CreateSessionBooking(
    $child: UUID!
    $date: Date!
    $sessionType: BookingSessionTypeEnum
    $paymentExemptionReason: PaymentExemptionReasonEnum
    $promotion: UUID
    $room: UUID
    $additionalNotes: String
    $customSessionType: UUID
    $bookingToCancel: UUID
    $forceRequest: Boolean
  ) {
    submitSessionBooking(
      child: $child
      date: $date
      sessionType: $sessionType
      paymentExemptionReason: $paymentExemptionReason
      promotion: $promotion
      room: $room
      additionalNotes: $additionalNotes
      customSessionType: $customSessionType
      bookingToCancel: $bookingToCancel
      forceRequest: $forceRequest
    ) {
      bookingRequest {
        id
      }
    }
  }
`;

export default submitSessionBooking;
