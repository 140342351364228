import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconActivity = ({ className, color = 'currentColor', size = 32, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.406 25.2146C21.3513 26.9831 18.6903 27.9694 15.9957 27.9694C9.66323 27.9694 4.47601 22.7659 4.47601 16.3381V15.2838H6.39596C6.6991 15.2838 7.00225 15.1137 7.13699 14.8416C7.27172 14.5695 7.27172 14.2295 7.1033 13.9574L4.3076 9.57011C4.00445 9.05996 3.19605 9.05996 2.85922 9.57011L0.130874 13.9234C-0.037542 14.1954 -0.0375421 14.5355 0.0971909 14.8076C0.231924 15.0797 0.535073 15.2497 0.838222 15.2497H2.75817V16.3381C2.75817 23.6842 8.68642 29.6699 15.962 29.6699C19.0945 29.6699 22.126 28.5476 24.4839 26.507C24.8544 26.2009 24.8881 25.6567 24.5849 25.2826C24.3154 24.9425 23.7765 24.9085 23.406 25.2146Z"
      fill={color}
    />
    <path
      d="M31.1525 17.4202C31.4556 17.4202 31.7588 17.5902 31.8935 17.8623C32.0282 18.1344 32.0282 18.4745 31.9272 18.7465L29.1315 23.1338C28.9967 23.4059 28.6936 23.5419 28.4241 23.5419C28.1547 23.5419 27.8852 23.3719 27.7168 23.1338L24.9211 18.7465C24.7527 18.4745 24.7527 18.1344 24.8874 17.8623C25.0221 17.5902 25.3253 17.4202 25.6284 17.4202H27.5484V16.3658C27.5484 9.938 22.3612 4.7345 16.0287 4.7345C13.334 4.7345 10.6731 5.72078 8.61839 7.48929C8.24788 7.79538 7.70895 7.76137 7.4058 7.38726C7.10265 7.01316 7.13633 6.469 7.50685 6.16291C9.86467 4.12232 12.8962 3 16.0287 3C23.3043 3 29.2325 8.98572 29.2325 16.3318V17.4202H31.1525Z"
      fill={color}
    />
    <path
      d="M22.4107 16.4709C22.4107 12.5937 19.3118 9.46484 15.4719 9.46484C11.6321 9.46484 8.5332 12.5937 8.5332 16.4709C8.5332 20.348 11.6321 23.4769 15.4719 23.4769C19.3118 23.4769 22.4107 20.348 22.4107 16.4709ZM10.251 16.4709C10.251 13.546 12.6089 11.1993 15.4719 11.1993C18.335 11.1993 20.6929 13.58 20.6929 16.4709C20.6929 19.3617 18.335 21.7424 15.4719 21.7424C12.6089 21.7424 10.251 19.3957 10.251 16.4709Z"
      fill={color}
    />
    <path
      d="M18.6785 15.2509C19.0153 14.9108 19.0153 14.3667 18.6785 14.0266C18.3417 13.6865 17.8027 13.6865 17.4659 14.0266L15.3439 16.1692L14.266 15.0809C13.9292 14.7408 13.3902 14.7408 13.0534 15.0809C12.7166 15.421 12.7166 15.9651 13.0534 16.3052L14.7376 18.0057C14.906 18.1758 15.1081 18.2438 15.3439 18.2438C15.5796 18.2438 15.7817 18.1417 15.9502 18.0057L18.6785 15.2509Z"
      fill={color}
    />
  </svg>
);
