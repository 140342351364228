import { FC } from 'react';
import { Button, Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import styles from './ExternalNurseryErrorDialog.module.scss';

interface IExternalNurseryErrorDialogProps {
  show: boolean;
  onGoBack: () => void;
}

export const ExternalNurseryErrorDialog: FC<IExternalNurseryErrorDialogProps> = ({
  show,
  onGoBack,
}) => {
  return (
    <>
      <Dialog
        isVisible={show}
        title="No match"
        onClose={undefined}
        menu={
          <>
            <Button onClick={onGoBack} expand>
              Go back
            </Button>
            <br />
            <a href="mailto:support@bookpebble.co.uk?subject=Busy Bees records do not match">
              Contact support
            </a>
          </>
        }
        zIndex="6"
      >
        <div className={styles.message}>
          Your name and/or email address do not match our records for Busy Bees. Please go back and
          try again or contact Pebble support.
        </div>
      </Dialog>
      <ModalBackdrop isVisible={show} onClick={() => null} opacity={0.7} />
    </>
  );
};
