import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { addDays, format } from 'date-fns';

import { useProfile } from 'app/profile';
import { CalendarDay } from 'models';
import { errorCodes, hasError } from 'graphql/constants';
import getBookings from 'graphql/queries/getBookings';
import getAvailability from 'graphql/queries/getAvailability';
import getPriceList from 'graphql/queries/getPriceList';
import { getChildPricingByDay } from 'utilities/date';

/**
 *
 * @param {Object} options An object with settings for the hook
 * @returns
 */
export const useNurseryCalendar = ({
  canInstantBook = false,
  childIds = [],
  startDate = defaultStartDate,
  endDate = defaultEndDate,
  fetchPricing = false,
  isFullDaySessionsOnly = false,
}) => {
  const { profile } = useProfile();

  const children = useMemo(
    () => profile.children.filter((c) => childIds.includes(c.id)),
    [profile, childIds],
  );

  const [calendar, setCalendar] = useState(makeCalendar());

  const { data: availabilityData, loading: availabilityLoading } = useQuery(getAvailability, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      childId: childIds[0],
    },
    skip: !childIds?.[0],
  });

  const { data: bookingsData, loading: bookingsLoading } = useQuery(getBookings, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });

  const {
    data: pricingData,
    loading: pricingDataLoading,
    error: pricingDataError,
  } = useQuery(getPriceList, {
    variables: {
      childId: childIds?.[0],
    },
    skip: childIds.length === 0 || !fetchPricing,
  });

  const bookingsForSelectedChildren = useMemo(() => {
    const bookings = bookingsData?.bookings ?? [];

    return bookings.filter((booking) => childIds.includes(booking.child.id));
  }, [bookingsData, childIds]);

  const priceListMissing =
    hasError(pricingDataError, errorCodes.NURSERY_PRICE_LIST_MISSING) || // Nursery has no prices set up
    Object.values(calendar).every((d) => !d.hasPricing); // Child is outside of the range set up by nursery

  useEffect(() => {
    const priceList = pricingData?.childPriceListById?.primaryNurseryPriceList;

    if (availabilityData?.availabilityForGuardian?.dates?.length === 0) return;

    setCalendar(
      makeCalendar(
        availabilityData?.availabilityForGuardian,
        bookingsForSelectedChildren,
        priceList,
        canInstantBook,
        isFullDaySessionsOnly,
      ),
    );
  }, [
    bookingsForSelectedChildren,
    availabilityData,
    pricingData,
    canInstantBook,
    isFullDaySessionsOnly,
  ]);

  return {
    loading: availabilityLoading || bookingsLoading || pricingDataLoading,
    calendar,
    priceListMissing,
  };
};

const BOOKING_CALENDAR_WEEKS = 24; // How many weeks to display inside the valendar view

const defaultStartDate = new Date(); // Today
const defaultEndDate = addDays(new Date(), BOOKING_CALENDAR_WEEKS * 7);

const getCanInstantBookValue = (day, isFullDaySessionsOnly, canInstantBook) => {
  return isFullDaySessionsOnly
    ? canInstantBook && day.availability.amSessionsAvailable && day.availability.pmSessionsAvailable
    : canInstantBook;
};

const makeCalendarDay = (dateStamp) => ({
  dateStamp,
  availability: [],
  bookings: [],
  pricing: null,
});

const makeCalendar = (
  availabilityData = {},
  bookings = [],
  priceList = [],
  canInstantBook = false,
  isFullDaySessionsOnly = false,
) => {
  const availabilityByDay = availabilityData?.dates ?? [];
  const { earliestInstantBookingSession, earliestBookingRequestSession } = availabilityData;
  const dateBucket = {};

  if (!Array.isArray(availabilityByDay))
    throw new TypeError(
      'Invalid availabilityByDay, expected Array but received + ',
      availabilityByDay,
    );

  if (!Array.isArray(bookings))
    throw new TypeError('Invalid bookings, expected Array but received + ', bookings);

  availabilityByDay.forEach((availability) => {
    const dateStamp = availability.date;

    if (!dateBucket[dateStamp]) {
      dateBucket[dateStamp] = makeCalendarDay(dateStamp);
    }

    dateBucket[dateStamp].availability = availability;
    dateBucket[dateStamp].pricing = getChildPricingByDay(priceList, dateStamp);
  });

  bookings.forEach((booking) => {
    const dateStamp = booking.date;

    if (!dateBucket[dateStamp]) {
      dateBucket[dateStamp] = makeCalendarDay(dateStamp);
    }

    dateBucket[dateStamp].bookings = [...dateBucket[dateStamp].bookings, booking];
  });

  // Convert each item in the bucket into a `CalendarDay`
  for (const [dateStamp, day] of Object.entries(dateBucket)) {
    dateBucket[dateStamp] = new CalendarDay({
      ...day,
      canInstantBook: getCanInstantBookValue(day, isFullDaySessionsOnly, canInstantBook),
      earliestInstantBookingSession,
      earliestBookingRequestSession,
    });
  }

  return dateBucket;
};
