import { useState } from 'react';

import { useAuth } from 'app/auth';
import { useWizard } from 'app/wizard';
import { DefaultAppPage } from 'layouts';
import { Button, Dialog, ErrorMessage, Input } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import styles from './ResetPassword.module.scss';
import { StickyNav } from 'ui/StickyNav';

export const ResetPassword = () => {
  const { controls, formData, onChange } = useWizard();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordConfirmationError, setShowPasswordConfirmationError] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const auth = useAuth();
  const isPasswordValid =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){0,})(?!.*\s).{8,}$/.test(
      formData.newPassword,
    );
  const isPasswordMatch = formData.newPassword === confirmedPassword;
  const isStepValid = formData.otpCode.length >= 6 && isPasswordValid && isPasswordMatch;

  const handleNextClick = async (e) => {
    e.preventDefault();

    if (!isStepValid) {
      return;
    }

    const { mobileNumber, newPassword, otpCode } = formData;

    try {
      await auth.forgotPasswordSubmit(mobileNumber, otpCode, newPassword);
      controls.goToNextScreen();
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  const closeErrorDialog = () => setErrorMessage(null);

  return (
    <DefaultAppPage
      title="Reset Password"
      footerActions={[
        <StickyNav>
          <Button onClick={handleNextClick} disabled={!isStepValid} expand key="reset">
            Reset Password
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      <div className={styles.instructions}>
        To verify your identity, please enter the 6-digit code that was sent to{' '}
        {formData.mobileNumber}
      </div>

      <Input
        label="Enter the OTP code you received"
        className={styles.inputField}
        placeholder="OTP code"
        value={formData.otpCode}
        onChange={(e) => onChange('otpCode', e.target.value)}
        maxLength={6}
        aria-label="OTP code"
      />

      <Input
        label="Enter a new password"
        className={styles.inputField}
        placeholder="New Password"
        type="password"
        name="newPassword"
        onChange={(e) => {
          onChange('newPassword', e.target.value);
          setShowPasswordError(false);
        }}
        onBlur={() => {
          setShowPasswordError(!isPasswordValid);
        }}
        value={formData.newPassword}
        aria-label="New password"
      />
      <ErrorMessage isVisible={showPasswordError}>
        Must contain at least 8 characters (upper and lower case) and include at least one number.
      </ErrorMessage>
      <p className={styles.instructions}>
        Your password must contain 8 characters (upper and lower case) and include at least one
        number.
      </p>

      <Input
        label="Confirm password"
        className={styles.inputField}
        placeholder="Passwords must match"
        type="password"
        value={confirmedPassword}
        onChange={(e) => {
          setConfirmedPassword(e.target.value);
          setShowPasswordConfirmationError(false);
        }}
        onBlur={() => {
          setShowPasswordConfirmationError(!isPasswordMatch);
        }}
      />
      <ErrorMessage isVisible={showPasswordConfirmationError}>Password doesn't match.</ErrorMessage>

      <Dialog
        isVisible={errorMessage !== null}
        title="Error"
        onClose={closeErrorDialog}
        menu={
          <Button onClick={closeErrorDialog} expand>
            Try again
          </Button>
        }
      >
        {errorMessage || 'The OTP code you entered was invalid. Please try again.'}
      </Dialog>
      <ModalBackdrop isVisible={errorMessage !== null} onClick={closeErrorDialog} />
    </DefaultAppPage>
  );
};
