import { useState } from 'react';

import { useAuth } from 'app/auth';
import { useWizard } from 'app/wizard';
import { DefaultAppPage } from 'layouts';
import { Button, Dialog, ErrorMessage, TelInput } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import styles from './EnterPhone.module.scss';
import { logButtonEvent } from 'app/amplitude';
import { StickyNav } from 'ui/StickyNav';

export const EnterPhone = () => {
  const { controls, formData, onChange } = useWizard();

  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const auth = useAuth();

  const isStepValid = /[0-9]{10,}/.test(formData?.mobileNumber);

  const handleNextClick = async (e) => {
    e.preventDefault();

    setShowErrors(false);
    logButtonEvent('reset_password');

    if (!isStepValid) {
      setShowErrors(true);
      return;
    }

    try {
      await auth.forgotPasswordInitiate(formData.mobileNumber);
      controls.goToNextScreen();
    } catch (error) {
      setErrorMessage(error?.message);
      console.log('# error!', error);
    }
  };

  const closeErrorDialog = () => setErrorMessage(null);

  return (
    <DefaultAppPage
      title="Forgot Password"
      footerActions={[
        <StickyNav>
          <Button onClick={handleNextClick} visuallyDisabled={!isStepValid} expand key="reset">
            Reset Password
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      <TelInput
        label="Enter your phone number and we’ll send you a secure code to reset your
      password"
        autoFocus={formData?.mobileNumber === ''}
        placeholder="Mobile number"
        value={formData.mobileNumber}
        onChange={(e) => {
          onChange('mobileNumber', e.target.value);
        }}
        onBlur={() => logButtonEvent('enter_mobile_forgot')}
        aria-label="Mobile number"
      />

      <ErrorMessage isVisible={!isStepValid && showErrors} className={styles.errorMessage}>
        You must enter a valid phone number
      </ErrorMessage>

      <Dialog isVisible={errorMessage !== null} title="Error" onClose={closeErrorDialog}>
        {errorMessage || 'The OTP code you entered was invalid. Please try again.'}
      </Dialog>
      <ModalBackdrop isVisible={errorMessage !== null} onClick={closeErrorDialog} />
    </DefaultAppPage>
  );
};
