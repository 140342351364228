import { gql } from '@apollo/client';

const getCustomSessionsByChild = gql`
  query GetCustomSessionsByChild($nursery: UUID!, $child: UUID!) {
    customSessionTypesByChild(nursery: $nursery, child: $child) {
      id
      name
      startTime
      endTime
      availabilityType
      price
      requestOnly
    }
  }
`;

export default getCustomSessionsByChild;
