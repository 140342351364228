import { useStateMachine } from 'little-state-machine';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ExternalNurseryErrorDialog } from 'app/components/ExternalNurseryErrorDialog';
import { ExternalNurseryReferralDialog } from 'app/components/ExternalNurseryReferralDialog';
import { deleteState } from '../state/updateFormState';

export const ErrorWrapper: FC = ({ children }) => {
  const history = useHistory();
  const { actions, state } = useStateMachine({ deleteState });
  const goBack = () => {
    actions.deleteState(undefined);

    history.push({
      // eslint-disable-next-line
      // @ts-ignore
      pathname: state.logoutUser ? `/logout` : `/onboarding/guardian-info`,
      search: history.location.search,
    });
  };
  return (
    <>
      {children}

      <ExternalNurseryErrorDialog
        // eslint-disable-next-line
        // @ts-ignore
        show={state.showExternalNurseryErrorDialog}
        onGoBack={goBack}
      />
      <ExternalNurseryReferralDialog
        // eslint-disable-next-line
        // @ts-ignore
        show={state.showExternalNurseryReferralDialog}
        onGoBack={goBack}
      />
    </>
  );
};
