import { gql } from '@apollo/client';

const getPaymentMethods = gql`
  query GetPaymentMethods {
    guardian {
      id
      paymentMethods {
        id
        type
        created
        isDefault
        card {
          brand
          last4
        }
      }
    }
  }
`;

export default getPaymentMethods;
