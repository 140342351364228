import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import emailValidator from 'email-validator';
import { useStateMachine } from 'little-state-machine';

import { DefaultAppPage } from 'layouts';
import { useProfile } from 'app/profile';
import { Button, ErrorMessage, Input } from 'ui';
import { usePageViewTracker, useQueryParams } from 'hooks';
import getOrganizations from 'graphql/queries/getOrganizations';
import { updateState } from 'pages/Onboarding/state/updateFormState';
import { useCreateGuardian } from 'pages/Onboarding/hooks/useCreateGuardian';

import styles from './EnterGuardianInfo.module.scss';
import { logButtonEvent } from 'app/amplitude';
import { StickyNav } from 'ui/StickyNav';
import { useMemo } from 'react';

export const EnterGuardianInfo = (props) => {
  const { history } = props;
  const { organisation } = useQueryParams();
  const { profile } = useProfile();

  usePageViewTracker('onboarding/guardian_info');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const { actions, state } = useStateMachine({ updateState });

  const [createGuardian, { loading: guardianCreateLoading }] = useCreateGuardian();

  const { data } = useQuery(getOrganizations);

  const orgIsExternallyManaged = useMemo(() => {
    if (!organisation) {
      return false;
    }
    const org = data?.nurseryOrganizationsForGuardians?.find((org) => org.id === organisation);
    return org?.isManagedByExternalSystem ?? false;
  }, [data, organisation]);

  const onSubmit = (data) => {
    actions.updateState({
      ...data,
    });

    logButtonEvent('add_guardian_fullname', { fullName: data.fullName });
    logButtonEvent('add_guardian_email', { email: data.email });

    if (organisation && orgIsExternallyManaged) {
      if (profile.anyChildrenExternallyManaged && profile.externalSystemId) {
        history.push({ pathname: '/onboarding/review-children', search: history.location.search });
      } else {
        const guardianDetails = {
          fullName: data.fullName,
          email: data.email,
          children: [],
          organizationId: organisation,
        };
        createGuardian({ variables: guardianDetails });
      }
      return;
    }

    if (organisation && !orgIsExternallyManaged) {
      actions.updateState({
        establishment: 'nursery',
      });
      history.push({ pathname: `/onboarding/pick-nursery`, search: history.location.search });
      return;
    }

    history.push({
      pathname: `/onboarding/child-establishment`,
      search: history.location.search,
    });
  };

  return (
    <DefaultAppPage
      title="A little bit about you"
      useHistoryBack
      footerActions={[
        <StickyNav>
          <Button
            type="submit"
            form="guardian-form"
            expand
            key="next"
            visuallyDisabled={guardianCreateLoading}
            disabled={guardianCreateLoading}
            isWorking={guardianCreateLoading}
          >
            Next
          </Button>
        </StickyNav>,
      ]}
    >
      <main>
        <form id="guardian-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputRow}>
            <Input
              label="What’s your full name?"
              className={styles.inputField}
              placeholder="Full name"
              aria-label="Full name"
              {...register('fullName', {
                validate: {
                  hasFirstAndLastNames: (v) =>
                    v.split?.(' ').filter(Boolean).length > 1 ||
                    'Please enter a first and last name',
                },
              })}
              defaultValue={state.fullName}
            />
            {errors?.fullName && (
              <ErrorMessage
                isVisible={!isValid && 'fullName' in errors}
                className={styles.errorMessage}
                role="alert"
              >
                {errors?.fullName?.message}
              </ErrorMessage>
            )}
          </div>
          <div className={styles.inputRow}>
            <Input
              label="What’s your e-mail address?"
              className={styles.inputField}
              placeholder="name@mail.com"
              aria-label="name@mail.com"
              {...register('email', {
                validate: {
                  validEmail: (v) => emailValidator.validate(v) || 'The email address is not valid',
                },
              })}
              defaultValue={state.email}
            />
            {errors?.email && (
              <ErrorMessage
                isVisible={!isValid && 'email' in errors}
                className={styles.errorMessage}
                role="alert"
              >
                {errors?.email?.message}
              </ErrorMessage>
            )}
          </div>
        </form>
      </main>
    </DefaultAppPage>
  );
};

EnterGuardianInfo.buttonEvent = 'onboarding_guardian_info';
