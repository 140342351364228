import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconHelpCircle = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.03223 9.00008C9.26733 8.33175 9.73138 7.76819 10.3422 7.40921C10.953 7.05024 11.6711 6.91902 12.3694 7.03879C13.0677 7.15857 13.701 7.52161 14.1573 8.06361C14.6136 8.60561 14.8633 9.2916 14.8622 10.0001C14.8622 12.0001 11.8622 13.0001 11.8622 13.0001"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9424 17H11.9524"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
