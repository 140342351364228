import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePageViewTracker, useQueryParams } from 'hooks';
import { DefaultAppPage } from 'layouts';
import { Button, Pebbles, RadioButtonList } from 'ui';

import { useSessionDetails } from 'pages/SessionDetails/useSessionDetails';
import styles from './SessionSelection.module.scss';

export const SessionSelection = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const bookingIds = useMemo(() => queryParams?.sessionIds?.split?.(','), [queryParams]);
  const { loading, sessions } = useSessionDetails(bookingIds);
  const [selectedSession, setSelectedSession] = useState(null);

  usePageViewTracker('cancel_session/select_session');

  const handleNextClick = (e) => {
    e.preventDefault();

    if (!selectedSession) return;

    history.push(`/cancel-session/${selectedSession}`);
  };

  const childOptions = useMemo(() => mapSessionsToOptions(sessions), [sessions]);

  return (
    <DefaultAppPage
      title="Who are you cancelling for?"
      useHistoryBack
      footerActions={
        <Button disabled={selectedSession === null} onClick={handleNextClick} expand key="next">
          Next
        </Button>
      }
    >
      <main className={styles.container}>
        <p>Select which child(ren) you’d like to cancel this session for.</p>

        {loading && sessions.length === 0 && (
          <Pebbles className={styles.loadingIndicator} fillSpace />
        )}

        <RadioButtonList
          options={childOptions}
          onChange={setSelectedSession}
          value={selectedSession}
          className={styles.options}
        />
      </main>
    </DefaultAppPage>
  );
};

const mapSessionsToOptions = (sessions) =>
  sessions.map((session) => ({
    label: session?.child?.fullName,
    value: session.id,
  }));
