import { useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { format, parseISO, isValid } from 'date-fns';
import { useMutation } from '@apollo/client';

import { useProfile } from 'app/profile';
import { useQueryParams } from 'hooks';
import {
  BookingRequestTypeEnum,
  BookingSessionTypeEnum,
  PaymentExemptionReasonEnum,
} from 'graphql/constants';
import { useNurseryData } from 'graphql/hooks/useNurseryData';
import submitSessionBooking from 'graphql/mutations/submitSessionBooking';
import { SERVER_DATE_FORMAT } from 'utilities/date';
import { QUERY_PARAM_CHECKMARK } from 'utilities/string';

import { BookingContext } from './';

export const useBookingParamsInternal = () => {
  const { profile } = useProfile();
  const history = useHistory();
  const [sessionBookingSubmit, { loading: submitBookingSaving }] = useMutation(
    submitSessionBooking,
    {
      onError(error) {
        // handle all type of External Errors (.e.g BusyBees)
        // `EXTERNAL_SESSION_BOOKING_ERROR`: Error creating session booking in external booking system API.
        // `REFER_TO_NURSERY`: Call to external booking system returned a 404, either child or room not recognised.
        // `INVALID_EXTERNAL_DATA_SESSION_BOOKING_CREATE`: Error updating availability at instant booking creation.
        const externalErrors = [
          'EXTERNAL_SESSION_BOOKING_ERROR',
          'REFER_TO_NURSERY',
          'INVALID_EXTERNAL_DATA_SESSION_BOOKING_CREATE',
        ];
        if (error.graphQLErrors.some((error) => externalErrors.includes(error.message))) {
          throw new Error('EXTERNAL_BOOKING_ERROR');
        } else {
          throw new Error('Error creating booking');
        }
      },
    },
  );
  const searchParams = useQueryParams();

  const date = useMemo(() => {
    const parsedDate = parseISO(searchParams.date);
    return isValid(parsedDate) ? parsedDate : undefined;
  }, [searchParams.date]);

  const childIds = useMemo(() => searchParams.children?.split?.(',') ?? [], [searchParams]);

  const selectedChildren = useMemo(
    () => childIds.map((id) => profile.children.find((c) => c.id === id)),
    [profile, childIds],
  );

  const allowInstantBook = useMemo(
    () => selectedChildren.every((child) => child.canBook),
    [selectedChildren],
  );

  const childNames = useMemo(
    () => selectedChildren?.map?.((c) => c.fullName.split(' ')[0]) ?? [],
    [selectedChildren],
  );

  const nurseryId = useMemo(
    () => searchParams?.nurseryId ?? selectedChildren?.[0]?.primaryNursery?.id,
    [searchParams, selectedChildren],
  );

  const nursery = useNurseryData(nurseryId, selectedChildren?.[0]?.primaryNursery.organization.id);

  const roomId = useMemo(() => searchParams?.roomId ?? null, [searchParams]);

  const promotionId = useMemo(() => searchParams?.promotionId ?? null, [searchParams]);

  const paymentExemptReason = useMemo(
    () => PaymentExemptionReasonEnum[searchParams.paymentExemptReason],
    [searchParams],
  );

  const sessionType = useMemo(
    () => BookingSessionTypeEnum[searchParams.sessionType],
    [searchParams.sessionType],
  );

  const sessionId = useMemo(() => searchParams?.sessionId ?? null, [searchParams]);

  const requestType = useMemo(() => BookingRequestTypeEnum[searchParams.type], [searchParams]);

  const isNonPrimary = useMemo(() => {
    if (childIds.length === 1 && !!roomId) {
      return selectedChildren[0]?.primaryNurseryRoom?.id !== roomId;
    } else {
      return false;
    }
  }, [childIds, selectedChildren, roomId]);

  const buildQueryParams = () => {
    let queryParams = {};

    if (childIds.length > 0) queryParams.children = searchParams.children;
    if (date) queryParams.date = format(date, SERVER_DATE_FORMAT);
    if (sessionType) queryParams.type = sessionType;
    if (requestType) queryParams.type = requestType;
    if (paymentExemptReason) queryParams.paymentExemptReason = paymentExemptReason;
    if (sessionId) queryParams.sessionId = sessionId;

    return queryParams;
  };

  const hasValidQueryParams =
    date !== undefined &&
    requestType !== undefined &&
    selectedChildren.length > 0 &&
    (sessionType !== undefined || sessionId !== undefined);

  const replaceQueryParams = (stringOrValue, value) => {
    let queryParams = buildQueryParams();

    if (typeof stringOrValue === 'string') {
      // Update data by single key
      queryParams[stringOrValue] = value;
    } else {
      // Update data with an object
      queryParams = {
        ...queryParams,
        ...stringOrValue,
      };
    }

    history.replace(`${history.location.pathname}?${stringify(queryParams)}`);
  };

  const createBooking = async (overrideParams = {}) => {
    let bookingRequestError = false;

    for (const childId of childIds) {
      const bookingDetails = {
        date: format(date, 'yyyy-MM-dd'),
        ...(sessionId
          ? {
              customSessionType: sessionId,
            }
          : {
              sessionType,
            }),
        child: childId,
        room: roomId,
        paymentExemptionReason: paymentExemptReason,
        promotion: promotionId,
        ...overrideParams,
      };

      try {
        const { data } = await sessionBookingSubmit({
          variables: bookingDetails,
        });
        // return booking id on success.
        return data?.submitSessionBooking?.bookingRequest?.id;
      } catch (error) {
        bookingRequestError = error;
        break;
      }
    }

    if (bookingRequestError) {
      throw bookingRequestError;
    }
  };

  const getBookingMetadata = (overrideParams = {}) => {
    const childId = childIds.length > 0 ? childIds[0] : null;
    if (childId) {
      return {
        date: format(date, 'yyyy-MM-dd'),
        ...(sessionId
          ? {
              customSessionType: sessionId,
            }
          : {
              sessionType,
            }),
        child: childId,
        room: roomId,
        paymentExemptionReason: paymentExemptReason,
        promotion: promotionId,
        ...overrideParams,
      };
    }
  };

  return {
    allowInstantBook,
    childIds,
    childNames,
    date,
    dateStamp: searchParams.date,
    hasValidQueryParams,
    isPaymentExempt:
      paymentExemptReason !== undefined || searchParams.isPaymentExempt === QUERY_PARAM_CHECKMARK,
    nursery,
    paymentExemptReason,
    requestType,
    selectedChildren,
    sessionType,
    sessionId,
    isNonPrimary,
    isPrimaryNursery: !isNonPrimary,
    queryString: stringify(buildQueryParams()),
    replaceQueryParams,
    createBooking,
    submitBookingSaving,
    getBookingMetadata,
  };
};

export const useBookingParams = () => {
  return useContext(BookingContext);
};
