import { BookingType } from 'graphql/generated-types';
import { FC } from 'react';

import styles from './Filters.module.scss';

interface IFiltersProps {
  bookingTypes: string[];
  filter: string;
  setFilter: (filter: string) => void;
}

interface IBookingFilterConfig {
  label: string;
  type: BookingType;
}

const bookingFiltersConfig: { [key: string]: IBookingFilterConfig } = {
  SESSION_PRIMARY: {
    label: 'My Nursery',
    type: BookingType.SessionPrimary,
  },
  SESSION_NON_PRIMARY: {
    label: 'Other Nurseries',
    type: BookingType.SessionNonPrimary,
  },
  MARKETPLACE_ACTIVITY_BOOKING: {
    label: 'Activities',
    type: BookingType.MarketplaceActivityBooking,
  },
};

export const Filters: FC<IFiltersProps> = ({ bookingTypes, filter, setFilter }) => {
  return (
    <ul className={styles.filters}>
      <li>
        <input
          className={styles.input}
          type="radio"
          id={BookingType.All}
          value={BookingType.All}
          name="filters"
          checked={filter === BookingType.All}
          onChange={(e) => setFilter(e.target.value)}
        />
        <label className={styles.label} htmlFor={BookingType.All}>
          All Bookings
        </label>
      </li>
      {/* To maintain preferred order */}
      {Object.keys(bookingFiltersConfig).map((bookingType) => {
        if (!bookingTypes.includes(bookingType)) {
          return null;
        }

        const { type, label } = bookingFiltersConfig[bookingType] || {};

        return (
          <li key={type}>
            <input
              className={styles.input}
              type="radio"
              id={type}
              value={type}
              name="filters"
              checked={filter === type}
              onChange={(e) => setFilter(e.target.value)}
            />
            <label
              className={styles.label}
              htmlFor={type}
              data-cy={label.replace(' ', '-').toLowerCase()}
            >
              {label}
            </label>
          </li>
        );
      })}
    </ul>
  );
};
