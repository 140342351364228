import { logButtonEvent } from 'app/amplitude';

export const trackAction = (propertyToEdit: string): void => {
  switch (propertyToEdit) {
    case 'fullName':
      logButtonEvent('child_name_updated');
      break;
    case 'birthDate':
      logButtonEvent('child_DOB_updated');
      break;
    case 'guardianRelationship':
      logButtonEvent('child_relationship_updated');
      break;
    case 'emergencyContactName':
      logButtonEvent('child_emergency_contact_name_updated');
      break;
    case 'emergencyContactRelationship':
      logButtonEvent('child_emergency_contact_relationship_updated');
      break;
    case 'doctorFullName':
      logButtonEvent('child_GP_Name_updated');
      break;
    case 'doctorSurgeryName':
      logButtonEvent('child_GP_Surgery_updated');
      break;
    case 'emergencyContactNumber':
      logButtonEvent('child_emergency_contact_mob_updated');
      break;
    case 'doctorSurgeryContactNumber':
      logButtonEvent('child_GP_contact_updated');
      break;
    case 'medicalNotes':
      logButtonEvent('child_conditions_updated');
      break;
    default:
      const prefix = 'child_nursery';
      logButtonEvent(`${prefix}_${propertyToEdit}_updated`);
  }
};
