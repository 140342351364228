import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconUser = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 12 15"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.73095 1.20301C8.08706 0.507828 7.18773 0.125 6.19509 0.125C5.19715 0.125 4.29484 0.505511 3.65393 1.19639C3.00607 1.89487 2.69041 2.84417 2.76453 3.86923C2.91144 5.89157 4.45036 7.5367 6.19509 7.5367C7.93982 7.5367 9.47609 5.8919 9.62532 3.86989C9.70043 2.85409 9.38278 1.90679 8.73095 1.20301Z"
      fill={color}
    />
    <path
      d="M10.9842 14.125H1.22881C1.10112 14.1268 0.974666 14.0977 0.858648 14.0399C0.742631 13.9821 0.639967 13.897 0.558126 13.7908C0.377984 13.5575 0.305373 13.239 0.359138 12.9168C0.593046 11.5111 1.32304 10.3302 2.4704 9.50127C3.48973 8.76538 4.78093 8.36035 6.1065 8.36035C7.43207 8.36035 8.72327 8.76568 9.7426 9.50127C10.89 10.3299 11.62 11.5108 11.8539 12.9165C11.9076 13.2387 11.835 13.5572 11.6549 13.7905C11.5731 13.8968 11.4704 13.9819 11.3544 14.0398C11.2384 14.0976 11.1119 14.1267 10.9842 14.125Z"
      fill={color}
    />
  </svg>
);
