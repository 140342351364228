import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { logButtonEvent } from 'app/amplitude';
import { useProfile } from 'app/profile';
import getMarketingPreferences from 'graphql/queries/getMarketingPreferences';
import marketingOptInMutation from 'graphql/mutations/updateMarketingOptIn';
import { Checkbox } from 'ui';

import styles from './MarketingPreferences.module.scss';

export const MarketingPreferences = () => {
  const { profile } = useProfile();
  const history = useHistory();
  const { data, loading: isLoading } = useQuery(getMarketingPreferences);
  const [saveMarketingPreferences, { loading: isSaving }] = useMutation(marketingOptInMutation);

  const isEnabled = data?.guardian?.marketingOptIn ?? false;

  const handleCheckboxClick = async (e) => {
    let errorHappened = false;

    const variables = {
      guardianId: profile.id,
      marketingOptIn: !isEnabled,
    };

    try {
      const { errors } = await saveMarketingPreferences({
        variables,
      });

      if (errors) {
        console.warn('[AccountDetails] Failed to update', errors);
        errorHappened = true;
      }
    } catch (error) {
      console.warn('[AccountDetails] try/catch failed', error);
      errorHappened = true;
    }

    if (errorHappened) {
      history.push('/error', { from: '/profile' });
    }
    const track_status = !isEnabled ? 'on' : 'out';
    logButtonEvent(`marketing_consent_opt_in_${track_status}`);
  };

  // Don't render component if guardian has no email address
  if (!profile.email) return null;

  return (
    <div className={styles.container}>
      <label className={styles.maxWidthWrapper}>
        <p>Be the first to hear about special offers and updates</p>

        <div className={styles.checkbox}>
          <Checkbox
            disabled={isLoading || isSaving}
            checked={isEnabled}
            onClick={handleCheckboxClick}
            aria-label="Be the first to hear about special offers and updates"
          />
        </div>
      </label>
    </div>
  );
};
