import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { logButtonEvent } from 'app/amplitude';
import { useWizard } from 'app/wizard';
import getNurseries from 'graphql/queries/getNurseries';
import { DefaultAppPage } from 'layouts';
import { Button, Pebbles, RadioButtonList } from 'ui';

import styles from './PickNurseryScreen.module.scss';

export const PickNurseryScreen = ({
  isPrefetched,
  nurseryId,
  organizationId,
  organizationName,
}) => {
  const { controls, onChange } = useWizard();

  const { loading, data } = useQuery(getNurseries, {
    variables: {
      organizationId,
    },
  });

  const nurseryOptions = useMemo(() => mapNurseriesToRadioOptions(data?.nurseries ?? []), [data]);
  const onNurseryChange = (id) => {
    onChange('nurseryId', id);
    logButtonEvent('nursery_selected');
  };
  const onNext = () => {
    logButtonEvent('nursery_selected_next');
    controls.goToNextScreen();
  };
  return (
    <DefaultAppPage
      title={`Which ${organizationName} childcare provider are you enrolled at?`}
      footerActions={[
        <Button onClick={onNext} disabled={!nurseryId} expand key="next">
          Next
        </Button>,
      ]}
      useHistoryBack
    >
      {loading && <Pebbles className={styles.loadingIndicator} fillSpace />}

      <div className={styles.options}>
        <RadioButtonList
          options={nurseryOptions}
          onChange={onNurseryChange}
          value={nurseryId}
          className={styles.radioList}
        />
      </div>

      <div className={styles.supportSection}>
        <p>Your nursery is not in the list?</p>
        <a href="mailto:support@bookpebble.co.uk">Contact us</a>
      </div>
    </DefaultAppPage>
  );
};

PickNurseryScreen.buttonEvent = 'onboarding_nursery_select';

/**
 * Converts a list of nurseries to a format that is required for the RadioButtonList components
 *
 * @param {Array} nurseries A list of nurseries to convert to RadioListOptions
 */
const mapNurseriesToRadioOptions = (nurseryList) =>
  nurseryList.map((nursery) => ({
    label: nursery.name,
    value: nursery.id,
  }));
