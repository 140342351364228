import classNames from 'classnames';
import styles from './Icon.module.scss';

export const BlockIndividual = ({ className = '', color = '#0E206F', size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(styles.icon, className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8002 1.7998L4.20019 1.7998C2.87471 1.7998 1.80019 2.87432 1.80019 4.1998L1.80019 15.7998C1.80019 17.1253 2.87471 18.1998 4.20019 18.1998L15.8002 18.1998C17.1257 18.1998 18.2002 17.1253 18.2002 15.7998L18.2002 4.1998C18.2002 2.87432 17.1257 1.7998 15.8002 1.7998ZM3.00019 4.1998C3.00019 3.53706 3.53745 2.9998 4.20019 2.9998L15.8002 2.9998C16.4629 2.9998 17.0002 3.53706 17.0002 4.1998L17.0002 15.7998C17.0002 16.4625 16.4629 16.9998 15.8002 16.9998L4.20019 16.9998C3.53745 16.9998 3.00019 16.4625 3.00019 15.7998L3.00019 4.1998ZM15.0001 5.02009C15.0001 4.75793 14.7876 4.54541 14.5255 4.54541L5.29778 4.54541C5.03562 4.54541 4.8231 4.75793 4.8231 5.02009L4.8231 6.61499C4.8231 6.87715 5.03562 7.08967 5.29778 7.08967L14.5255 7.08967C14.7876 7.08967 15.0001 6.87715 15.0001 6.61499L15.0001 5.02009Z"
      fill={color}
    />
  </svg>
);
