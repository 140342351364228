import classNames from 'classnames';
import { BackButton } from 'app/components';
import { useWindowHeight } from 'hooks';

import styles from './IllustratedInfoPage.module.scss';

export const IllustratedInfoPage = ({
  children,
  className,
  headerLink,
  illustration,
  footer,
  title,
  welcomeTitle,
  onBack,
  useHistoryBack,
}) => {
  const { boxStyle } = useWindowHeight();

  const hasBackButton = typeof onBack === 'function' || useHistoryBack;

  const illustrationOnly = illustration && !hasBackButton;

  return (
    <div className={classNames(styles.container, className)} style={boxStyle}>
      {headerLink && (
        <div className={styles.header}>
          <div className={styles.headerLink}>{headerLink}</div>
        </div>
      )}

      {hasBackButton && (
        <div className={classNames(styles.buttonWrapper)}>
          <BackButton
            className={styles.BackButton}
            useHistoryBack={useHistoryBack}
            onClick={onBack}
          />
          {illustration}
        </div>
      )}

      {illustrationOnly && <div className={styles.illustration}>{illustration}</div>}

      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        {welcomeTitle && <div className={styles.welcomeTitle}>{welcomeTitle}</div>}

        {children}
      </div>

      <div className={styles.footer}>{footer}</div>
    </div>
  );
};
