import { useContext, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';

import { AuthContext } from 'app/auth';
import { useQueryParams } from 'hooks';
import { FullScreen, Pebbles } from 'ui';
import { useProfile } from 'app/profile';

/**
 * Higher-order component that accepts an auth object. If the user is authenticated, redirects
 * the user to the specified `redirectTo` route.
 */
export const AnonymousRoute = ({ component: Component, redirectTo, ...rest }) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { redirect: queryParamRedirect, ...params } = useQueryParams();
  const { requiresOnboarding, isInitializing } = useProfile();

  const isAuthenticated = authContext.isAuthenticated();
  const redirectIsAbsolute = queryParamRedirect?.startsWith('http');

  const newParams = qs.stringify({
    ...params,
    redirect: queryParamRedirect || rest.location.pathname,
  });

  useEffect(() => {
    if (authContext.isInitializing || isInitializing) {
      return;
    }

    if (isAuthenticated && redirectIsAbsolute) {
      if (requiresOnboarding && rest.path !== '/onboarding') {
        history.push({
          pathname: '/onboarding',
          search: newParams,
        });
        return;
      }

      window.location.href = `${queryParamRedirect}?${qs.stringify(params)}`;
    }
  }, [isAuthenticated, redirectIsAbsolute, requiresOnboarding, isInitializing]);

  if (authContext.isInitializing) {
    return (
      <FullScreen>
        <Pebbles fillSpace />s
      </FullScreen>
    );
  }

  if (isAuthenticated && redirectIsAbsolute) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return authContext.isAuthenticated() === false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: queryParamRedirect || redirectTo,
              search: qs.stringify(params),
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

AnonymousRoute.defaultProps = {
  redirectTo: '/home',
};
