import { useHistory } from 'react-router-dom';

/**
 * Searchs the current history object and returns stored form data if found, or
 * defaultFormData if there was nothing found.
 *
 * @param {Object} defaultFormData The default formData to use if none was found
 * @returns {Object}
 */
export const useInitialFormData = (defaultFormData = null) => {
  const history = useHistory();

  // Return formData from history object if it exists, otherwise return `defaultFormData`
  // if it's provided, or lastly return an empty object if all else fails
  return history.location?.state?.formData ?? defaultFormData ?? {};
};
