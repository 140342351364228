import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { formatPrice } from 'utilities/string';
import { formatTimeString } from 'utilities/date';

import styles from './SessionCard.module.scss';

interface ISessionCardProps {
  title: string;
  startTime: string;
  endTime: string;
  price?: number;
  subtitle?: string;
  Button?: ReactNode;
  Footer?: ReactNode;
  message?: string;
  isUnavailable?: boolean;
  footer?: ReactNode;
  showSessionTimes: boolean;
}

export const SessionCard: FC<ISessionCardProps> = ({
  title,
  startTime,
  endTime,
  price,
  subtitle,
  Button,
  Footer,
  message,
  isUnavailable,
  showSessionTimes,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.unavailable]: isUnavailable,
      })}
    >
      <div className={styles.main}>
        <div className={styles.details}>
          <h2 className={styles.title}>{title}</h2>
          {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}

          <div className={styles.timeAndPrice}>
            {showSessionTimes && (
              <>
                {formatTimeString(startTime)} - {formatTimeString(endTime)}{' '}
              </>
            )}
            {price && ` (${formatPrice(price)})`}
          </div>
          {message && <div className={styles.message}>{message}</div>}
        </div>
        {Button && <div className={styles.button}>{Button}</div>}
      </div>
      {Footer && <div className={styles.footer}>{Footer}</div>}
    </div>
  );
};
