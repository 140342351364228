import { ChangeEvent, FC, MouseEvent, useState } from 'react';

import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Input } from 'ui';
import { isValidBirthDate } from 'utilities/date';

import styles from './EditBirthDate.module.scss';
import { StickyNav } from 'ui/StickyNav';

interface IEditBirthDateProps {
  initialValue: string;
  isSaving: boolean;
  postToApi: (value: Record<string, string>) => void;
  onBack?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const EditBirthDate: FC<IEditBirthDateProps> = ({
  initialValue,
  isSaving,
  onBack,
  postToApi,
}) => {
  const [newValue, setNewValue] = useState(initialValue);
  const [showErrors, setShowErrors] = useState<boolean | null>(null);
  const isScreenValid = isValidBirthDate(newValue) && newValue !== '';

  const handleSubmitClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setShowErrors(false);

    if (!isScreenValid) {
      setShowErrors(true);

      return;
    }

    const [day, month, year] = newValue.split(' | ');

    postToApi({ birthDate: [year, month, day].join('-') });
  };

  return (
    <DefaultAppPage
      title="Date of birth"
      onBack={onBack}
      footerActions={
        <StickyNav>
          <Button
            expand
            onClick={handleSubmitClick}
            visuallyDisabled={!isScreenValid || isSaving}
            isWorking={isSaving}
            data-testid="editBirthDate__submitButton"
          >
            Update birth date
          </Button>
        </StickyNav>
      }
    >
      <Input
        label="The correct format is DD | MM | YYYY"
        placeholder="dd | mm | yyyy"
        autoFocus
        value={newValue}
        mask="99 | 99 | 9999"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value)}
        type="text"
        data-testid="editBirthDate__input"
      />

      <ErrorMessage isVisible={showErrors} className={styles.errorMessage}>
        This is not a valid date. Please make sure you've used the format DD | MM | YYYY
      </ErrorMessage>
    </DefaultAppPage>
  );
};
