import {
  Maybe,
  NurseryType,
  NurseryRoomType,
  NurseryPaymentAccountType,
  NurseryOrganizationType,
  CustomSessionTypeModelType,
} from 'graphql/generated-types';
import {
  INurseryOrganizationModel,
  NurseryOrganization,
} from 'models/NurseryOrganization/NurseryOrganization';
import { getFields } from 'models/utils';

interface IDefaultFields {
  readonly id: Maybe<string>;
  readonly addressLine1: string;
  readonly addressLine2: string;
  readonly allowOnlyFullDaySessions: boolean;
  readonly bookingHoldThresholdDays: number;
  readonly city: string;
  readonly country: string;
  readonly hubspotAccountId: string;
  readonly isBookingHoldEnabled: boolean;
  readonly isCustomSessionBookingsEnabled: boolean;
  readonly showCustomSessionTimes: boolean;
  readonly isNonPrimaryBookingEnabled: boolean;
  readonly isVisible: boolean;
  readonly name: string;
  readonly nurseryRooms: NurseryRoomType[];
  readonly organization: NurseryOrganizationType | Partial<NurseryOrganizationType>;
  readonly organizationName: string;
  readonly paymentAccount: NurseryPaymentAccountType;
  readonly paymentsEnabled: boolean;
  readonly phoneNumber: string;
  readonly postCode: string;
  readonly sessionTypes: CustomSessionTypeModelType[];
}

export interface INurseryModel extends Omit<IDefaultFields, 'organization'> {
  readonly isLoaded: boolean;
  readonly organization: INurseryOrganizationModel;
  readonly __originalData: NurseryType | Partial<NurseryType>;
}

const defaultFields: IDefaultFields = {
  addressLine1: '',
  addressLine2: '',
  allowOnlyFullDaySessions: false,
  bookingHoldThresholdDays: 0,
  city: '',
  country: '',
  hubspotAccountId: '',
  id: null,
  isBookingHoldEnabled: false,
  isCustomSessionBookingsEnabled: false,
  showCustomSessionTimes: false,
  isVisible: false,
  isNonPrimaryBookingEnabled: false,
  name: '',
  nurseryRooms: [],
  organization: {},
  organizationName: '',
  paymentAccount: {},
  paymentsEnabled: false,
  phoneNumber: '',
  postCode: '',
  sessionTypes: [],
};

export const Nursery = (nurseryData?: Maybe<NurseryType>): INurseryModel => {
  const fields = getFields<NurseryType, IDefaultFields>(nurseryData || null, defaultFields);

  return {
    ...fields,
    isLoaded: !!nurseryData?.id && typeof nurseryData.id === 'string',
    organization: NurseryOrganization(nurseryData?.organization),
    __originalData: nurseryData || {},
  };
};
