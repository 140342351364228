import classNames from 'classnames';

import styles from './Icon.module.scss';

export const NonPrimaryBooking = ({
  className = '',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1938 17H2.81178C1.81141 17 1 16.2154 1 15.2481V5.35902C1 4.7679 1.30567 4.21977 1.81697 3.89197L7.50795 0.286155C8.11373 -0.0953848 8.8918 -0.0953848 9.49202 0.286155L15.183 3.89197C15.6943 4.2144 16 4.7679 16 5.35902V15.2481C16.0055 16.2154 15.1941 17 14.1938 17Z"
      fill={color}
    />
    <path
      d="M11.9996 6.25488V14.0786H10.4722L6.92297 9.63932V14.0786H5V6.25488H6.50541L10.0547 10.6722V6.25488H11.9996Z"
      fill="white"
    />
  </svg>
);
