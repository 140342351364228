import { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'app/auth';

import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Input } from 'ui';

import { logButtonEvent } from 'app/amplitude';

import styles from './VerifyAccount.module.scss';
import { usePageViewTracker } from 'hooks';
import { StickyNav } from 'ui/StickyNav';

import { getGtagPageTracker } from '../../utilities/gtag';
import { useGtagTracking } from 'hooks/useGtagTracking';

const RESEND_CODE_DELAY = 120000;

export const VerifyAccount = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const isValidFormat = /[0-9]{4}/.test(verificationCode);
  const history = useHistory();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const [showResendCodeCopy, setResendCodeCopy] = useState(false);

  const { trackGtagEvent } = useGtagTracking();

  let disableResendTimeout;

  // clear timeout on unmount
  useEffect(
    () => () => {
      window.clearTimeout(disableResendTimeout);
    },
    [disableResendTimeout],
  );

  const handleReSendVerification = async () => {
    await authContext.resendVerificationCode();
    setResendCodeCopy(true);
    disableResendTimeout = window.setTimeout(() => {
      setResendCodeCopy(false);
    }, RESEND_CODE_DELAY);
  };

  const getPhoneNumber = () => {
    //Case when the user just signed up
    return authContext.getUserPhoneNumber();
  };

  const handleVerify = async () => {
    setShowError(false);
    if (verificationCode.length < 6) return;
    try {
      logButtonEvent('verification_code_complete');
      await authContext.confirmSignup(getPhoneNumber(), verificationCode);
      window.clearTimeout(disableResendTimeout);
      trackGtagEvent('guardian_complete_registration');
      history.push({
        pathname: '/login',
        search: location?.search,
        state: location?.state,
      });
    } catch (error) {
      setShowError(true);
      console.log('[VerifyAccount] Verificaiton Code Failed');
    }
  };

  return (
    <DefaultAppPage
      title="We’ve sent you a verification code. Please wait a few minutes for it to come through."
      footerActions={[
        <StickyNav>
          <Button
            onClick={() => {
              handleVerify();
              logButtonEvent('OTP_verify');
            }}
            disabled={!isValidFormat}
            key="verify"
            expand
          >
            Verify
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack={false}
    >
      <main className={styles.container}>
        <Input
          autoFocus
          label={`Enter the 6-digit code sent to ${getPhoneNumber()}.`}
          className={styles.inputField}
          placeholder="OTP Code"
          onChange={(e) => {
            setVerificationCode(e.target.value);
          }}
          onBlur={() => logButtonEvent('OTP_enter')}
          value={verificationCode}
          type="tel"
          maxLength={6}
        />

        <ErrorMessage isVisible={showError} className={styles.error}>
          This code is not valid. Please try again
        </ErrorMessage>

        <div className={styles.supportInfo}>
          <p>
            Trouble receiving your code? Check your phone number above is correct and try again.
            <br />
            <br />
            Still having issues? Contact our support team{' '}
            <a href="mailto:support@bookpebble.co.uk" className={styles.supportLink}>
              support@bookpebble.co.uk
            </a>
          </p>
        </div>

        <button
          disabled={showResendCodeCopy}
          className={styles.resendVerificationLink}
          onClick={() => {
            handleReSendVerification();
          }}
          onBlur={() => logButtonEvent('OTP_resend')}
        >
          Resend verification code
        </button>

        <ErrorMessage isVisible={showResendCodeCopy} className={styles.error}>
          Verification code resent. Please wait a few minutes for it to come through.
        </ErrorMessage>
      </main>
    </DefaultAppPage>
  );
};
