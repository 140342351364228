import { useNurseryData } from 'graphql/hooks';

/**
 * Checks a given nursery to see if it has payments enabled
 *
 * @param {String} nurseryId The nurseryId to search for
 *
 * @returns {Boolean}
 */
export const useNurseryCanAcceptPayments = (nurseryId) => {
  const nursery = useNurseryData(nurseryId);

  return nursery?.paymentsEnabled === true ?? false;
};
