import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconClock = ({ className, color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.25 27.25C21.8774 27.25 27.25 21.8774 27.25 15.25C27.25 8.62258 21.8774 3.25 15.25 3.25C8.62258 3.25 3.25 8.62258 3.25 15.25C3.25 21.8774 8.62258 27.25 15.25 27.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.25 10.1069V15.2498L17.8214 17.8212"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
