import { FC, MouseEventHandler, ReactNode, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { motion, MotionStyle } from 'framer-motion';

import { usePortal, useWindowHeight } from 'hooks';

import styles from './ModalBackdrop.module.scss';

interface ModalBackdropProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  isVisible: boolean;
  children?: ReactNode;

  preventBackgroundScroll?: boolean;
  opacity?: number;
  zIndex?: number;
}

export const ModalBackdrop: FC<ModalBackdropProps> = ({
  children,
  isVisible,
  onClick,
  opacity,
  preventBackgroundScroll,
  zIndex,
}) => {
  const target = usePortal('modal-backdrops');
  const { height } = useWindowHeight();
  const backdropStyle: MotionStyle = useMemo(
    () => ({
      pointerEvents: isVisible ? 'initial' : 'none',
      height: isVisible ? height : 0,
      ...(zIndex && isVisible && { zIndex }),
    }),
    [isVisible, zIndex, height],
  );

  const animate = {
    opacity: isVisible ? opacity : 0,
  };

  useEffect(() => {
    if (!preventBackgroundScroll) return;

    if (isVisible) {
      document.body.classList.add(styles.preventScroll);
    } else {
      document.body.classList.remove(styles.preventScroll);
    }
  }, [isVisible, preventBackgroundScroll]);

  const content = (
    <motion.div
      className={styles.container}
      initial={false}
      animate={animate}
      style={backdropStyle}
      onClick={onClick}
    >
      {children}
    </motion.div>
  );

  return createPortal(content, target);
};
