import { Route as PublicRoute, Switch } from 'react-router-dom';

import { AnonymousRoute, PrivateRoute } from 'app/auth';
import * as Pages from 'pages';

const Routes = () => (
  <Switch>
    <AnonymousRoute exact path="/" component={Pages.Landing} redirectTo="/home" />
    <AnonymousRoute path="/login" component={Pages.Login} />
    <AnonymousRoute path="/welcome" component={Pages.Welcome} />

    <PublicRoute path="/logout" component={Pages.Logout} />
    <PublicRoute path="/forgot-password" component={Pages.ForgotPassword} />
    <PublicRoute path="/register" component={Pages.Register} />
    <PublicRoute path="/verify" component={Pages.VerifyAccount} />
    <PublicRoute path="/error" component={Pages.Error} />
    <PublicRoute path="https://www.bookpebble.co.uk/terms" target="_blank" />
    <PublicRoute path="https://www.bookpebble.co.uk/privacy-policy" target="_blank" />
    <PublicRoute path="/cookies-policy" component={Pages.CookiesPolicy} />

    <PrivateRoute path="/home" component={Pages.Home} />
    <PrivateRoute path="/session-or-marketplace" component={Pages.SessionOrMarketplace} />
    <PublicRoute path="/activities" component={Pages.OnboardingIntroActivities} />
    <PrivateRoute path="/onboarding" component={Pages.Onboarding} />
    <PrivateRoute path="/booking" component={Pages.Booking} />
    <PrivateRoute path="/session" component={Pages.SessionDetails} />
    <PrivateRoute path="/marketplace" component={Pages.MarketPlaceActivities} />
    <PrivateRoute path="/cancel-session" component={Pages.CancelSession} />
    <PrivateRoute path="/support" component={Pages.Support} />
    <PrivateRoute path="/confirmation" component={Pages.AwaitingConfirmation} />
    <PrivateRoute path="/all-done" component={Pages.AllDone} />
    <PrivateRoute path="/profile" component={Pages.Profile} />

    {/* Catch-all route must be the last route inside of the Switch */}
    <PublicRoute component={Pages.FourOhFour} />
  </Switch>
);

export default Routes;
