import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { IconTimes, IconCheck } from 'icons';

import styles from './ErrorHighlighter.module.scss';

export const ErrorHighlighter = ({ children, isVisible, wobbleAmount, isValid }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.stop();

    if (isVisible || isValid) {
      controls.start({
        scale: [1.5, 1.5, 1],
        rotate: [
          wobbleAmount,
          -wobbleAmount,
          wobbleAmount,
          -wobbleAmount,
          wobbleAmount,
          -wobbleAmount,
          0,
        ], // Wobble wobble 🦃
        opacity: 1,
        transition: {
          duration: 0.8,
        },
      });
    } else {
      // Shrink and fade out
      controls.start({
        opacity: 0,
        x: 0,
        scale: 0,
      });
    }

    return controls.stop;
  }, [isVisible, isValid, controls, wobbleAmount]);

  return (
    <div className={styles.container}>
      {children}

      <div className={styles.errorIconArea}>
        <motion.div initial={false} animate={controls} originX={0.5} originY={0.5}>
          {!isValid ? (
            <IconTimes size={18} className={styles.errorIcon} />
          ) : (
            <IconCheck size={18} className={styles.checkIcon} />
          )}
        </motion.div>
      </div>
    </div>
  );
};

ErrorHighlighter.defaultProps = {
  wobbleAmount: 9,
  isValid: false,
};
