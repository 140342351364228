import { FC, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import { IconPencil } from 'icons';

import styles from './AccountDetailsTile.module.scss';

interface IAccountDetailsTileProps {
  className?: string;
  label?: ReactNode;
  value?: ReactNode;
  onEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const AccountDetailsTile: FC<IAccountDetailsTileProps> = ({
  className,
  label,
  onEdit,
  value,
}) => (
  <div
    className={classNames(styles.container, className)}
    role="region"
    aria-label={`${label} ${value}`}
  >
    <div className={styles.tileContent}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
    {!!onEdit && (
      <button className={styles.editButton} onClick={onEdit} aria-label={`Edit ${label}`}>
        <IconPencil size={16} />
      </button>
    )}
  </div>
);
