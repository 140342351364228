import { gql } from '@apollo/client';

const getCheckoutSession = gql`
  query GetCheckoutSessionMetadata($checkoutSessionId: String!) {
    getCheckoutSessionMetadata(checkoutSessionId: $checkoutSessionId) {
      mode
      metadata
    }
  }
`;

export default getCheckoutSession;
