import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconHome = ({
  className = '',
  color = 'currentColor',
  size = 24,
  stroke = null,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 3.60742L4.25 9.82964V19.6074C4.25 20.0789 4.4373 20.5311 4.7707 20.8645C5.1041 21.1979 5.55628 21.3852 6.02778 21.3852H9V13.6074H16V21.3852H18.4722C18.9437 21.3852 19.3959 21.1979 19.7293 20.8645C20.0627 20.5311 20.25 20.0789 20.25 19.6074V9.82964L12.25 3.60742Z"
      fill={color}
    />
    <path
      d="M4.25 9.82964L3.78954 9.23763C3.60685 9.37972 3.5 9.5982 3.5 9.82964H4.25ZM12.25 3.60742L12.7105 3.01541C12.4396 2.80476 12.0604 2.80476 11.7895 3.01541L12.25 3.60742ZM4.7707 20.8645L4.24037 21.3948L4.24037 21.3948L4.7707 20.8645ZM9 21.3852V22.1352C9.41421 22.1352 9.75 21.7994 9.75 21.3852H9ZM9 13.6074V12.8574C8.58579 12.8574 8.25 13.1932 8.25 13.6074H9ZM16 13.6074H16.75C16.75 13.1932 16.4142 12.8574 16 12.8574V13.6074ZM16 21.3852H15.25C15.25 21.7994 15.5858 22.1352 16 22.1352V21.3852ZM19.7293 20.8645L20.2596 21.3948L20.2596 21.3948L19.7293 20.8645ZM20.25 9.82964H21C21 9.5982 20.8931 9.37972 20.7105 9.23763L20.25 9.82964ZM4.71046 10.4217L12.7105 4.19944L11.7895 3.01541L3.78954 9.23763L4.71046 10.4217ZM5 19.6074V9.82964H3.5V19.6074H5ZM5.30103 20.3342C5.10828 20.1414 5 19.88 5 19.6074H3.5C3.5 20.2778 3.76632 20.9208 4.24037 21.3948L5.30103 20.3342ZM6.02778 20.6352C5.75519 20.6352 5.49377 20.5269 5.30103 20.3342L4.24037 21.3948C4.71442 21.8689 5.35737 22.1352 6.02778 22.1352V20.6352ZM9 20.6352H6.02778V22.1352H9V20.6352ZM9.75 21.3852V13.6074H8.25V21.3852H9.75ZM9 14.3574H16V12.8574H9V14.3574ZM15.25 13.6074V21.3852H16.75V13.6074H15.25ZM18.4722 20.6352H16V22.1352H18.4722V20.6352ZM19.199 20.3342C19.0062 20.5269 18.7448 20.6352 18.4722 20.6352V22.1352C19.1426 22.1352 19.7856 21.8689 20.2596 21.3948L19.199 20.3342ZM19.5 19.6074C19.5 19.88 19.3917 20.1414 19.199 20.3342L20.2596 21.3948C20.7337 20.9208 21 20.2778 21 19.6074H19.5ZM19.5 9.82964V19.6074H21V9.82964H19.5ZM11.7895 4.19944L19.7895 10.4217L20.7105 9.23763L12.7105 3.01541L11.7895 4.19944Z"
      fill={color}
    />
  </svg>
);
