import { useStateMachine } from 'little-state-machine';

import { DefaultAppPage } from 'layouts';
import { logButtonEvent } from 'app/amplitude';
import { ChildTile } from './components/ChildTile';
import { updateState } from 'pages/Onboarding/state/updateFormState';

import styles from './ReviewChildrenScreen.module.scss';
import { Button } from 'ui';
import { usePageViewTracker } from 'hooks';

export const ReviewChildrenScreen = (props) => {
  const { history } = props;
  const { state } = useStateMachine({ updateState });

  usePageViewTracker('onboarding/review_children');

  const onEditChild = (index) => () => {
    logButtonEvent('review_child_edit');
    history.push({
      pathname: `/onboarding/child-info/${index}`,
      search: history.location.search,
    });
  };

  const handleAddChildClick = () => {
    const nextId = state.temporaryChildData.length;
    history.push({
      pathname: `/onboarding/child-info/${nextId}`,
      search: history.location.search,
    });
    logButtonEvent('review_add_child');
  };

  const handleReviewSaveClick = () => {
    history.push({
      pathname: `/onboarding/opt-in`,
      search: history.location.search,
    });
    logButtonEvent('review_next');
  };

  return (
    <DefaultAppPage
      title="Review and add another child"
      footer={
        <div className={styles.footer}>
          {!state.isExternallyManaged && (
            <Button
              variant="outline"
              expand
              aria-label="Add another child"
              onClick={handleAddChildClick}
            >
              + Add another child
            </Button>
          )}
          <Button
            onClick={handleReviewSaveClick}
            expand
            key="next"
            disabled={state.temporaryChildData.length === 0}
          >
            Next
          </Button>
        </div>
      }
      useHistoryBack
    >
      <main>
        <div className={styles.children}>
          {state.temporaryChildData.map((child, index) => (
            <ChildTile
              child={child}
              fullName={child.fullName}
              roomName={child.roomName}
              key={index}
              onEditClick={onEditChild(index)}
              isExternallyManaged={state.isExternallyManaged}
            />
          ))}
        </div>
        {state.isExternallyManaged && (
          <div className={styles.externallyManagedMessage}>
            If the information is incorrect, please contact Pebble for help
            <br />
            <a href="mailto:support@bookpebble.co.uk?subject=Child profile details incorrect">
              Contact us
            </a>
          </div>
        )}
      </main>
    </DefaultAppPage>
  );
};

ReviewChildrenScreen.defaultProps = {
  title: 'Review child details',
};
