import { useState, useContext } from 'react';
import { useHistory, Link, withRouter } from 'react-router-dom';
import { AuthContext } from 'app/auth';
import { useProfile } from 'app/profile';
import { DefaultAppPage } from 'layouts';
import { usePageViewTracker, useQueryParams } from 'hooks';
import { Button, ErrorMessage, Input, StickyNav, TelInput } from 'ui';

import styles from './Login.module.scss';
import { logButtonEvent } from 'app/amplitude';

/**
 * Simple login form. Displays a form, and
 */
export const Login = withRouter(({ location, onLogin }) => {
  const { redirect } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { refetch: fetchProfile } = useProfile();

  usePageViewTracker('login');

  const handleLogin = (e) => {
    e.preventDefault();

    setShowError(false);
    setLoading(true);

    // Exit early if no user name
    if (phoneNumber.trim() === '') return;

    login();
  };

  const login = async () => {
    try {
      await authContext.login(phoneNumber, password);
      await fetchProfile();
      // No need to redirect here as taken care of in Anonymous/Private route HOCs.
      return;
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        console.log('[Login] Login Failed: the user is not confirmed');
        authContext.resendVerificationCode(phoneNumber);
        history.push({
          pathname: '/verify',
          search: location?.search,
        });
      } else if (error.code === 'PasswordResetRequiredException') {
        console.log('[Login] Login Failed: the user requires a new password');
        history.push('/forgot-password');
      } else {
        console.log('[Login] Login Failed: ', error);
        setLoading(false);
        setShowError(true);
      }
    }
  };

  const isDisabled = phoneNumber.trim().length === 0 || password.trim().length === 0;

  return (
    <DefaultAppPage useHistoryBack>
      <main className={styles.container}>
        <form onSubmit={handleLogin}>
          <h1 className={styles.title}>Welcome to Pebble!</h1>

          <TelInput
            name="mobile"
            className={styles.inputField}
            placeholder="Mobile number"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            onBlur={logButtonEvent('mobile_login')}
            value={phoneNumber}
            aria-label="Mobile number"
            data-testid="login__phone"
            disabled={loading}
          />

          <Input
            className={styles.inputField}
            placeholder="Password"
            type="password"
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onBlur={() => logButtonEvent('password_login')}
            value={password}
            aria-label="Password"
            data-testid="login__password"
            disabled={loading}
          />

          <ErrorMessage
            isVisible={showError}
            data-testid="login__error"
            className={styles.errorMessage}
          >
            This username or password is not valid. Please try again
          </ErrorMessage>

          <div className={styles.forgotPassword}>
            <Link to="/forgot-password" onClick={() => logButtonEvent('forgot_password')}>
              Forgot password
            </Link>
          </div>

          <div className={styles.forgotPassword}>
            Don't have an account yet?{' '}
            <Link
              to={{
                pathname: '/register',
                state: { from: location?.state?.from },
                search: location?.state?.from?.search || location?.search,
              }}
              onClick={() => logButtonEvent('register')}
            >
              Register
            </Link>
          </div>

          <StickyNav>
            <footer>
              <Button
                onClick={() => logButtonEvent('login_CTA')}
                type="submit"
                disabled={isDisabled || loading}
                expand
                isWorking={loading}
                data-testid="login__submit"
              >
                Log In
              </Button>
            </footer>
          </StickyNav>
        </form>
      </main>
    </DefaultAppPage>
  );
});
