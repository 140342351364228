import { gql } from '@apollo/client';

const cancelBooking = gql`
  mutation CancelBooking(
    $id: UUID!
    $cancellationReason: BookingCancellationEnum!
    $cancellationDetails: String
  ) {
    cancelSessionBooking(
      id: $id
      cancellationReason: $cancellationReason
      cancellationDetails: $cancellationDetails
    ) {
      bookingRequest {
        id
        status
        cancellationReason
        cancellationDetails
        cancelledAt
      }
    }
  }
`;

export default cancelBooking;
