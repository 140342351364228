import { FC, useState } from 'react';
import classNames from 'classnames';
import { ApolloError, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { DefaultAppPage } from 'layouts';
import { NonPrimaryBooking, IconHome } from 'icons';
import { formatTimeString } from 'utilities/date';
import { BookingButton } from 'app/components/BookingButton';
import { NoBookings, SessionCard } from 'app/components';
import getAvailabilityForGuardianAtNonPrimary from 'graphql/queries/getAvailabilityForGuardianAtNonPrimary';
import { formatPrice, QUERY_PARAM_CHECKMARK } from 'utilities/string';
import {
  CustomSessionTypesForChildType,
  GetAvailabilityForGuardianAtNonPrimaryQuery,
  GetCustomSessionTypeByIdQuery,
  NurseryRoomWithAvailabilityAndPriceForDateType,
  SessionBookingType,
} from 'graphql/generated-types';
import { useBookingParams } from '../../useBookingParams';
import { usePageViewTracker, useQueryParams } from 'hooks';

import styles from './NearbyNurseries.module.scss';
import getCustomSessionTypeById from 'graphql/queries/getCustomSessionTypeById';
import { logButtonEvent } from 'app/amplitude';

interface IBookingReviewQueryParams {
  date: string;
  children: string;
  type: string;
  paymentExemptReason: string;
  sessionId: string;
  isPaymentExempt?: string;
  roomId?: string;
  nurseryId?: string;
}

export const NearbyNurseries: FC = () => {
  const [availabilities, setAvailabilities] =
    useState<GetAvailabilityForGuardianAtNonPrimaryQuery['availabilityForGuardianAtNonPrimary']>();
  const [session, setSession] = useState<CustomSessionTypesForChildType | null>();
  const { childId, date, nurseryId, sessionType, sessionId, paymentExemptReason, bookingType } =
    useQueryParams();

  usePageViewTracker('booking/nearby_nurseries');

  const history = useHistory();
  const { nursery } = useBookingParams();

  const { loading: availabilitiesLoading } = useQuery<GetAvailabilityForGuardianAtNonPrimaryQuery>(
    getAvailabilityForGuardianAtNonPrimary,
    {
      onCompleted: (data) => {
        if (data?.availabilityForGuardianAtNonPrimary) {
          setAvailabilities(data.availabilityForGuardianAtNonPrimary);
        }
      },
      onError: (error: ApolloError) => {
        console.log(error);
      },
      variables: {
        nurseryId,
        childId,
        date,
        sessionType,
      },
    },
  );

  const { loading: customSessionLoading } = useQuery<GetCustomSessionTypeByIdQuery>(
    getCustomSessionTypeById,
    {
      onCompleted: (data) => {
        if (data?.customSessionTypeById) {
          setSession(data?.customSessionTypeById);
        }
      },
      onError: (error: ApolloError) => {
        console.log(error);
      },
      variables: {
        customSessionType: sessionId,
        child: childId,
      },
      skip: !childId && !sessionId,
    },
  );

  const navigateToBookingReview = (queryParams: IBookingReviewQueryParams) => {
    if (paymentExemptReason) {
      queryParams.isPaymentExempt = QUERY_PARAM_CHECKMARK;
    }

    history.push(`/booking/review?${queryString.stringify(queryParams)}`);
  };

  const handlePrimarySessionButtonClick = (
    primarySession?: CustomSessionTypesForChildType | null,
  ) => {
    if (bookingType === 'REQUEST') {
      logButtonEvent('primary_nursery_request');
    }
    return navigateToBookingReview({
      date: date as string,
      children: childId as string,
      type:
        bookingType === SessionBookingType.Request
          ? SessionBookingType.Request
          : SessionBookingType.Instant,
      paymentExemptReason: paymentExemptReason as string,
      sessionId: primarySession?.id,
    });
  };

  const handleNearbySessionButtonClick = (
    nearbySession: NurseryRoomWithAvailabilityAndPriceForDateType,
    isIntant: boolean,
  ) => {
    if (isIntant) {
      logButtonEvent('nearby_nursery_instant');
    }
    return navigateToBookingReview({
      date: nearbySession.date,
      children: childId as string,
      type: isIntant ? SessionBookingType.Instant : SessionBookingType.Request,
      paymentExemptReason: paymentExemptReason as string,
      sessionId: nearbySession?.customSessionType?.id,
      roomId: nearbySession.id,
      nurseryId: nearbySession?.nursery?.id,
    });
  };

  return (
    <DefaultAppPage
      useResponsiveCentered={false}
      title={
        <div className={styles.title}>
          <NonPrimaryBooking
            className={styles.sessionTypeIcon}
            size={20}
            role="img"
            aria-label="Non-Primary booking icon"
            focusable="false"
          />{' '}
          <div>Nearby Nurseries</div>
        </div>
      }
      introText={
        <div className={styles.title}>
          Need to book now? Select an Instant Booking at a nursery nearby.
        </div>
      }
      useHistoryBack
      isLoading={availabilitiesLoading || customSessionLoading}
    >
      <main className={styles.container}>
        <div className={styles.primaryNurserySession}>
          <h2>
            <IconHome
              className={styles.sessionTypeIcon}
              size={20}
              role="img"
              aria-label="Primary booking icon"
              focusable="false"
            />

            <span>
              {/* eslint-disable-next-line */}
              {/* @ts-ignore */}
              {nursery?.organization?.name} - {nursery?.name}
            </span>
          </h2>
          <div className={classNames(styles.content)}>
            <div className={styles.details}>
              <h3 className={styles.name}>{session?.name}</h3>

              <div className={styles.timeAndPrice}>
                {formatTimeString(session?.startTime)} - {formatTimeString(session?.endTime)} (
                {formatPrice(session?.price)})
              </div>
            </div>
            <BookingButton
              className={styles.bookingButton}
              onClick={() => handlePrimarySessionButtonClick(session)}
              disabled={false}
              disabledText="Unavailable"
              isInstant={bookingType === SessionBookingType.Instant}
              data-testid="booking-session-selection__session-picker-booking-button"
            />
          </div>
        </div>

        {!availabilities || availabilities?.nurseryRooms?.length === 0 ? (
          <NoBookings
            message={
              <p>
                No instant book sessions available
                <br /> at nearby nurseries.
              </p>
            }
          />
        ) : (
          <div className={styles.nonPrimaryNurserySessions}>
            {availabilities?.nurseryRooms?.map((nearbyNurserySession) => {
              if (!nearbyNurserySession) return null;

              const isIntant = nearbyNurserySession?.availableSessionCount
                ? nearbyNurserySession?.availableSessionCount > 0
                : false;

              return (
                <SessionCard
                  key={nearbyNurserySession?.id}
                  title={`${nearbyNurserySession?.nursery?.organizationName} - ${nearbyNurserySession?.nursery?.name}`}
                  subtitle={nearbyNurserySession?.customSessionType?.name}
                  startTime={nearbyNurserySession?.customSessionType?.startTime}
                  endTime={nearbyNurserySession?.customSessionType?.endTime}
                  price={nearbyNurserySession?.sessionPrice || 0}
                  showSessionTimes={Boolean(nearbyNurserySession.nursery?.showCustomSessionTimes)}
                  Button={
                    <BookingButton
                      onClick={() =>
                        nearbyNurserySession &&
                        handleNearbySessionButtonClick(nearbyNurserySession, isIntant)
                      }
                      isInstant={isIntant}
                      data-testid="booking-session-selection__session-picker-booking-button"
                      disabled={false}
                      disabledText="Unavailable"
                    />
                  }
                />
              );
            })}
          </div>
        )}
      </main>
    </DefaultAppPage>
  );
};
