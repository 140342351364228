import { useState, useEffect, FC } from 'react';

import { Pebbles } from 'ui/Pebbles';
import { AllBookingsForGuardianType, Status } from 'graphql/generated-types';

import { SessionCard } from '../SessionCard';

import styles from './SessionList.module.scss';

const sortByDate = (a: AllBookingsForGuardianType, b: AllBookingsForGuardianType) =>
  new Date(a.date).valueOf() - new Date(b.date).valueOf();

interface ISessionListProps {
  data: AllBookingsForGuardianType[];
  loading: boolean;
  showChildNames: boolean;
}

export const SessionList: FC<ISessionListProps> = ({ data, loading, showChildNames }) => {
  const [sessions, setSessions] = useState<AllBookingsForGuardianType[]>([]);

  useEffect(() => {
    if (data) {
      const sortData = data.slice().sort(sortByDate);
      const filteredData = sortData.filter((session) => session.status !== Status.Moved);
      setSessions(filteredData);
    }
  }, [data]);

  if (loading) {
    return <Pebbles className={styles.loadingIndicator} fillSpace />;
  }

  return (
    <div className={styles.container}>
      {sessions.length === 0 && <div className={styles.noSessions}>Need extra childcare?</div>}

      {sessions.length > 0 && (
        <div className={styles.session}>
          {sessions.map((session, index) => (
            <div className={styles.session} key={index}>
              <SessionCard
                session={session}
                showChildNames={showChildNames}
                data-testid={`home__session-${index + 1}`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
