import { RadioButton, SelectionList } from 'ui';

export const RadioButtonList = ({ onChange, value, renderOption, ...props }) => (
  <SelectionList
    {...props}
    renderSelectable={(option) => (
      <RadioButton
        id={option.label}
        checked={value === option.value}
        onChange={() => onChange(option.value)}
        disabled={option?.disabled ?? false}
      />
    )}
    renderOption={renderOption}
  />
);
