import { FC } from 'react';
import classNames from 'classnames';

import { PaymentType, PromotionType } from 'graphql/generated-types';
import { IconInfoCircle, PonchoPay } from 'icons';
import { StatusLabel } from '../StatusLabel';
import { CardIcon, UCardType } from 'ui/CardIcon';
import { formatPenceToPounds, formatPrice } from 'utilities/string';

import styles from './PaymentStatus.module.scss';
import { format } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';

type TPaymentInfo = PaymentType & {
  statusLabel: string;
  formattedAmount: string;
};

type TPromoInfo = {
  finalAmount: Maybe<number> | undefined;
  originalAmount: Maybe<number> | undefined;
  discount: Maybe<number> | undefined;
  promotion: PromotionType;
};

interface IPaymentStatusProps {
  payment: TPaymentInfo;
  isSubscription?: boolean;
  promotion?: TPromoInfo;
  onIconClick(): void;
  isPaymentExempt?: boolean;
  isActivity?: boolean;
  sessionLabel?: string;
  amountOfSessions?: number;
  sessionCost?: string;
  nextRenewalDate?: string;
  subscriptionCost?: number | null;
}

const getSessionCost = (amountOfSessions = 1, totalPrice: string): number => {
  return Number(totalPrice) / amountOfSessions;
};

export const PaymentStatus: FC<IPaymentStatusProps> = ({
  payment,
  isSubscription = false,
  promotion = {},
  onIconClick,
  isPaymentExempt = false,
  sessionLabel,
  amountOfSessions = 1,
  isActivity = false,
  nextRenewalDate,
  subscriptionCost,
}) => {
  const hasDiscount = Boolean(promotion?.discount);
  const subTotalValue = !hasDiscount
    ? payment.amount
    : isActivity
    ? formatPenceToPounds(promotion.originalAmount)
    : promotion.originalAmount;
  const sessionCost = getSessionCost(amountOfSessions, subTotalValue);
  const cardType = payment?.paymentMethod?.card?.brand ?? 'unknown';
  const isPonchoPay = payment?.processor === 'poncho_pay';

  const getPriceLabel = (price: number) =>
    price === 0 ? <span className={styles.freeLabel}>Free</span> : formatPrice(price);

  return (
    <div className={styles.paymentStatus}>
      <h2>
        Payment status
        <button
          className={styles.infoButton}
          onClick={onIconClick}
          aria-label="payment status information"
        >
          <IconInfoCircle size={16} />
        </button>
      </h2>

      {isActivity && amountOfSessions > 1 && !isSubscription && (
        <>
          <div className={styles.paymentSection}>
            <h3 className={styles.paymentLabel}>Session cost</h3>
            <div className={styles.price}>{getPriceLabel(sessionCost)}</div>
          </div>
          <div className={styles.paymentSection}>
            <h3 className={styles.paymentLabel}>Number of sessions</h3>
            <div className={styles.price}>{amountOfSessions}x</div>
          </div>
          {hasDiscount && (
            <div className={styles.paymentSection}>
              <h3 className={styles.paymentLabel}>Sub-Total</h3>
              <div className={styles.price}>{getPriceLabel(subTotalValue)}</div>
            </div>
          )}
        </>
      )}

      {promotion?.discount && (
        <>
          {(amountOfSessions <= 1 || !isActivity) && (
            <div className={styles.paymentSection}>
              <h3 className={styles.paymentLabel}>Session cost</h3>
              <div className={styles.price}>{getPriceLabel(sessionCost)}</div>
            </div>
          )}
          <h4 className={styles.discountLabel}>Discount code</h4>
          <div className={styles.paymentSection}>
            <span className={styles.discountCode}>{promotion?.promotion?.code}</span>
            <div className={styles.price}>
              -
              {formatPrice(
                isActivity ? formatPenceToPounds(promotion?.discount) : promotion?.discount,
              )}
            </div>
          </div>
        </>
      )}

      {isPaymentExempt ? (
        <div className={styles.exempt}>
          <div className={styles.sessionLabel}>{sessionLabel}</div>
          <div className={styles.price}>{formatPrice(0)}</div>
        </div>
      ) : (
        <>
          <div className={styles.details}>
            <h3
              className={classNames(
                styles.paymentLabel,
                payment.paymentMethod && payment.status && styles.fullWidth,
              )}
            >
              {isSubscription ? `Total monthly cost` : `Total cost`}
            </h3>
            {payment.paymentMethod && (
              <div className={styles.cardNumber}>
                <CardIcon cardType={cardType as UCardType} className={styles.cardIcon} />
                •••• {payment?.paymentMethod?.card?.last4}
              </div>
            )}
            {isPonchoPay && (
              <div className={styles.processor}>
                <PonchoPay />
              </div>
            )}

            {payment.status && (
              <div className={styles.status}>
                <StatusLabel status={payment?.status ?? ''}>{payment?.statusLabel}</StatusLabel>
              </div>
            )}

            {isSubscription && subscriptionCost ? (
              <div className={styles.price} data-testid="total-price">
                {formatPrice(subscriptionCost / 100)}
              </div>
            ) : (
              <div className={styles.price} data-testid="total-price">
                {payment?.formattedAmount === '£0' ? (
                  <span className={styles.freeLabel}>Free</span>
                ) : (
                  payment?.formattedAmount
                )}
              </div>
            )}
          </div>

          {isSubscription && nextRenewalDate && (
            <div className={styles.renewal}>
              Next payment: {format(new Date(nextRenewalDate), 'MMM do yyy')}
            </div>
          )}
        </>
      )}
    </div>
  );
};
