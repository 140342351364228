import { usePageViewTracker } from 'hooks';
import { Route, Switch } from 'react-router-dom';

import * as pages from './pages';

export const Profile = () => {
  usePageViewTracker('profile');
  return (
    <Switch>
      <Route exact path="/profile" component={pages.ProfileHome} />
      <Route path="/profile/detail" component={pages.AccountDetails} />
      <Route path="/profile/child/:id" component={pages.ChildDetails} />
      <Route path="/profile/add-child" component={pages.AddChild} />
    </Switch>
  )
};