import { useStateMachine } from 'little-state-machine';
import { useMutation } from '@apollo/client';
import { captureException, captureMessage } from '@sentry/react';
import qs from 'query-string';

import { DefaultAppPage } from 'layouts';
import { isValidBirthDate } from 'utilities/date';
import { Button } from 'ui';
import { deleteState } from 'pages/Onboarding/state/updateFormState';
import { useCreateGuardian } from 'pages/Onboarding/hooks/useCreateGuardian';
import marketingOptInMutation from 'graphql/mutations/updateMarketingOptIn';

import styles from './OptIn.module.scss';
import illustration from './images/newIllustration.png';
import { usePageViewTracker, useQueryParams } from 'hooks';
import { logButtonEvent } from 'app/amplitude';
import { StickyNav } from 'ui/StickyNav';

const createChildrenArray = (children, state) => {
  const { nurseryId } = state;
  return children.map((initialChild) => {
    // NOTE: Hotfix v3.8.0
    const { roomName, establishment, updateIndex, nurseryName, ...child } = initialChild;
    let birthDateFormatted = null;

    if (child.birthDate && isValidBirthDate(child.birthDate)) {
      const [day, month, year] = child.birthDate.split(' | ').map((n) => n?.padStart(2, 0));
      birthDateFormatted = `${year}-${month}-${day}`;
    }

    return {
      ...child,
      // For these fields the server requires sending null instead of an empty string
      primaryNurseryId: nurseryId === '' ? null : nurseryId,
      primaryNurseryRoomId: child.primaryNurseryRoomId === '' ? null : child.primaryNurseryRoomId,
      birthDate: birthDateFormatted,
    };
  });
};

export const OptIn = (props) => {
  const { history } = props;
  const { actions, state } = useStateMachine({ deleteState });
  const { redirect, ...params } = useQueryParams();

  const redirectIsAbsolute = redirect?.startsWith('http');

  usePageViewTracker('onboarding/opt_in');

  const [createGuardian, { loading: guardianCreateLoading }] = useCreateGuardian();
  const [saveMarketingPreferences, { loading: updateMarketingOptInLoading }] = useMutation(
    marketingOptInMutation,
    {
      onCompleted: () => {
        if (redirectIsAbsolute) {
          window.location.href = `${redirect}?${qs.stringify(params)}`;
        }

        const establishment = state.establishment;
        actions.deleteState();
        if (redirect) {
          history.push({
            pathname: redirect,
            search: qs.stringify(params),
          });
          return;
        }

        if (establishment === 'nursery') {
          history.push('/confirmation');
          return;
        }
        if (establishment === 'activities') {
          history.push('/all-done');
        }
      },
      onError: (error) => {
        captureException(error);
        console.log('[Onboarding] Update Marketing OptIn Error: ', error);
        captureMessage('[Onboarding] Update Marketing OptIn Error: ', error);

        history.push('/error', { from: '/onboarding' });
      },
    },
  );

  const handleOptIn = (optIn) => () => {
    if (optIn) {
      logButtonEvent('opt_in');
    } else {
      logButtonEvent('opt_out');
    }

    if (state.isExternallyManaged) {
      saveMarketingPreferences({
        variables: {
          guardianId: state.guardianId,
          marketingOptIn: optIn,
        },
      });
    } else {
      const children = createChildrenArray(state.temporaryChildData, state);
      const guardianDetails = {
        fullName: state.fullName,
        email: state.email,
        children: children,
        marketingOptIn: optIn,
        ...(state.isExternallyManaged ? { organizationId: state.organizationId } : {}),
      };

      createGuardian({ variables: guardianDetails });
    }
  };

  return (
    <DefaultAppPage
      footerActions={[
        <StickyNav>
          <Button
            expand
            className={styles.allowButton}
            onClick={handleOptIn(true)}
            disabled={guardianCreateLoading || updateMarketingOptInLoading}
            key="allow"
          >
            Allow
          </Button>
          <Button
            variant="outline"
            expand
            onClick={handleOptIn(false)}
            disabled={guardianCreateLoading || updateMarketingOptInLoading}
            key="skip"
          >
            Skip for now
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      <main>
        <div className={styles.illustration}>
          <img
            src={illustration}
            alt="A phone being held in a person's hands with a checkmark displayed on the screen"
          />
        </div>
        <h1 className={styles.message}>Get notified about special offers and discounts.</h1>
      </main>
    </DefaultAppPage>
  );
};

OptIn.buttonEvent = 'onboarding_marketing_preference';
