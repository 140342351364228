import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import { logButtonEvent } from 'app/amplitude';
import { useProfile } from 'app/profile';
import getOrganizations from 'graphql/queries/getOrganizations';
import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Pebbles, RadioButtonList, StickyNav } from 'ui';
import { updateState } from 'pages/Onboarding/state/updateFormState';
import { useCreateGuardian } from 'pages/Onboarding/hooks/useCreateGuardian';

import styles from './PickOrganizationScreen.module.scss';
import { usePageViewTracker } from 'hooks';

/**
 * Converts a list of nurseries to a format that is required for the RadioButtonList components
 *
 * @param {Array} nurseries A list of nurseries to convert to RadioListOptions
 */
const mapOrganizationsToRadioOptions = (nurseryList) =>
  nurseryList.map((nursery) => ({
    label: nursery.name,
    value: nursery.id,
  }));

export const PickOrganizationScreen = (props) => {
  const { history } = props;
  const { data, loading } = useQuery(getOrganizations);
  const [createGuardian, { loading: guardianCreateLoading }] = useCreateGuardian();
  const { profile } = useProfile();

  usePageViewTracker('onboarding/pick_organization');

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { actions, state } = useStateMachine({ updateState });

  const organizationOptions = useMemo(() => {
    return mapOrganizationsToRadioOptions(data?.nurseryOrganizationsForGuardians ?? []);
  }, [data]);

  const onSubmit = async (newData) => {
    actions.updateState(newData);
    logButtonEvent('org_selected_next');

    if (newData.isExternallyManaged || state.isExternallyManaged) {
      if (profile.anyChildrenExternallyManaged && profile.externalSystemId) {
        history.push({ pathname: '/onboarding/review-children', search: history.location.search });
      } else {
        const guardianDetails = {
          fullName: state.fullName,
          email: state.email,
          children: [],
          organizationId: newData.organizationId,
        };

        await createGuardian({ variables: guardianDetails });
      }
    } else {
      history.push({ pathname: `/onboarding/pick-nursery`, search: history.location.search });
    }
  };

  return (
    <DefaultAppPage
      title={<div className={styles.title}>What group is your childcare provider part of?</div>}
      footerActions={
        <StickyNav className={styles.stickyNav}>
          <Button
            type="submit"
            form="organisation-form"
            expand
            key={'next'}
            visuallyDisabled={guardianCreateLoading}
            disabled={guardianCreateLoading}
            isWorking={guardianCreateLoading}
          >
            Next
          </Button>
        </StickyNav>
      }
      useHistoryBack
    >
      {loading && <Pebbles className={styles.loadingIndicator} fillSpace />}

      {!loading && (
        <form id="organisation-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.options}>
            <Controller
              control={control}
              name="organizationId"
              rules={{ required: 'Please select an organisation' }}
              defaultValue={state.organizationId}
              render={({ field: { value, name } }) => (
                <RadioButtonList
                  className={styles.radioList}
                  options={organizationOptions}
                  onChange={(id) => {
                    const organization = data?.nurseryOrganizationsForGuardians.find(
                      (o) => o.id === id,
                    );
                    logButtonEvent('org_selected');
                    [
                      { name: 'organizationId', value: id, options: { shouldValidate: true } },
                      { name: 'organizationName', value: organization.name },
                      {
                        name: 'isExternallyManaged',
                        value: organization.isManagedByExternalSystem,
                      },
                    ].forEach(({ name, value, options }) => setValue(name, value, options));
                  }}
                  value={value}
                  name={name}
                />
              )}
            />
          </div>
          {errors?.organizationId && (
            <ErrorMessage className={styles.errorMessage} role="alert">
              {errors?.organizationId?.message}
            </ErrorMessage>
          )}
        </form>
      )}

      <div className={styles.goToActivitiesSection}>
        <p>Childcare provider not listed?</p>
        <p>
          Find and book activities with us{' '}
          <a href={`/onboarding/child-info${history.location.search}`}>here</a>
        </p>
      </div>
    </DefaultAppPage>
  );
};
// TODO: do we need this????
PickOrganizationScreen.buttonEvent = 'onboarding_org_select';
