import classNames from 'classnames';

import styles from './ListTile.module.scss';

export const ListTile = ({ icon = null, children, className, contentClass }) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles.maxWidthWrapper}>
      {icon && <div className={styles.icon}>{icon}</div>}

      <div className={classNames(styles.content, contentClass)}>{children}</div>
    </div>
  </div>
);
