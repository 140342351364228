import { FC, ReactNode } from 'react';
import { isBefore, format, parse } from 'date-fns';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { IconHome, IconTimes, IconCheck } from 'icons';
import { Pebbles } from 'ui/Pebbles';
import { BookingButton } from 'app/components/BookingButton';
import { SessionCard } from 'app/components/SessionCard';
import { NearbyNurseriesButton } from '../NearbyNurseriesButton/NearbyNurseriesButton';
import styles from './CustomSessionPicker.module.scss';
import { BookingSessionTypeEnum, BookingRequestTypeEnum } from 'graphql/constants';
import {
  CustomSessionTypesForChildType,
  SessionBookingType,
  Scalars,
  SessionBookingStatus,
} from 'graphql/generated-types';

interface IAvailabilityKeys {
  [key: string]: boolean;
}

interface IAvailability extends IAvailabilityKeys {
  amSessionsAvailable: boolean;
  pmSessionsAvailable: boolean;
}

interface IExistingBooking {
  status: SessionBookingStatus;
  sessionId: string;
}

interface ICustomSessionPickerProps {
  isLoading: boolean;
  nurseryId: Scalars['UUID'];
  childId: Scalars['UUID'];
  nurseryOrg: string;
  nurseryName: string;
  sessions: CustomSessionTypesForChildType[];
  handleBookingButtonClick(sessionId: string, requestType: SessionBookingType): void;
  availability: IAvailability;
  existingBookings: IExistingBooking[];
  allowInstantBookings: boolean;
  earliestInstantBookingSession: Date;
  earliestBookingRequestSession: Date;
  date: Date;
  isNonPrimaryBookingEnabled: boolean;
  paymentsEnabled: boolean;
  showSessionTimes: boolean;
}

const sortSessionByStartTime = (
  a: CustomSessionTypesForChildType,
  b: CustomSessionTypesForChildType,
) => {
  if (a.startTime > b.startTime) {
    return 1;
  }
  if (a.startTime < b.startTime) {
    return -1;
  }
  return 0;
};

const availabilityTypeMap = {
  [BookingSessionTypeEnum.AM]: 'amSessionsAvailable',
  [BookingSessionTypeEnum.PM]: 'pmSessionsAvailable',
  [BookingSessionTypeEnum.FULL]: ['amSessionsAvailable', 'pmSessionsAvailable'],
};

export const CustomSessionPicker: FC<ICustomSessionPickerProps> = ({
  isLoading = true,
  nurseryId,
  childId,
  nurseryName,
  nurseryOrg,
  sessions,
  handleBookingButtonClick,
  availability,
  existingBookings = [],
  allowInstantBookings,
  earliestInstantBookingSession,
  earliestBookingRequestSession,
  date = new Date(),
  isNonPrimaryBookingEnabled,
  paymentsEnabled,
  showSessionTimes,
}) => {
  const history = useHistory();
  const isAlreadyBooked = (session: CustomSessionTypesForChildType): boolean =>
    existingBookings
      .filter(
        ({ status }) =>
          status !== SessionBookingStatus.Cancelled && status !== SessionBookingStatus.Rejected,
      )
      .some(({ sessionId }) => session.id === sessionId);

  const canInstantBook = (session: CustomSessionTypesForChildType): boolean => {
    if (session.requestOnly) return false;
    const availabilityType = session.availabilityType
      ? availabilityTypeMap[session.availabilityType]
      : null;
    if (!availabilityType || !allowInstantBookings || !availability) return false;
    if (Array.isArray(availabilityType)) {
      return availabilityType.every((type) => availability[type]);
    }
    return availability[availabilityType];
  };

  const isSessionBeforeNextAvailableBookingTime = (
    session: CustomSessionTypesForChildType,
    bookingType: SessionBookingType,
  ): boolean => {
    const sessionStartTime = parse(session.startTime, 'HH:mm:ss', date);

    const nextAvailableBookingTime =
      bookingType === BookingRequestTypeEnum.REQUEST
        ? earliestBookingRequestSession
        : earliestInstantBookingSession;

    return isBefore(sessionStartTime, nextAvailableBookingTime);
  };

  const getButton = (session: CustomSessionTypesForChildType, bookingType: SessionBookingType) => {
    if (isAlreadyBooked(session)) {
      return (
        <BookingButton
          className={styles.bookingButton}
          onClick={null}
          disabled={true}
          disabledText={
            <>
              <IconCheck size={16} className={styles.checkIcon} />
              Booked
            </>
          }
          isInstant={false}
          data-testid="booking-session-selection__session-picker-booking-button"
          aria-label="Booked"
        />
      );
    }

    if (isSessionBeforeNextAvailableBookingTime(session, bookingType)) {
      return (
        <BookingButton
          className={styles.bookingButton}
          onClick={() => handleBookingButtonClick(session.id, bookingType)}
          disabled={true}
          disabledText={
            <>
              <IconTimes size={16} />
              Unavailable
            </>
          }
          isInstant={true}
          data-testid="booking-session-selection__session-picker-booking-button"
          aria-label="Unavailable"
        />
      );
    }

    return (
      <BookingButton
        className={styles.bookingButton}
        onClick={() => handleBookingButtonClick(session.id, bookingType)}
        disabled={false}
        disabledText="Unavailable"
        isInstant={bookingType === SessionBookingType.Instant}
        data-testid="booking-session-selection__session-picker-booking-button"
        aria-label={bookingType === SessionBookingType.Instant ? 'Book now' : 'Request'}
      />
    );
  };

  const getMessage = (
    session: CustomSessionTypesForChildType,
    bookingType: SessionBookingType,
  ): string => {
    if (isSessionBeforeNextAvailableBookingTime(session, bookingType)) {
      return 'Sorry, you have missed the booking cutoff for this session';
    }
    return '';
  };

  const getFooter = (bookingType: SessionBookingType, session: CustomSessionTypesForChildType) => {
    const showNearby =
      bookingType === SessionBookingType.Request &&
      isNonPrimaryBookingEnabled &&
      !isAlreadyBooked(session) &&
      !isSessionBeforeNextAvailableBookingTime(session, bookingType);

    const queryParams = {
      nurseryId,
      childId,
      date: format(date, 'yyyy-MM-dd'),
      sessionId: session.id,
      sessionType: session.availabilityType,
      bookingType,
    };
    const onNearbyClick = () => {
      history.push(`/booking/nearby-nurseries?${queryString.stringify(queryParams)}`);
    };
    return showNearby ? <NearbyNurseriesButton onClick={onNearbyClick} /> : null;
  };

  const getSessionCard = (session: CustomSessionTypesForChildType): ReactNode => {
    const bookingType = canInstantBook(session)
      ? SessionBookingType.Instant
      : SessionBookingType.Request;
    const Button = getButton(session, bookingType);
    const Footer = getFooter(bookingType, session);
    const message = getMessage(session, bookingType);
    const isUnavailable = isSessionBeforeNextAvailableBookingTime(session, bookingType);

    return (
      <SessionCard
        key={session.id}
        title={session.name || ''}
        startTime={session.startTime}
        endTime={session.endTime}
        price={paymentsEnabled ? session.price || 0 : undefined}
        Button={Button}
        message={message}
        isUnavailable={isUnavailable}
        Footer={Footer}
        showSessionTimes={showSessionTimes}
      />
    );
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Pebbles className={styles.loadingIndicator} size={60} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.iconWrapper}>
          <IconHome size={24} />
        </div>

        <h2 className={styles.nurseryInfo}>
          {nurseryOrg} - {nurseryName}
        </h2>
      </header>

      {sessions?.slice().sort(sortSessionByStartTime).map(getSessionCard)}
    </div>
  );
};
