import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Routes from 'app/routes';
import { AuthProvider } from 'app/auth';
import { ProfileProvider } from 'app/profile';
import { FeatureFlagProvider } from 'app/featureFlags';
import { createClient } from 'graphql/client';
import { NewNameNotice } from 'pages';

import { initializeAmplitude, AmplitudePageTracker } from 'app/amplitude';
import { Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const isSproutDomain = window.location.host.match(/booksprout/i) !== null;

export const ErrorWrapper = ({ initialShow, onClose }) => {
  const delay = 5;
  const [show, setShow] = useState(null);
  const [timeLeft, setTimeLeft] = useState(delay);

  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);

  useEffect(() => {
    let lockUiTimer;

    if (initialShow && show) {
      lockUiTimer = setTimeout(() => {
        setShow(false);
        setTimeLeft(5);
        onClose();
      }, delay * 1000);
    }

    return () => {
      if (lockUiTimer) {
        clearTimeout(lockUiTimer);
      }
    };
  }, [initialShow, show]);

  useEffect(() => {
    if (!timeLeft || !show) return;

    const lockUiCountdown = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(lockUiCountdown);
  }, [timeLeft, show]);

  return (
    <>
      <Dialog
        isVisible={show}
        title="We're a bit busy. Try again shortly"
        onClose={undefined}
        zIndex="6"
      >
        <div>{timeLeft}s left</div>
      </Dialog>
      <ModalBackdrop isVisible={show} onClick={() => null} opacity={0.7} />
    </>
  );
};

export const App = () => {
  const [globalError, setGlobalError] = useState({});

  return isSproutDomain ? (
    <NewNameNotice />
  ) : (
    <ApolloProvider client={createClient(setGlobalError)}>
      <AuthProvider>
        <ProfileProvider>
          <FeatureFlagProvider>
            <Router>
              <AmplitudePageTracker />
              <Routes />
              <ErrorWrapper
                initialShow={!!globalError?.error?.length}
                onClose={() => setGlobalError({})}
              />
            </Router>
          </FeatureFlagProvider>
        </ProfileProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
