import { FC } from 'react';
import {
  IconCalendar,
  IconClock,
  IconMapMarker,
  IconActivity,
  IconTicket,
  Camera,
  PlusSquareOutlined,
} from 'icons';
import { SessionBookingSessionType } from 'graphql/generated-types';

import styles from './MarketplaceSessionDetails.module.scss';
import { format } from 'date-fns';

type Address = {
  addressLine1?: string | null;
  city?: string | null;
  postCode?: string | null;
};

interface ISessionDetailsProps {
  activityName?: string;
  dateString: string;
  sessionType?: SessionBookingSessionType;
  addOns?: string;
  address?: Address | null;
  sessionTimeString?: string;
  ticketName?: string | null;
  isSubscription?: boolean;
  subsOffDates?: string[];
  sessionWeekday?: string;
  isOnline?: boolean;
}

export const MarketplaceSessionDetails: FC<ISessionDetailsProps> = ({
  activityName,
  dateString,
  address,
  addOns,
  sessionTimeString,
  ticketName,
  isSubscription,
  subsOffDates,
  sessionWeekday,
  isOnline,
}) => {
  const { addressLine1, city, postCode } = address || {};

  return (
    <div className={styles.sessionDetails}>
      {activityName && (
        <div className={styles.sessionDetail}>
          <IconActivity className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Activity</div>
          <div className={styles.detailValue}>{activityName}</div>
        </div>
      )}

      {ticketName && (
        <div className={styles.sessionDetail}>
          <IconTicket className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Ticket</div>
          <div className={styles.detailValue}>
            {isSubscription ? `x1 ${ticketName} subscription` : ticketName}
          </div>
        </div>
      )}

      {dateString && !isSubscription && (
        <>
          <div className={styles.sessionDetail}>
            <IconCalendar className={styles.detailIcon} size={28} />
            <div className={styles.detailLabel}>Date</div>
            <div className={styles.detailValue}>{dateString}</div>
          </div>
        </>
      )}

      {dateString && isSubscription && (
        <>
          <div className={styles.subsSessionDetail}>
            <IconCalendar className={styles.detailIcon} size={28} />
            <div className={styles.detailLabel}>Next date:</div>
            <div className={styles.datesNotRunning}>
              <div>{dateString}</div>
              <br />
              {subsOffDates && subsOffDates?.length > 0 && (
                <>
                  <div>Dates not running:</div>
                  {subsOffDates?.map((offDate) => (
                    <div>{format(new Date(offDate), 'E do MMM yyy')}</div>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}

      {sessionTimeString && (
        <div className={styles.sessionDetail}>
          <IconClock className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>{isSubscription ? 'Session' : 'Time'}</div>
          <div className={styles.detailValue}>
            <span className={styles.sessionTime}>
              {isSubscription ? (
                <div className={styles.detailValue}>
                  {sessionWeekday} {sessionTimeString}
                </div>
              ) : (
                <div className={styles.detailValue}>{sessionTimeString}</div>
              )}
            </span>
          </div>
        </div>
      )}

      {addOns && (
        <div className={styles.sessionDetail}>
          <PlusSquareOutlined className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Add-On</div>
          <div className={styles.detailValue}>{addOns}</div>
        </div>
      )}

      {isOnline ? (
        <div className={styles.sessionDetail}>
          <Camera className={styles.detailIcon} style={{ marginLeft: '0.25rem' }} size={18} />
          <div className={styles.detailLabel}>Location</div>
          <div className={styles.detailValue}>ONLINE</div>
        </div>
      ) : (
        <div className={styles.sessionDetail}>
          <IconMapMarker className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Location</div>
          <div className={styles.address}>
            {addressLine1}
            <br />
            {[city, postCode].filter(Boolean).join(', ')}
          </div>
        </div>
      )}
    </div>
  );
};
