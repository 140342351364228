import { Route, Switch } from 'react-router-dom';

import { FourOhFour } from 'pages';

import { BookingProvider } from './context';
import {
  BookingConfirmation,
  BypassPayment,
  ChildSelection,
  DateSelection,
  ReviewBooking,
  SessionSelection,
  NearbyNurseries,
} from './pages';
import { usePageViewTracker } from 'hooks';

export const Booking = () => {
  usePageViewTracker('booking');
  return (
    <BookingProvider>
      <Switch>
        <Route path="/booking/child-selection" component={ChildSelection} />
        <Route path="/booking/date-selection" component={DateSelection} />
        <Route path="/booking/session-selection" component={SessionSelection} />
        <Route path="/booking/nearby-nurseries" component={NearbyNurseries} />
        <Route path="/booking/review" component={ReviewBooking} />
        <Route path="/booking/confirmation" component={BookingConfirmation} />
        <Route path="/booking/payment-exempt" component={BypassPayment} />

        <Route component={FourOhFour} />
      </Switch>
    </BookingProvider>
  );
};

export * from './context';
export * from './useBookingParams';
export * from './useSessionSelectionParams';
