import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';

import {
  EnterGuardianInfo,
  OnboardingIntro,
  PickOrganizationScreen,
  ChildEstablishmentInfoScreen,
  PickNurseryScreen,
  ChildInfoEntryScreen,
  PickNurseryRoomScreen,
  ReviewChildrenScreen,
  OptIn,
} from './screens';
import { ErrorWrapper } from './ErrorWrapper';
import { DEFAULT_INITIALFORM_DATA } from './state/updateFormState';
import { usePageViewTracker } from 'hooks';

// Create peristant store in session storage
createStore(DEFAULT_INITIALFORM_DATA, {
  name: 'onboarding',
});

export const Onboarding: FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route
  const { path } = useRouteMatch();

  usePageViewTracker('onboarding');

  return (
    <StateMachineProvider key={'onboarding'}>
      <ErrorWrapper>
        <Switch>
          <Route exact path={path} component={OnboardingIntro} />
          <Route path={`${path}/guardian-info`} component={EnterGuardianInfo} />
          <Route path={`${path}/child-establishment`} component={ChildEstablishmentInfoScreen} />
          <Route path={`${path}/pick-organization`} component={PickOrganizationScreen} />
          <Route path={`${path}/pick-nursery`} component={PickNurseryScreen} />
          <Route path={`${path}/child-info/:id?`} component={ChildInfoEntryScreen} />
          <Route path={`${path}/pick-nursery-room/:id?`} component={PickNurseryRoomScreen} />
          <Route path={`${path}/review-children`} component={ReviewChildrenScreen} />
          <Route path={`${path}/opt-in`} component={OptIn} />
        </Switch>
      </ErrorWrapper>
    </StateMachineProvider>
  );
};
