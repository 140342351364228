import { gql } from '@apollo/client';

import NURSERY_FIELDS_FRAGMENT from '../fragments/NurseryFieldsFragment';

const getGuardian = gql`
  ${NURSERY_FIELDS_FRAGMENT}

  query GetGuardian {
    guardian {
      id
      fullName
      phoneNumber
      status
      email
      anyChildrenExternallyManaged
      externalSystemId
      canBookChildcare
      canBookMarketplaceActivities
      children {
        isRegisteredWithNursery
        isExternallyManaged
        id
        fullName
        status
        birthDate
        isAdult
        instantBook
        primaryNursery {
          ...NurseryFields
        }
        primaryNurseryRoom {
          id
          name
        }
      }
    }
  }
`;

export default getGuardian;
