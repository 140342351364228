import { createTrackerContext, withTrackerContext } from 'gtm-event-tracker';

// you can pass a user id in here for logged in users
const trackerContext = createTrackerContext();

const tracker = withTrackerContext(trackerContext);

export const setUser = (userId) => {
  trackerContext.setProps({ userId });
};

export const getGtagPageTracker = (current_page) => (event) => {
  tracker.trackEvent({
    event,
    current_page,
  });
};
