import { gql } from '@apollo/client';

const updateChildByGuardian = gql`
  mutation UpdateChildByGuardian($child: ChildrenInput!) {
    childUpdateByGuardian(child: $child) {
      child {
        id
        fullName
        birthDate
      }
      success
      errors {
        code
        message
      }
    }
  }
`;

export default updateChildByGuardian;
