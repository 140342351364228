import { createElement, FC, HTMLProps, MouseEvent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ThreeDots } from 'ui/ThreeDots';

export const outlineSuppressionHandlers = {
  onMouseDown: (e: MouseEvent<HTMLButtonElement>): void => {
    e.currentTarget.style.outline = 'none';
    e.currentTarget.style.boxShadow = 'none';
  },
  // eslint-disable-next-line
  onBlur: (e: React.FocusEvent<any>): void => {
    e.currentTarget.style.outline = null;
    e.currentTarget.style.boxShadow = null;
  },
};

import styles from './Button.module.scss';

interface IButtonProps {
  expand?: boolean;
  isWorking?: boolean;
  visuallyDisabled?: boolean;
  type?: HTMLButtonElement['type'];
  prefix?: string;
  suffix?: string;
  className?: string;
  color?: null | 'green';
  variant?: null | 'outline' | 'inverted' | 'link';
  href?: string;
}

export const Button: FC<IButtonProps & HTMLProps<HTMLButtonElement>> = ({
  children,
  className,
  expand,
  color,
  href,
  isWorking,
  prefix,
  suffix,
  variant = null,
  visuallyDisabled,
  type,
  ...buttonProps
}) => {
  const buttonClass = classNames(
    styles.button,
    visuallyDisabled && styles.visuallyDisabled,
    expand && styles.expanded,
    variant && styles[`${variant}Variant`],
    color && styles[`${color}Color`],
    className,
  );

  const buttonContent = isWorking ? (
    <span className={styles.workingIndicator}>
      <ThreeDots />
    </span>
  ) : (
    <>
      {prefix && <span className={styles.prefix}>{prefix}</span>}
      {children && <span className={styles.label}>{children}</span>}
      {suffix && <span className={styles.suffix}>{suffix}</span>}
    </>
  );

  const props: {
    className: string;
    type?: string;
    to?: string;
    href?: string;
    role?: string;
    disabled?: boolean;
  } = {
    type,
    ...outlineSuppressionHandlers,
    ...buttonProps,
    className: buttonClass,
    disabled: buttonProps.disabled || visuallyDisabled,
  };

  // TODO: use any for now, propably need to refactor the entire button implimentation
  // eslint-disable-next-line
  let element: any = 'button';

  if (href) {
    // Remove button `type` prop if it was set
    delete props.type;

    if (href.startsWith('/')) {
      element = Link;
      props.to = href;
    } else {
      element = 'a';
      props.href = href;
    }
  }

  return createElement(element, props, buttonContent);
};
