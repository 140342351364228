import { Maybe } from 'graphql/generated-types';

export const getFields = <TRawData, TDefaultFields>(
  data: Maybe<TRawData>,
  defaultValues: TDefaultFields,
): TDefaultFields => {
  return Object.keys(defaultValues).reduce((acc, key) => {
    if (data && data[key as keyof TRawData] !== undefined) {
      return { ...acc, [key]: data[key as keyof TRawData] };
    }

    return { ...acc, [key]: defaultValues[key as keyof TDefaultFields] };
  }, {} as TDefaultFields);
};
