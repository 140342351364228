import { gql } from '@apollo/client';

import NURSERY_FIELDS_FRAGMENT from '../fragments/NurseryFieldsFragment';

const getNurseryById = gql`
  ${NURSERY_FIELDS_FRAGMENT}

  query GetNurseryById($id: UUID!) {
    nurseryById(nurseryId: $id) {
      ...NurseryFields
    }
  }
`;

export default getNurseryById;
