import { gql } from '@apollo/client';

const getAvailability = gql`
  query GetAvailability($startDate: Date!, $endDate: Date!, $childId: UUID!) {
    availabilityForGuardian(startDate: $startDate, endDate: $endDate, childId: $childId) {
      earliestInstantBookingSession
      earliestBookingRequestSession
      nurseryRooms {
        id
        name
      }
      dates {
        date
        amSessionsAvailable
        pmSessionsAvailable
      }
    }
  }
`;

export default getAvailability;
