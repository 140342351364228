import { motion } from 'framer-motion';
import { useQuery } from '@apollo/client';

import getGuardian from 'graphql/queries/getGuardian';
import { IllustratedInfoPage } from 'layouts';
import { Button } from 'ui';

import womanWithChild from './womanWithChild.png';
import styles from './AwaitingConfirmation.module.scss';

export const AwaitingConfirmation = ({ isPrefetched }) => {
  const { data, loading } = useQuery(getGuardian);

  const firstName = data?.guardian?.fullName?.split?.(' ')?.[0];

  return (
    <IllustratedInfoPage
      illustration={
        <img
          src={womanWithChild}
          className={styles.illustration}
          alt="Woman with briefcase holding child's hand"
        />
      }
      footer={
        <Button href="/home" expand>
          Done
        </Button>
      }
    >
      <main>
        <motion.div
          animate={{ opacity: loading ? 0 : 1, translateY: loading ? -20 : 0 }}
          initial={false}
          className={styles.introText}
        >
          <h1 className={styles.title}>All done, {firstName}!</h1>

          <p>
            You can now request additional sessions. As soon as your child is verified we will
            unlock instant bookings.
          </p>
        </motion.div>
      </main>
    </IllustratedInfoPage>
  );
};
