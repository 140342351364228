import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconMapMarker = ({ className, color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 13.333C27 21.8886 16 29.2219 16 29.2219C16 29.2219 5 21.8886 5 13.333C5 10.4156 6.15893 7.61773 8.22183 5.55483C10.2847 3.49193 13.0826 2.33301 16 2.33301C18.9174 2.33301 21.7153 3.49193 23.7782 5.55483C25.8411 7.61773 27 10.4156 27 13.333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0002 16.9996C18.0252 16.9996 19.6668 15.358 19.6668 13.3329C19.6668 11.3079 18.0252 9.66626 16.0002 9.66626C13.9751 9.66626 12.3335 11.3079 12.3335 13.3329C12.3335 15.358 13.9751 16.9996 16.0002 16.9996Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
