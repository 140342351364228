import { gql } from '@apollo/client';

const createChild = gql`
  mutation CreateChild($child: ChildrenInput!) {
    childCreate(child: $child) {
      child {
        id
        fullName
        status
        birthDate
        primaryNursery {
          id
          name
          phoneNumber
          addressLine1
          addressLine2
          city
          country
          postCode
          paymentsEnabled
        }
        primaryNurseryRoom {
          id
          name
        }
      }
      success
      errors {
        message
        code
      }
    }
  }
`;

export default createChild;
