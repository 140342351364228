import { FC } from 'react';
import { format } from 'date-fns';
import { BookingSessionTypeEnum } from 'graphql/constants';
import { IconCalendar, IconClock, IconMapMarker, IconActivity } from 'icons';
import {
  SessionBookingSessionType,
  NurseryType,
  CustomSessionTypeModelType,
} from 'graphql/generated-types';
import { formatTimeString } from 'utilities/date';

import styles from './SessionDetails.module.scss';

interface ISessionDetailsProps {
  activityName?: string;
  date: Date;
  sessionType: SessionBookingSessionType;
  nurseryAddress: Pick<NurseryType, 'addressLine1' | 'city' | 'postCode'>;
  dateFormat: string;
  customSessionDetails?: CustomSessionTypeModelType;
  showSessionTimes: boolean;
}

const sessionLabels = {
  [BookingSessionTypeEnum.AM]: 'Morning',
  [BookingSessionTypeEnum.PM]: 'Afternoon',
  [BookingSessionTypeEnum.FULL]: 'Full day',
};

const getSessionTypeLabel = (type: string): string => {
  return sessionLabels[type] || type;
};

export const SessionDetails: FC<ISessionDetailsProps> = ({
  activityName,
  date,
  sessionType,
  nurseryAddress = {},
  dateFormat,
  customSessionDetails,
  showSessionTimes,
}) => {
  const { addressLine1, city, postCode } = nurseryAddress;
  return (
    <div className={styles.sessionDetails}>
      {activityName && (
        <div className={styles.sessionDetail}>
          <IconActivity className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Activity</div>
          <div className={styles.detailValue}>{activityName}</div>
        </div>
      )}
      {date && (
        <div className={styles.sessionDetail}>
          <IconCalendar className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Date</div>
          <div className={styles.detailValue}>{format(date, dateFormat)}</div>
        </div>
      )}

      <div className={styles.sessionDetail}>
        <IconClock className={styles.detailIcon} size={28} />
        <div className={styles.detailLabel}>Session</div>
        {!customSessionDetails ? (
          <div className={styles.detailValue}>{getSessionTypeLabel(sessionType)}</div>
        ) : (
          <div className={styles.detailValue}>
            {customSessionDetails.name && (
              <span className={styles.sessionName}>{customSessionDetails.name}</span>
            )}
            {showSessionTimes && (
              <span className={styles.sessionTime}>
                {formatTimeString(customSessionDetails.startTime)} -{' '}
                {formatTimeString(customSessionDetails.endTime)}
              </span>
            )}
          </div>
        )}
      </div>

      {addressLine1 && (
        <div className={styles.sessionDetail}>
          <IconMapMarker className={styles.detailIcon} size={28} />
          <div className={styles.detailLabel}>Address</div>
          <div className={styles.address}>
            {addressLine1}
            <br />
            {[city, postCode].filter(Boolean).join(', ')}
          </div>
        </div>
      )}
    </div>
  );
};

SessionDetails.defaultProps = {
  dateFormat: 'EEEE, do MMM',
};
