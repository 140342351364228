import classNames from 'classnames';
import styles from './Icon.module.scss';

export const IconCalendar = ({ className, color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.1667 4.6665H5.83333C4.54467 4.6665 3.5 5.71117 3.5 6.99984V23.3332C3.5 24.6218 4.54467 25.6665 5.83333 25.6665H22.1667C23.4553 25.6665 24.5 24.6218 24.5 23.3332V6.99984C24.5 5.71117 23.4553 4.6665 22.1667 4.6665Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.666 2.3335V7.00016"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33398 2.3335V7.00016"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 11.6665H24.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
