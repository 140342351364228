import { gql } from '@apollo/client';

const getNurseries = gql`
  query GetNurseries($organizationId: UUID) {
    nurseries(organizationId: $organizationId) {
      id
      name
      isVisible
      isExternallyManaged
      nurseryRooms {
        id
        name
      }
    }
  }
`;

export default getNurseries;
