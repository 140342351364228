import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import { logButtonEvent } from 'app/amplitude';
import getNurseries from 'graphql/queries/getNurseries';
import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Pebbles, RadioButtonList } from 'ui';
import { updateState } from 'pages/Onboarding/state/updateFormState';

import styles from './PickNurseryScreen.module.scss';
import { usePageViewTracker, useQueryParams } from 'hooks';
import { StickyNav } from 'ui/StickyNav';

/**
 * Converts a list of nurseries to a format that is required for the RadioButtonList components
 *
 * @param {Array} nurseries A list of nurseries to convert to RadioListOptions
 */
const mapNurseriesToRadioOptions = (nurseryList) =>
  nurseryList.map((nursery) => ({
    label: nursery.name,
    value: nursery.id,
  }));

export const PickNurseryScreen = (props) => {
  const { history } = props;

  usePageViewTracker('onboarding/pick_nursery');

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { actions, state } = useStateMachine({ updateState });
  const { organisation } = useQueryParams();

  const { loading, data } = useQuery(getNurseries, {
    variables: {
      organizationId: organisation || state.organizationId,
    },
  });

  const nurseryOptions = useMemo(() => mapNurseriesToRadioOptions(data?.nurseries ?? []), [data]);

  const onSubmit = (newData) => {
    logButtonEvent('nursery_selected_next');
    actions.updateState(newData);
    history.push({ pathname: `/onboarding/child-info`, search: history.location.search });
  };

  return (
    <DefaultAppPage
      title={`Which ${state.organizationName} childcare provider are you enrolled at?`}
      footerActions={[
        <StickyNav>
          <Button type="submit" form="pick-nursery-form" expand key="next">
            Next
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      {loading && <Pebbles className={styles.loadingIndicator} fillSpace />}
      {!loading && (
        <form id="pick-nursery-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.options}>
            <Controller
              control={control}
              name="nurseryId"
              rules={{ required: 'Please select a nursery' }}
              defaultValue={state.nurseryId}
              render={({ field: { name, value } }) => (
                <RadioButtonList
                  className={styles.radioList}
                  options={nurseryOptions}
                  onChange={(id) => {
                    logButtonEvent('nursery_selected');
                    setValue(name, id, { shouldValidate: true });
                  }}
                  value={value}
                  name={name}
                />
              )}
            />
          </div>
          {errors?.nurseryId && (
            <ErrorMessage className={styles.errorMessage} role="alert">
              {errors?.nurseryId?.message}
            </ErrorMessage>
          )}
        </form>
      )}

      <div className={styles.supportSection}>
        <p>Your nursery is not in the list?</p>
        <a href="mailto:support@bookpebble.co.uk">Contact us</a>
      </div>
    </DefaultAppPage>
  );
};

PickNurseryScreen.buttonEvent = 'onboarding_nursery_select';
