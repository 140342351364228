import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './StatusLabel.module.scss';

interface StatusLabelProps {
  children: ReactNode;
  status: string;
}

export const StatusLabel: FC<StatusLabelProps> = ({ children, status }) => (
  <span className={classNames(styles.container, styles[status])}>{children}</span>
);
