import { useMemo } from 'react';
import classNames from 'classnames';
import { format, addDays, subDays, isDate, isPast, isToday, isSameDay } from 'date-fns';

import { chunk } from 'utilities/array';

import styles from './DayPicker.module.scss';

const MONDAY = 0;
const SUNDAY = 6;

/**
 * Returns the previous Monday of the given date
 * @param {Date} d The initial date to start from
 */
const getMonday = (d) => subDays(d, d.getDay() - 1);

/**
 * Returns an array of all days to be rendered inside of the DayPicker
 * @param {Date} date The initial date to start from, which will be rounded back to Monday
 * @param {Number} weeksToRender The number of weeks to return
 */
const getDaysToRender = (date, weeksToRender = 2) => {
  const startDate = getMonday(date);
  const totalDays = weeksToRender * 7;
  const daysToRender = Array.from({ length: totalDays }, (_, i) => addDays(startDate, i));

  return daysToRender;
};

/**
 * Returns the title of a month for a group of days
 *
 * @param {Aray} days The days of the month
 * @param {String} monthFormat The date-fns formatting to use for the month
 * @param {String} yearFormat The date-fns formatting to use for the year
 * @returns {String}
 */
const getMonthTitle = (days, monthFormat, yearFormat) => {
  // find the first day of the first week that isn't blank
  const firstDayOfMonth = days[0].find((b) => !b.isBlank);

  return [format(firstDayOfMonth.day, monthFormat), format(firstDayOfMonth.day, yearFormat)].join(
    ' ',
  );
};

const DefaultDay = ({ day, onChange, isWeekend, isBeforeToday, isSelected, isToday: _isToday }) => (
  <td
    onClick={onChange}
    className={classNames(styles.day, {
      [styles.weekend]: isWeekend,
      [styles.beforeToday]: isBeforeToday,
      [styles.today]: _isToday,
      [styles.selected]: isSelected,
    })}
  >
    {format(day, 'd')}
  </td>
);

export const DayPicker = ({
  initialWeek,
  monthFormat,
  renderDay,
  value,
  weeksToRender,
  yearFormat,
}) => {
  const today = useMemo(() => new Date(), []);
  let startDate = today;

  try {
    // Try to parse unix timestamps, if not passed a Date object
    startDate = isDate(initialWeek) ? initialWeek : new Date(initialWeek);
  } catch (error) {
    console.log('[DayPicker] Error initializing DayPicker:', error);
  }

  // Build an array containing all the days we'll need to render, then sort into months,
  // finally chunk each month into groups of 7 to represent each week.
  const months = useMemo(() => {
    const monthBuckets = {};

    getDaysToRender(startDate, weeksToRender).forEach((day) => {
      // Key used for sorting
      const bucketName = format(day, 'MM-yyyy');

      // Create bucket if it doesn't exist
      if (!monthBuckets[bucketName]) {
        monthBuckets[bucketName] = [];
      }

      // Sort date into bucket
      monthBuckets[bucketName].push({
        day,
        isFirstDayOfMonth: false,
        isBeforeToday: isPast(day) && !isSameDay(day, today),
        isWeekend: [SUNDAY, MONDAY].includes(day.getDay()),
        isToday: isToday(day),
        isSelected: isDate(value) ? isSameDay(value, day) : false,
        isBlank: false,
      });
    });

    return Object.values(monthBuckets).map((days) => {
      // If the first day of the month doesn't happen on a Monday some padding
      // will need to be injected, this gets rendered as an empty cell
      const emptyDayCount = days[0].day.getDay() === 0 ? 6 : days[0].day.getDay() - 1;
      const padding = Array.from({ length: emptyDayCount }, () => ({
        isBlank: true,
      }));

      return chunk([...padding, ...days], 7);
    });
  }, [startDate, weeksToRender, today, value]);

  const renderContext = {};

  return (
    <div className={styles.container}>
      {months.map((month, monthIndex) => (
        <div className={styles.month} key={monthIndex}>
          <div className={styles.title}>{getMonthTitle(month, monthFormat, yearFormat)}</div>

          <table className={styles.table}>
            <tbody>
              {month.map((week, weekIndex) => (
                <tr key={weekIndex}>
                  {week.map(({ day, isBlank, ...dayMeta }, dayIndex) => {
                    if (isBlank) {
                      return (
                        <td key={dayIndex}>
                          <div className={styles.blankDay} />
                        </td>
                      );
                    }

                    if (typeof renderDay !== 'function') {
                      return <DefaultDay key={day.toString()} day={day} {...dayMeta} />;
                    } else {
                      return renderDay(day, dayMeta, renderContext);
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

DayPicker.defaultProps = {
  initialWeek: new Date(),
  monthFormat: 'MMMM',
  onChange: null,
  value: null,
  weeksToRender: 4,
  yearFormat: 'yyyy',
};
