import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './StickyNav.module.scss';

export const StickyNav = ({ children, className, position, withFade }) => (
  <div
    className={classNames(styles.container, styles[position], withFade && styles.faded, className)}
  >
    {children}
  </div>
);

StickyNav.defaultProps = {
  position: 'bottom',
  withFade: false,
};

StickyNav.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node,
  className: PropTypes.string,
  withFade: PropTypes.bool,
};
