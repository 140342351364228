import { IconBaby } from 'icons';

import styles from './ChildTile.module.scss';

export const ChildTile = ({ child, onEditClick, roomName, isExternallyManaged }) => (
  <div className={styles.container}>
    <div className={styles.iconWrapper}>
      <IconBaby size={32} className={styles.babyIcon} />
    </div>

    <div className={styles.childDetails}>
      <div className={styles.fullName}>{child.fullName}</div>
      {child.birthDate && (
        <div className={styles.childInfo}>Date of birth: {child.birthDate ?? '–'}</div>
      )}
      {child.nurseryName && <div className={styles.childInfo}>Nursery: {child.nurseryName}</div>}
      {roomName && <div className={styles.childInfo}>Room: {roomName}</div>}
    </div>

    <div className={styles.actions}>
      {!isExternallyManaged && (
        <button onClick={onEditClick} className={styles.editButton}>
          Edit
        </button>
      )}
    </div>
  </div>
);
