import { FC } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { logButtonEvent } from 'app/amplitude';
import {
  IconCheck,
  IconTimes,
  PrimaryBooking,
  NonPrimaryBooking,
  BlockIndividual,
  Subscription,
} from 'icons';
import { Tag } from 'ui';
import {
  ActivityBookingType,
  AllBookingsForGuardianType,
  BookingType,
  Status,
} from 'graphql/generated-types';

import {
  formatSessionType,
  getBookingDetailsHref,
  mapBookingStatusToTagType,
  mapBookingStatusToText,
} from './utils';
import styles from './SessionCard.module.scss';

interface IBookingTypeIconProps {
  bookingType: BookingType | ActivityBookingType;
}

const BookingTypeIcon: FC<IBookingTypeIconProps> = ({ bookingType }) => {
  switch (bookingType) {
    case ActivityBookingType.Instant:
      return (
        <BlockIndividual
          className={styles.detailIcon}
          size={20}
          aria-label="Activity booking icon"
        />
      );
    case ActivityBookingType.Subscription:
      return (
        <Subscription className={styles.detailIcon} size={20} aria-label="Subscription icon" />
      );
    case BookingType.SessionNonPrimary:
      return (
        <NonPrimaryBooking
          className={styles.detailIcon}
          size={15}
          aria-label="Non-Primary booking icon"
          focusable="false"
        />
      );
    case BookingType.SessionPrimary:
      return (
        <PrimaryBooking
          className={styles.detailIcon}
          size={15}
          aria-label="Primary booking icon"
          focusable="false"
        />
      );
    default:
      return null;
  }
};
const trackClick = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.MarketplaceActivityBooking:
      logButtonEvent('activity_confirmation_selected');
      return;
    case BookingType.SessionNonPrimary:
      logButtonEvent('nonprimary_confirmation_selected');
      return;
    case BookingType.SessionPrimary:
      logButtonEvent('session_confirmation_selected');
      return;
    default:
      return;
  }
};

interface ISessionCardProps {
  session: AllBookingsForGuardianType;
  showChildNames: boolean;
}

export const SessionCard: FC<ISessionCardProps> = ({ session, showChildNames, ...props }) => {
  const sessionDetailsHref = getBookingDetailsHref(session);
  const sessionStatus = session.status;
  const showCheckmark = sessionStatus === Status.Confirmed;
  const showCross = sessionStatus === Status.Cancelled || sessionStatus === Status.Rejected;

  const isInstantOrSubscription =
    session.marketplaceActivity?.bookingType === ActivityBookingType.Instant ||
    session.marketplaceActivity?.bookingType === ActivityBookingType.Subscription;

  const hideEndTimes = session.marketplaceActivity?.hideEndTimes;

  return (
    <Link
      onClick={() => trackClick(session.bookingType as BookingType)}
      className={styles.container}
      to={sessionDetailsHref}
      {...props}
    >
      <div className={styles.header}>
        {/* session.bookingType is still return as Scalars['String'] by API */}
        <div className={styles.info}>
          <BookingTypeIcon
            bookingType={
              isInstantOrSubscription
                ? (session.marketplaceActivity?.bookingType as ActivityBookingType)
                : (session.bookingType as BookingType)
            }
          />
          <div className={styles.sessionDetails}>
            {session.subscriptionDayOfWeek
              ? session.subscriptionDayOfWeek + 's'
              : format(new Date(session.date), 'E, do MMM.')}
          </div>
          <div className={styles.sessionType}>
            {formatSessionType(session, Boolean(hideEndTimes))}
          </div>
          {session.marketplaceActivity && (
            <div className={styles.activityName}>{session.marketplaceActivity.name}</div>
          )}
        </div>
        <div className={styles.status}>
          <Tag type={mapBookingStatusToTagType(sessionStatus)} className={styles.statusLabel}>
            {showCheckmark && <IconCheck className={styles.tagIcon} size={10} />}
            {showCross && <IconTimes className={styles.tagIcon} size={12} />}
            {mapBookingStatusToText(sessionStatus)}
          </Tag>
        </div>
      </div>

      {showChildNames && session.child && (
        <Tag className={styles.child}>{session.child.fullName.split(' ')[0]}</Tag>
      )}
    </Link>
  );
};
