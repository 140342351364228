import { NavLink } from 'react-router-dom';

import { usePageViewTracker, useWindowHeight } from 'hooks';
import { Button, Carousel, CarouselPage } from 'ui';
import { logButtonEvent } from 'app/amplitude';

import calendar from './images/newCalendar.png';
import listOfBookings from './images/computerWithListOfBookings.png';
import postItBooked from './images/newBooked.png';
import styles from './Landing.module.scss';
import { WhitePebbles } from 'icons';

export const Landing = () => {
  usePageViewTracker('landing');
  const { boxStyle } = useWindowHeight();

  return (
    <main className={styles.container} style={boxStyle}>
      <div className={styles.desktopContent}>
        <img src={calendar} alt="Calendar with days marked" draggable="false" />
        <div className={styles.cardsRow}>
          <div className={styles.card}>
            <WhitePebbles
              className={styles.detailIcon}
              style={{ marginBottom: '2rem' }}
              size={32}
            />
            <p>View all available nursery sessions.</p>
          </div>
          <div className={styles.card}>
            <WhitePebbles
              className={styles.detailIcon}
              style={{ marginBottom: '2rem' }}
              size={32}
            />
            <p>
              Book sessions instantly. <br /> No need to wait for approval.
            </p>
          </div>
          <div className={styles.card}>
            <WhitePebbles
              className={styles.detailIcon}
              style={{ marginBottom: '2rem' }}
              size={32}
            />
            <p>View all your bookings in one place.</p>
          </div>
        </div>
      </div>

      <Carousel
        className={styles.carousel}
        height={310}
        updateInterval={5000} // 5 seconds
        dotColor={styles.dotColor}
        activeDotColor={styles.activeDotColor}
      >
        <CarouselPage className={styles.carouselPage}>
          <div className={styles.illustrationWrapper}>
            <img src={calendar} alt="Calendar with days marked" draggable="false" />
          </div>

          <WhitePebbles className={styles.detailIcon} style={{ marginBottom: '1rem' }} size={32} />
          <p>View all available nursery sessions.</p>
        </CarouselPage>
        <CarouselPage className={styles.carouselPage}>
          <div className={styles.illustrationWrapper}>
            <img src={postItBooked} alt="Sign with `Booked` written on it" draggable="false" />
          </div>

          <WhitePebbles className={styles.detailIcon} style={{ marginBottom: '1rem' }} size={32} />
          <p>
            Book sessions instantly.
            <br /> No need to wait for approval.
          </p>
        </CarouselPage>
        <CarouselPage className={styles.carouselPage}>
          <div className={styles.illustrationWrapper}>
            <img src={listOfBookings} alt="List of bookings" draggable="false" />
          </div>

          <WhitePebbles className={styles.detailIcon} style={{ marginBottom: '1rem' }} size={32} />
          <p>View all your bookings in one place.</p>
        </CarouselPage>
      </Carousel>

      <div className={styles.footer}>
        <div className={styles.footerActions}>
          <Button
            href="/register"
            className={styles.signUp}
            onClick={() => logButtonEvent('create_an_account')}
          >
            Create an account
          </Button>

          <NavLink
            to="login"
            onClick={() => {
              logButtonEvent('have_account');
            }}
            className={styles.login}
          >
            I already have an account
          </NavLink>
        </div>
      </div>
    </main>
  );
};
