import { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { AuthContext } from 'app/auth';
import { IconTimes } from 'icons';
import { Button } from 'ui';

import illustration from './puzzleIllustration.png';
import styles from './Support.module.scss';
import { usePageViewTracker } from 'hooks';

export const Support = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  usePageViewTracker('support');

  const logout = async () => {
    try {
      await authContext.logout();
      history.push('/');
    } catch (error) {
      console.log('[Account] Unable to log out');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.illustration}>
          <img src={illustration} alt="Puzzle pieces in a pile" />
        </div>

        <h1 className={styles.title}>Do you need help?</h1>
        <p>Simply drop us a message and we’ll do everything we can to sort you out.</p>

        <NavLink to="/home" className={styles.closeButton}>
          <IconTimes size={20} />
        </NavLink>

        <div className={styles.footerActions}>
          <Button href="https://wa.me/message/FOG5FS434HTXP1" expand>
            Contact Us
          </Button>

          <Button onClick={logout} expand variant="outline" className={styles.logOutButton}>
            Log out
          </Button>
        </div>
      </div>
    </div>
  );
};
