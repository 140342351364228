import { FC, MouseEvent, ReactNode } from 'react';
import { ListTile } from 'app/components';
import { IconPencil } from 'icons';

import styles from './EditableField.module.scss';

interface IEditableFieldProps {
  label?: ReactNode;
  value?: ReactNode;
  onEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
  disableEdit?: boolean;
}

export const EditableField: FC<IEditableFieldProps> = ({
  label,
  value,
  onEdit,
  disableEdit = false,
}) => (
  <ListTile className={styles.tile} contentClass={styles.tileContent}>
    <div>
      <h2 className={styles.label}>{label}</h2>
      <div className={styles.value}>{value}</div>
    </div>
    {!disableEdit && (
      <button onClick={onEdit} className={styles.editButton} aria-label={`Edit ${label}`}>
        <IconPencil size={18} />
      </button>
    )}
  </ListTile>
);
