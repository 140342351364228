import { PaymentExemptionReasonEnum } from 'graphql/constants';
import { RadioButton } from 'ui';

import styles from './PaymentExemptionReasons.module.scss';

export const PaymentExemptionReasons = ({ onChange, value }) => (
  <div className={styles.container}>
    {exemptionReasons.map((reason) => (
      <label className={styles.reason} key={reason.value}>
        <RadioButton
          name="exemptionReason"
          value={reason.value}
          checked={reason.value === value}
          onChange={onChange}
          className={styles.radioButton}
        />
        {reason.label}
      </label>
    ))}
  </div>
);

const exemptionReasons = [
  {
    label: 'This is an emergency care session paid for by my employer',
    value: PaymentExemptionReasonEnum.EMERGENCY_CARE_SESSION,
  },
  {
    label: 'This session will be paid via tax-free account',
    value: PaymentExemptionReasonEnum.TAX_FREE_SESSION,
  },
  {
    label: 'This is a day in lieu',
    value: PaymentExemptionReasonEnum.SESSION_IN_LIEU,
  },
  {
    label: 'This is a settle-in session',
    value: PaymentExemptionReasonEnum.SETTLE_IN_SESSION,
  },
];
