import classNames from 'classnames';
import { format } from 'date-fns';
import { logButtonEvent } from 'app/amplitude';
import { DayPicker, outlineSuppressionHandlers } from 'ui';

import styles from './SessionDayPicker.module.scss';

const getTracker = (isInstantBookable) =>
  isInstantBookable ? 'instant_book_day_selection' : 'request_day_selection';

export const SessionDayPicker = ({ calendar, onChange, pickedDay, weeksToRender }) => (
  <DayPicker
    value={pickedDay}
    weeksToRender={weeksToRender}
    renderDay={(day, { isSelected, isWeekend, isBeforeToday, isToday }, renderContext) => {
      const calendarDay = calendar[format(day, 'yyyy-MM-dd')];

      // Determines whether or not this day is clickable
      const isPickable = calendarDay?.isDayPickable ?? false;
      const isInstantBookable = calendarDay?.isDayInstantBookable ?? false;
      const isRequestOnly = isPickable && !isInstantBookable;

      const dayClass = classNames(styles.day, {
        [styles.weekend]: isWeekend,
        [styles.beforeToday]: isBeforeToday,
        [styles.today]: isToday,
        [styles.selected]: isSelected,
        [styles.instantBook]: isPickable && isInstantBookable,
        [styles.requestOnly]: isPickable && isRequestOnly,
      });

      if (isPickable) {
        renderContext.pickableDays = (renderContext.pickableDays ?? 0) + 1;
      } else {
        renderContext.pickableDays = renderContext.pickableDays ?? 0;
      }

      return (
        <td
          onClick={() => {
            if (!isPickable) return;
            logButtonEvent(getTracker(isInstantBookable));
            onChange(day);
          }}
          className={dayClass}
          key={day.toString()}
          data-testid={`booking-date-selection__days-${
            isPickable ? renderContext.pickableDays : 0
          }`}
        >
          <button {...outlineSuppressionHandlers} className={styles.button} disabled={!isPickable}>
            {format(day, 'd')}
          </button>
        </td>
      );
    }}
  />
);

SessionDayPicker.defaultProps = {
  weeksToRender: 4,
};
