import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { useMemo } from 'react';

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

export const createClient = (dispatch) => {
  const apolloClient = useMemo(() => {
    return new ApolloClient({
      cache,
      link: ApolloLink.from([
        authLink,
        onError(({ graphQLErrors, networkError, response }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              );
              if (message === 'EXTERNAL_SYSTEM_RATE_LIMIT_EXCEEDED') {
                dispatch({ error: graphQLErrors || networkError });
              }
            });
          }
        }),
        httpLink,
      ]),
      defaultOptions: {
        // Note: The useQuery hook uses Apollo Client's watchQuery function.
        // To set defaultOptions when using the useQuery hook,
        // make sure to set them under the defaultOptions.watchQuery property.
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
  }, [dispatch]);

  return apolloClient;
};
