import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { usePageViewTracker, useQueryParams } from 'hooks';
import { MarketplaceBookingDetails } from 'layouts/MarketplaceBookingDetails';

import { Checkbox, FullScreen, Pebbles } from 'ui';
import { getFirstName } from 'utilities/string';
import getMarketplaceBookingByIds from 'graphql/queries/getMarketplaceBookingsByIds';
import getMarketingPreferences from 'graphql/queries/getMarketingPreferences';
import marketingOptInMutation from 'graphql/mutations/updateMarketingOptIn';
import { Payment } from 'models';
import { PaymentInfoModal } from 'app/components/PaymentInfoModal';
import { PaymentStatus } from 'app/components/PaymentStatus';

import statusConfirmed from './images/statusConfirmed.png';
import statusCancelled from './images/statusCancelled.png';
import styles from './Details.module.scss';
import {
  GetMarketplaceBookingsByIdsQuery,
  GetMarketplaceBookingsByIdsQueryVariables,
  ActivityBookingStatus,
  ActivityBookingTicketSessionType,
  ActivityBookingSchemaType,
  ActivityBookingTicketType,
  ActivityBookingType,
  TicketTicketType,
} from 'graphql/generated-types';
import {
  getBookingDates,
  getSessionTime,
  getSessionDate,
  getFormattedSessionWeekday,
  getNextSubscriptionDate,
  calculateAddons,
} from 'pages/Marketplace/utils';

const illustrationMap = {
  [ActivityBookingStatus.Confirmed]: statusConfirmed,
  [ActivityBookingStatus.Moved]: statusConfirmed,
  [ActivityBookingStatus.Cancelled]: statusCancelled,
};

export const Details: FC = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [notifyMe, setNotifyMe] = useState(false);
  const history = useHistory();
  const { bookingId, sessionId, childId } = useQueryParams();

  usePageViewTracker('marketplace/details');

  const [saveMarketingPreferences, { loading: isSaving }] = useMutation(marketingOptInMutation);
  const { data: marketingPreferencesData, loading: loadingMarketingPreferences } =
    useQuery(getMarketingPreferences);

  const { data, loading } = useQuery<
    GetMarketplaceBookingsByIdsQuery,
    GetMarketplaceBookingsByIdsQueryVariables
  >(getMarketplaceBookingByIds, {
    variables: {
      activityBookingIds: [bookingId],
    },
    skip: !bookingId,
    fetchPolicy: 'cache-and-network',
  });

  // TODO: ?value of booking
  // eslint-disable-next-line
  // @ts-ignore
  const booking = useMemo<ActivityBookingSchemaType | undefined>(() => {
    if (data && data.activityBookingsByIds && Array.isArray(data.activityBookingsByIds)) {
      const matchedBooking = data.activityBookingsByIds.find((b) => b?.id === bookingId);
      return matchedBooking || undefined;
    }

    return undefined;
  }, [data, bookingId]);

  const isSubscription = booking?.activity.bookingType === ActivityBookingType.Subscription;

  const hasPaidTrial =
    booking?.bookedTickets?.[0]?.subscriptionTrialSelected &&
    Boolean(booking?.activity?.subscriptionTrialPrice);

  const addOnsString = useMemo(() => {
    return calculateAddons(booking?.bookedTickets);
  }, [booking?.bookedTickets]);

  const ticketInfo = useMemo<ActivityBookingTicketType | undefined | null>(() => {
    if (!booking) {
      return undefined;
    }
    return booking?.bookedTickets?.find((ticket) =>
      ticket?.attendees?.find((attendee) => attendee.id === childId),
    );
  }, [booking, childId]);

  const child = useMemo(() => {
    if (!ticketInfo) {
      return undefined;
    }
    return ticketInfo.attendees.find((attendee) => attendee.id === childId);
  }, [ticketInfo, childId]);

  const uniqueSessions = useMemo(() => {
    if (!ticketInfo?.sessions) {
      return [];
    }
    return ticketInfo.sessions.filter(
      (session, index, self) =>
        index ===
        self.findIndex(
          (s) => s?.sessionStart === session?.sessionStart && s?.sessionEnd === session?.sessionEnd,
        ),
    );
  }, [ticketInfo]);

  const sessionTimes = useMemo(() => {
    if (isSubscription && uniqueSessions?.length) {
      return getSessionTime(
        uniqueSessions,
        uniqueSessions?.[0]?.id,
        booking?.activity?.hideEndTimes,
      );
    }
    if (!sessionId) {
      return '';
    }
    if (uniqueSessions?.length) {
      return getSessionTime(uniqueSessions, sessionId, booking?.activity?.hideEndTimes);
    }
    return '';
  }, [uniqueSessions, sessionId, isSubscription, booking?.activity?.hideEndTimes]);

  const promotionInfo = useMemo(() => {
    if (booking && booking.promotion) {
      return {
        finalAmount: booking.finalAmount,
        originalAmount: booking.originalAmount,
        discount: booking.discount,
        promotion: booking.promotion,
      };
    }
  }, [booking]);

  const handleCloseClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (marketingPreferencesData.guardian.marketingOptIn) {
      return history.push('/home');
    }

    try {
      await saveMarketingPreferences({
        variables: {
          guardianId: marketingPreferencesData.guardian.id,
          marketingOptIn: notifyMe,
        },
      });
    } catch (error) {
      // Agreed with PO to silently fail
      console.log(`error`, error);
    }
    history.push('/home');
  };

  const sessionWeekday = useMemo(() => {
    if (uniqueSessions && sessionId) {
      return getFormattedSessionWeekday(
        uniqueSessions as ActivityBookingTicketSessionType[],
        sessionId,
      );
    }
    if (uniqueSessions && isSubscription) {
      return getFormattedSessionWeekday(
        uniqueSessions as ActivityBookingTicketSessionType[],
        uniqueSessions?.[0]?.id,
      );
    }
  }, [uniqueSessions, sessionId, isSubscription]);

  const bookingDates = useMemo(() => {
    if (ticketInfo && sessionId) {
      return getSessionDate(ticketInfo?.sessions as ActivityBookingTicketSessionType[], sessionId);
    }
    if (ticketInfo && isSubscription) {
      return getNextSubscriptionDate(ticketInfo?.sessions as ActivityBookingTicketSessionType[]);
    }
    if (ticketInfo) {
      return getBookingDates(
        ticketInfo?.firstSessionDate,
        ticketInfo?.lastSessionDate,
        uniqueSessions?.length,
      );
    }
    return '';
  }, [ticketInfo, sessionId, isSubscription, uniqueSessions]);

  if (loading || loadingMarketingPreferences) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  const activityName = booking?.activity?.name;
  const status = booking?.status as ActivityBookingStatus;
  const childName = getFirstName(child?.fullName || '');
  const payment = booking ? new Payment(booking?.payment) : new Payment(null);
  const isCancelled = status === ActivityBookingStatus.Cancelled;
  const illustrationSrc = status ? illustrationMap[status] : '';
  const address = booking?.activity?.location;
  const isBlockBooking = ticketInfo?.ticket?.ticketType === TicketTicketType.Block;
  const subsOffDates = booking?.activity?.subscriptionOffDates;
  const nextRenewalDate = booking?.subscriptionNextPayment;
  const isOnline = booking?.activity?.isOnline;

  return (
    <MarketplaceBookingDetails
      title={
        <div className={styles.title}>
          {isSubscription ? 'Subscription for ' : 'Upcoming activity for '}
          <br />
          {childName} at <span className={styles.nurseryName}>{activityName}</span>
        </div>
      }
      illustration={<img src={illustrationSrc} alt={status} />}
      footer={
        <>
          {!marketingPreferencesData.guardian.marketingOptIn && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                name="marketingOptIn"
                id="marketingOptIn"
                className={styles.checkbox}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotifyMe(e.target.checked)}
                checked={notifyMe}
                disabled={isSaving}
              />
              <label className={styles.agreementText} htmlFor="marketingOptIn">
                Notify me about special offers and updates
              </label>
            </div>
          )}
        </>
      }
      dateString={bookingDates}
      activityName={activityName || ''}
      sessionTimeString={sessionTimes}
      onClose={handleCloseClick}
      showCancellationPolicy={!isCancelled}
      useHistoryBack={false}
      childName={childName}
      address={address}
      addOns={addOnsString}
      ticketName={ticketInfo?.ticketNameAtBooking}
      isSubscription={isSubscription}
      subsOffDates={subsOffDates || []}
      sessionWeekday={sessionWeekday}
      isOnline={isOnline}
    >
      <div className={styles.paymentInfoWrapper}>
        <PaymentStatus
          payment={payment}
          promotion={promotionInfo}
          onIconClick={() => setShowInfoModal(true)}
          amountOfSessions={uniqueSessions?.length || 1}
          isActivity={true}
          isSubscription={isSubscription}
          nextRenewalDate={nextRenewalDate}
          subscriptionCost={hasPaidTrial ? null : booking?.bookedTickets?.[0]?.ticketPriceAtBooking}
        />
      </div>

      <PaymentInfoModal
        isVisible={showInfoModal}
        isPaymentExempt={false}
        onClose={() => setShowInfoModal(false)}
      />

      {isBlockBooking && !isSubscription ? (
        <div className={styles.blockBooking}>
          <h2>This is a block booking</h2>
          <p>This is a block booking totalling {ticketInfo?.numberOfSessions} sessions.</p>
        </div>
      ) : (
        <div />
      )}
    </MarketplaceBookingDetails>
  );
};
