import { FC } from 'react';
import { IconChevronRight, NonPrimaryBooking } from 'icons';
import styles from './NearbyNurseriesButton.module.scss';

interface INearbyNurseriesButtonProps {
  onClick(): void;
}

export const NearbyNurseriesButton: FC<INearbyNurseriesButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.container} onClick={onClick} aria-label="find nearby nurseries">
      <NonPrimaryBooking className={styles.nearby} size={18} />
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Need an instant book?</h4>
        <div className={styles.message}>Find nearby nurseries</div>
      </div>
      <IconChevronRight className={styles.chevron} />
    </button>
  );
};
