import { gql } from '@apollo/client';

const getAllBookings = gql`
  query GetAllBookings($bookingFilter: BookingType!, $startDate: Date) {
    allBookingsForGuardian(bookingFilter: $bookingFilter, startDate: $startDate) {
      bookingId
      sessionId
      bookingType
      subscriptionStartTime
      subscriptionEndTime
      subscriptionDayOfWeek
      status
      date
      sessionType
      showCustomSessionTimes
      customSessionType {
        id
        name
        startTime
        endTime
        availabilityType
        requestOnly
      }
      marketplaceActivity {
        id
        name
        bookingType
        hideEndTimes
      }
      startTime
      endTime
      child {
        id
        fullName
      }
    }
  }
`;

export default getAllBookings;
