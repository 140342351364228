import { gql } from '@apollo/client';

import NURSERY_FIELDS_FRAGMENT from '../fragments/NurseryFieldsFragment';

const getBookings = gql`
  ${NURSERY_FIELDS_FRAGMENT}

  query GetBookings($startDate: Date!) {
    bookings(startDate: $startDate) {
      id
      date
      sessionType
      status
      cancellationReason
      cancellationDetails
      rejectionReason
      rejectionDetails
      isPrimary
      child {
        id
        fullName
        primaryNursery {
          ...NurseryFields
        }
      }
      nursery {
        ...NurseryFields
      }
      customSessionType {
        id
      }
    }
  }
`;

export default getBookings;
