import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useWizard } from 'app/wizard';
import cancelBookingMutation from 'graphql/mutations/cancelBooking';
import { BookingStatusEnum } from 'graphql/constants';
import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Textarea } from 'ui';

import styles from './CancellationAdditionalDetails.module.scss';

export const CancellationAdditionalDetails = () => {
  const { formData, onChange } = useWizard();
  const [showErrors, setShowErrors] = useState(false);
  const { id, date } = useParams();
  const history = useHistory();

  const [cancelBooking, { loading: isCancelling }] = useMutation(cancelBookingMutation, {
    onError(error) {
      console.log('[CancellationFlow] failed to cancel session', error);
      history.push('/error');
    },
    onCompleted() {
      history.push(`/session?date=${date}&sessionIds=${id}`);
    },
  });

  const isStepValid = formData.cancellationDetails !== '';

  const handleConfirmationClick = async (e) => {
    e.preventDefault();

    setShowErrors(false);

    if (!isStepValid) {
      setShowErrors(true);
      return;
    }

    const variables = {
      id,
      status: BookingStatusEnum.CANCELLED,
      ...formData,
    };

    await cancelBooking({
      variables,
    });
  };

  return (
    <DefaultAppPage
      title="Please provide additional details for your cancellation"
      footerActions={
        <Button
          visuallyDisabled={!isStepValid}
          onClick={handleConfirmationClick}
          expand
          isWorking={isCancelling}
          data-testid="cancel-session__confirm-cancellation"
        >
          Confirm cancellation
        </Button>
      }
      useHistoryBack
    >
      <Textarea
        placeholder="Type here…"
        rows={4}
        onChange={(e) => onChange('cancellationDetails', e.target.value)}
        value={formData.cancellationDetails}
        data-testid="cancel-session__cancellation-details"
      />

      <ErrorMessage isVisible={showErrors} className={styles.errorMessage}>
        Please add details to continue
      </ErrorMessage>
    </DefaultAppPage>
  );
};
