import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconChevronRight = ({
  className = '',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 12 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 17L10 9.5L2 2"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
