import { gql } from '@apollo/client';

const updateGuardian = gql`
  mutation UpdateGuardian($guardianId: UUID!, $fullName: String, $email: String) {
    guardianUpdate(guardianId: $guardianId, fullName: $fullName, email: $email) {
      guardian {
        id
        fullName
        phoneNumber
        status
      }
      success
      errors {
        message
        code
      }
    }
  }
`;

export default updateGuardian;
