import { FC, MouseEvent, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { logButtonEvent } from 'app/amplitude';
import { AnimateExpandFromPoint } from 'app/components';
import getGuardian from 'graphql/queries/getGuardian';
import updateGuardianMutation from 'graphql/mutations/updateGuardian';
import { SettingsPage } from 'layouts';

import { AccountDetailsTile, EditEmail, EditFullName } from './components';
import styles from './AccountDetails.module.scss';
import { usePageViewTracker } from 'hooks';

const BLANK_VALUE = '\u00A0';

interface IClickCoords {
  originX: string;
  originY: string;
}

export const AccountDetails: FC = () => {
  const history = useHistory();
  const [clickCoords, setClickCoords] = useState<null | IClickCoords>(null);

  usePageViewTracker('profile/detail');

  // Determine which property to edit based on the current hash
  const propertyToEdit = useMemo(() => history.location.hash.slice(1), [history.location.hash]);

  // If hash is set, see if there's a modal to display with it
  const ModalContent = useMemo(() => {
    if (propertyToEdit === 'fullName') return EditFullName;
    if (propertyToEdit === 'email') return EditEmail;

    return null;
  }, [propertyToEdit]);
  const showModal = ModalContent !== null;

  const { data, refetch: refetchGuardian } = useQuery(getGuardian);
  const [updateGuardian, { loading: guardianSaving }] = useMutation(updateGuardianMutation);
  const isExternallyManaged = data?.guardian?.anyChildrenExternallyManaged;

  /**
   *
   * @param {String} propertyName The property name to edit
   * @returns
   */
  const editAccountProperty = (propertyName: string) => (e: MouseEvent<HTMLButtonElement>) => {
    // If other fields become mutable this needs to be updated
    if (propertyName === 'fullName') {
      logButtonEvent('guardian_name_updated');
    } else {
      logButtonEvent('guardian_email_updated');
    }

    history.push({
      hash: propertyName,
    });

    setClickCoords({
      originX: `${e.nativeEvent.clientX}px`,
      originY: `${e.nativeEvent.clientY}px`,
    });
  };

  /**
   * Handles hiding the modal window
   */
  const hideModal = () => {
    history.goBack();
  };

  /**
   * Handles graphql mutation
   *
   * @param {Object} payload An object containing the updated data to post to API
   */
  // eslint-disable-next-line
  const handleUpdate = async (payload: any) => {
    let errorHappened = false;

    try {
      const variables = {
        guardianId: data?.guardian?.id,
        ...payload,
      };
      if (location.hash === '#fullName') {
        logButtonEvent('guardian_name_updated');
      } else if (location.hash === '#email') {
        logButtonEvent('guardian_email_updated');
      }
      const { errors } = await updateGuardian({
        variables,
      });

      if (errors) {
        console.warn('[AccountDetails] Failed to update', errors);
        errorHappened = true;
      }
    } catch (error) {
      console.warn('[AccountDetails] try/catch failed', error);
      errorHappened = true;
    }

    if (errorHappened) {
      history.push('/error', { from: '/profile/detail' });
    } else {
      refetchGuardian();
      hideModal();
    }
  };

  return (
    <SettingsPage title="Account details" useHistoryBack className={styles.container}>
      <div className={styles.tiles}>
        <AccountDetailsTile
          label="Full name"
          value={data?.guardian?.fullName || BLANK_VALUE}
          className={styles.tile}
          onEdit={editAccountProperty('fullName')}
        />

        <AccountDetailsTile
          label="Phone number"
          value={data?.guardian?.phoneNumber || BLANK_VALUE}
          className={styles.tile}
        />

        <AccountDetailsTile
          label="Email address"
          value={data?.guardian?.email || BLANK_VALUE}
          className={styles.tile}
          onEdit={!isExternallyManaged ? editAccountProperty('email') : undefined}
        />

        <AccountDetailsTile label="Password" value="••••••••" className={styles.tile} />
      </div>

      <footer className={styles.footerMessage}>
        {isExternallyManaged
          ? 'If the information is incorrect, please contact Pebble for help.'
          : 'To change your phone number, please'}
        <br />
        <a href="mailto:support@bookpebble.co.uk?subject=Guardian profile details incorrect">
          Contact us
        </a>
      </footer>

      <AnimateExpandFromPoint
        coords={clickCoords}
        isVisible={showModal}
        className={styles.editScreen}
      >
        {ModalContent && (
          <ModalContent
            guardian={data?.guardian}
            initialValue={data?.guardian?.[propertyToEdit] ?? ''}
            isSaving={guardianSaving}
            postToApi={handleUpdate}
            onBack={hideModal}
          />
        )}
      </AnimateExpandFromPoint>
    </SettingsPage>
  );
};
