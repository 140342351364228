import { gql } from '@apollo/client';

const nurseryFields = gql`
  fragment NurseryFields on NurseryType {
    id
    name
    phoneNumber
    addressLine1
    addressLine2
    city
    country
    postCode
    paymentsEnabled
    isNonPrimaryBookingEnabled
    bookingHoldThresholdDays
    isBookingHoldEnabled
    isExtracurricularActivityEnabled
    allowOnlyFullDaySessions
    isCustomSessionBookingsEnabled
    showCustomSessionTimes
    isExternallyManaged
    sessionTypes {
      id
      name
      startTime
      endTime
      availabilityType
    }
    organization {
      id
      name
    }
  }
`;

export default nurseryFields;
