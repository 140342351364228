import { FC } from 'react';

interface EmojiProps {
  symbol: string;
  label: string;
  hidden?: boolean;
}

export const Emoji: FC<EmojiProps> = ({ symbol, label, hidden = false }) => (
  <span className="emoji" role="img" aria-label={label || ''} aria-hidden={hidden}>
    {symbol}
  </span>
);
