import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';

import { usePortal } from 'hooks';
import { CloseButton } from 'ui';

import styles from './Modal.module.scss';

export const Modal = ({ className, children, onClose, isVisible }) => {
  const target = usePortal('modals');
  const containerRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      // For accessibility, focus the container element so that the user can use
      // the Tab key to correctly hit the first element in the newly visible modal
      containerRef.current.focus();
    }
  }, [isVisible]);

  const content = (
    <div className={styles.container} tabIndex="0" ref={containerRef}>
      <motion.div
        animate={{ height: isVisible ? 'auto' : 0 }}
        initial={false}
        className={classNames(styles.modal, !isVisible && styles.hidden)}
      >
        {isVisible && (
          <>
            <div className={classNames(styles.modalContent, className)}>{children}</div>

            <CloseButton onClick={onClose} className={styles.closeButton} />
          </>
        )}
      </motion.div>
    </div>
  );

  return createPortal(content, target);
};
