import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { logButtonEvent } from 'app/amplitude';
import { useWizard } from 'app/wizard';
import getOrganizations from 'graphql/queries/getOrganizations';
import { DefaultAppPage } from 'layouts';
import { Button, Pebbles, RadioButtonList } from 'ui';

import styles from './PickOrganizationScreen.module.scss';
import { StickyNav } from 'ui/StickyNav';

/**
 * Converts a list of nurseries to a format that is required for the RadioButtonList components
 *
 * @param {Array} nurseries A list of nurseries to convert to RadioListOptions
 */
const mapOrganizationsToRadioOptions = (nurseryList) =>
  nurseryList.map((nursery) => ({
    label: nursery.name,
    value: nursery.id,
  }));

export const PickOrganizationScreen = ({
  isPrefetched,
  organizationId,
  onNext,
  isSubmitting,
  hideExternallyManaged,
}) => {
  const { controls, onChange } = useWizard();
  const { data, loading } = useQuery(getOrganizations);

  const organizationOptions = useMemo(() => {
    if (hideExternallyManaged) {
      const nonExternalOrganisationOnly = data?.nurseryOrganizationsForGuardians.filter(
        (org) => !org.isManagedByExternalSystem,
      );
      return mapOrganizationsToRadioOptions(nonExternalOrganisationOnly ?? []);
    }
    return mapOrganizationsToRadioOptions(data?.nurseryOrganizationsForGuardians ?? []);
  }, [data]);

  const handleOnNext = () => {
    logButtonEvent('org_selected_next');
    if (onNext) {
      onNext();
    } else {
      controls.goToNextScreen();
    }
  };

  const linkClick = () => {
    controls.goToScreen('childInfoEntry');
  };

  return (
    <DefaultAppPage
      title={<div className={styles.title}>What group is your childcare provider part of?</div>}
      footerActions={
        <StickyNav className={styles.stickyNav}>
          <Button
            onClick={handleOnNext}
            disabled={!organizationId | isSubmitting}
            expand
            isWorking={isSubmitting}
          >
            Next
          </Button>
        </StickyNav>
      }
      useHistoryBack
    >
      {loading && <Pebbles className={styles.loadingIndicator} fillSpace />}

      {!loading && (
        <div className={styles.options}>
          <RadioButtonList
            options={organizationOptions}
            onChange={(id) => {
              const organization = data?.nurseryOrganizationsForGuardians.find((o) => o.id === id);
              logButtonEvent('org_selected');
              onChange({
                organizationId: id,
                organizationName: organization.name,
                isExternallyManaged: organization.isManagedByExternalSystem,
              });
            }}
            value={organizationId}
            className={styles.radioList}
          />
        </div>
      )}

      <div className={styles.goToActivitiesSection}>
        <p>Childcare provider not listed?</p>
        <p>
          Find and book activities with us <button onClick={linkClick}>here</button>
        </p>
      </div>
    </DefaultAppPage>
  );
};

PickOrganizationScreen.buttonEvent = 'onboarding_org_select';
