import { gql } from '@apollo/client';

const getAvailabilityForGuardianAtNonPrimary = gql`
  query GetAvailabilityForGuardianAtNonPrimary(
    $nurseryId: UUID!
    $childId: UUID!
    $date: Date!
    $sessionType: BookingSessionTypeEnum!
  ) {
    availabilityForGuardianAtNonPrimary(
      nurseryId: $nurseryId
      childId: $childId
      date: $date
      sessionType: $sessionType
    ) {
      nurseryRooms {
        id
        roomName
        nursery {
          id
          name
          organizationName
          showCustomSessionTimes
        }
        date
        sessionType
        # sessionPrice and availableSessionCount can used to display price and count
        # for custom and normal sessions
        sessionPrice
        availableSessionCount
        customSessionType {
          id
          name
          startTime
          endTime
          availabilityType
          requestOnly
        }
      }
    }
  }
`;

export default getAvailabilityForGuardianAtNonPrimary;
