import { formatPrice } from 'utilities/string';

export class RequestPayment {
  constructor(props) {
    this.hasPaymentDetails = props !== null;

    this.amount = Number(props?.finalAmount ?? 0);
    this.status = 'PENDING';
    this.statusLabel = 'Pending';
    this.isRefunded = false;

    this.date = props?.date;
  }

  get formattedAmount() {
    return formatPrice(this.amount);
  }

  get isPaymentExempt() {
    return this.amount === 0;
  }
}
