import { ChildType, GuardianStatus, GuardianType, Maybe } from 'graphql/generated-types';
import { getFirstName } from 'utilities/string';
import { getFields } from 'models/utils';
import { Child, IChildModel } from 'models/Child';

interface IDefaultFields {
  readonly id?: Maybe<string>;
  readonly status: Maybe<GuardianStatus>;
  readonly phoneNumber: string;
  readonly fullName: string;
  readonly children: IChildModel[];
  readonly email: string;
  readonly givenName: string;
  readonly cognitoId: Maybe<string>; // uuid
  readonly canBookChildcare: boolean;
  readonly canBookMarketplaceActivities: boolean;
  readonly externalSystemId: string | null;
  readonly anyChildrenExternallyManaged: boolean;
}

export interface IGuardianModel extends IDefaultFields {
  readonly isLoaded: boolean;
  readonly _children: Maybe<ChildType>[];
  readonly firstName: string;
  readonly hasChildren: boolean;
  readonly childrenEligibleForBooking: IChildModel[];
  readonly childrenEligibleForSessionBooking: IChildModel[];
  readonly isRejected: boolean;
}

const getChildrenEligibleForBooking = (children: IChildModel[]) => {
  const activeAccounts = children.filter((c) => c.isActive);
  const unrecognizedAccounts = children.filter((c) => c.isUnrecognized);

  return [...activeAccounts, ...unrecognizedAccounts];
};

const defaultFields: IDefaultFields = {
  id: null,
  status: null,
  phoneNumber: '',
  fullName: '',
  email: '',
  givenName: '',
  cognitoId: null,
  children: [],
  canBookChildcare: false,
  canBookMarketplaceActivities: false,
  anyChildrenExternallyManaged: false,
  externalSystemId: null,
};

export const Guardian = (guardianData?: Maybe<GuardianType>): IGuardianModel => {
  const fields = getFields<GuardianType, IDefaultFields>(guardianData || null, defaultFields);

  const children = guardianData?.children?.length
    ? guardianData?.children.map((c) => Child(c))
    : [];
  const childrenEligibleForBooking = getChildrenEligibleForBooking(children);

  return {
    ...fields,
    isLoaded: !!guardianData?.id && typeof guardianData.id === 'string',
    children: children,
    // Store copy of raw/unmodelized children
    _children: guardianData?.children || [],
    firstName: getFirstName(fields.fullName),
    hasChildren: children.length > 0,
    childrenEligibleForBooking: childrenEligibleForBooking,
    childrenEligibleForSessionBooking: childrenEligibleForBooking.filter(
      (c) => c.isRegisteredWithNursery,
    ),
    isRejected: fields.status === GuardianStatus.Rejected,
  };
};
