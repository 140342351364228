import { parseISO, isWithinInterval, isFuture, isValid, format, parse } from 'date-fns';

export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Determines whether or not a string representation of a date is valid
 *
 * @param {String} string The formatted string ("dd | mm | yyyy")
 * @returns {Boolean
 */
export const isValidBirthDate = (string) => {
  const [day, month, year] = string.split(' | ');
  const parsedDate = parseISO([year, month, day].join('-'));

  return isValid(parsedDate) && !isFuture(parsedDate);
};

export const getChildPricingByDay = (priceList, dateStamp) => {
  if (!Array.isArray(priceList)) return null;

  const date = parseISO(dateStamp);

  return (
    priceList.find((price) => {
      const startDate = parseISO(price.startDate);
      const endDate = parseISO(price.endDate);

      return isWithinInterval(date, { start: startDate, end: endDate });
    }) || null
  );
};

export const formatTimeString = (time, timeFormat = 'HH:mm') => {
  const formatString = time?.length === 5 ? 'HH:mm' : 'HH:mm:ss';
  return format(parse(time || '00:00:00', formatString, new Date()), timeFormat);
};

export const getFormattedActivityTimes = (activity = {}) => {
  const dayOfTheWeek = parse(Number(activity.dayOfTheWeek) + 1, 'i', new Date());
  const startTime = parse(activity.startTime, 'HH:mm:ss', new Date());
  const endTime = parse(activity.endTime, 'HH:mm:ss', new Date());
  const formattedStartTime = format(startTime, 'HH:mm');
  const formattedEndTime = format(endTime, 'HH:mm');
  return `${format(dayOfTheWeek, 'EEEE')} ${formattedStartTime}-${formattedEndTime}`;
};
