import { useNurseryData } from 'graphql/hooks';

/**
 * Checks a given nursery to see if it allows full day bookings only
 *
 * @param {String} nurseryId The nurseryId to search for
 *
 * @returns {Boolean}
 */
export const useNurseryIsFullDayOnly = (nurseryId) => {
  const nursery = useNurseryData(nurseryId);

  return nursery?.allowOnlyFullDaySessions === true ?? false;
};
