import { useForm, Controller, useWatch } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import { DefaultAppPage } from 'layouts';
import { Button, Label, RadioButtonList } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import { updateState } from 'pages/Onboarding/state/updateFormState';

import styles from './ChildEstablishmentInfoScreen.module.scss';
import { usePageViewTracker } from 'hooks';
import { StickyNav } from 'ui/StickyNav';

export const ChildEstablishmentInfoScreen = (props) => {
  const { history } = props;

  usePageViewTracker('onboarding/child-establishment');

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm();

  const { actions, state } = useStateMachine({ updateState });

  const establishment = useWatch({
    control,
    name: 'establishment',
    defaultValue: state.establishment,
  });

  const onSubmit = (newData) => {
    logButtonEvent(`onboarding_${establishment}_next_selected`);
    actions.updateState(newData);
    const nextRoute =
      establishment === 'nursery' ? `/onboarding/pick-organization` : `/onboarding/child-info`;
    history.push({ pathname: nextRoute, search: history.location.search });
  };

  return (
    <DefaultAppPage
      title={'Pebble works with childcare and activity providers.'}
      className={styles.container}
      footerActions={[
        <StickyNav>
          <Button
            type="submit"
            form="child-establishment-form"
            onClick={logButtonEvent('CE_next')}
            disabled={!isValid}
            expand
            key="next"
          >
            Next
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      <main>
        <form id="child-establishment-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputRow}>
            <Label className={styles.dropdownLabel}>What would you like to do?</Label>
            <Controller
              control={control}
              name="establishment"
              defaultValue={state.establishment}
              rules={{ required: 'Please select an establishment' }}
              render={({ field: { value, name } }) => (
                <RadioButtonList
                  className={styles.radioButtons}
                  options={[
                    {
                      value: 'activities',
                      label: 'Book activities only',
                    },
                    {
                      value: 'nursery',
                      label:
                        'Check if my childcare provider is listed (book childcare & activities)',
                      description:
                        "If your nursery, after school club or breakfast club is listed then you'll be able to register to book extra sessions.",
                    },
                  ]}
                  value={value}
                  onChange={(value) => {
                    setValue(name, value, { shouldValidate: true });
                    const activitiesOnly = value === 'activities';
                    activitiesOnly
                      ? logButtonEvent('add_child_activities_next_selected')
                      : logButtonEvent('add_child_nursery_next_selected');
                  }}
                  name={name}
                />
              )}
            />
          </div>
        </form>
      </main>
    </DefaultAppPage>
  );
};

ChildEstablishmentInfoScreen.defaultProps = {
  fullName: '',
  birthDate: '',
  title: 'Add a child',
};
