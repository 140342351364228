import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Input } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import { updateTemporaryChildData } from 'pages/Onboarding/state/updateFormState';
import { isValidBirthDate } from 'utilities/date';

import styles from './ChildInfoEntryScreen.module.scss';
import { usePageViewTracker } from 'hooks';
import { StickyNav } from 'ui/StickyNav';

const isUnique = (fullName, birthDate, state, currentChildIndex) => {
  const newArray = (state?.temporaryChildData || []).slice();
  newArray.splice(currentChildIndex, 1);
  const isDuplicated = newArray.find(
    (child) => child.fullName === fullName && child.birthDate === birthDate,
  );

  if (isDuplicated) {
    return "You've already added a child with the same combination of name and date of birth";
  }
  return true;
};

export const ChildInfoEntryScreen = (props) => {
  const { history } = props;
  const { id } = useParams();
  const temporaryChildIndex = id || 0;
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isValid },
  } = useForm();
  const { actions, state } = useStateMachine({ updateTemporaryChildData });

  usePageViewTracker('onboarding/child_info_entry');

  const getFieldFromState = (field) => state.temporaryChildData?.[temporaryChildIndex]?.[field];
  const getErrorByField = (field) => errors?.temporaryChildData?.[temporaryChildIndex]?.[field];
  const getFieldName = (field) => `temporaryChildData[${temporaryChildIndex}][${field}]`;

  const fullName = useWatch({
    control,
    name: getFieldName('fullName'),
    defaultValue: getFieldFromState('fullName'),
  });
  const birthDate = useWatch({
    control,
    name: getFieldName('birthDate'),
    defaultValue: getFieldFromState('birthDate'),
  });

  const onSubmit = (data) => {
    logButtonEvent('org_selected_next');
    actions.updateTemporaryChildData(data);

    if (state.establishment === 'nursery') {
      history.push({
        pathname: `/onboarding/pick-nursery-room/${temporaryChildIndex}`,
        search: history.location.search,
      });
    } else {
      history.push({ pathname: `/onboarding/opt-in`, search: history.location.search });
    }
  };

  return (
    <DefaultAppPage
      title={'A little bit about your child'}
      className={styles.container}
      footerActions={[
        <StickyNav key="next">
          <Button type="submit" form="child-info-form" expand>
            Next
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      <main>
        <form id="child-info-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputRow}>
            <Input
              label="What’s your child’s name?"
              placeholder="Full name"
              aria-label="Full name"
              {...register(getFieldName('fullName'), {
                required: 'Please enter a first and last name',
                validate: (v) => {
                  if (v.split?.(' ').filter(Boolean).length <= 1) {
                    return 'Please enter a first and last name';
                  }

                  return isUnique(fullName, birthDate, state, temporaryChildIndex);
                },
                onBlur: (e) => {
                  const action =
                    state.establishment === 'nursery'
                      ? 'full_name_added'
                      : 'child_activities_name_add';
                  logButtonEvent(action);
                  return e;
                },
              })}
              defaultValue={getFieldFromState('fullName')}
            />
            <ErrorMessage
              isVisible={!isValid && getErrorByField('fullName')}
              className={styles.errorMessage}
            >
              {getErrorByField('fullName')?.message}
            </ErrorMessage>
          </div>

          <div className={styles.inputRow}>
            <Input
              label="When is your child’s birthday?"
              mask="99 | 99 | 9999"
              maskPlaceholder="DD | MM | YYYY"
              placeholder="DD | MM | YYYY"
              aria-label="DD | MM | YYYY"
              {...register(getFieldName('birthDate'), {
                required: 'Please enter a date of birth',
                validate: (v) => {
                  if (!isValidBirthDate(v)) {
                    return 'This date is not valid';
                  }

                  return isUnique(fullName, birthDate, state, temporaryChildIndex);
                },
                onChange: (e) => {
                  const action =
                    state.establishment === 'nursery' ? 'DOB_added' : 'child_activities_DOB_add';
                  logButtonEvent(action);
                  return e;
                },
              })}
              defaultValue={getFieldFromState('birthDate')}
            />
            <ErrorMessage
              isVisible={!isValid && getErrorByField('birthDate')}
              className={styles.errorMessage}
            >
              {getErrorByField('birthDate')?.message}
            </ErrorMessage>
          </div>
        </form>
      </main>
    </DefaultAppPage>
  );
};

ChildInfoEntryScreen.defaultProps = {
  title: 'Add a child',
  fullName: '',
  birthDate: '',
  establishment: '',
};
