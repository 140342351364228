import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery, useMutation } from '@apollo/client';

import { logButtonEvent } from 'app/amplitude';
import { BookingRequestTypeEnum, BookingSessionTypeEnum, BookingTypeEnum } from 'graphql/constants';
import { useSessionPrice } from 'graphql/hooks';
import getCustomSessionTypeById from 'graphql/queries/getCustomSessionTypeById';
import initiatePaidSessionBooking from 'graphql/mutations/initiatePaidSessionBooking';
import { useNurseryCanAcceptPayments, usePageViewTracker, useQueryParams } from 'hooks';
import { IconLightningBolt } from 'icons';
import { BookingDetailsPage } from 'layouts';
import { useBookingParams } from 'pages/Booking';
import { Button, FullScreen, Pebbles } from 'ui';
import { getFirstName, QUERY_PARAM_CHECKMARK } from 'utilities/string';
import { combineNames } from 'utilities/jsx';
import { SessionCost } from 'app/components/SessionCost';
import { PaymentExemptDialog } from 'app/components';

import styles from './ReviewBooking.module.scss';
import { ExternalBookingErrorDialog } from 'app/components/ExternalBookingErrorDialog';
import { getStripe } from 'utilities/stripe';

const initialPromotionState = {
  finalAmount: null,
  id: null,
};

const TermsAndConditions = () => (
  <i
    style={{
      fontSize: '12px',
      marginTop: '10px',
    }}
  >
    By clicking proceed to payment, you agree to the{' '}
    <a
      href="https://bookpebble.co.uk/terms/care-seeker"
      target="_blank"
      style={{ marginLeft: '3px' }}
    >
      Care Seeker Terms
    </a>
    {/**/}. For further information about how Pebble processes your personal data, please refer to
    Pebble's
    <a
      href="https://www.bookpebble.co.uk/privacy-policy"
      target="_blank"
      style={{
        marginLeft: '3px',
      }}
    >
      Privacy Policy
    </a>
    {/**/}.
  </i>
);

export const ReviewBooking = () => {
  const queryParams = useQueryParams();
  const { location, push, replace } = useHistory();

  usePageViewTracker('booking/review');

  const {
    createBooking,
    submitBookingSaving,
    isPaymentExempt,
    hasValidQueryParams,
    date,
    nursery,
    requestType,
    selectedChildren,
    sessionType,
    sessionId,
    getBookingMetadata,
  } = useBookingParams();
  const [promotion, setPromotion] = useState(initialPromotionState);
  const [showExternalBookingError, setShowExternalBookingError] = useState(false);
  const [showPaymentExemptDialog, setShowPaymentExemptDialog] = useState(false);

  const canNurseryAcceptPayments = useNurseryCanAcceptPayments(nursery.id);

  const {
    loading: priceListLoading,
    price,
    priceListMissing,
  } = useSessionPrice({
    childId: queryParams.children,
    date: queryParams.date,
    isPaymentExempt,
    nurseryId: queryParams.nurseryId,
    sessionType,
  });

  const { data: customSession, loading: customSessionLoading } = useQuery(
    getCustomSessionTypeById,
    {
      variables: {
        customSessionType: sessionId,
        child: queryParams.children,
      },
      skip: !queryParams.children || !sessionId,
    },
  );

  const [getCheckout, { loading: checkoutLoading }] = useMutation(initiatePaidSessionBooking);

  const customSessionDetails = useMemo(() => {
    return customSession?.customSessionTypeById;
  }, [customSession?.customSessionTypeById]);

  const canUsePayments = canNurseryAcceptPayments && !priceListMissing && !isPaymentExempt;

  if (priceListLoading || customSessionLoading) {
    // Only displays if the user refreshes or lands here from a deep-link
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  if (!hasValidQueryParams) {
    replace('/error', { from: '/home' });
    return null;
  }

  const handleCreateBookingClick = async () => {
    logButtonEvent('session_proceed_payment_selected');

    if (submitBookingSaving) return;
    logButtonEvent('session_confirm_booking_selected');

    const { forceRequest } = queryParams;

    try {
      const bookingId = await createBooking({
        promotion: promotion?.id,
        // force request booking for external nursery (.e.g BusyBees)
        ...(forceRequest && { forceRequest: Boolean(forceRequest) }),
      });
      const confirmationParams = {
        type: requestType,
        bookingId,
        ...(!canUsePayments && {
          isPaymentExempt: QUERY_PARAM_CHECKMARK,
        }),
      };

      if (bookingId) {
        push(`/booking/confirmation?${queryString.stringify(confirmationParams)}`);
      }
    } catch (error) {
      if (error.message === 'EXTERNAL_BOOKING_ERROR') {
        setShowExternalBookingError(true);
      } else {
        push('/error', { from: '/booking ' });
      }
    }
  };

  const makeRequestBooking = () => {
    setShowExternalBookingError(false);

    const newQueryParams = {
      ...queryParams,
      forceRequest: true,
      type: BookingRequestTypeEnum.REQUEST,
    };
    replace(`/booking/review?${queryString.stringify(newQueryParams)}`);
  };

  const closePaymentExemptDialog = () => {
    setShowPaymentExemptDialog(false);
  };

  const handleClickExemptPayment = () => {
    logButtonEvent('session_payment_exempt');
    if (!promotion?.id) {
      push(`/booking/payment-exempt${location.search}`);
    } else {
      setShowPaymentExemptDialog(true);
    }
  };

  const handlePayNowClick = async () => {
    const stripe = await getStripe();
    const { child, date, customSessionType, room, forceRequest } = getBookingMetadata();
    const input = {
      child,
      date,
      customSessionType,
      room,
      forceRequest,
      ...(promotion.id && {
        promotion: promotion.id,
      }),
    };

    const { data } = await getCheckout({ variables: input });

    stripe.redirectToCheckout({
      sessionId: data.initiatePaidSessionBooking.sessionBookingStripeCheckout.checkoutSessionId,
    });
  };

  const childNames = selectedChildren.map((c) => getFirstName(c?.fullName));
  const isInstantBook = requestType === BookingRequestTypeEnum.INSTANT;
  const sessionTypeLabel = sessionTypeMap[sessionType];
  const isZeroCostBooking = promotion ? promotion.finalAmount === 0 : false;
  let footer = (
    <>
      <Button
        onClick={handleCreateBookingClick}
        className={styles.submitButton}
        expand
        isWorking={submitBookingSaving}
        data-testid="booking-review__request-button"
      >
        {isInstantBook ? 'Book now' : 'Request'}
      </Button>
      <TermsAndConditions />
    </>
  );
  if (isZeroCostBooking) {
    footer = (
      <>
        <Button
          onClick={handleCreateBookingClick}
          className={styles.submitButton}
          expand
          isWorking={submitBookingSaving}
          data-testid="booking-review__request-button"
        >
          Confirm Booking
        </Button>
        <TermsAndConditions />
      </>
    );
  } else if (canUsePayments) {
    footer = (
      <div className={styles.footerWrapper}>
        <Button
          onClick={handlePayNowClick}
          className={styles.submitButton}
          expand
          isWorking={submitBookingSaving}
          data-testid="booking-review__proceed-button"
        >
          Next
        </Button>
        <div className={styles.bypassPaymentLinkWrapper}>
          <Button
            className={styles.bypassPaymentLink}
            variant="link"
            onClick={handleClickExemptPayment}
          >
            Don’t need to pay on Pebble?
          </Button>
        </div>
        <TermsAndConditions />
      </div>
    );
  }

  let sessionCostCopy = isPaymentExempt
    ? 'You are not paying for this session on Pebble. Your nursery may still charge you for it if applicable. '
    : null;

  return (
    <BookingDetailsPage
      title={
        <div className={styles.title}>
          {isInstantBook ? 'Instant booking' : 'Booking request'} for
          <br /> {combineNames(childNames)} at{' '}
          <span className={styles.nurseryName}>{nursery?.name}</span>
        </div>
      }
      nursery={nursery}
      date={date}
      sessionType={sessionTypeLabel}
      requestType={requestType}
      footer={footer}
      useHistoryBack
      customSessionDetails={customSessionDetails}
      onBack={() => logButtonEvent('back_booking_review')}
    >
      {canUsePayments && (
        <SessionCost
          price={customSessionDetails ? customSessionDetails.price : price}
          sessionCostCopy={sessionCostCopy}
          nurseryId={nursery.id}
          bookingDate={date}
          bookingType={BookingTypeEnum.Session}
          onDiscountApplied={setPromotion}
          onDiscountRemoved={setPromotion}
          className="sessionBooking"
        />
      )}

      <section className={styles.bookingType}>
        <h2>
          {isInstantBook && <IconLightningBolt size={24} className={styles.lightningBolt} />}
          This is {isInstantBook ? 'an instant booking' : 'a booking request'}
        </h2>

        <p>
          {isInstantBook
            ? `Your booking will be confirmed right away.`
            : `We can’t confirm your booking just yet. We’ll do our best to accomodate your request and get back to you within the next business day.`}
        </p>
      </section>
      <ExternalBookingErrorDialog
        show={showExternalBookingError}
        onMakeRequestBooking={makeRequestBooking}
      />
      <PaymentExemptDialog
        show={showPaymentExemptDialog}
        message={
          <>
            <p>Discounts are only valid on bookings paid via Pebble.</p>
            <p>
              If you don’t need to pay on Pebble, click back to remove the discount code to proceed.
            </p>
          </>
        }
        onClose={closePaymentExemptDialog}
      />
    </BookingDetailsPage>
  );
};

/**
 * Maps a `sessionType` to an appropriate label.
 *
 * If the session type doesn't exist here it's invalid.
 */
const sessionTypeMap = {
  [BookingSessionTypeEnum.AM]: 'Morning',
  [BookingSessionTypeEnum.PM]: 'Afternoon',
  [BookingSessionTypeEnum.FULL]: 'Full Day',
};
