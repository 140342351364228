import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { logButtonEvent } from 'app/amplitude';
import { BackButton } from 'app/components';
import { useNurseryCalendar } from 'graphql/hooks';
import { useNurseryCanAcceptPayments, useNurseryIsFullDayOnly, usePageViewTracker } from 'hooks';
import { useSessionSelectionParams } from 'pages/Booking';
import { Button, Dialog, FullScreen, Pebbles } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import { sequencedDelimiterJoin } from 'utilities/array';
import { SERVER_DATE_FORMAT } from 'utilities/date';

import { SessionDayPicker } from './components/SessionDayPicker';
import styles from './DateSelection.module.scss';

const BOOKING_CALENDAR_WEEKS = 24; // How many weeks to display inside the valendar view

export const DateSelection = () => {
  const history = useHistory();
  const [day, setDay] = useState(null);

  usePageViewTracker('booking/date_selection');

  const [acceptsOutOfBounds, setAcceptsOutOfBounds] = useState(false);
  const { allowInstantBookings, childNames, nurseryId, hasValidQueryParams, queryParams } =
    useSessionSelectionParams();
  const canNurseryAcceptPayments = useNurseryCanAcceptPayments(nurseryId);
  const isFullDaySessionsOnly = useNurseryIsFullDayOnly(nurseryId);
  const childIds = useMemo(() => queryParams.children.split(','), [queryParams]);

  const {
    calendar: availabilityByDay,
    loading,
    priceListMissing,
  } = useNurseryCalendar({
    canInstantBook: allowInstantBookings,
    childIds,
    fetchPricing: canNurseryAcceptPayments,
    isFullDaySessionsOnly,
  });

  const canUsePayments = canNurseryAcceptPayments;

  if (!hasValidQueryParams) {
    history.push('/error', { from: '/home' });
  }

  if (loading) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  const handlePriceListMissingConfirmation = () => {
    setAcceptsOutOfBounds(true);
  };

  const handleDayPicked = (selectedDay) => {
    setDay(selectedDay);
    history.push(
      '/booking/session-selection' +
        history.location.search +
        `&date=${format(selectedDay, SERVER_DATE_FORMAT)}`,
    );
  };

  return (
    <main className={styles.container}>
      <div className={styles.floatingHeader}>
        <div className={styles.floatingHeaderContent}>
          <BackButton
            onClick={() => {
              logButtonEvent('back_calendar');
            }}
          />

          <h1 className={styles.title}>Select a date for {sequencedDelimiterJoin(childNames)}</h1>

          <div className={styles.floatingWeekHeader}>
            <div className={styles.week}>M</div>
            <div className={styles.week}>T</div>
            <div className={styles.week}>W</div>
            <div className={styles.week}>T</div>
            <div className={styles.week}>F</div>
            <div className={styles.week}>S</div>
            <div className={styles.week}>S</div>
          </div>
        </div>
      </div>

      <div className={styles.calendarView}>
        <div className={styles.dayPickerWrapper}>
          <SessionDayPicker
            calendar={availabilityByDay}
            onChange={handleDayPicked}
            pickedDay={day}
            weeksToRender={BOOKING_CALENDAR_WEEKS}
          />

          <footer className={styles.supportText}>
            Need to book for a date that is not shown?
            <a href="mailto:support@bookpebble.co.uk">Contact us</a>
          </footer>
        </div>
      </div>

      <div className={styles.floatingLegend}>
        <div className={styles.legendItems}>
          <div className={styles.instantBook}>Instant booking available</div>
          <div className={styles.requestOnly}>Request only</div>
        </div>
      </div>

      {canUsePayments && (
        <>
          <Dialog
            title="Pricing issue"
            isVisible={priceListMissing && !acceptsOutOfBounds}
            onClose={handlePriceListMissingConfirmation}
            menu={
              <Button expand onClick={handlePriceListMissingConfirmation}>
                Continue booking
              </Button>
            }
          >
            <p>
              We were unable to retrieve a rate for your booking. You will not be charged for this
              session in Pebble but the nursery may still require payment. Please contact{' '}
              <a href="mailto:support@bookpebble.co.uk" className={styles.supportLink}>
                support@bookpebble.co.uk
              </a>{' '}
              with any questions.
            </p>
          </Dialog>
          <ModalBackdrop isVisible={priceListMissing && !acceptsOutOfBounds} />
        </>
      )}
    </main>
  );
};
