import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import { shouldFullstoryRecord } from 'utilities/fullstory';

import './index.css';
import App from 'app';
import * as serviceWorker from './serviceWorker';

// Fullstory should only be used in staging or production, no need to use in local
if (process.env.REACT_APP_FULLSTORY_ORG !== undefined) {
  FullStory.init({
    devMode: !shouldFullstoryRecord,
    orgId: process.env.REACT_APP_FULLSTORY_ORG,
  });
}

if (process.env.REACT_APP_ENV !== 'production') {
  const React = require('react');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
