import { createContext } from 'react';

import { noOp, asyncNoOp } from 'utilities/functions';
import { useAuth } from './useAuth';

export const AuthContext = createContext({
  resendVerificationCode: noOp,
  isAuthenticated: noOp,
  getUserPhoneNumber: noOp,
  isInitializing: true,
  activeUser: null,
  login: asyncNoOp,
  logout: asyncNoOp,
  signup: asyncNoOp,
  confirmSignup: asyncNoOp,
  forgotPasswordInitiate: asyncNoOp,
  forgotPasswordSubmit: asyncNoOp,
});

export const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useAuth()}>{children}</AuthContext.Provider>
);
