import { FC, ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';

import { BackButton } from 'app/components';
import { useWindowHeight } from 'hooks';
import { Pebbles } from 'ui';

import styles from './DefaultAppPage.module.scss';
import { Link, useLocation } from 'react-router-dom';

interface IDefaultAppPageProps {
  className?: string;
  headerClass?: string;
  isLoading?: boolean;
  useHistoryBack?: boolean;
  useResponsiveCentered?: boolean;
  title?: ReactNode;
  introText?: ReactNode;
  footer?: ReactNode;
  footerActions?: ReactNode;
  illustration?: ReactNode;
  onBack?: (e: MouseEvent<HTMLButtonElement>) => void;
  loginLink?: ReactNode;
}

export const DefaultAppPage: FC<IDefaultAppPageProps> = ({
  children,
  loginLink,
  className,
  footer,
  footerActions,
  headerClass,
  illustration,
  introText,
  isLoading,
  onBack,
  title,
  useHistoryBack,
  useResponsiveCentered = true,
}) => {
  const { boxStyle } = useWindowHeight();
  const location = useLocation();

  const hasBackButton = typeof onBack === 'function' || useHistoryBack;

  return (
    <div className={classNames(styles.container, className)} style={boxStyle}>
      {hasBackButton && (
        <div className={classNames(styles.header, headerClass)}>
          <BackButton useHistoryBack={useHistoryBack} onClick={onBack} />
          {loginLink && (
            <Link
              className={styles.loginLink}
              to={{ pathname: '/login', search: location?.search }}
            >
              Log in
            </Link>
          )}
        </div>
      )}

      <div className={classNames(styles.content, useResponsiveCentered && styles.centered)}>
        {illustration && <div className={styles.illustration}>{illustration}</div>}
        {title && <div className={styles.title}>{title}</div>}
        {introText && <div className={styles.introText}>{introText}</div>}
        {isLoading ? (
          <div className={styles.loadingIndicator}>
            <Pebbles />
          </div>
        ) : (
          children
        )}
      </div>

      {footer && <div className={styles.footer}>{footer}</div>}

      {footerActions && <div className={styles.footerActions}>{footerActions}</div>}
    </div>
  );
};
