import { gql } from '@apollo/client';

import NURSERY_FIELDS_FRAGMENT from '../fragments/NurseryFieldsFragment';

const getBookingsByIds = gql`
  ${NURSERY_FIELDS_FRAGMENT}

  query GetBookingsByIds($bookingIds: [UUID]!) {
    bookingsByIds(bookingIds: $bookingIds) {
      id
      date
      sessionType
      customSessionType {
        name
        startTime
        endTime
        availabilityType
        id
      }
      status
      cancellationReason
      cancellationDetails
      rejectionReason
      rejectionDetails
      finalAmount
      originalAmount
      discount
      child {
        id
        fullName
        primaryNursery {
          ...NurseryFields
        }
      }
      nursery {
        ...NurseryFields
      }
      payment {
        amount
        created
        status
        isRefunded
        processor
        paymentMethod {
          card {
            brand
            last4
          }
        }
      }
      promotion {
        code
      }
      paymentMethod {
        card {
          brand
          last4
        }
      }
    }
  }
`;

export default getBookingsByIds;
