import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconExclamationTriangle = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.34965 1.91365L1.24285 15.4473C1.07571 15.7368 0.987272 16.0649 0.986336 16.3992C0.9854 16.7334 1.072 17.0621 1.23752 17.3525C1.40304 17.6429 1.64171 17.8849 1.92979 18.0544C2.21786 18.2239 2.5453 18.315 2.87953 18.3187H19.0931C19.4274 18.315 19.7548 18.2239 20.0429 18.0544C20.3309 17.8849 20.5696 17.6429 20.7351 17.3525C20.9007 17.0621 20.9873 16.7334 20.9863 16.3992C20.9854 16.0649 20.8969 15.7368 20.7298 15.4473L12.623 1.91365C12.4524 1.63236 12.2121 1.39979 11.9254 1.23839C11.6388 1.07698 11.3153 0.992188 10.9863 0.992188C10.6573 0.992188 10.3339 1.07698 10.0472 1.23839C9.76052 1.39979 9.52028 1.63236 9.34965 1.91365V1.91365Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9863 6.83301V10.6615"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9863 14.4902H10.9963"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
