import { Error } from 'pages';
import { Button } from 'ui';

import newLogo from './newLogo.png';
import styles from './NewNameNotice.module.scss';

export const NewNameNotice = () => (
  <Error
    illustration={newLogo}
    title="Sprout is now Pebble!"
    messageText="Don’t worry, we’ve changed our name but you can still book extra sessions at your nursery. You will be redirected to our new website, see you there!"
    action={
      <Button href="https://app.bookpebble.co.uk/" className={styles.pebbleButton}>
        Go to Pebble
      </Button>
    }
  />
);
