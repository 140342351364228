import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import styles from './ExternalBookingErrorDialog.module.scss';

interface IExternalBookingErrorDialogProps {
  show: boolean;
  onMakeRequestBooking: () => void;
}

export const ExternalBookingErrorDialog: FC<IExternalBookingErrorDialogProps> = ({
  show,
  onMakeRequestBooking,
}) => {
  return (
    <>
      <Dialog
        isVisible={show}
        title="Booking error"
        onClose={undefined}
        menu={
          <>
            <Button onClick={onMakeRequestBooking} expand>
              Make a request
            </Button>
            <br />
            <Link to="/home">Back to home</Link>
          </>
        }
        zIndex="6"
      >
        <div className={styles.message}>
          Sorry, we can’t take your instant booking at the moment but you can send a request to the
          nursery instead.
        </div>
      </Dialog>
      <ModalBackdrop isVisible={show} onClick={() => null} opacity={0.7} />
    </>
  );
};
