import { IllustratedInfoPage } from 'layouts';
import { Button } from 'ui';
import { StickyNav } from 'ui/StickyNav';

import illustration from './illustration.png';
import styles from './ResetPasswordConfirmation.module.scss';

export const ResetPasswordConfirmation = () => (
  <IllustratedInfoPage
    title="Password successfully changed"
    illustration={
      <img
        src={illustration}
        className={styles.illustration}
        alt="Woman with briefcase holding child's hand"
      />
    }
    footer={
      <StickyNav>
        <Button href="/login" expand>
          Login
        </Button>
      </StickyNav>
    }
  >
    You may now login using your phone number and new password.
  </IllustratedInfoPage>
);
