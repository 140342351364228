export const Present = ({
  className = '',
  color = 'currentColor',
  size = 24,
  stroke = null,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.88933 2.72267C5.33565 3.24618 5.01037 3.9483 5.00426 4.67473C5.00426 5.65538 5.35658 6.34813 5.80074 6.87095H2.87997C2.50922 6.87095 2.17188 7.21972 2.17188 7.60304V11.5074C2.17188 11.8907 2.50921 12.2395 2.87997 12.2395H3.35196V21.2681C3.35196 21.6514 3.6893 22.0002 4.06006 22.0002H19.1652C19.536 22.0002 19.8733 21.6514 19.8733 21.2681V12.2395H20.3453C20.7161 12.2395 21.0534 11.8907 21.0534 11.5074V7.60304C21.0534 7.21973 20.7161 6.87095 20.3453 6.87095H17.4245C17.9718 6.24639 18.221 5.45647 18.221 4.68239C18.221 3.94726 17.7883 3.1626 17.3359 2.72267C16.5994 2.15155 15.8589 1.9006 15.0347 2.03636C14.2085 2.23915 13.6048 2.98961 13.2351 3.63764C13.0316 4.01073 12.808 4.4621 12.527 4.99493C12.2735 4.80507 11.9664 4.67458 11.6125 4.67458C11.2585 4.67458 10.9514 4.8049 10.6979 4.99493C10.4169 4.46193 10.1933 4.01072 9.98985 3.63764C9.58139 2.86592 9.00881 2.21688 8.21263 2.04402C7.12851 1.99502 6.44974 2.24903 5.88933 2.72267ZM7.94707 3.4852C8.37034 3.60786 8.59076 4.06977 8.75096 4.36206C9.04845 4.90765 9.44886 5.73926 10.1006 6.87082H9.4811C8.19632 6.7502 7.41872 6.41778 6.99545 6.04722C6.60362 5.63245 6.42564 5.12394 6.42024 4.68226C6.42469 4.43592 6.57253 4.05875 6.84072 3.8054C7.23073 3.48709 7.51376 3.44148 7.94707 3.4852ZM16.3847 3.80556C16.6529 4.05905 16.8007 4.43608 16.8052 4.68242C16.7497 5.26529 16.5508 5.75796 16.23 6.04738C15.8069 6.41775 15.0291 6.75037 13.7443 6.87097H13.1248C13.7766 5.73928 14.177 4.90785 14.4745 4.36221C14.6643 3.95577 14.9295 3.62316 15.2709 3.48535C15.6682 3.41118 16.1277 3.56822 16.3847 3.80556ZM3.58832 8.3351H9.9608V10.7753H3.58832V8.3351ZM11.3769 8.3351H11.8489V10.7753H11.3769V8.3351ZM13.2651 8.3351H19.6376V10.7753H13.2651V8.3351Z"
      fill="#FB54AE"
    />
  </svg>
);
