import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import getNurseries from 'graphql/queries/getNurseries';
import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Pebbles, RadioButtonList } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import { updateTemporaryChildData } from 'pages/Onboarding/state/updateFormState';

import styles from './PickNurseryRoomScreen.module.scss';
import { usePageViewTracker, useQueryParams } from 'hooks';
import { StickyNav } from 'ui/StickyNav';

/**
 * Converts a list of rooms from the server into the format required by `RadioButtonList`
 *
 * @param {Array} roomList The list of rooms to convert
 */
const mapRoomsToOptions = (roomList) => [
  ...roomList.map((room) => ({
    label: room.name,
    value: room.id,
  })),
  {
    value: null,
    label: "I'm not sure",
  },
];

export const PickNurseryRoomScreen = (props) => {
  const { history } = props;
  const { id, organisation } = useQueryParams();
  const temporaryChildIndex = id || 0;
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm();
  const { actions, state } = useStateMachine({ updateTemporaryChildData });

  usePageViewTracker('onboarding/pick_nursery_room');

  const { data: nurseryData, loading } = useQuery(getNurseries, {
    variables: {
      organizationId: organisation || state.organizationId,
    },
  });

  const nurseryRooms = useMemo(() => {
    if (!nurseryData || !nurseryData.nurseries) return;
    const nursery = nurseryData.nurseries.find((n) => n.id === state.nurseryId);

    return nursery?.nurseryRooms ?? [];
  }, [nurseryData, state.nurseryId]);

  const childFirstName = state?.temporaryChildData?.[temporaryChildIndex].fullName.split(' ')[0];
  const roomOptions = useMemo(
    () => (nurseryRooms?.length > 0 ? mapRoomsToOptions(nurseryRooms) : []),
    [nurseryRooms],
  );

  const onSubmit = (data) => {
    logButtonEvent('room_selected_next');
    actions.updateTemporaryChildData(data);
    history.push({
      pathname: `/onboarding/review-children/${temporaryChildIndex}`,
      search: history.location.search,
    });
  };

  return (
    <DefaultAppPage
      title={`Which room is ${childFirstName} in?`}
      isLoading={loading}
      footerActions={
        <StickyNav>
          <Button type="submit" form="pick-nursery-room-form" expand key="next">
            Next
          </Button>
        </StickyNav>
      }
      useHistoryBack
    >
      <main className={styles.container}>
        {loading && <Pebbles className={styles.loadingIndicator} fillSpace />}
        <form id="pick-nursery-room-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={`temporaryChildData[${temporaryChildIndex}].primaryNurseryRoomId`}
            rules={{
              validate: (v) => {
                // I don't know === null
                if (v || v === null) {
                  return true;
                }

                return 'Please select a nursery room';
              },
            }}
            defaultValue={state?.temporaryChildData?.[temporaryChildIndex].primaryNurseryRoomId}
            render={({ field: { name, value } }) => (
              <RadioButtonList
                className={styles.options}
                options={roomOptions}
                onChange={(roomId) => {
                  const room = nurseryRooms.find((r) => r.id === roomId);
                  const roomName = room?.name ?? `I’m not sure`;
                  logButtonEvent('room_selected');
                  [
                    {
                      name: `temporaryChildData[${temporaryChildIndex}].primaryNurseryRoomId`,
                      value: roomId || null,
                      options: { shouldValidate: true },
                    },
                    {
                      name: `temporaryChildData[${temporaryChildIndex}].roomName`,
                      value: roomName,
                    },
                  ].forEach(({ name, value, options }) => setValue(name, value, options));
                }}
                value={value}
                name={name}
              />
            )}
          />
          <ErrorMessage
            isVisible={
              !isValid && errors?.temporaryChildData?.[temporaryChildIndex].primaryNurseryRoomId
            }
            className={styles.errorMessage}
          >
            {errors?.temporaryChildData?.[temporaryChildIndex].primaryNurseryRoomId?.message}
          </ErrorMessage>
        </form>
      </main>
    </DefaultAppPage>
  );
};
