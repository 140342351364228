import { FC, MouseEvent } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { IconChevronLeft } from 'icons';

import styles from './BackButton.module.scss';

interface IBackButtonProps {
  buttonLabel?: string;
  className?: string;
  redirectTo?: string;
  useHistoryBack?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const BackButton: FC<IBackButtonProps> = ({
  buttonLabel = 'Back',
  className,
  onClick,
  redirectTo,
  useHistoryBack = true,
}) => {
  const history = useHistory();

  const handleBackClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (typeof onClick === 'function') {
      onClick(e);
    }

    if (redirectTo) {
      history.push(redirectTo);
    } else if (useHistoryBack) {
      history.goBack();
    }
  };

  return (
    <button
      className={classNames(className, styles.backButton)}
      onClick={handleBackClick}
      type="button"
    >
      <IconChevronLeft className={styles.chevronIcon} size={14} />
      {buttonLabel}
    </button>
  );
};
