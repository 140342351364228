import { useHistory } from 'react-router-dom';

import { Button, Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

export const MissingChildInfoDialog = ({ childId, isVisible, onClose }) => {
  const history = useHistory();

  const handleAddBirthDateClick = () => history.push(`/profile/child/${childId}`);

  return (
    <>
      <Dialog
        isVisible={isVisible}
        title="Missing info"
        onClose={onClose}
        menu={
          <Button onClick={handleAddBirthDateClick} expand>
            Add birthdate
          </Button>
        }
      >
        In order to pay for your booking, you will need to add a birthday for your child so Pebble
        can calculate your rate.
      </Dialog>
      <ModalBackdrop isVisible={isVisible} onClick={onClose} />
    </>
  );
};
