import { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { useApolloClient } from '@apollo/client';

import { setUserId } from 'app/amplitude';

import config from './config';

import * as FullStory from '@fullstory/browser';
import { shouldFullstoryRecord } from 'utilities/fullstory';

/**
 * Custom hook that provides all the auth logic to the `<AuthContext.Provider>`
 *
 * This hook should be used to maintain the internal state and methods of the Auth
 * object that gets passed to any components that `useContext(AuthContext)`.
 */
export const useAuth = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [activeUser, setActiveUser] = useState(null);
  const [unauthenticataedPhoneNumber, setUnauthenticatedPhoneNumber] = useState('');
  const apolloClient = useApolloClient();

  const configureAmplify = () => {
    Amplify.configure({
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      cookieStorage: {
        domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN, //localhost or .<domain>.com
        path: '/',
        expires: 365,
        sameSite: 'strict',
        secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== 'localhost',
      },
    });
  };

  const isAuthenticated = () => {
    return activeUser?.signInUserSession != null ?? false;
  };

  const getUserPhoneNumber = () => {
    return activeUser?.username ?? unauthenticataedPhoneNumber;
  };

  const initializeUser = async () => {
    let isUserSignedIn = false;
    try {
      const user = await Auth.currentAuthenticatedUser();
      setActiveUser(user);
      setUserId(user.username);
      isUserSignedIn = true;
    } catch (error) {
      setActiveUser(null);
    } finally {
      setIsInitializing(false);
    }
    console.log('[Auth] Is user signed in: ', isUserSignedIn);
  };

  const login = async (phoneNumber, password) => {
    try {
      phoneNumber = formatPhoneNumber(phoneNumber);
      const user = await Auth.signIn(phoneNumber.trim(), password.trim());
      console.log('[Auth] Login Success');
      setActiveUser(user);
      setUserId(user.username);
    } catch (error) {
      console.log('[Auth] Login Failed: ', error);
      setUnauthenticatedPhoneNumber(phoneNumber);
      throw new AuthError(error.code, error.message);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      apolloClient.clearStore();

      console.log('[Auth] Logout Success');
      setActiveUser(null);
      setUserId(null);
    } catch (error) {
      console.log('[Auth] Logout Failed: ', error);
      throw new Error('Logout Error', error);
    }
  };

  const signup = async (phoneNumber, password) => {
    try {
      phoneNumber = formatPhoneNumber(phoneNumber);
      const { user } = await Auth.signUp({
        username: phoneNumber.trim(),
        password: password.trim(),
      });
      console.log('[Auth] Signup Success');
      setActiveUser(user);
    } catch (error) {
      console.log('[Auth] Signup Failed:', error);
      throw new AuthError(error.code);
    }
  };

  const confirmSignup = async (phoneNumber, code) => {
    try {
      await Auth.confirmSignUp(phoneNumber.trim(), code.trim());
      console.log('[Auth] Confirm Signup Success');
      setUnauthenticatedPhoneNumber('');
    } catch (error) {
      console.log('[Auth] Confirm Signup Failed', error);
      throw new Error('Confirm Signup Error', error);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const firstThreeChar = phoneNumber.substring(0, 3);
    const firstTwoChar = phoneNumber.substring(0, 2);

    //Check for th UK country code
    if (firstThreeChar === '+44') {
      return phoneNumber;
    }

    //Check for leading 07 - UK unformatted phone numbers
    if (firstTwoChar === '07') {
      phoneNumber = phoneNumber.substring(1);
      return `+44${phoneNumber}`;
    }

    return;
  };

  const resendVerificationCode = async (phoneNumber = getUserPhoneNumber()) => {
    await Auth.resendSignUp(phoneNumber.trim());
  };

  const forgotPasswordInitiate = async (phoneNumber) => {
    try {
      phoneNumber = formatPhoneNumber(phoneNumber);
      await Auth.forgotPassword(phoneNumber.trim());
      console.log('[Auth] Forgot Password Inititate Success');
    } catch (error) {
      console.log('[Auth] Forgot Password Initiate Failed', error);
      throw error;
    }
  };

  const forgotPasswordSubmit = async (phoneNumber, code, newPassword) => {
    try {
      phoneNumber = formatPhoneNumber(phoneNumber);
      await Auth.forgotPasswordSubmit(phoneNumber.trim(), code.trim(), newPassword.trim());
      console.log('[Auth] Forgot Password Submit Success');
    } catch (error) {
      console.log('[Auth] Forgot Password Submit Failed', error);
      throw error;
    }
  };

  useEffect(() => {
    configureAmplify();
    initializeUser();
  }, []);

  useEffect(() => {
    if (activeUser?.username && shouldFullstoryRecord) {
      FullStory.identify(activeUser?.username);
      console.log(activeUser?.username, 'activeUser');
    }
  }, [activeUser?.username]);

  return {
    resendVerificationCode,
    isAuthenticated,
    getUserPhoneNumber,
    isInitializing,
    activeUser,
    login,
    logout,
    signup,
    confirmSignup,
    forgotPasswordInitiate,
    forgotPasswordSubmit,
  };
};

export class AuthError extends Error {
  constructor(errorCode, errorMessage) {
    super(errorCode);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }

    this.name = 'AuthError';

    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}
