import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { IconChevronRight } from 'icons';
import { Pebbles } from 'ui';

import styles from './Tile.module.scss';

export const Tile = ({ children, className, href, icon, isLoading, onClick, ...otherProps }) => {
  if (isLoading) {
    return (
      <div className={classNames(styles.loadingIndicator, className)}>
        <Pebbles size={50} fillSpace />
      </div>
    );
  }

  return (
    <div {...otherProps} className={styles.container}>
      <div className={styles.maxWidthWrapper}>
        {icon && <div className={styles.icon}>{icon}</div>}

        <div className={classNames(styles.content, className)}>{children}</div>

        {href && (
          <NavLink to={href} className={styles.link} onClick={onClick}>
            <IconChevronRight size={14} className={styles.chevronRight} />
          </NavLink>
        )}
      </div>
    </div>
  );
};
