import { FC, MouseEvent, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { captureMessage } from '@sentry/react';
import { useMutation } from '@apollo/client';
import { trackAction } from './utils';
import { AnimateExpandFromPoint, ChildStatusLabel, ListTile } from 'app/components';
import { useProfile } from 'app/profile';
import { ChildStatusEnum } from 'graphql/constants';
import updateChildByGuardian from 'graphql/mutations/updateChildByGuardian';
import { SettingsPage } from 'layouts';

import { EditBirthDate, EditableField } from './components';
import { EditFullName } from '../AccountDetails/components';
import styles from './ChildDetails.module.scss';
import { usePageViewTracker } from 'hooks';

interface ChildDetailsQueryParams {
  id: string;
}

interface IClickCoords {
  originX: string;
  originY: string;
}

export const ChildDetails: FC = () => {
  const history = useHistory();
  const { id } = useParams<ChildDetailsQueryParams>();

  usePageViewTracker('profile/child_details');

  const { profile, refetch: refetchGuardian } = useProfile();
  const child = useMemo(() => profile.children.find((c) => c.id === id), [profile, id]);
  const [clickCoords, setClickCoords] = useState<null | IClickCoords>(null);
  const [saveChild, { loading: isSaving }] = useMutation(updateChildByGuardian);

  // Determine which property to edit based on the current hash
  const propertyToEdit = useMemo(() => history.location.hash.slice(1), [history.location.hash]);

  // If hash is set, see if there's a modal to display with it
  const ModalContent = useMemo(() => {
    switch (propertyToEdit) {
      case 'fullName':
        return EditFullName;

      case 'birthDate':
        return EditBirthDate;

      default:
        return null;
    }
  }, [propertyToEdit]);
  const showModal = ModalContent !== null;

  const editAccountProperty = (propertyName: string) => (e: MouseEvent<HTMLButtonElement>) => {
    history.push({ hash: propertyName });

    trackAction(propertyName);

    setClickCoords({
      originX: `${e.nativeEvent.clientX}px`,
      originY: `${e.nativeEvent.clientY}px`,
    });
  };

  // Child will only be undefined if it was not found in `me.children`
  if (child === undefined) {
    // Child doesn't belong to this guardian or invalid ID, push to error page
    captureMessage('[Profile/ChildDetails] Invalid child ID');
    console.log('[Profile/ChildDetails] Invalid child ID');

    history.push('/error', { from: '/profile' });
    return null;
  }

  /**
   * Handles hiding the modal window
   */
  const hideModal = (e?: MouseEvent<HTMLButtonElement>) => {
    if (e) e.preventDefault();

    history.goBack();
  };

  /**
   * Handles graphql mutation
   *
   * @param {Object} payload An object containing the updated data to post to API
   */
  const handlePostToApi = async (payload: Record<string, string>) => {
    let errorHappened = false;

    try {
      const variables = {
        child: {
          ...payload,
          id: id,
        },
      };
      const { errors } = await saveChild({
        variables,
      });
      if (errors) {
        console.warn('[ChildDetails] Failed to update', errors);
        errorHappened = true;
      }
    } catch (error) {
      console.warn('[ChildDetails] try/catch failed', error);
      errorHappened = true;
    }

    if (errorHappened) {
      history.push('/error', { from: '/profile' });
    } else {
      refetchGuardian();
      hideModal();
    }
  };

  return (
    <SettingsPage
      title={`${child.firstName}’s details ${child.isAdult && '(Adult)'}`}
      className={styles.container}
      useResponsiveCentered={false}
    >
      <div className={styles.tiles}>
        <EditableField
          label="Full name"
          value={child.fullName}
          onEdit={editAccountProperty('fullName')}
          disableEdit={child.isExternallyManaged}
        />

        <EditableField
          label="Date of birth"
          value={child.birthDateFormatted}
          onEdit={editAccountProperty('birthDate')}
          disableEdit={child.isAdult || child.isExternallyManaged}
        />

        {child.isRegisteredWithNursery && (
          <>
            {/* Edit should always be disabled */}
            <EditableField label="Nursery" value={child.primaryNursery?.name} disableEdit={true} />
            <EditableField label="Room" value={child.primaryNurseryRoomName} disableEdit={true} />
          </>
        )}

        {child.hasPrimaryNursery && (
          <ListTile className={styles.statusTile} contentClass={styles.statusTileContent}>
            <div>
              <h2 className={styles.label}>Status</h2>

              {child.status === ChildStatusEnum.APPROVED && (
                <p>Your child is now verified and is eligible for instant bookings.</p>
              )}

              {child.status === ChildStatusEnum.UNRECOGNIZED && (
                <p>
                  Your child is not recognised by your childcare provider. Please contact them if
                  this is an error.
                </p>
              )}

              {child.status === ChildStatusEnum.PENDING && (
                <p>
                  Your child still needs to be verified by your childcare provider. You can request
                  sessions while you wait.
                </p>
              )}

              {child.status === ChildStatusEnum.DEACTIVATED && (
                <p>Your child has been deactivated by their childcare provider</p>
              )}
            </div>

            <ChildStatusLabel status={child.status} className={styles.statusLabel} />
          </ListTile>
        )}
      </div>
      {child.isExternallyManaged && (
        <footer className={styles.footerMessage}>
          If the information is incorrect, please contact Pebble for help
          <br />
          <a href="mailto:support@bookpebble.co.uk?subject=Child profile details incorrect">
            Contact us
          </a>
        </footer>
      )}

      <AnimateExpandFromPoint
        coords={clickCoords}
        isVisible={showModal}
        className={styles.editScreen}
      >
        {ModalContent && (
          <ModalContent
            initialValue={
              propertyToEdit === 'birthDate' ? child?.birthDateFormatted : child?.fullName
            }
            isSaving={isSaving}
            postToApi={handlePostToApi}
            onBack={hideModal}
          />
        )}
      </AnimateExpandFromPoint>
    </SettingsPage>
  );
};
