import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import queryString from 'query-string';
import { logButtonEvent } from 'app/amplitude';
import { BookingButton } from 'app/components';
import { BookingRequestTypeEnum, BookingSessionTypeEnum } from 'graphql/constants';
import { useAvailabilityForNonPrimaryNursery } from 'graphql/hooks';
import { useBookingParams } from 'pages/Booking';
import { SERVER_DATE_FORMAT } from 'utilities/date';
import { formatPrice, QUERY_PARAM_CHECKMARK } from 'utilities/string';
import { Pebbles } from 'ui';

import emptyStateIllustration from './emptyState.png';
import styles from './AlternateAvailabilityGrid.module.scss';
import { SessionCard } from '../SessionCard';

export const AlternateAvailabilityGrid = ({
  className,
  date,
  child,
  sessionType,
  isCustomSessionBookingsEnabled,
  bookingToCancel,
}) => {
  const history = useHistory();
  const { paymentExemptReason } = useBookingParams();

  const dateStamp = format(date, SERVER_DATE_FORMAT);
  const { data: extraAvailability, loading } = useAvailabilityForNonPrimaryNursery({
    child,
    date: dateStamp,
    sessionType,
  });

  const navigateToBookingReview = (queryParams) => {
    logButtonEvent('non_primary_selected');
    if (paymentExemptReason) {
      bookingReviewQueryParams.isPaymentExempt = QUERY_PARAM_CHECKMARK;
    }

    history.push(`/booking/review?${queryString.stringify(queryParams)}`);
  };

  const handleBookingButtonClick = (nurseryId, roomId) =>
    navigateToBookingReview({
      date: format(date, SERVER_DATE_FORMAT),
      sessionType,
      children: child.id,
      type: BookingRequestTypeEnum.INSTANT,
      paymentExemptReason,
      nurseryId,
      roomId,
      bookingToCancel,
    });

  const handleCustomSessionButtonClick = (sessionId, roomId) =>
    navigateToBookingReview({
      date: format(date, SERVER_DATE_FORMAT),
      children: child.id,
      sessionId,
      type: BookingRequestTypeEnum.INSTANT,
      paymentExemptReason,
      roomId,
      bookingToCancel,
    });

  const sessionLabel = sessionLabels[sessionType];

  return (
    <div className={classNames(styles.container, className)}>
      <h2>
        <em>New!</em> Availability at nearby nurseries:
      </h2>

      {!loading && extraAvailability.length === 0 ? (
        <div className={styles.emptyState}>
          <p>
            Oh no! It seems there are no nearby nurseries with availability on this day and time.
            Try selecting another session time, you might be lucky!
          </p>

          <img src={emptyStateIllustration} alt="Two children playing" />
        </div>
      ) : (
        <>
          <p>
            Did you know you can now select an Instant Booking at a nursery nearby, if your home
            nursery doesn’t have spaces available?
          </p>

          {loading ? (
            <div className={styles.loadingIndicator}>
              <Pebbles size={60} />
            </div>
          ) : (
            <motion.div initial="hidden" animate="visible" variants={listAnimationProps}>
              {extraAvailability.map((availability) =>
                !isCustomSessionBookingsEnabled ? (
                  <motion.div
                    className={styles.extraAvailabilitySlot}
                    key={availability.id}
                    variants={itemAnimationProps}
                  >
                    <div className={styles.sessionDetails}>
                      <strong>
                        {availability.nursery.organizationName} - {availability.nursery.name}
                      </strong>
                      {sessionLabel} ({formatPrice(availability.sessionPrice)})
                    </div>

                    <BookingButton
                      isInstant
                      className={styles.bookingButton}
                      onClick={() =>
                        handleBookingButtonClick(availability.nursery.id, availability.id)
                      }
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    key={availability.customSessionType?.id}
                    variants={itemAnimationProps}
                  >
                    <SessionCard
                      title={`${availability?.nursery?.organizationName} - ${availability?.nursery?.name}`}
                      subtitle={availability?.customSessionType?.name}
                      startTime={availability?.customSessionType?.startTime}
                      endTime={availability?.customSessionType?.endTime}
                      price={availability?.sessionPrice}
                      showSessionTimes={availability?.nursery?.showCustomSessionTimes}
                      Button={
                        <BookingButton
                          onClick={() =>
                            handleCustomSessionButtonClick(
                              availability.customSessionType?.id,
                              availability.id,
                            )
                          }
                          isInstant={availability?.availableSessionCount > 0}
                          disabled={false}
                          disabledText="Unavailable"
                        />
                      }
                    />
                  </motion.div>
                ),
              )}
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

AlternateAvailabilityGrid.defaultProps = {
  sessionType: BookingSessionTypeEnum.FULL,
};

const listAnimationProps = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.125,
    },
  },
};

const itemAnimationProps = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 40,
  },
};

const sessionLabels = {
  [BookingSessionTypeEnum.AM]: 'Morning',
  [BookingSessionTypeEnum.PM]: 'Afternoon',
  [BookingSessionTypeEnum.FULL]: 'Full day',
};
