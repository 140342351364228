export const errorCodes = {
  GUARDIAN_DOES_NOT_EXIST: 'GUARDIAN_DOES_NOT_EXIST',
  GUARDIAN_DOES_NOT_EXIST_LEGACY: 'Guardian matching query does not exist.',
  NURSERY_PRICE_LIST_MISSING: 'NURSERY_PRICE_LIST_MISSING',
};

/**
 * Checks the provided GraphQL error object to see it contains the provided errorCode
 *
 * @param {Error} error The GraphQL error object
 * @param {String} errorCode The error code to check for
 * @returns
 */
export const hasError = (error, errorCode) => {
  if (error && error.message === errorCode) {
    return true;
  }
  return false;
};

export const GuardianStatusEnum = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const BookingRequestTypeEnum = {
  INSTANT: 'INSTANT',
  REQUEST: 'REQUEST',
};

export const BookingSessionTypeEnum = {
  AM: 'AM',
  PM: 'PM',
  FULL: 'FULL',
};

export const BookingStatusEnum = {
  PENDING: 'PENDING',
  SCHEDULING: 'SCHEDULING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ON_HOLD: 'ON_HOLD',
};

export const BookingTypeEnum = {
  Session: 'SESSION',
  Extra: 'EXTRA',
};

export const ExtraBookingStatusEnum = {
  PENDING: 'PENDING',
  SCHEDULING: 'SCHEDULING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ON_HOLD: 'ON_HOLD',
};

export const ChildStatusEnum = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  UNRECOGNIZED: 'UNRECOGNIZED',
  DEACTIVATED: 'DEACTIVATED',
};

export const BookingCancellationReason = {
  ILLNESS: 'ILLNESS',
  NO_LONGER_NEEDED: 'NO_LONGER_NEEDED',
  OTHER: 'OTHER',
};

export const PaymentStatusEnum = {
  PAID: 'PAID',
  PENDING: 'PENDING',
};

export const PaymentExemptionReasonEnum = {
  EMERGENCY_CARE_SESSION: 'EMERGENCY_CARE_SESSION',
  SETTLE_IN_SESSION: 'SETTLE_IN_SESSION',
  SESSION_IN_LIEU: 'SESSION_IN_LIEU',
  TAX_FREE_SESSION: 'TAX_FREE_SESSION',
  // CHILD_OUTSIDE_PRICE_RANGE: 'CHILD_OUTSIDE_PRICE_RANGE',
};

export const ExtraActivityTimeEnum = {
  ALL: 'ALL',
  AM: 'AM',
  PM: 'PM',
};
