import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconPencil = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.19993 12.2934L10.8667 7.62661L8.65008 5.40998L3.98331 10.0768C3.91906 10.1411 3.87342 10.2216 3.85123 10.3098L3.33398 12.9427L5.96642 12.4255C6.0548 12.4034 6.13566 12.3577 6.19993 12.2934ZM12.3733 6.12007C12.5615 5.93172 12.6673 5.6763 12.6673 5.40998C12.6673 5.14366 12.5615 4.88824 12.3733 4.6999L11.5768 3.90344C11.3884 3.71515 11.133 3.60938 10.8667 3.60938C10.6004 3.60938 10.345 3.71515 10.1566 3.90344L9.36016 4.6999L11.5768 6.91653L12.3733 6.12007Z"
      fill={color}
    />
  </svg>
);
