import { getGtagPageTracker } from '../utilities/gtag';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useGtagTracking() {
  const location = useLocation();

  const trackGtagEvent = useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      return getGtagPageTracker(location?.pathname || '');
    }

    return () => {};
  }, [location]);

  useEffect(() => {
    trackGtagEvent('page_view');
  }, [trackGtagEvent]);

  return { trackGtagEvent };
}
