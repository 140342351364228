import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { MissingChildInfoDialog } from 'app/components';
import { useProfile } from 'app/profile';
import { DefaultAppPage } from 'layouts';
import { Button, RadioButtonList } from 'ui';

import { logButtonEvent } from 'app/amplitude';

import styles from './ChildSelection.module.scss';
import { usePageViewTracker } from 'hooks';

const mapChildrenToOptions = (childList) =>
  childList.map((child) => ({
    label: child.fullName,
    value: child.id,
    disabled: child.isUnrecognized ?? false,
  }));

export const ChildSelection = () => {
  const { profile, refetch: refreshProfile } = useProfile();
  const [selectedChild, setSelectedChild] = useState(null);
  const [showBirthDateError, setShowBirthDateError] = useState(false);
  const history = useHistory();

  usePageViewTracker('booking/child_selection');

  useEffect(() => {
    const fecthProfile = async () => {
      // Making sure that the profile is up to date with any changes
      // made via the Nursery portal app
      if (refreshProfile) {
        await refreshProfile();
      }
    };

    fecthProfile();
  }, []);

  const handleNextClick = () => {
    logButtonEvent('booking_next_child_selection');

    const child = profile.children.find((c) => selectedChild === c.id);

    history.push(`/booking/date-selection?children=${child.id}`);
  };

  const childOptions = useMemo(
    () => mapChildrenToOptions(profile.childrenEligibleForSessionBooking),
    [profile],
  );

  const handleChildSelected = (childId) => {
    const child = profile.children.find((c) => c.id === childId);

    if (!child.hasBirthDate) {
      setShowBirthDateError(true);
    }

    setSelectedChild(child.id);
  };

  const handleBirthDateErrorDialogClose = () => {
    setSelectedChild(null);
    setShowBirthDateError(false);
  };

  return (
    <DefaultAppPage
      title="Who are you booking for?"
      useHistoryBack
      onBack={() => logButtonEvent('booking_back_child_selection')}
      footerActions={[
        <Button
          disabled={selectedChild === null}
          onClick={handleNextClick}
          expand
          key="next"
          data-testid="booking-child-selection__next-button"
        >
          Next
        </Button>,
      ]}
    >
      <main className={styles.container}>
        <p>Select which child you’d like to book an extra session for.</p>

        <RadioButtonList
          options={childOptions}
          onChange={handleChildSelected}
          value={selectedChild}
          className={styles.childList}
        />
      </main>

      <MissingChildInfoDialog
        childId={selectedChild}
        isVisible={showBirthDateError}
        onClose={handleBirthDateErrorDialogClose}
      />
    </DefaultAppPage>
  );
};
