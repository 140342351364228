import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'app/auth';

import { useApolloClient } from '@apollo/client';
import { usePageViewTracker } from 'hooks';

/**
 * Simple logout component. Removes localStorage items and redirects the user back to the application root
 */
export const Logout = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const apolloClient = useApolloClient();

  usePageViewTracker('logout');

  authContext.logout();

  useEffect(() => {
    // Async needs to be wrapped this way to work properly with hooks
    async function onLogout() {
      await apolloClient.clearStore();
    }

    onLogout();
  }, []);

  history.push('/');

  return null;
};
