import { useMemo } from 'react';
import classNames from 'classnames';
import { useSelect } from 'downshift';

import { IconChevronDown } from 'icons';

import styles from './Dropdown.module.scss';

export const Dropdown = ({
  className,
  itemToLabel,
  itemToValue,
  noValueText,
  onChange,
  options,
  value,
  testID,
}) => {
  const selectedItem = useMemo(
    () => options.find((o) => itemToValue(o) === value) || null,
    [options, value, itemToValue],
  );

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: options,
    itemToString: itemToLabel,
    onSelectedItemChange: (item) => onChange(item.selectedItem),
    selectedItem,
  });

  return (
    <div className={classNames(styles.container, isOpen && styles.dropdownOpen, className)}>
      <button
        type="button"
        {...getToggleButtonProps()}
        className={styles.button}
        data-testid={testID}
      >
        {selectedItem?.label || noValueText}

        <IconChevronDown className={styles.chevronDown} size={12} />
      </button>

      <ul
        {...getMenuProps({
          'aria-label': noValueText,
          'aria-labelledby': getToggleButtonProps().id,
        })}
        className={classNames(styles.dropdown, !isOpen && styles.hide)}
      >
        {options.map((item, index) => (
          <li
            className={classNames(styles.option, highlightedIndex === index && styles.highlighted)}
            key={`${item}${index}`}
            {...getItemProps({ item, index })}
          >
            {itemToLabel(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.defaultProps = {
  itemToLabel: (item) => (item && 'label' in item ? item.label : ''),
  itemToValue: (item) => (item && 'value' in item ? item.value : ''),
  noValueText: 'Select an item',
};
