import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { logButtonEvent } from 'app/amplitude';
import { useWizard } from 'app/wizard';
import { BookingCancellationReason } from 'graphql/constants';
import { DefaultAppPage } from 'layouts';
import { useSessionDetails } from 'pages/SessionDetails/useSessionDetails';
import { Button, FullScreen, Pebbles, RadioButtonList } from 'ui';
import { getFirstName } from 'utilities/string';

import styles from './CancellationReason.module.scss';

export const CancellationReason = () => {
  const { controls, formData, onChange } = useWizard();
  const { id } = useParams();
  const bookingIds = useMemo(() => [id], [id]);
  const { loading, sessions } = useSessionDetails(bookingIds);
  const booking = useMemo(() => sessions[0], [sessions]);

  const handleNextClick = (e) => {
    e.preventDefault();
    if (formData.cancellationReason === null) return;
    logButtonEvent('next_selected');
    controls.goToNextScreen();
  };

  if (loading || !booking) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  return (
    <DefaultAppPage
      title={`Please select a reason for cancelling ${getFirstName(
        booking.child.fullName,
      )}’s session`}
      useHistoryBack
      footerActions={
        <Button
          disabled={formData.cancellationReason === null}
          onClick={handleNextClick}
          expand
          data-testid="cancel-session__next-button"
        >
          Next
        </Button>
      }
    >
      <main className={styles.container}>
        <p>
          You might not receive a refund.
          <br />
          Please contact your childcare provider for more information.
        </p>

        <RadioButtonList
          options={cancellationOptions}
          onChange={(value) => onChange('cancellationReason', value)}
          value={formData.cancellationReason}
          className={styles.options}
        />
      </main>
    </DefaultAppPage>
  );
};

const cancellationOptions = [
  { value: BookingCancellationReason.ILLNESS, label: 'Unwell / sick' },
  {
    value: BookingCancellationReason.NO_LONGER_NEEDED,
    label: 'No longer needed',
  },
  { value: BookingCancellationReason.OTHER, label: 'Other' },
];
