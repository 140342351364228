import { useState } from 'react';

import { Wizard, useInitialFormData } from 'app/wizard';

import { CancellationReason, CancellationAdditionalDetails } from './screens';

export const CancellationWizard = () => {
  const initialFormData = useInitialFormData({
    cancellationReason: null,
    cancellationDetails: '',
  });
  const [formData, setFormData] = useState(initialFormData);

  return (
    <Wizard formData={formData} onChange={setFormData}>
      <CancellationReason screenName="cancellationReason" />
      <CancellationAdditionalDetails screenName="additionalDetails" />
    </Wizard>
  );
};
