import classNames from 'classnames';

import { IconLightningBolt } from 'icons';

import styles from './BookingButton.module.scss';

export const BookingButton = ({
  className = '',
  disabled,
  disabledText,
  isInstant,
  ...buttonProps
}) => {
  let buttonContent = 'Request';

  if (isInstant) {
    buttonContent = (
      <>
        <IconLightningBolt className={styles.lightningBoltIcon} size={16} />
        Book now
      </>
    );
  }

  if (disabled && disabledText) {
    buttonContent = disabledText;
  }

  return (
    <button
      {...buttonProps}
      type="button"
      disabled={disabled}
      className={classNames(styles.container, isInstant && styles.instant, className)}
    >
      {buttonContent}
    </button>
  );
};

BookingButton.defaultProps = {
  isInstant: false,
  disabled: false,
  disabledText: 'Unavailable',
  className: '',
  onClick: null,
};
