import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { useQueryParams } from 'hooks';

const events = {
  PAGE_VIEW_PREFIX: 'page_view',
  ACTION_PREFIX: 'action',
  APP_LAUNCH: 'guardian_app_launch',
};

export const AMPLITUDE_DEBUG_VARIABLE = 'track';

export const initializeAmplitude = (deviceId) => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    logAttributionCapturedEvent: true,
    deviceId: deviceId || '',
  });
  amplitude.getInstance().logEvent(events.APP_LAUNCH);
};

export const getDeviceId = () => {
  return amplitude.getInstance().options.deviceId;
}

export const AmplitudePageTracker = (props) => {
  const params = useQueryParams();

  useEffect(() => {
    const amplitudeDeviceId = params.deviceId;

    if (typeof amplitudeDeviceId === 'string') {
      initializeAmplitude(amplitudeDeviceId);

      return () => console.log('amplitude initialised with device id');
    }

    initializeAmplitude();

    return () => console.log('amplitude initialised without device id');
  }, [params.deviceId]);

  return null;
};

export const setUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const logButtonEvent = (event, data = {}) => {
  try {
    logEvent(`${events.ACTION_PREFIX}/${event}`, data);
  } catch (e) {
    console.error(e);
  }
};

export const logEvent = (event, data = {}) => {
  amplitude.getInstance().logEvent(event, data);
};
