import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { logButtonEvent } from 'app/amplitude';
import { PaymentExemptionReasons } from 'app/components';
import { DefaultAppPage } from 'layouts';
import { useBookingParams } from 'pages/Booking';
import { Button } from 'ui';
import { QUERY_PARAM_CHECKMARK } from 'utilities/string';
import { BookingRequestTypeEnum } from 'graphql/constants';

import styles from './BypassPayment.module.scss';
import { usePageViewTracker } from 'hooks';

export const BypassPayment = () => {
  const [exemptReason, setExemptReason] = useState(null);
  const { location, push, replace } = useHistory();

  usePageViewTracker('booking/payment_exempt');

  const { hasValidQueryParams, createBooking, submitBookingSaving } = useBookingParams();

  if (!hasValidQueryParams) {
    replace('/error', { from: '/home' });
    return null;
  }

  const handleConfirmBooking = async () => {
    try {
      const bookingId = await createBooking({
        paymentExemptionReason: exemptReason,
        forceRequest: true,
      });
      const confirmationParams = {
        type: BookingRequestTypeEnum.REQUEST,
        bookingId,
        isPaymentExempt: QUERY_PARAM_CHECKMARK,
      };
      logButtonEvent('exemption_reason_confirm');
      push(`/booking/confirmation?${queryString.stringify(confirmationParams)}`);
    } catch (error) {
      push('/error', {
        from: location.pathname + location.search,
      });
    }
  };

  const onExemptionReasonChange = (e) => {
    setExemptReason(e.target.value);
    logButtonEvent('session_exemption_reason_selection');
  };

  return (
    <DefaultAppPage
      title="Don’t need to pay on Pebble?"
      introText="If one of the following applies to you, you do not have to pay with
      Pebble:"
      footer={
        <footer>
          <div className={styles.disclaimer}>
            By clicking confirm, I confirm that one of the above is true and correct.
          </div>

          <Button
            onClick={handleConfirmBooking}
            expand
            className={styles.confirmButton}
            disabled={!exemptReason || submitBookingSaving}
            isWorking={submitBookingSaving}
          >
            Request booking
          </Button>
        </footer>
      }
      useHistoryBack
      onBack={() => logButtonEvent('exemption_reason_back')}
    >
      <main className={styles.container}>
        <PaymentExemptionReasons value={exemptReason} onChange={onExemptionReasonChange} />
      </main>
    </DefaultAppPage>
  );
};
