import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { logButtonEvent } from 'app/amplitude';
import { AlternateAvailabilityGrid } from 'app/components';
import { BookingStatusEnum } from 'graphql/constants';
import { PaymentStatus as PaymentStatusEnum } from 'graphql/generated-types';
import { usePageViewTracker, useQueryParams } from 'hooks';
import { BookingDetailsPage } from 'layouts';
import { Button, FullScreen, Pebbles, Checkbox } from 'ui';
import { combineNames } from 'utilities/jsx';
import { getFirstName } from 'utilities/string';
import { PaymentInfoModal } from 'app/components/PaymentInfoModal';
import { PaymentStatus } from 'app/components/PaymentStatus';
import marketingOptInMutation from 'graphql/mutations/updateMarketingOptIn';
import getMarketingPreferences from 'graphql/queries/getMarketingPreferences';

import { useSessionDetails } from './useSessionDetails';
import statusPending from './images/statusPending.png';
import statusConfirmed from './images/statusConfirmed.png';
import statusCancelled from './images/statusCancelled.png';
import statusDeclined from './images/statusDeclined.png';
import statusOnHold from './images/statusOnHold.png';
import styles from './SessionDetails.module.scss';

export const SessionDetails = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { sessionIds } = useQueryParams();
  const bookingIds = useMemo(() => sessionIds.split(',') ?? [], [sessionIds]);
  const [notifyMe, setNotifyMe] = useState(false);

  usePageViewTracker('session_details');

  const {
    children,
    date,
    isCancelled,
    isDeclined,
    isOnHold,
    maxHoldTimeInWeeks,
    loading,
    nursery,
    isPrimaryNursery,
    payment,
    sessions,
    sessionTypeLabel,
    status,
    promotion,
    customSessionDetails,
  } = useSessionDetails(bookingIds);
  const history = useHistory();
  const childNames = sessions.map((s) => getFirstName(s.child.fullName));
  const paymentsEnabled = nursery?.paymentsEnabled ?? false;
  const isExternallyManaged = nursery?.isExternallyManaged ?? false;
  const hideCancelButton =
    isCancelled || isDeclined || (isExternallyManaged && status === BookingStatusEnum.CONFIRMED);
  const isPaymentExempt = paymentsEnabled && !payment.hasPaymentDetails;
  const showAlternateAvailability =
    isPrimaryNursery &&
    nursery?.isNonPrimaryBookingEnabled === true &&
    [BookingStatusEnum.ON_HOLD, BookingStatusEnum.PENDING, BookingStatusEnum.REJECTED].includes(
      status,
    );
  const [saveMarketingPreferences] = useMutation(marketingOptInMutation);
  const { data: marketingPreferencesData } = useQuery(getMarketingPreferences);

  const handleCancelClick = (e) => {
    e.preventDefault();
    logButtonEvent(isPrimaryNursery ? 'cancel_session_selected' : 'cancel_non_primary_selected');
    if (sessions.length === 1) {
      // Skip session selection
      history.push(`/cancel-session/${sessions[0].id}`);
    } else {
      history.push(`/cancel-session/select-session${history.location.search}`);
    }
  };

  const handleCloseClick = async (e) => {
    e.preventDefault();
    logButtonEvent('close_button_selected');
    if (marketingPreferencesData?.guardian?.marketingOptIn) {
      return history.push('/home');
    }

    try {
      await saveMarketingPreferences({
        variables: {
          guardianId: marketingPreferencesData.guardian.id,
          marketingOptIn: notifyMe,
        },
      });
    } catch (error) {
      // Agreed with PO to silently fail
      console.log(`error`, error);
    } finally {
      history.push('/home');
    }
  };

  if (loading) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  const isPaymentHoldExpired =
    status === BookingStatusEnum.CONFIRMED && payment.status === PaymentStatusEnum.Canceled;

  const hasNotices = [isOnHold, isCancelled, isDeclined, isPaymentHoldExpired].some(Boolean);

  return (
    <BookingDetailsPage
      title={
        <div className={styles.title}>
          Upcoming session for <br />
          {combineNames(childNames)} at <span className={styles.nurseryName}>{nursery?.name}</span>
        </div>
      }
      illustration={<img src={illustrationMap[status]} alt={status} />}
      footer={
        <>
          {!marketingPreferencesData?.guardian?.marketingOptIn && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                name="marketingOptIn"
                id="marketingOptIn"
                className={styles.checkbox}
                onChange={(e) => setNotifyMe(e.target.checked)}
                checked={notifyMe}
              />
              <label className={styles.agreementText} htmlFor="marketingOptIn">
                Notify me about special offers and updates
              </label>
            </div>
          )}
          {hideCancelButton ? null : (
            <Button
              onClick={handleCancelClick}
              className={styles.submitButton}
              expand
              variant="outline"
              data-testid="session__cancel-session-button"
            >
              Cancel session
            </Button>
          )}
        </>
      }
      date={date}
      nursery={nursery}
      sessionType={sessionTypeLabel}
      customSessionDetails={customSessionDetails}
      onClose={handleCloseClick}
      showCancellationPolicy={!hideCancelButton}
    >
      {paymentsEnabled && (
        <div className={styles.paymentInfoWrapper}>
          <PaymentStatus
            payment={payment}
            promotion={promotion}
            onIconClick={() => setShowInfoModal(true)}
            isPaymentExempt={isPaymentExempt}
            sessionLabel={childNames.length > 0 ? `Extra session for ${childNames[0]}` : ''}
          />
        </div>
      )}

      {hasNotices && (
        <div className={styles.notices}>
          {isOnHold && (
            <div className={styles.textSession}>
              <h2>Bookings waiting nursery response</h2>
              On Hold means you should hear back at least {maxHoldTimeInWeeks} weeks ahead of your
              requested session date.
            </div>
          )}

          {isDeclined && (
            <div className={styles.declinedNotice}>
              <strong>This booking request has been declined by the childcare provider.</strong>
            </div>
          )}

          {isCancelled && (
            <div className={styles.cancelledNotice}>
              <strong>You have cancelled this session.</strong>
              Reason: {sessions[0]?.cancellationDetails}
            </div>
          )}

          {isPaymentHoldExpired && (
            <div className={styles.cancelledNotice}>
              <strong>Payment not processed.</strong>
              The nursery took a bit too long to process your request. Don’t worry, your booking
              will still go ahead but it will charged to your monthly invoice.
            </div>
          )}
        </div>
      )}

      {showAlternateAvailability && (
        <AlternateAvailabilityGrid
          child={children[0]}
          date={date}
          sessionType={
            nursery.isCustomSessionBookingsEnabled
              ? sessions?.[0]?.customSessionType.availabilityType
              : sessions?.[0]?.sessionType
          }
          className={styles.alternateAvailability}
          isCustomSessionBookingsEnabled={nursery.isCustomSessionBookingsEnabled}
          bookingToCancel={bookingIds[0]}
        />
      )}

      <PaymentInfoModal
        isVisible={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        isPaymentExempt={isPaymentExempt}
      />
    </BookingDetailsPage>
  );
};

const illustrationMap = {
  [BookingStatusEnum.PENDING]: statusPending,
  [BookingStatusEnum.CONFIRMED]: statusConfirmed,
  [BookingStatusEnum.CANCELLED]: statusCancelled,
  [BookingStatusEnum.REJECTED]: statusDeclined,
  [BookingStatusEnum.ON_HOLD]: statusOnHold,
};
