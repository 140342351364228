import { useHistory } from 'react-router-dom';

import { Button } from 'ui';

import defaultIllustration from './illustration.png';
import styles from './Error.module.scss';
import { usePageViewTracker } from 'hooks';

export const Error = ({ action = null, defaultReturnLink, messageText, illustration, title }) => {
  const { location } = useHistory();

  usePageViewTracker('error');

  // Check to see if there's a `from` in location.state to determine where
  // to send the user back to, otherwise send them to index
  const returnLink = location?.state?.from ?? defaultReturnLink;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.illustration}>
          <img src={illustration} alt="Tower of puzzle blocks" />
        </div>

        <div className={styles.title}>{title}</div>

        <p>{messageText}</p>
      </div>

      <div className={styles.footerActions}>
        {action || (
          <Button href={typeof returnLink === 'string' ? returnLink : returnLink.pathname}>
            {returnLink === '/' ? 'Home' : 'Try Again'}
          </Button>
        )}
      </div>
    </div>
  );
};

Error.defaultProps = {
  defaultReturnLink: '/',
  illustration: defaultIllustration,
  messageText: 'We’re sorry, we were unable to submit your request. Please try again.',
  title: 'Something went wrong',
};
