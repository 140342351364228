import { gql } from '@apollo/client';

const getMarketplaceBookingsByIds = gql`
  query GetMarketplaceBookingsByIds($activityBookingIds: [UUID]!) {
    activityBookingsByIds(activityBookingIds: $activityBookingIds) {
      id
      status
      createdAt
      subscriptionNextPayment
      activity {
        id
        name
        description
        subscriptionOffDates
        subscriptionStart
        subscriptionTrialPrice
        bookingType
        isOnline
        hideEndTimes
        supplier {
          id
          name
        }
        location {
          id
          addressLine1
          addressLine2
          city
          country
          postCode
        }
      }
      payment {
        amount
        created
        status
        isRefunded
        paymentMethod {
          card {
            brand
            last4
          }
        }
      }
      finalAmount
      originalAmount
      discount
      promotion {
        code
      }
      bookedTickets {
        attendees {
          id
          fullName
          externalSystemActive
          status
        }
        ticket {
          ticketType
        }
        numberOfSessions
        firstSessionDate
        lastSessionDate
        ticketNameAtBooking
        ticketPriceAtBooking
        subscriptionTrialSelected
        sessions {
          sessionStart
          sessionEnd
          id
          sessionAddons {
            addonId
            name
            price
          }
          bookingAddons {
            addonId
            name
            price
            quantity
          }
        }
      }
    }
  }
`;

export default getMarketplaceBookingsByIds;
