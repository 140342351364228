import { useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { logButtonEvent } from 'app/amplitude';
import { useProfile } from 'app/profile';
import { Wizard, useInitialFormData } from 'app/wizard';
import {
  ChildEstablishmentInfoScreen,
  ChildInfoEntryScreen,
  PickOrganizationScreen,
  PickNurseryScreen,
  PickNurseryRoomScreen,
  ReviewChildrenScreen,
} from 'app/wizard/commonScreens';
import createChildMutation from 'graphql/mutations/createChild';
import { Button } from 'ui';
import { isValidBirthDate } from 'utilities/date';

import styles from './AddChild.module.scss';
import { usePageViewTracker } from 'hooks';

export const AddChild = () => {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const { refetch: refetchProfile } = useProfile();
  const initialChildData = useInitialFormData({
    fullName: '',
    birthDate: '',
    organizationId: '',
    organizationName: '',
    nurseryId: '',
    primaryNurseryRoomId: null,
    roomName: '',
    establishment: 'nursery',
  });
  const [child, setChildData] = useState(initialChildData);
  const [establishmentValue, setEstablishmentValue] = useState('');
  const [createChild] = useMutation(createChildMutation);
  const wizard = useRef(null);

  usePageViewTracker('profile/add_child');

  const errors = useMemo(() => {
    const childDataErrors = {};

    if (!child.fullName) return;

    // Child name may not be empty
    if (child.fullName === '') childDataErrors.fullName = true;

    // Child name must be at least two words
    if (child.fullName?.split(' ').filter(Boolean).length < 2) childDataErrors.fullName = true;

    //  Birthdate must not be empty
    if (!isValidBirthDate(child.birthDate)) childDataErrors.birthDate = true;

    return childDataErrors;
  }, [child]);

  const handleSaveClick = async () => {
    setIsSaving(true);
    logButtonEvent('review_child_save');
    const {
      primaryNurseryRoomId,
      nurseryId,
      roomName,
      establishment,
      updateIndex,
      organizationId,
      organizationName,
      isExternallyManaged,
      ...restChild
    } = child;
    const [day, month, year] = child.birthDate.split(' | ').map((n) => n?.padStart(2, 0));
    const birthDate = `${year}-${month}-${day}`;

    const childDetails = {
      ...restChild,
      birthDate,
      primaryNurseryId: nurseryId === '' ? null : nurseryId,
      primaryNurseryRoomId: primaryNurseryRoomId === '' ? null : primaryNurseryRoomId,
    };

    const { errors: saveChildErrors } = await createChild({
      variables: {
        child: childDetails,
      },
    });

    if (!saveChildErrors) {
      await refetchProfile();
      setIsSaving(false);

      history.push('/profile');
    } else {
      setIsSaving(false);

      console.log('# [Profile/AddChild] Error saving new child', childDetails);
      // @todo: Possibly show a dialog instead??
      history.push('/error', { from: '/profile' });
    }
  };

  const handleCancelClick = () => {
    const confirmed = window.confirm('If you tap on cancel, none of your changes will be saved');
    if (confirmed) {
      logButtonEvent('review_child_cancel');
      history.push('/profile');
    }
  };

  return (
    <Wizard formData={child} onChange={setChildData} errors={errors} ref={wizard}>
      <ChildEstablishmentInfoScreen
        title="Pebble works with childcare and activity providers."
        screenName="childEstablishmentEntry"
        establishment={child.establishment}
        setEstablishmentValue={setEstablishmentValue}
        establishmentValue={establishmentValue}
      />

      <PickOrganizationScreen
        screenName="pickOrganization"
        organizationId={child.organizationId}
        hideExternallyManaged={true}
      />

      <PickNurseryScreen
        screenName="pickNursery"
        organizationId={child.organizationId}
        organizationName={child.organizationName}
        nurseryId={child.nurseryId}
      />

      <ChildInfoEntryScreen
        title="A little bit about your child"
        screenName="childInfoEntry"
        establishment={child.establishment}
        birthDate={child.birthDate}
        fullName={child.fullName}
      />

      <PickNurseryRoomScreen
        screenName="pickNurseryRoom"
        fullName={child.fullName}
        organizationId={child.organizationId}
        nurseryId={child.nurseryId}
        primaryNurseryRoomId={child.primaryNurseryRoomId}
      />

      <ReviewChildrenScreen
        screenName="reviewChildren"
        children={[child]}
        editScreenName="childInfoEntry"
        footer={
          <div className={styles.footer}>
            <Button onClick={handleSaveClick} expand isWorking={isSaving} disabled={isSaving}>
              Save
            </Button>
            <Button onClick={handleCancelClick} variant="outline" expand>
              Cancel
            </Button>
          </div>
        }
      />
    </Wizard>
  );
};
