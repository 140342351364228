import { useEffect, useRef } from 'react';

/**
 * Provides a declarative useInterval
 *
 * @param {Function} callback Function that will be called every `delay` ms.
 * @param {Number, Null} delay Number representing the delay in ms. Set to `null` to "pause" the interval.
 */
export const useInterval = (callback, delay) => {
  const savedCallbackRef = useRef();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args) => {
      if (savedCallbackRef.current) savedCallbackRef.current(...args);
    };

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

export default useInterval;
