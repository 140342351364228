import { loadStripe } from '@stripe/stripe-js';

export const formatPaymentMethod = (selectedPaymentMethod, billingDetails, card) => {
  if (selectedPaymentMethod) {
    // First payment method
    return selectedPaymentMethod.id;
  } else {
    return {
      card,
      billing_details: {
        address: {
          country: billingDetails.country,
          postal_code: billingDetails.postalCode,
        },
        email: billingDetails.email,
        name: billingDetails.name,
      },
    };
  }
};

let stripePromise;

export const getStripe = () => {
  const stripeKey = process.env.REACT_APP_STRIPE_KEY;
  if (!stripeKey) {
    throw Error('No Stripe Key');
  }
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKey);
  }
  return stripePromise;
};
