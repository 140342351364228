import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useProfile } from 'app/profile';
import { DefaultAppPage } from 'layouts';
import { getDeviceId, logButtonEvent } from 'app/amplitude';
import { IGuardianModel } from 'models';
import { IconChevronRight } from 'icons';

import styles from './SessionOrMarketplace.module.scss';
import { usePageViewTracker } from 'hooks';

const getSessionUrl = (profile: IGuardianModel) => {
  if (profile.childrenEligibleForSessionBooking.length > 1) {
    return '/booking/child-selection';
  }

  return `/booking/date-selection?children=${profile.childrenEligibleForSessionBooking[0].id}`;
};

interface OptionProps {
  label: string;
  smallText?: string;
  url: string;
  key: string;
}

export const SessionOrMarketplace: FC = () => {
  const { profile } = useProfile();
  const history = useHistory();

  usePageViewTracker('session_or_marketplace');

  const handleNextClick = (url: string) => {
    if (url?.startsWith('http')) {
      logButtonEvent('book_an_marketplace_activity_selection');
      window.location.href = url;
    } else {
      logButtonEvent('book_a_nursery_session_selection');
      if (url) {
        history.push(url);
      }
    }
  };

  const options: OptionProps[] = useMemo(() => {
    const amplitudeDeviceId = getDeviceId();
    const hasDeviceId = Boolean(amplitudeDeviceId);

    return [
      {
        label: 'Book activities',
        url: `https://activities.bookpebble.co.uk/${
          hasDeviceId ? `?deviceId=${amplitudeDeviceId}` : ''
        }`,
        key: 'activity-url',
      },
      {
        label: 'Book childcare',
        smallText: '(e.g. nursery, WAC or after school club)',
        url: getSessionUrl(profile),
        key: 'session-url',
      },
    ];
  }, [profile]);

  return (
    <DefaultAppPage
      useHistoryBack
      title="What would you like to do?"
      onBack={() => logButtonEvent('back_activity_session_selection')}
    >
      <main className={styles.container}>
        <ul className={styles.buttonList}>
          {options.map((option) => (
            <li key={option.key} className={styles.option}>
              <a onClick={() => handleNextClick(option.url)}>
                <div>
                  {option.label}
                  <br />
                  <span className={styles.smallText}>{option.smallText}</span>
                </div>
                <IconChevronRight className={styles.chevronIcon} size={14} />
              </a>
            </li>
          ))}
        </ul>
      </main>
    </DefaultAppPage>
  );
};
