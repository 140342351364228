import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import { IconTimes } from 'icons';

import styles from './CloseButton.module.scss';

interface ICloseButtonProps {
  className?: string;
  iconSize?: number;
}

export const CloseButton: FC<ICloseButtonProps & HTMLAttributes<HTMLButtonElement>> = ({
  className,
  iconSize = 20,
  ...buttonProps
}) => (
  <button {...buttonProps} className={classNames(styles.container, className)} aria-label="Close">
    <IconTimes size={iconSize} className={styles.closeIcon} />
  </button>
);
