import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { useProfile } from 'app/profile';
import { AuthContext } from 'app/auth';
import { useQueryParams } from 'hooks';
import { FullScreen, Pebbles } from 'ui';

/**
 * Higher-order component that accepts an auth object. If the user is authenticated, allows the route to execute as normal. If
 * the user is not authenticated, redirects the user to the `/login` route
 */
export const PrivateRoute = ({ component: Component, requiresProfile, ...rest }) => {
  const auth = useContext(AuthContext);
  const { isInitializing, requiresOnboarding } = useProfile();
  const isAuthenticated = auth.isAuthenticated();
  const { redirect, ...params } = useQueryParams();
  const newParams = qs.stringify({
    ...params,
    redirect: redirect || rest.location.pathname,
  });

  if (!auth.isInitializing && !isAuthenticated) {
    // Force user to login
    return (
      <Redirect
        to={{
          pathname: '/welcome',
          search: newParams,
        }}
      />
    );
  }

  if (auth.isInitializing || isInitializing) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  if (requiresOnboarding && rest.path !== '/onboarding') {
    return (
      <Redirect
        to={{
          pathname: '/onboarding',
          search: newParams,
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      component={Component}
      path={rest.location.search ? `${rest.path}${rest.location.search}` : rest.path}
    />
  );
};

PrivateRoute.defaultProps = {
  requiresProfile: true,
};
