import { useMemo, useState, Children, FC, ReactElement, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { motion, PanInfo, useMotionValue } from 'framer-motion';
import useResizeObserver from 'use-resize-observer';

import { useInterval } from 'hooks';
import { outlineSuppressionHandlers } from 'ui';

import styles from './Carousel.module.scss';

interface AnimationProps {
  x?: number | string;
  opacity?: number;
  top?: number;
}

const moveLeft: AnimationProps = { x: '-100vw', opacity: 0 };
const moveRight: AnimationProps = { x: '100vw', opacity: 0 };
const center: AnimationProps = { x: 0, opacity: 1, top: 0 };
const initial: AnimationProps = { opacity: 0 };
const pageTransition = {
  type: 'spring',
  duration: 0.6,
  bounce: 0.25,
};

interface ICarouselPageProps {
  className?: string;
  visible?: boolean;
}

export const CarouselPage: FC<ICarouselPageProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

interface ICarouselProps {
  className?: string;
  height?: number;
  initialIndex?: number;
  updateInterval?: number | null;
  pauseDuration?: number;
  dotColor?: string;
  activeDotColor?: string;
}

export const Carousel: FC<ICarouselProps> = ({
  children,
  className,
  height = 200,
  initialIndex = 0,
  pauseDuration = 10000,
  updateInterval = null,
  dotColor,
  activeDotColor,
}) => {
  const { ref, width = 1 } = useResizeObserver();
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const pageCount = Children.count(children);
  const boxStyle = useMemo(() => ({ height, width }), [height, width]);
  const x = useMotionValue(0);
  const [interval, setLocalInterval] = useState(updateInterval);

  /**
   * Handles moving to the previous page
   * If the previous page is out of bounds, moves to the last page instead
   */
  const showPreviousPage = () => {
    let previousPage = activeIndex - 1;

    if (previousPage < 0) previousPage = pageCount - 1;

    setActiveIndex(previousPage);
  };

  /**
   * Handles moving to the next page
   * If the next page is out of bounds, move to the first page instead
   */
  const showNextPage = () => {
    let nextPage = activeIndex + 1;

    if (nextPage >= pageCount) nextPage = 0;

    setActiveIndex(nextPage);
  };

  /**
   * Pauses the updateInterval for a given duration
   *
   */
  const pauseForDuration = (duration: number) => {
    setLocalInterval(null);

    setTimeout(() => {
      setLocalInterval(updateInterval);
      // eslint-disable-next-line
      //@ts-ignore
    }, duration - updateInterval);
  };

  // How far, in pixels, the user has to drag in order for the swipe to count
  const dragDistanceThreshold = width * 0.45;

  /**
   * Handles what happens when the user finishes dragging
   */
  const handleDragEnd = (_: globalThis.MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const { offset } = info;
    // If the distance dragged is less than the threshold, don't do anything
    if (Math.abs(offset.x) < dragDistanceThreshold) return;

    if (offset.x > 0) {
      showPreviousPage();
    } else {
      showNextPage();
    }

    // Automatically pause the updateInterval for a moment for usability
    pauseForDuration(pauseDuration);
  };

  useInterval(showNextPage, interval);

  return (
    <div className={classNames(styles.container, className)} ref={ref}>
      <div
        className={styles.pages}
        style={boxStyle}
        onMouseEnter={() => {
          // Pause interval
          setLocalInterval(null);
        }}
        onMouseLeave={() => {
          // Resume interval
          setLocalInterval(updateInterval);
        }}
      >
        {Children.map(children, (child, index) => {
          const item = child as ReactElement<PropsWithChildren<ICarouselPageProps>>;
          const { props } = item;
          if (!props?.children || props.visible === false) return null; // Skip hidden pages
          const { children: pageContent, className: pageClassName } = props;

          let animationProps = center;
          if (index < activeIndex) animationProps = moveLeft;
          else if (index > activeIndex) animationProps = moveRight;

          return (
            <motion.div
              animate={animationProps}
              className={classNames(styles.page, pageClassName)}
              style={boxStyle}
              initial={initial}
              key={index}
              transition={pageTransition}
            >
              <motion.div
                style={{
                  x,
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                onDragEnd={handleDragEnd}
                dragDirectionLock
              >
                {pageContent}
              </motion.div>
            </motion.div>
          );
        })}
      </div>

      <div className={styles.dots}>
        {Children.map(children, (page, index) => {
          if (!page) return null; // Skip hidden pages

          const isPressed = index === activeIndex;

          return (
            <button
              {...outlineSuppressionHandlers}
              className={classNames(
                styles.dot,
                dotColor,
                isPressed && (activeDotColor || styles.active),
              )}
              onClick={() => {
                setActiveIndex(index);
                pauseForDuration(15000);
              }}
              key={index}
              aria-pressed={isPressed}
              aria-label={`Page ${index + 1}`}
            />
          );
        })}
      </div>
    </div>
  );
};
