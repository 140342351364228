import { Maybe, NurseryOrganizationType } from 'graphql/generated-types';
import { getFields } from 'models/utils';

interface IDefaultFields {
  readonly id: Maybe<string>;
  readonly isVisible: boolean;
  readonly name: string;
}

export interface INurseryOrganizationModel extends IDefaultFields {
  readonly isLoaded: boolean;
  readonly __originalData: NurseryOrganizationType | Partial<NurseryOrganizationType>;
}

const defaultFields: IDefaultFields = {
  id: null,
  isVisible: false,
  name: '',
};

export const NurseryOrganization = (
  organisationData?: NurseryOrganizationType,
): INurseryOrganizationModel => {
  const fields = getFields<NurseryOrganizationType, IDefaultFields>(
    organisationData || null,
    defaultFields,
  );

  return {
    ...fields,
    isLoaded: !!organisationData?.id && typeof organisationData.id === 'string',
    __originalData: organisationData || {},
  };
};
