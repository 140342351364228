import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import { AuthContext } from 'app/auth';
import getGuardian from 'graphql/queries/getGuardian';
import { errorCodes } from 'graphql/constants';
import { Guardian } from 'models';

/**
 * Custom hook that provides all the Nursery Profile logic to the `<ProfileContext.Provider>`
 *
 * This hook should be used to maintain the internal state and methods of the Profile
 * object that gets passed to any components that `useContext(ProfileContext)`.
 */
export const useProfileData = () => {
  const authContext = useContext(AuthContext);

  const { data, refetch, error } = useQuery(getGuardian, {
    skip: authContext.isInitializing || !authContext.isAuthenticated(),
  });

  const [profile, setProfile] = useState(Guardian());

  useEffect(() => {
    if (!data || !data.guardian) return;

    setProfile(Guardian(data.guardian));
  }, [data]);

  const requiresOnboarding =
    (error && error.message === errorCodes.GUARDIAN_DOES_NOT_EXIST) || false;

  useEffect(() => {
    if (!data || !data.guardian) return;

    Sentry.setUser({
      id: data.guardian.id,
      'Cognito ID': authContext.activeUser?.username,
    });
  }, [authContext.activeUser?.username, data]);

  return {
    error,
    isInitializing: !profile.isLoaded && !error,
    profile,
    refetch,
    requiresOnboarding,
  };
};
