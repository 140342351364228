/**
 * Splits a full name into parts and returns the first name
 *
 * @param {String} fullName The full name
 * @returns String
 */
export const getFirstName = (fullName) => {
  if (!fullName || typeof fullName !== 'string') return fullName;

  return fullName.split(' ')[0];
};

/**
 * Consistently format price
 */
export const formatPrice = (price) => {
  if (price === undefined) return price;

  const priceInt = typeof price === 'number' ? price : Number(price.replace(/(-|£)/g, ''));
  const isWhole = Number.isInteger(priceInt);

  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: isWhole ? 0 : 2,
    maximumFractionDigits: isWhole ? 0 : 2,
  };

  return priceInt.toLocaleString('en-GB', opts);
};

export const formatPenceToPounds = (priceInPence) => {
  if (priceInPence === undefined) return priceInPence;
  const priceInPounds = priceInPence / 100;

  return priceInPounds;
};

export const QUERY_PARAM_CHECKMARK = '✓';
