import { format, parseISO } from 'date-fns';

import {
  Maybe,
  ChildType,
  NurseryType,
  NurseryRoomType,
  ChildStatus,
} from 'graphql/generated-types';
import { getFields } from 'models/utils';

interface IDefaultFields {
  readonly id: Maybe<string>;
  readonly primaryNursery: Maybe<NurseryType>;
  readonly primaryNurseryRoom?: Maybe<NurseryRoomType>;
  readonly status: Maybe<ChildStatus>;
  readonly fullName: string;
  readonly birthDate: Maybe<string>;
  readonly isExternallyManaged: boolean;
  readonly isRegisteredWithNursery: boolean;
  readonly instantBook: boolean;
  readonly isAdult: boolean;
}
export interface IChildModel extends IDefaultFields {
  readonly birthDateObj: Maybe<Date>;
  readonly firstName: string;
  readonly hasBirthDate: boolean;
  readonly isActive: boolean;
  readonly isUnrecognized: boolean;
  readonly birthDateFormatted: string;
  readonly hasPrimaryNursery: boolean;
  readonly primaryNurseryId: Maybe<string>;
  readonly primaryNurseryRoomName: string;
  readonly canBook: boolean;
}

const getBirthDateFormatted = (birthDateObj: Maybe<Date>) => {
  if (!birthDateObj) return '–';

  return format(birthDateObj, 'dd | MM | yyy');
};

const getPrimaryNurseryRoomName = (fields: IDefaultFields) => {
  if (!fields?.primaryNurseryRoom || !fields.primaryNurseryRoom.name) return '–';

  return fields.primaryNurseryRoom.name;
};

const defaultFields: IDefaultFields = {
  id: null,
  primaryNursery: null,
  primaryNurseryRoom: null,
  status: null,
  fullName: '',
  birthDate: null,
  isExternallyManaged: false,
  isRegisteredWithNursery: false,
  instantBook: false,
  isAdult: false,
};

export const Child = (childData: Maybe<ChildType>): IChildModel => {
  const fields = getFields<ChildType, IDefaultFields>(childData, defaultFields);

  const birthDateObj = fields?.birthDate ? parseISO(fields.birthDate) : null;

  return {
    ...fields,
    birthDateObj,
    firstName: fields.fullName ? fields?.fullName.split(' ')[0] : '',
    hasBirthDate: fields.birthDate !== null,
    isActive:
      !!fields?.status && [ChildStatus.Approved, ChildStatus.Pending].includes(fields?.status),
    isUnrecognized: fields?.status === ChildStatus.Unrecognized,
    hasPrimaryNursery: fields?.primaryNursery !== null,
    primaryNurseryId: fields?.primaryNursery?.id ? fields?.primaryNursery.id : null,
    birthDateFormatted: getBirthDateFormatted(birthDateObj),
    primaryNurseryRoomName: getPrimaryNurseryRoomName(fields),
    canBook:
      fields.status !== ChildStatus.Deactivated && fields.status !== ChildStatus.Unrecognized,
    instantBook: fields.instantBook,
  };
};
