import { createContext } from 'react';

import { asyncNoOp, noOp } from 'utilities/functions';

import { useBookingParamsInternal } from './';

export const BookingContext = createContext({
  // Properties
  allowInstantBook: false,
  childIds: [],
  submitBookingSaving: false,
  date: undefined,
  dateStamp: undefined,
  hasValidQueryParams: false,
  isPaymentExempt: false,
  nursery: undefined,
  paymentExemptReason: undefined, // <PaymentExemptionReasonEnum>
  selectedChildren: [],
  sessionType: undefined, // <BookingSessionTypeEnum>
  type: undefined, // <BookingRequestTypeEnum>

  // Methods
  createBooking: asyncNoOp,
  replaceQueryParams: noOp,
});

export const BookingProvider = ({ children }) => (
  <BookingContext.Provider value={useBookingParamsInternal()}>{children}</BookingContext.Provider>
);
