import { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import { AuthContext, AuthError } from 'app/auth';
import { logButtonEvent } from 'app/amplitude';

import { YourDetails } from './screens';
import styles from './Register.module.scss';
import { usePageViewTracker } from 'hooks';

export const Register = () => {
  const history = useHistory();
  const location = useLocation();

  const [formData, setFormData] = useState({
    mobileNumber: '',
    password: '',
    termsOfUse: false,
    privacyPolicy: false,
  });
  const [showError, setShowError] = useState(false);
  const authContext = useContext(AuthContext);

  usePageViewTracker('register');

  /**
   * Performs a user registration with the collected formData.
   */
  const register = async () => {
    try {
      logButtonEvent('register_terms_and_conditions');
      await authContext.signup(formData.mobileNumber, formData.password);

      history.push({
        pathname: '/verify',
        search: location?.search,
      });
    } catch (error) {
      if (error instanceof AuthError && error.errorCode === 'UsernameExistsException') {
        setShowError(true);
      } else {
        history.push('/error', { from: '/register' });
      }
    }
  };

  return (
    <>
      <YourDetails register={register} formData={formData} setFormData={setFormData} />

      <Dialog
        title="Registration issue"
        isVisible={showError}
        onClose={() => setShowError(false)}
        menu={
          <Button href="/forgot-password" expand>
            Reset password
          </Button>
        }
      >
        <p>
          We’re sorry, we were unable to create your account. If you already registered and forgot
          your password you can reset it below, or contact{' '}
          <a href="mailto:support@bookpebble.co.uk" className={styles.supportLink}>
            support@bookpebble.co.uk
          </a>
        </p>
      </Dialog>

      <ModalBackdrop isVisible={showError} />
    </>
  );
};
