import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import getNurseryById from 'graphql/queries/getNurseryById';
import { Nursery } from 'models';

export const useNurseryData = (nurseryId) => {
  const [nursery, setNursery] = useState(Nursery());
  const { data } = useQuery(getNurseryById, {
    variables: {
      id: nurseryId,
    },
    skip: !nurseryId,
  });

  useEffect(() => {
    if (!data || !data.nurseryById) return;

    setNursery(Nursery(data.nurseryById));
  }, [data]);

  return nursery;
};
