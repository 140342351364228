import { createElement, Fragment } from 'react';

/**
 * Takes a list of names and joins them in a way to match the design.
 *
 * Each name must be wrapped in an `em` for styling. Each name must be
 * separated by a comma, except the last name which gets separted by an
 * ampersand.
 *
 * @param {Array} names The list of names to render
 */
export const combineNames = (names) => {
  switch (names.length) {
    case 1:
      return <em>{names.toString()}</em>;
    case 2:
      return (
        <Fragment>
          <em>{names[0]}</em> & <em>{names[1]}</em>
        </Fragment>
      );

    default: {
      const firstNames = names.slice(0, -1);
      const finalName = names.slice(-1);

      return (
        <Fragment>
          {firstNames.map((name, index) => (
            <Fragment key={index}>
              <em>{name}</em>
              {index !== firstNames.length - 1 && ', '}
            </Fragment>
          ))}{' '}
          & <em>{finalName}</em>
        </Fragment>
      );
    }
  }
};

/**
 * Converts a string with line breaks to <br /> tags
 *
 * @param {String} str The string to convert
 * @returns {JSX}
 */
export const nl2br = (str) => {
  var newlineRegex = /(\r\n|\r|\n)/g;

  if (typeof str !== 'string') {
    return str;
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return createElement('br', { key: index });
    }
    return line;
  });
};
