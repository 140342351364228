import { gql } from '@apollo/client';

const getCustomSessionTypeById = gql`
  query GetCustomSessionTypeById($child: UUID!, $customSessionType: UUID!) {
    customSessionTypeById(child: $child, customSessionType: $customSessionType) {
      id
      name
      startTime
      endTime
      availabilityType
      price
    }
  }
`;

export default getCustomSessionTypeById;
