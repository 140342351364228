import { IllustratedInfoPage } from 'layouts';
import { useQuery } from '@apollo/client';

import getGuardian from 'graphql/queries/getGuardian';
import { Button } from 'ui';
import illustration from '../../illustrations/womanWithChild.png';
import styles from './AllDone.module.scss';
import { usePageViewTracker } from 'hooks';

export const AllDone = () => {
  const { data } = useQuery(getGuardian);

  usePageViewTracker('all_done');

  const firstName = data?.guardian?.fullName?.split?.(' ')?.[0];

  return (
    <IllustratedInfoPage
      illustration={
        <img
          src={illustration}
          className={styles.illustration}
          alt="Father and daugher clapping hands"
        />
      }
      footer={
        <Button expand href="/home" key="home">
          Let's start
        </Button>
      }
    >
      <main className={styles.container}>
        <div className={styles.introText}>
          <h1 className={styles.title}>All done {firstName}!</h1>

          <p>You can now book activities for your child.</p>
        </div>
      </main>
    </IllustratedInfoPage>
  );
};

AllDone.buttonEvent = 'onboarding_intro';
