import { useMemo } from 'react';

import { useProfile } from 'app/profile';
import { useQueryParams } from 'hooks';

export const useSessionSelectionParams = () => {
  const { profile } = useProfile();
  const queryParams = useQueryParams();
  const childIds = useMemo(() => queryParams.children?.split?.(','), [queryParams]);

  let hasValidQueryParams = true;
  let errors = {};

  if (childIds.some((i) => i?.length !== 36)) {
    hasValidQueryParams = false;
    errors.children =
      'Invalid child IDs in query paramters, received `' + queryParams.children + '`';
  }

  const selectedChildren = useMemo(
    () => profile.children.filter?.((c) => childIds.includes(c.id)),
    [profile, childIds],
  );

  // Data that depends on `selectedChildren`
  const { allowInstantBookings, childNames, nurseryId } = useMemo(() => {
    // Disallow any bookings when child is deactivated or unrecognized
    const canBook = selectedChildren.every(
      (c) => c?.status !== 'DEACTIVATED' && c?.status !== 'UNRECOGNIZED',
    );

    // Determine if selected children can be booked instantly
    const allChildrenHavePrimaryRooms = selectedChildren.every(
      (c) => c?.primaryNurseryRoom?.id !== null,
    );
    const allChildrenAreApproved = selectedChildren.every((c) => c?.status === 'APPROVED');
    const childrenCanInstantBook = selectedChildren.every((c) => c?.instantBook);

    return {
      allowBookings: canBook,
      allowInstantBookings:
        allChildrenHavePrimaryRooms && allChildrenAreApproved && childrenCanInstantBook,
      childNames: selectedChildren?.map?.((c) => c.fullName.split(' ')[0]) ?? [],
      nurseryId: selectedChildren?.[0]?.primaryNursery?.id ?? null,
    };
  }, [selectedChildren]);

  return {
    allowInstantBookings,
    childNames,
    errors,
    hasValidQueryParams,
    nurseryId,
    queryParams,
  };
};
