import { FC, useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import { logButtonEvent } from 'app/amplitude';
import classNames from 'classnames';
import promotion from 'graphql/queries/getPromotion';
import { Input } from 'ui/Input';
import { ErrorMessage } from 'ui/ErrorMessage';

import styles from './SessionCost.module.scss';
import { Scalars, BookingTypeEnum } from 'graphql/generated-types';
import { formatPrice } from 'utilities/string';

interface ISessionCostProps {
  price: number;
  sessionCostCopy?: string;
  nurseryId?: Scalars['UUID'];
  supplierId?: Scalars['UUID'];
  bookingType: BookingTypeEnum;
  bookingDate: Date;
  onDiscountApplied(promo: {
    id: Scalars['UUID'];
    finalAmount: number;
    discount: number;
    originalAmount: number;
  }): void;
  onDiscountRemoved(): void;
  className?: string;
}

type State = {
  discountCode: string;
  discount: number;
  discountError: string;
  discountCodeValid: boolean;
  finalAmount: number | null;
};

type Action =
  | { type: 'VALID'; payload: { discount: number; finalAmount: number } }
  | { type: 'ERROR'; payload: string }
  | { type: 'CHANGE'; payload: string }
  | { type: 'REMOVE' };

const VALID = 'VALID';
const ERROR = 'ERROR';
const CHANGE = 'CHANGE';
const REMOVE = 'REMOVE';

const initialState = {
  discountCode: '',
  discount: 0,
  discountError: '',
  discountCodeValid: false,
  finalAmount: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case VALID:
      return {
        ...state,
        discount: action.payload.discount / 100,
        finalAmount: action.payload.finalAmount / 100,
      };
    case ERROR:
      return {
        ...state,
        discountError: action.payload,
      };
    case CHANGE:
      return {
        ...state,
        discount: 0,
        discountError: '',
        discountCode: action.payload,
        discountCodeValid: action.payload.length > 2,
        finalAmount: null,
      };
    case REMOVE:
      return initialState;
    default:
      return state;
  }
};

let changeTimeout: ReturnType<typeof setTimeout>;

export const SessionCost: FC<ISessionCostProps> = ({
  price,
  sessionCostCopy,
  nurseryId,
  supplierId,
  bookingType,
  bookingDate,
  onDiscountApplied,
  onDiscountRemoved,
  className = '',
}) => {
  const priceInPence = Math.round(price * 100);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { discount, discountCode, discountCodeValid, discountError, finalAmount } = state;
  const actionPrefix = 'session';
  const [getPromotion] = useLazyQuery(promotion, {
    onCompleted: ({ promotionForGuardians }) => {
      logButtonEvent(`${actionPrefix}_promo_code_valid`);
      dispatch({
        type: VALID,
        payload: {
          discount: promotionForGuardians.discount,
          finalAmount: promotionForGuardians.finalAmount,
        },
      });
      if (onDiscountApplied) {
        onDiscountApplied(promotionForGuardians);
      }
    },
    onError: (e) => {
      logButtonEvent(`${actionPrefix}_promo_code_invalid`);
      dispatch({ type: ERROR, payload: e.message });
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      code: discountCode,
      nursery: nurseryId,
      supplier: supplierId,
      bookingType: bookingType,
      bookingDate: format(bookingDate, 'yyyy-MM-dd'),
      amount: priceInPence,
    },
  });

  const totalCost = finalAmount ?? price;

  useEffect(() => {
    if (changeTimeout) {
      clearTimeout(changeTimeout);
    }
    if (discountCodeValid) {
      changeTimeout = setTimeout(() => {
        getPromotion({
          variables: {
            code: discountCode,
            nursery: nurseryId,
            supplier: supplierId,
            bookingType: bookingType,
            bookingDate: format(bookingDate, 'yyyy-MM-dd'),
            amount: priceInPence,
          },
        });
      }, 600);
    }
    return () => clearTimeout(changeTimeout);
  }, [
    discountCodeValid,
    getPromotion,
    discountCode,
    nurseryId,
    supplierId,
    bookingDate,
    bookingType,
    priceInPence,
  ]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (discountCodeValid) {
      onDiscountRemoved();
    }
    dispatch({ type: CHANGE, payload: e.target.value });
  };

  const removeCode = () => {
    dispatch({ type: REMOVE });
    logButtonEvent(`${actionPrefix}_remove_code`);
    if (onDiscountRemoved) {
      onDiscountRemoved();
    }
  };

  return (
    <section className={classNames(styles.sessionCostInfo, styles[className])}>
      <div className={styles.sessionCostSection}>
        <div>
          <h2>Session cost</h2>
          <p>{sessionCostCopy}</p>
        </div>

        <div className={styles.price}>{formatPrice(price)}</div>
      </div>

      <div className={styles.sessionCostSection}>
        <div className={styles.discount}>
          <Input
            className={styles.discountInput}
            label="Discount code"
            value={discountCode}
            placeholder="Insert your code here"
            onChange={handleChange}
            isInvalid={Boolean(discountError)}
            showAsValid={discount > 0}
            // mask={false}
            prefix={undefined}
            suffix={undefined}
          />
          <ErrorMessage isVisible={Boolean(discountError)} className={styles.errorMessage}>
            {discountError}
          </ErrorMessage>
        </div>
        <div
          className={classNames(styles.price, styles.discountAmount, {
            [styles.hasDiscountError]: Boolean(discountError),
          })}
        >
          {discount > 0 ? '-' : ''}
          {formatPrice(discount)}
        </div>
      </div>
      {discount > 0 && (
        <button onClick={removeCode} className={styles.removeButton}>
          Remove code
        </button>
      )}
      <div className={classNames(styles.sessionCostSection, styles.totalCostSection)}>
        <div>
          <h1>Total cost</h1>
        </div>
        <div className={styles.totalPrice}>{formatPrice(totalCost)}</div>
      </div>
    </section>
  );
};
