import classNames from 'classnames';

import { IconCheck } from 'icons';

import styles from './Checkbox.module.scss';

export const Checkbox = ({
  className = '',
  disabled = false,
  visuallyDisabled = false,
  ...props
}) => (
  <label
    className={classNames(
      styles.container,
      (disabled || visuallyDisabled) && styles.disabled,
      className,
    )}
  >
    <input {...props} disabled={disabled} type="checkbox" className={styles.input} />
    <div className={styles.label}>
      <IconCheck size={12} className={styles.checkMark} />
    </div>
  </label>
);
