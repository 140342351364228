import {
  useMemo,
  useEffect,
  useState,
  useImperativeHandle,
  Children,
  isValidElement,
  createContext,
  forwardRef,
} from 'react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';

import { useWindowSize } from 'hooks';
import { noOp } from 'utilities/functions';

import styles from './Wizard.module.scss';

export const WizardContext = createContext({
  controls: {
    goToNextScreen: noOp,
    goToPreviousScreen: noOp,
    goToScreen: noOp,
  },
  currentScreen: '',
  formData: {},
  onChange: noOp,
});

export const Wizard = forwardRef(
  ({ children, useHistoryApi, formData, onChange, onScreenChange, ...wizardProps }, ref) => {
    const history = useHistory();
    const location = useLocation();
    const windowSize = useWindowSize();

    const screens = useMemo(
      () =>
        Children.map(children, ({ props }, index) => {
          const { screenName, ...originalProps } = props;
          const component = isValidElement(children[index]) ? children[index].type : null;

          return {
            screenName,
            component,
            originalProps,
          };
        }),
      [children],
    );

    const initialScreen = history.location?.state?.screenName ?? screens[0].screenName;
    const [currentScreen, setScreen] = useState(initialScreen);

    const currentIndex = useMemo(
      () => screens.findIndex((s) => s.screenName === currentScreen),
      [screens, currentScreen],
    );

    const controls = {
      goToNextScreen: () => {
        const nextScreen = screens[currentIndex + 1];

        if (!nextScreen) return;

        setScreen(nextScreen.screenName);

        if (typeof onScreenChange === 'function') {
          onScreenChange(nextScreen.screenName, currentScreen, true);
        }

        if (useHistoryApi) {
          history.push(
            {
              pathname: history.location.pathname,
              search: location?.search,
            },
            {
              screenName: nextScreen.screenName,
              formData,
            },
          );
        }
      },
      goToPreviousScreen: () => {
        const previousScreen = screens[currentIndex - 1];

        if (!previousScreen) return;

        setScreen(previousScreen.screenName);

        if (typeof onScreenChange === 'function') {
          onScreenChange(previousScreen.screenName, currentScreen, false);
        }

        if (useHistoryApi) {
          history.push(
            {
              pathname: history.location.pathname,
              search: location?.search,
            },
            {
              screenName: previousScreen.screenName,
              formData,
            },
          );
        }
      },
      goToScreen: (screenName) => {
        setScreen(screenName);

        if (typeof onScreenChange === 'function') {
          onScreenChange(screenName, currentScreen, false);
        }

        if (useHistoryApi) {
          history.push(
            {
              pathname: history.location.pathname,
              search: location?.search,
            },
            {
              screenName,
              formData,
            },
          );
        }
      },
    };

    const updateFormData = (stringOrValue, value) => {
      const customUpdater = screens[currentIndex]?.originalProps?.onChange;

      // Allow screens to override the default `onChange` handler if one is specified specially
      // for this screen
      if (typeof customUpdater === 'function') {
        customUpdater(stringOrValue, value);
        return;
      }

      if (typeof stringOrValue === 'string') {
        // Update data by single key
        onChange({
          ...formData,
          [stringOrValue]: value,
        });
      } else {
        // Update data with an object
        onChange({
          ...formData,
          ...stringOrValue,
        });
      }
    };

    const wizard = {
      currentScreen,
      formData,
      controls,
      onChange: updateFormData,
    };

    // Allow accessing the wizard properties from the parent component using a ref
    useImperativeHandle(ref, () => wizard);

    /**
     * Sets the current screen whenever the browser location changes
     */
    useEffect(() => {
      const { screenName } = history.location?.state ?? {};

      if (!useHistoryApi || screenName === undefined) return;

      const firstScreen = screens[0].screenName;

      // Only update screen name in this way if it differs from what's currently in browser state.
      if (screenName !== currentScreen) {
        setScreen(screenName ?? firstScreen);
      }
    }, [history.location, currentScreen, screens, setScreen, useHistoryApi]);

    /**
     * Ensures `screen` is always set when using useHistoryApi
     */
    useEffect(() => {
      if (!useHistoryApi) return;

      const screenNameFromHistory = history?.location?.state?.screenName;

      if (screenNameFromHistory === undefined) {
        // If using Wizard's `useHistoryApi`, update the current browsing location with the initial screen name
        history.replace(
          {
            pathname: history.location.pathname,
            search: location?.search,
          },
          {
            screenName: currentScreen,
            formData,
          },
        );
      }
    }, [useHistoryApi, currentScreen, history, formData]);

    return (
      <WizardContext.Provider value={wizard}>
        <div className={styles.container} style={windowSize}>
          {screens.map((screen, index) => {
            const Screen = screen.component;
            const isVisible = screen.screenName === currentScreen;
            const position = isVisible ? center : index < currentIndex ? moveLeft : moveRight;

            return (
              <motion.div
                key={screen.screenName}
                animate={position}
                initial={false}
                exit={moveLeft}
                className={styles.screen}
                style={windowSize}
                ref={ref}
              >
                {isVisible && <Screen {...screen.originalProps} {...wizardProps} />}
              </motion.div>
            );
          })}
        </div>
      </WizardContext.Provider>
    );
  },
);

Wizard.defaultProps = {
  errors: {},
  formData: {},
  onChange: noOp,
  useHistoryApi: true,
};

const moveLeft = { x: '-100vw', opacity: 0 };
const moveRight = { x: '100vw', opacity: 0 };
const center = { x: 0, opacity: 1 };
