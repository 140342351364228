import { logButtonEvent } from 'app/amplitude';
import { useProfile } from 'app/profile';
import { usePageViewTracker } from 'hooks';
import { IllustratedInfoPage } from 'layouts';
import { useEffect } from 'react';
import { Button } from 'ui';
import { StickyNav } from 'ui/StickyNav';

import illustration from './newFamilyIllustration.png';
import styles from './OnboardingIntro.module.scss';

export const OnboardingIntro = (props) => {
  const { history } = props;

  const { profile } = useProfile();

  usePageViewTracker('onboarding/intro');

  const handleNext = () => {
    history.push({ pathname: `/onboarding/guardian-info`, search: history.location.search });
    logButtonEvent('set_up_account');
  };

  useEffect(() => {
    if (profile.id && profile.email) {
      history.replace({ pathname: `/`, search: history.location.search });
    }
  }, [profile]);

  return (
    <IllustratedInfoPage
      illustration={<img src={illustration} className={styles.illustration} alt="Family playing" />}
      footer={
        <StickyNav>
          <Button expand onClick={handleNext} key="next">
            Set up my account
          </Button>
        </StickyNav>
      }
    >
      <main className={styles.container}>
        <div className={styles.introText}>
          <h1 className={styles.title}>Welcome to Pebble!</h1>

          <p>Now, let’s set up your account.</p>
        </div>
      </main>
    </IllustratedInfoPage>
  );
};

OnboardingIntro.buttonEvent = 'onboarding_intro';
