import { gql } from '@apollo/client';

const getPriceList = gql`
  query GetPriceList($childId: UUID!) {
    childPriceListById(childId: $childId) {
      id
      primaryNurseryPriceList {
        amPrice
        endDate
        fullDayPrice
        id
        pmPrice
        startDate
      }
    }
  }
`;

export default getPriceList;
