import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';

export const useQueryParams = (): ParsedQuery<string> => {
  const history = useHistory();
  const { search } = history.location;

  const queryParams = useMemo(() => queryString.parse(search), [search]);
  return queryParams || {};
};
