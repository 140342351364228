import { gql } from '@apollo/client';

const getOrganizations = gql`
  query GetOrganizations {
    nurseryOrganizationsForGuardians {
      id
      name
      isVisible
      isManagedByExternalSystem
    }
  }
`;

export default getOrganizations;
