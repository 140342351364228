import { FC } from 'react';

import { PaymentStatus } from 'graphql/generated-types';
import { Modal } from 'ui/Modal';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import { StatusLabel } from '../StatusLabel';

import styles from './PaymentInfoModal.module.scss';

interface PaymentInfoModalProps {
  isVisible: boolean;
  isPaymentExempt: boolean;
  onClose: () => void;
}

export const PaymentInfoModal: FC<PaymentInfoModalProps> = ({
  isVisible,
  isPaymentExempt,
  onClose,
}) => {
  let modalContent = (
    <>
      <section>
        <StatusLabel status={PaymentStatus.Processing}>Pending</StatusLabel>
        <p>
          “Pending” means your payment has been authorised and you may see a hold on your card. It
          will be processed when the nursery approves your booking.
        </p>
      </section>

      <section>
        <StatusLabel status={PaymentStatus.Succeeded}>Paid</StatusLabel>
        <p>“Paid” means your booking is confirmed and your payment has been processed.</p>
      </section>

      <section>
        <StatusLabel status="refunded">Refunded</StatusLabel>
        <p>
          “Refunded” means all or part of your payment has been refunded to your card. This may
          occur if a booking you already paid for is cancelled.
        </p>
      </section>

      <section>
        <StatusLabel status={PaymentStatus.Canceled}>Cancelled</StatusLabel>
        <p>
          “Cancelled” means that the payment was never processed. This may occur if the booking was
          cancelled or the nursery took more than 7 days to respond to your request.
        </p>
      </section>
    </>
  );

  if (isPaymentExempt) {
    modalContent = (
      <div className={styles.paymentExempt}>
        <p>
          Your session is free of charge because you have declared that you are exempt from payment
          when using Pebble for one of the following reasons:
        </p>

        <ul className={styles.exemptionReasons}>
          <li>This is an emergency care session paid for by my employer</li>
          <li>This session will be paid via tax-free account</li>
          <li>This is a day in lieu</li>
          <li>This is a settle-in session</li>
        </ul>
      </div>
    );
  }

  return (
    <>
      <ModalBackdrop isVisible={isVisible} onClick={onClose} />
      <Modal isVisible={isVisible} className={styles.container} onClose={onClose}>
        <h2>About payments</h2>

        {modalContent}
      </Modal>
    </>
  );
};
