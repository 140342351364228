import { GlobalState } from 'little-state-machine';

export const DEFAULT_INITIALFORM_DATA = {
  fullName: '',
  email: '',
  nurseryId: '',
  isExternallyManaged: false,
  guardianId: null,
  organizationId: '',
  organizationName: '',
  temporaryChildData: [],
  optIn: false,
  establishment: null,
};

interface TemporaryChildData {
  fullName: string;
  primaryNurseryRoomId: null | string;
  roomName: string;
  nurseryName: string; // Only used with external nurseries .e.g Busy
  birthDate: string;
  establishment: string;
  updateIndex: null;
}

export interface FormState extends GlobalState {
  fullName: string;
  email: string;
  nurseryId: string;
  isExternallyManaged: boolean;
  guardianId: null | string;
  organizationId: string;
  organizationName: string;
  temporaryChildData: TemporaryChildData[];
  optIn: boolean;
  establishment: 'nursery' | 'activities' | null;
}

const mergeDeepArray = <T>(target: T[], source: T[]): T[] | [] => {
  const destination = (target || []).slice();
  source.forEach(function (e, i) {
    if (destination[i]) {
      destination[i] = { ...target[i], ...e };
    } else {
      destination[i] = e;
    }
  });
  return destination;
};

export function updateTemporaryChildData(state: FormState, payload: Partial<FormState>): FormState {
  return {
    ...state,
    temporaryChildData: mergeDeepArray<TemporaryChildData>(
      state.temporaryChildData,
      payload?.temporaryChildData || [],
    ),
  };
}

export function updateState(state: GlobalState, payload: Partial<GlobalState>): GlobalState {
  return {
    ...state,
    ...payload,
  };
}

export function deleteState(): Partial<FormState> {
  return DEFAULT_INITIALFORM_DATA;
}
