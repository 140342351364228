import { useMemo } from 'react';
import { IconHome } from 'icons';
import { Dropdown, Pebbles } from 'ui';

import { BookingButton } from 'app/components';
import { formatPrice } from 'utilities/string';
import styles from './SessionPicker.module.scss';

export const SessionPicker = ({
  availability,
  isLoading = true,
  nursery,
  onChange,
  session,
  handleBookingButtonClick,
}) => {
  const dropdownOptions = useMemo(() => {
    if (!availability || !availability.sessions) return [];

    const allSessions = availability.sessions.map(({ label, type, price = 0 }, i) => {
      return {
        label: nursery.paymentsEnabled ? `${label} (${formatPrice(price)})` : label,
        value: type,
      };
    });

    return nursery.allowOnlyFullDaySessions
      ? allSessions.filter(({ value }) => value === 'FULL')
      : allSessions;
  }, [availability, nursery]);

  const isAlreadyBooked = session?.isBooked === true;
  const isAltSessionBooked = !!session?.isAltSessionBooked;
  const isSessionPastCutoff =
    !isAltSessionBooked && !isAlreadyBooked && session?.isBookable === false;
  const buttonDisabled = isAlreadyBooked || isAltSessionBooked || isSessionPastCutoff;

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Pebbles className={styles.loadingIndicator} size={60} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.iconWrapper}>
          <IconHome size={24} />
        </div>

        <h2 className={styles.nurseryInfo}>
          {nursery?.organization.name} - {nursery?.name}
        </h2>
      </header>

      <div className={styles.controls}>
        <Dropdown
          options={dropdownOptions}
          value={session?.type ?? ''}
          onChange={onChange}
          className={styles.dropdown}
          noValueText="Select a session"
          testID="booking-session-selection__session-picker-dropdown"
        />

        <BookingButton
          isInstant={session?.isInstantBookable}
          className={styles.bookingButton}
          onClick={handleBookingButtonClick}
          disabledText={buttonDisabled ? 'Unavailable' : 'Availability'}
          disabled={!session || buttonDisabled}
          data-testid="booking-session-selection__session-picker-booking-button"
        />
      </div>

      {isSessionPastCutoff && (
        <div className={styles.bookingCutoffWarning}>
          Sorry, you have missed the booking cutoff for this session. Please try a later session.
        </div>
      )}
      {isAlreadyBooked && (
        <div className={styles.bookingCutoffWarning}>
          Your child is already booked for this session. Please review your existing session
          bookings.
        </div>
      )}
      {!isAlreadyBooked && isAltSessionBooked && (
        <div className={styles.bookingCutoffWarning}>
          Your child is already booked in for the {session?.isAltSessionBooked} session. If you'd
          like to turn this into a full-day session, you can book the{' '}
          {session?.isAltSessionBooked === 'morning' ? 'afternoon' : 'morning'} session separately.
        </div>
      )}
    </div>
  );
};
