import { useMemo } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './ErrorMessage.module.scss';

export const ErrorMessage = ({ children, className, isVisible, ...props }) => {
  const animationProps = useMemo(
    () => ({
      height: isVisible ? 'auto' : 0,
      opacity: isVisible ? 1 : 0,
      x: isVisible ? [0, 10, -10, 10, -10, 0] : -100,
    }),
    [isVisible],
  );

  return (
    <motion.div
      {...props}
      animate={animationProps}
      initial={false}
      style={errorStyle}
      className={classNames(styles.errorMessage, className)}
    >
      {children}
    </motion.div>
  );
};

ErrorMessage.defaultProps = {
  isVisible: true,
};

const errorStyle = {
  originY: 0.5,
};
