import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { parse } from 'date-fns';

import getBookingsByIds from 'graphql/queries/getBookingsByIds';
import { BookingSessionTypeEnum, BookingStatusEnum } from 'graphql/constants';
import { Child, Payment, RequestPayment } from 'models';

export const useSessionDetails = (bookingIds) => {
  const [sessions, setSessions] = useState([]);

  const { data, loading } = useQuery(getBookingsByIds, {
    variables: {
      bookingIds,
    },
    skip: bookingIds.length === 0,
  });

  useEffect(() => {
    if (data === undefined) return;

    if (Array.isArray(data.bookingsByIds)) {
      const populatedSessions = bookingIds.map((id) => data.bookingsByIds.find((b) => b.id === id));

      setSessions(populatedSessions);
    }
  }, [data, bookingIds]);

  const { date, status, sessionTypeLabel, nursery, isPrimaryNursery, customSessionDetails } =
    useMemo(() => {
      if (sessions.length === 0) return {};

      const firstSession = sessions[0];

      return {
        date: parse(firstSession.date, 'yyyy-MM-dd', new Date()),
        nursery: firstSession.nursery,
        isPrimaryNursery: firstSession.child.primaryNursery.id === firstSession.nursery.id,
        sessionTypeLabel: sessionTypeMap[firstSession.sessionType],
        customSessionDetails: firstSession.customSessionType,
        status: firstSession.status,
      };
    }, [sessions]);

  const payment = useMemo(() => {
    if (sessions.length === 0) return new Payment(null);

    const session = sessions[0];

    return session.status === 'PENDING'
      ? new RequestPayment(session)
      : new Payment(session.payment);
  }, [sessions]);

  const maxHoldTimeInWeeks = useMemo(() => {
    if (!nursery || !nursery.isBookingHoldEnabled) return 0;

    const { bookingHoldThresholdDays = 0 } = nursery;

    return Math.ceil(bookingHoldThresholdDays / 7);
  }, [nursery]);

  const children = useMemo(() => sessions.map((c) => Child(c.child)), [sessions]);

  const promotion = useMemo(() => {
    if (sessions.length === 0) return {};
    return {
      finalAmount: sessions[0].finalAmount,
      originalAmount: sessions[0].originalAmount,
      discount: sessions[0].discount,
      promotion: sessions[0].promotion,
    };
  }, [sessions]);

  return {
    children,
    date,
    isDeclined: status === BookingStatusEnum.REJECTED,
    isCancelled: status === BookingStatusEnum.CANCELLED,
    isOnHold: status === BookingStatusEnum.ON_HOLD,
    maxHoldTimeInWeeks,
    payment,
    loading,
    nursery,
    isPrimaryNursery,
    sessions,
    sessionTypeLabel,
    status,
    promotion,
    customSessionDetails,
  };
};

const sessionTypeMap = {
  [BookingSessionTypeEnum.AM]: 'Morning',
  [BookingSessionTypeEnum.PM]: 'Afternoon',
  [BookingSessionTypeEnum.FULL]: 'Full Day',
};
