import { format, parseISO, isFuture } from 'date-fns';
import {
  DateAvailabilityType,
  ActivityBookingTicketSessionType,
  Maybe,
  ProductsByDate,
  ProductType,
  ActivityBookingTicketType,
} from 'graphql/generated-types';

export const adjustForUTCOffset = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

// Get session time
export const getSessionTime = (
  sessions: Maybe<ActivityBookingTicketSessionType>[] | null | undefined,
  sessionId: string | string[],
  hideEndTimes?: boolean,
): string => {
  if (!sessions?.length) {
    return '';
  }
  const session = sessions?.find((s) => s?.id === sessionId);

  if (!session) {
    return '';
  }

  const startTime = adjustForUTCOffset(parseISO(session?.sessionStart || ''));
  const endTime = adjustForUTCOffset(parseISO(session?.sessionEnd || ''));
  if (hideEndTimes) {
    return format(startTime, 'HH:mm');
  }
  return `${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`;
};

// Get first and last booking dates

export const getBookingDates = (
  firstDate: Date,
  lastDate: Date,
  numberOfSessions?: number | null,
): string => {
  const FormattedFirstDate = format(new Date(firstDate), 'do MMMM');
  const FormattedLastDate = format(new Date(lastDate), 'do MMMM');

  if (numberOfSessions && numberOfSessions > 1) {
    return `${FormattedFirstDate} - ${FormattedLastDate}`;
  }
  return FormattedFirstDate;
};

export const getFormattedSessionWeekday = (
  sessions: ActivityBookingTicketSessionType[] | null | undefined,
  sessionId: string | string[],
): string => {
  const session = sessions?.find((s) => s.id === sessionId);
  if (!session) {
    return '';
  }

  const startDate = new Date(session?.sessionStart);
  const formattedDate = format(startDate, 'EEEE');
  return `${formattedDate}s`;
};

export const getSessionDate = (
  sessions: ActivityBookingTicketSessionType[] | null | undefined,
  sessionId: string | string[],
): string => {
  const session = sessions?.find((s) => s.id === sessionId);
  if (!session) {
    return '';
  }

  const startDate = new Date(session?.sessionStart);
  const formattedDate = format(startDate, 'do MMMM Y');
  return formattedDate;
};

export const getNextSubscriptionDate = (
  sessions: ActivityBookingTicketSessionType[] | null | undefined,
): string => {
  const allDates = sessions?.map((session) => new Date(session.sessionStart));
  const nextDate = allDates?.find((sessionDate) => isFuture(sessionDate));
  return nextDate ? format(nextDate, 'do MMMM Y') : '';
};

export const getUpcomingDates = (dates?: DateAvailabilityType[] | null): JSX.Element[] => {
  if (!dates) {
    return [];
  }
  return dates
    ?.filter((date) => !date?.isInPast)
    .map((upcoming) => (
      <div key={upcoming?.date}>{format(new Date(upcoming?.date), 'EEEE do MMMM')}</div>
    ));
};

export const getSingleSessionById = (
  sessionId: string,
  productsByDate?: ProductsByDate[] | null,
): ProductType | null => {
  if (!productsByDate) {
    return null;
  }
  const allProducts = productsByDate.flatMap((productByDate) => productByDate.products);
  return allProducts.find((product) => product?.checkoutValue === sessionId) || null;
};

export const getProductById = (id: string, products: ProductType[]): ProductType | null => {
  if (!id || !products) {
    return null;
  }
  return products.find((product) => product?.checkoutValue === id) || null;
};

export const calculateAddons = (
  bookedTickets: Maybe<Maybe<ActivityBookingTicketType>[]> | undefined,
): string => {
  if (!bookedTickets || bookedTickets?.length === 0) {
    return '';
  }

  // Use reducer to accumulate addon quantities
  const addonTotals = bookedTickets.reduce((acc: Record<string, number>, item) => {
    // Iterate over each session of the ticket
    item?.sessions?.forEach((session) => {
      // Iterate over addons of each session
      session?.sessionAddons?.forEach((addon) => {
        const name = addon?.name;
        if (name) {
          acc[name] = (acc[name] || 0) + 1;
        }
        // Initialize quantity count for addon if not present
      });
    });
    return acc;
  }, {});

  const bookingAddonTotals = bookedTickets?.reduce((acc: Record<string, number>, item) => {
    // Access the first session of the ticket -> have shared bookingAddons
    const firstSession = item?.sessions?.[0];
    if (firstSession) {
      // Iterate over booking addons of the first session
      firstSession?.bookingAddons?.forEach((addon) => {
        const name = addon?.name;
        if (name) {
          // Initialize quantity count for addon if not present
          acc[name] = (acc[name] || 0) + (addon?.quantity ?? 1);
        }
      });
    }
    return acc;
  }, {});

  // Convert addonTotals to a string representation
  const addonsString = Object.entries({ ...addonTotals, ...bookingAddonTotals })
    .map(([addonName, quantity]) => `${quantity} x ${addonName}`)
    .join('\n');

  return addonsString;
};
