import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useWizard } from 'app/wizard';
import { DefaultAppPage } from 'layouts';
import { Button, ErrorMessage, Input } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import styles from './ChildInfoEntryScreen.module.scss';

export const ChildInfoEntryScreen = ({ title, fullName, birthDate, establishment, errors }) => {
  const requiredFields = [fullName, birthDate];
  const location = useLocation();
  const noErrors = Object.keys(errors).length === 0;
  const isRequiredStepValid = noErrors && requiredFields.every((x) => !!x);
  const { controls, onChange } = useWizard();
  const [showErrors, setShowErrors] = useState(false);

  /**
   * Normal next click
   */
  const handleNextClick = (e) => {
    e.preventDefault();
    setShowErrors(false);

    if (!isRequiredStepValid) {
      setShowErrors(true);
      return;
    }

    if (establishment === 'nursery') {
      logButtonEvent('name_dob_next');
      controls.goToScreen('pickNurseryRoom');
    } else if (establishment === 'activities') {
      logButtonEvent('child_activities_info_next');
      if (location.pathname.includes('/profile')) {
        controls.goToScreen('reviewChildren');
      } else {
        controls.goToScreen('optIn');
      }
    }
  };
  const onFullNameChange = (e) => {
    onChange('fullName', e.target.value);

    const action = establishment === 'nursery' ? 'full_name_added' : 'child_activities_name_add';
    logButtonEvent(action);
  };
  const onDOBChange = (e) => {
    onChange('birthDate', e.target.value);
    const action = establishment === 'nursery' ? 'DOB_added' : 'child_activities_DOB_add';
    logButtonEvent(action);
  };

  return (
    <DefaultAppPage
      title={title}
      className={styles.container}
      footerActions={[
        <Button onClick={handleNextClick} visuallyDisabled={!isRequiredStepValid} expand key="next">
          Next
        </Button>,
      ]}
      useHistoryBack
    >
      <main>
        <div className={styles.inputRow}>
          <Input
            label="What’s your child’s name?"
            placeholder="Full name"
            aria-label="Full name"
            name="childName"
            value={fullName}
            onChange={onFullNameChange}
          />
          <ErrorMessage
            isVisible={showErrors && 'fullName' in errors}
            className={styles.errorMessage}
          >
            You must enter a full name
          </ErrorMessage>
          <ErrorMessage
            isVisible={showErrors && 'isDuplicate' in errors}
            className={styles.errorMessage}
          >
            You’ve already added a child with that name
          </ErrorMessage>
        </div>

        <div className={styles.inputRow}>
          <Input
            label="When is your child’s birthday?"
            mask="99 | 99 | 9999"
            name="birthDate"
            maskPlaceholder="DD | MM | YYYY"
            placeholder="DD | MM | YYYY"
            aria-label="DD | MM | YYYY"
            value={birthDate}
            onChange={onDOBChange}
          />
          <ErrorMessage
            isVisible={showErrors && 'birthDate' in errors}
            className={styles.errorMessage}
          >
            This date is not valid
          </ErrorMessage>
        </div>
      </main>
    </DefaultAppPage>
  );
};

ChildInfoEntryScreen.defaultProps = {
  title: 'Add a child',
  fullName: '',
  birthDate: '',
  establishment: '',
};
