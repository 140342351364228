import { useState } from 'react';

import { Wizard, useInitialFormData } from 'app/wizard';

import { EnterPhone } from './screens/EnterPhone';
import { ResetPassword } from './screens/ResetPassword';
import { ResetPasswordConfirmation } from './screens/ResetPasswordConfirmation';
import { usePageViewTracker } from 'hooks';

export const ForgotPassword = () => {
  const initialFormData = useInitialFormData({
    mobileNumber: '',
    otpCode: '',
    hasValidOtpCode: false, // switch to true after user verifies OTP code
    newPassword: '',
  });

  usePageViewTracker('forgot_password');

  const [formData, setFormData] = useState(initialFormData);

  return (
    <Wizard formData={formData} onChange={setFormData}>
      <EnterPhone screenName="enterPhone" />
      <ResetPassword screenName="resetPassword" />
      <ResetPasswordConfirmation screenName="confirmation" />
    </Wizard>
  );
};
