import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';

import getAvailabilityForGuardianAtNonPrimary from 'graphql/queries/getAvailabilityForGuardianAtNonPrimary';

import {
  BookingSessionTypeEnum,
  ChildType,
  GetAvailabilityForGuardianAtNonPrimaryQuery,
  QueriesAvailabilityForGuardianAtNonPrimaryArgs,
  NurseryRoomWithAvailabilityAndPriceForDateType,
  Maybe,
} from 'graphql/generated-types';

interface IAvalabilityForNonPrimaryNurseryOptions {
  child: ChildType;
  date: Date;
  sessionType: BookingSessionTypeEnum;
  skip: boolean | undefined;
}

interface IAvalabilityForNonPrimaryNurseryReturnData {
  data: NurseryRoomWithAvailabilityAndPriceForDateType[];
}

const toAvailabilityForNonPrimaryNursery = (
  room: Maybe<NurseryRoomWithAvailabilityAndPriceForDateType> | undefined,
): NurseryRoomWithAvailabilityAndPriceForDateType => {
  return {
    id: room?.id,
    roomName: room?.roomName,
    nursery: room?.nursery,
    date: parseISO(room?.date),
    sessionType: room?.sessionType,
    sessionPrice: room?.sessionPrice,
    availableSessionCount: room?.availableSessionCount,
    customSessionType: room?.customSessionType,
  };
};

/**
 * Fetches extra availability for a given child, and converts the returned data to models
 */
export const useAvailabilityForNonPrimaryNursery = (
  options: IAvalabilityForNonPrimaryNurseryOptions,
): IAvalabilityForNonPrimaryNurseryReturnData => {
  const [availability, setAvailability] = useState<
    Array<NurseryRoomWithAvailabilityAndPriceForDateType>
  >([]);
  const { child, date, sessionType, skip } = options;

  const variables = {
    nurseryId: child?.primaryNursery?.id,
    childId: child.id,
    date,
    sessionType,
  };

  const { data, ...queryResult } = useQuery<
    GetAvailabilityForGuardianAtNonPrimaryQuery,
    QueriesAvailabilityForGuardianAtNonPrimaryArgs
  >(getAvailabilityForGuardianAtNonPrimary, {
    variables,
    skip,
  });

  useEffect(() => {
    if (!data?.availabilityForGuardianAtNonPrimary?.nurseryRooms) {
      setAvailability([]);
      return;
    }

    const rooms = data.availabilityForGuardianAtNonPrimary.nurseryRooms;

    setAvailability(rooms.map(toAvailabilityForNonPrimaryNursery));
  }, [data]);

  return { data: availability, ...queryResult };
};
