import { BackButton } from 'app/components';
import { useWindowHeight } from 'hooks';
import { CloseButton } from 'ui';

import styles from './BookingDetailsPage.module.scss';
import { SessionDetails } from '../SessionDetails/SessionDetails';

export const BookingDetailsPage = ({
  children,
  activity,
  date,
  dateFormat,
  footer,
  illustration,
  nursery,
  onBack,
  onClose,
  sessionType,
  showCancellationPolicy,
  title,
  useHistoryBack,
  customSessionDetails,
}) => {
  const { boxStyle } = useWindowHeight();
  const hasBackButton = typeof onBack === 'function' || useHistoryBack;

  return (
    <div className={styles.container} style={boxStyle}>
      <div className={styles.layoutTop}>
        <header className={styles.header}>
          <div className={styles.headerContent}>
            {illustration && <div className={styles.illustration}>{illustration}</div>}
            {title && <h1 className={styles.title}>{title}</h1>}

            {hasBackButton && (
              <BackButton
                className={styles.backButton}
                useHistoryBack={useHistoryBack}
                onClick={onBack}
              />
            )}

            {onClose && <CloseButton className={styles.closeButton} onClick={onClose} />}
          </div>
        </header>

        <div className={styles.sessionDetailsWrapper}>
          <SessionDetails
            activityName={activity}
            date={date}
            sessionType={sessionType}
            nurseryAddress={nursery}
            dateFormat={dateFormat}
            customSessionDetails={customSessionDetails}
            showSessionTimes={nursery?.showCustomSessionTimes}
          />
        </div>

        {children && <div className={styles.content}>{children}</div>}

        {showCancellationPolicy && (
          <section className={styles.cancellationPolicy}>
            <h2>Cancellation policy</h2>
            <p>
              If you cancel a confirmed booking you may not receive a refund. Please contact your
              provider for further information on their cancellation policy.
            </p>
          </section>
        )}
      </div>

      {footer && (
        <div className={styles.layoutFooter}>
          <div className={styles.footerWrapper}>{footer}</div>
        </div>
      )}
    </div>
  );
};

BookingDetailsPage.defaultProps = {
  footerActions: null,
  showCancellationPolicy: true,
  status: null,
  useHistoryBack: false,
};
