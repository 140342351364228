import classNames from 'classnames';

import styles from './Icon.module.scss';

export const Camera = ({ className, color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.4088 2.74416L26.4092 2.74446C26.8542 3.03662 27.1175 3.52577 27.1128 4.0458V4.0467L27.1126 14.2068C27.1126 14.6185 26.9446 15.014 26.6448 15.3057C26.3444 15.5979 25.937 15.7624 25.5113 15.7624C25.3006 15.7618 25.0921 15.722 24.8971 15.6452C24.897 15.6452 24.897 15.6452 24.8969 15.6451L19.8991 13.6462L19.762 13.5914V13.7391V16.2224C19.762 16.6067 19.605 16.9755 19.3249 17.248L19.3249 17.248C19.0447 17.5208 18.6638 17.6744 18.2667 17.6744H1.59531C1.19793 17.6744 0.817257 17.5208 0.536891 17.248C0.25682 16.9755 0.1 16.6067 0.1 16.2224V2.0116C0.104242 1.63024 0.262899 1.26558 0.542672 0.996498L0.54272 0.996452C0.822326 0.727173 1.20059 0.575586 1.59531 0.575586H18.2667C18.6638 0.575586 19.0447 0.729359 19.3249 1.00193C19.605 1.27443 19.762 1.6437 19.762 2.02758V4.51085V4.659L19.8994 4.6036L24.8972 2.58817C24.8973 2.58815 24.8973 2.58813 24.8974 2.5881C25.3963 2.38973 25.9641 2.44869 26.4088 2.74416ZM18.1601 16.2152H18.2601V16.1152V5.73568V2.11442V2.01442H18.1601H1.70159H1.60159V2.11442V16.1152V16.2152H1.70159H18.1601ZM25.4868 14.2917L25.6239 14.3465V14.1989V4.04722V3.89973L25.4869 3.95432L19.825 6.20997L19.762 6.23507V6.30287V11.9351V12.0028L19.8248 12.0279L25.4868 14.2917Z"
      fill="#0E206F"
      stroke="white"
      strokeWidth="0.2"
    />
  </svg>
);
