export * from './Accordion';
export * from './Button';
export * from './CardIcon';
export * from './Carousel';
export * from './Checkbox';
export * from './CloseButton';
export * from './CheckboxList';
export * from './Dialog';
export * from './DayPicker';
export * from './Dropdown';
export * from './ErrorHighlighter';
export * from './ErrorMessage';
export * from './FullScreen';
export * from './FullScreenModal';
export * from './Input';
export * from './Label';
export * from './Modal';
export * from './Pebbles';
export * from './RadioButton';
export * from './RadioButtonList';
export * from './SelectionList';
export * from './StickyNav';
export * from './Tag';
export * from './ThreeDots';
export * from './TelInput';
export * from './Textarea';
export * from './Emoji';
export * from './Notification';

/**
 * Used to suppress the outline on clickable elements when clicked, but not when tabbed to
 */
export const outlineSuppressionHandlers = {
  onMouseDown: (e) => {
    e.currentTarget.style.outline = 'none';
    e.currentTarget.style.boxShadow = 'none';
  },

  onBlur: (e) => {
    e.currentTarget.style.outline = null;
    e.currentTarget.style.boxShadow = null;
  },
};
