import { motion, AnimatePresence } from 'framer-motion';

import styles from './SelectionList.module.scss';

export const SelectionList = ({
  animateEntrance,
  className,
  getKey,
  options,
  renderOption,
  renderSelectable,
}) => (
  <div className={className}>
    <AnimatePresence initial={false}>
      {options.map((option) => (
        <motion.label
          className={styles.option}
          key={getKey(option)}
          variants={animateEntrance ? animationVariants : null}
          animate="blinkIn"
          initial="initial"
          aria-label={option.label}
        >
          <div className={option.disabled ? styles.disabledLabel : styles.label}>
            {renderOption(option)}
            {option.description && <p className={styles.description}>{option.description}</p>}
            {option.note && <p className={styles.note}>{option.note}</p>}
          </div>

          {renderSelectable(option)}
        </motion.label>
      ))}
    </AnimatePresence>
  </div>
);

SelectionList.defaultProps = {
  options: [],
  getKey: (e) => e.value,
  renderSelectable: () => <input type="checkbox" />,
  renderOption: (option) => option.label,
  animateEntrance: false,
};

const animationVariants = {
  blinkIn: {
    opacity: [1, 0, 1, 0, 1],
  },
  initial: {
    opacity: 0,
  },
};
