import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { BookingSessionTypeEnum, errorCodes } from 'graphql/constants';
import getPriceList from 'graphql/queries/getPriceList';
import { getChildPricingByDay } from 'utilities/date';

/**
 * Returns pricing data for a child for a given day
 *
 * @param {String} childId The ID of the child to look up
 * @param {Date} date The date to fetch the price for
 * @returns {Object}
 */
export const useChildPriceList = ({ childId, date, skip }) => {
  const [price, setPrice] = useState({
    [BookingSessionTypeEnum.AM]: 0,
    [BookingSessionTypeEnum.PM]: 0,
    [BookingSessionTypeEnum.FULL]: 0,
  });
  const [isOutOfRange, setIsOutOfRange] = useState(false);

  const {
    data: pricingData,
    error,
    loading,
  } = useQuery(getPriceList, {
    variables: { childId },
    skip,
  });

  useEffect(() => {
    const priceList = pricingData?.childPriceListById?.primaryNurseryPriceList ?? null;

    if (priceList === null) return;

    const prices = getChildPricingByDay(priceList, date);

    if (!prices) {
      setIsOutOfRange(true);
      return;
    }

    const { amPrice, pmPrice, fullDayPrice } = prices;

    setPrice({
      [BookingSessionTypeEnum.AM]: amPrice,
      [BookingSessionTypeEnum.PM]: pmPrice,
      [BookingSessionTypeEnum.FULL]: fullDayPrice,
    });
  }, [pricingData, date]);

  const priceListMissing = useMemo(() => {
    if (!error) return false;

    return error.graphQLErrors.some((e) => e.message === errorCodes.NURSERY_PRICE_LIST_MISSING);
  }, [error]);

  return {
    priceList: price,
    priceListMissing,
    loading,
    isOutOfRange,
  };
};
