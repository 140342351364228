import { parseISO } from 'date-fns';
import { Nursery } from 'models';
import BaseModel from './BaseModel';

export class AvailabilityForNonPrimaryNursery extends BaseModel {
  static defaultFields = {
    id: null,
    roomName: '',
    nursery: {},
    date: '',
    sessionType: '',
    sessionPrice: 0,
    availableSessionCount: 0,
  };

  constructor(data) {
    super(data);

    this.date = parseISO(data.date);
    this.nursery = Nursery(data.nursery);
  }
}
