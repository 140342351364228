export const FilledCalendar = ({
  className = '',
  color = 'currentColor',
  size = 24,
  stroke = null,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1602 2.83362V5.33362"
      stroke="#4378FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06641 2.83362V5.33362"
      stroke="#4378FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40299 3.91693H6.31405V5.3336C6.31405 6.30009 7.09755 7.0836 8.06405 7.0836C9.03055 7.0836 9.81405 6.30009 9.81405 5.3336V3.91693H13.4106V5.3336C13.4106 6.30009 14.1941 7.0836 15.1606 7.0836C16.1271 7.0836 16.9106 6.30009 16.9106 5.3336V3.91693H17.8216C19.2387 3.91693 20.3457 5.09694 20.3457 6.50026V19.3335C20.3457 20.7369 19.2387 21.9169 17.8216 21.9169H5.40299C3.98595 21.9169 2.87891 20.7369 2.87891 19.3335V6.50026C2.87891 5.09694 3.98595 3.91693 5.40299 3.91693ZM2.90173 11.5002H20.323V10.0002H2.90173V11.5002Z"
      fill="#4378FF"
    />
  </svg>
);
