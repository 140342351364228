import { FetchResult, MutationTuple, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { captureException, captureMessage, Scope } from '@sentry/react';
import qs from 'query-string';

import createGuardianMutation from 'graphql/mutations/createGuardian';
import { deleteState, updateState, FormState } from 'pages/Onboarding/state/updateFormState';
import { initializeGuardian } from 'graphql/cache';
import {
  ChildType,
  CreateGuardianMutation,
  CreateGuardianMutationVariables,
} from 'graphql/generated-types';
import { useQueryParams } from 'hooks';

const convertChildrenFromCreateGuardian = (children: ChildType[] | []) =>
  children.map((child) => {
    const [year, month, day] = child.birthDate.split('-');

    // default - nursery
    return {
      fullName: child.fullName,
      primaryNurseryRoomId: child?.primaryNurseryRoom?.id,
      roomName: child.primaryNurseryRoom?.name,
      nurseryName: child.primaryNursery?.name, // Only used with external nurseries .e.g Busy
      birthDate: `${day} | ${month} | ${year}`,
      establishment: 'nursery',
    };
  });

export const useCreateGuardian = (): MutationTuple<
  CreateGuardianMutation,
  CreateGuardianMutationVariables
> => {
  const history = useHistory();
  const { redirect, organisation, ...params } = useQueryParams();
  const { actions, state } = useStateMachine({ deleteState, updateState });

  const redirectIsAbsolute = redirect?.toString().startsWith('http');

  const onboardingCompleted = () => {
    const establishment = (state as FormState).establishment;

    actions.deleteState(undefined);

    if (redirectIsAbsolute) {
      window.location.href = `${redirect}?${qs.stringify(params)}`;
    }

    if (redirect) {
      history.push({
        pathname: redirect as string,
        search: qs.stringify(params),
      });
      return;
    }

    if (establishment === 'nursery') {
      history.push('/confirmation');
      return;
    }
    if (establishment === 'activities') {
      history.push('/all-done');
      return;
    }

    history.push('/');
  };

  const rehydrateFormData = (newData: CreateGuardianMutation) => {
    const children = convertChildrenFromCreateGuardian(
      (newData?.guardianCreate?.guardian?.children as ChildType[]) || [],
    );
    const updatedFormData = {
      guardianId: newData.guardianCreate?.guardian?.id,
      isExternallyManaged: true,
      establishment: 'nursery',
      temporaryChildData: children,
    };

    actions.updateState(updatedFormData);
  };

  const [createGuardian, { data, loading, error, ...rest }] = useMutation<
    CreateGuardianMutation,
    CreateGuardianMutationVariables
  >(createGuardianMutation, {
    onCompleted: (newData) => {
      if (newData?.guardianCreate?.guardian?.anyChildrenExternallyManaged) {
        rehydrateFormData(newData);
        history.push({ pathname: '/onboarding/review-children', search: history.location.search });
        return;
      }

      if (newData?.guardianCreate?.guardian) {
        return onboardingCompleted();
      }
    },
    onError: (err) => {
      captureException(error);
      console.log('[Onboarding] Create Guardian Error: ', err, state);
      captureMessage('[Onboarding] Create Guardian Error: ', err as unknown as Scope);
      //  API RATE LIMIT
      if (err?.graphQLErrors?.some((e) => e.message === 'EXTERNAL_SYSTEM_RATE_LIMIT_EXCEEDED')) {
        return;
      }
      // handle all type of External Errors (.e.g BusyBees)
      // `ERROR_UPDATING_GUARDIAN_WITH_EXTERNAL_DATA`
      if (
        err?.graphQLErrors?.some((e) => e.message === 'ERROR_UPDATING_GUARDIAN_WITH_EXTERNAL_DATA')
      ) {
        actions.updateState({ showExternalNurseryErrorDialog: true, logoutUser: false });
        return;
      }
      // `EXTERNAL_REGISTRATION_ERROR`
      if (err?.graphQLErrors?.some((e) => e.message === 'EXTERNAL_REGISTRATION_ERROR')) {
        // Show External Error overlay
        actions.updateState({ showExternalNurseryErrorDialog: true, logoutUser: true });
        return;
      }
      // `REFER_TO_NURSERY`: Call to external booking system returned a 404, either child or room not recognised.
      if (err?.graphQLErrors?.some((e) => e.message === 'REFER_TO_NURSERY')) {
        // Show External Referral overlay
        actions.updateState({ showExternalNurseryReferralDialog: true, logoutUser: true });
        return;
      }
      // ELSE
      actions.deleteState(undefined);
      history.push('/error', { from: '/onboarding' });
    },
    update: (cache, result: FetchResult<CreateGuardianMutation>) => {
      const guardian = result?.data?.guardianCreate?.guardian;
      if (guardian) {
        initializeGuardian(cache, guardian);
      }
      if (guardian?.anyChildrenExternallyManaged || organisation) {
        rehydrateFormData(result.data || {});
      }
    },
  });

  return [createGuardian, { data, loading, error, ...rest }];
};
