import classNames from 'classnames';
import { FC } from 'react';

import styles from './CardIcon.module.scss';

export type UCardType =
  | 'diners'
  | 'discover'
  | 'jcb'
  | 'unionpay'
  | 'unknown'
  | 'amex'
  | 'mc'
  | 'visa';

interface ICardIconProps {
  className?: string;
  cardType: UCardType;
}

export const CardIcon: FC<ICardIconProps> = ({ className, cardType }) => (
  <div
    className={classNames(styles.cardIcon, styles[cardType], className)}
    data-testid="card-icon"
  />
);
