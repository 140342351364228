import { FilledCalendar, IconLightningBolt, Present } from 'icons';
import { IllustratedInfoPage } from 'layouts';
import qs from 'query-string';
import illustration from '../../illustrations/PebbleIllustration_BikeRide.png';
import { Button } from '../../ui/Button';
import styles from './Welcome.module.scss';
import { useQueryParams } from 'hooks';

export const Welcome = () => {
  const parsedParams = useQueryParams();
  const queryParams = qs.stringify(parsedParams);
  return (
    <IllustratedInfoPage
      illustration={<img src={illustration} alt="Pebble illustration learning to ride a bike" />}
      welcomeTitle={'Create an Account'}
      useHistoryBack
    >
      <div className={styles.infoWrapper}>
        <p>
          <IconLightningBolt size={24} className={styles.lightningBolt} /> Fast checkout
        </p>

        <p>
          <Present size={24} />
          Receive special offers
        </p>

        <p>
          <FilledCalendar size={24} />
          Manage your bookings
        </p>
      </div>

      <Button className={styles.registerButton} href={`/register?${queryParams}`}>
        Register
      </Button>

      <p>Already have an account?</p>

      <Button variant="outline" className={styles.loginButton} href={`/login?${queryParams}`}>
        Log in
      </Button>
    </IllustratedInfoPage>
  );
};
