import { FC, ReactElement } from 'react';
import { Button, Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import styles from './PaymentExemptDialog.module.scss';

interface IPaymentExemptDialogProps {
  show: boolean;
  message: string | ReactElement;
  onClose: () => void;
}

export const PaymentExemptDialog: FC<IPaymentExemptDialogProps> = ({ show, message, onClose }) => {
  return (
    <>
      <Dialog
        isVisible={show}
        title={null}
        onClose={onClose}
        menu={
          <Button onClick={onClose} expand>
            OK
          </Button>
        }
        zIndex="6"
      >
        {message ? <div className={styles.message}>{message}</div> : <br />}
      </Dialog>
      <ModalBackdrop isVisible={show} onClick={onClose} />
    </>
  );
};
