import classNames from 'classnames';

import styles from './RadioButton.module.scss';

export const RadioButton = ({ className, ...props }) => (
  <div className={classNames(styles.container, className)}>
    <input {...props} type="checkbox" className={styles.input} />
    <div className={styles.label} />
  </div>
);
