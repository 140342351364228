import { useState } from 'react';
import { DefaultAppPage } from 'layouts';
import { ErrorMessage, Button, Input, TelInput, Checkbox, Modal } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import { logButtonEvent } from 'app/amplitude';

import styles from './YourDetails.module.scss';
import { StickyNav } from 'ui/StickyNav';

export const YourDetails = ({ register, setFormData, formData }) => {
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordConfirmationError, setShowPasswordConfirmationError] = useState(false);

  const { mobileNumber, password } = formData;
  const [showModal, setShowModal] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const isMobileStepComplete = /((\+44(\s|\s0\s|\s)?)|0)7\d{3}?\d{6}/.test(mobileNumber);
  const isPasswordStepComplete =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){0,})(?!.*\s).{8,}$/.test(password);
  const isPasswordMatch = password === confirmedPassword;

  const closeModal = () => {
    setModalContent(null);
    setShowModal(false);
  };

  const handleNextClick = (e) => {
    e.preventDefault();

    logButtonEvent('legals_continue');
    logButtonEvent('password_create_next');
    logButtonEvent('mobile_next');

    register();
  };

  return (
    <>
      <DefaultAppPage
        loginLink
        footerActions={[
          <StickyNav>
            <Button
              disabled={!isMobileStepComplete || !isPasswordStepComplete || !isPasswordMatch}
              onClick={handleNextClick}
              expand
              key="next"
            >
              Next
            </Button>
            <i
              style={{
                fontSize: '12px',
                marginTop: '10px',
              }}
            >
              By accessing the Pebble platform, you agree to the
              <a
                href="https://bookpebble.co.uk/terms/care-seeker"
                target="_blank"
                style={{
                  marginLeft: '3px',
                }}
              >
                Care Seeker Terms
              </a>
              . For further information about how Pebble processes your personal data, please refer
              to Pebble's
              <a
                href="https://www.bookpebble.co.uk/privacy-policy"
                target="_blank"
                style={{
                  marginLeft: '3px',
                }}
              >
                Privacy Policy
              </a>
              .
            </i>
          </StickyNav>,
        ]}
        useHistoryBack
      >
        <main className={styles.container}>
          <TelInput
            label="Enter your mobile number"
            placeholder="Mobile number"
            type="tel"
            value={mobileNumber}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                mobileNumber: e.target.value,
              }));
              setShowPhoneError(false);
            }}
            onBlur={(e) => {
              logButtonEvent('enter_mobile', { mobileNumber: e.target.value });
              setShowPhoneError(!isMobileStepComplete);
            }}
            aria-label="Mobile number"
          />
          <ErrorMessage isVisible={showPhoneError} className={styles.error}>
            Phone number must contain only numbers, and should be at least 10 digits long
          </ErrorMessage>
          <p>
            <strong>Your mobile number will be used to login.</strong> You will receive an SMS for
            verification. Message and data rates may apply.
          </p>

          <Input
            label="Create a password"
            placeholder="Enter password"
            type="password"
            value={password}
            onChange={(e) => {
              setFormData((prevState) => ({
                ...prevState,
                password: e.target.value,
              }));
              setShowPasswordError(false);
            }}
            onBlur={() => {
              logButtonEvent('password_create');
              setShowPasswordError(!isPasswordStepComplete);
            }}
          />
          <ErrorMessage isVisible={showPasswordError} className={styles.error}>
            Your password must contain at least 8 characters (upper and lower case) and include at
            least one number.
          </ErrorMessage>
          <p>Must contain 8 characters (upper and lower case) and include at least one number.</p>

          <Input
            label="Confirm password"
            placeholder="Passwords must match"
            type="password"
            value={confirmedPassword}
            onChange={(e) => {
              setConfirmedPassword(e.target.value);
              setShowPasswordConfirmationError(false);
            }}
            onBlur={() => {
              logButtonEvent('password_create_validation');
              setShowPasswordConfirmationError(!isPasswordMatch);
            }}
          />
          <ErrorMessage isVisible={showPasswordConfirmationError} className={styles.error}>
            Password doesn't match.
          </ErrorMessage>

          <ModalBackdrop isVisible={showModal} onClick={closeModal} />
          <Modal isVisible={showModal} onClose={closeModal}>
            {modalContent}
          </Modal>
        </main>
      </DefaultAppPage>
    </>
  );
};
