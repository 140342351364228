import { Route, Switch } from 'react-router-dom';

import { FourOhFour } from 'pages';

import { Checkout, Payment, Confirmation, Details } from './pages';
import { usePageViewTracker } from 'hooks';

export const MarketPlaceActivities = () => {
  usePageViewTracker('marketplace');
  return (
    <Switch>
      <Route path="/marketplace/details" component={Details} />
      <Route component={FourOhFour} />
    </Switch>
  );
};
