import { gql } from '@apollo/client';

const getMarketingPreferences = gql`
  query GetMarketingPreferences {
    guardian {
      id
      marketingOptIn
    }
  }
`;

export default getMarketingPreferences;
