/**
 * Converts an array of strings to a properly formatted English list.
 *
 * @example
 * // returns 'Leela, Fry, Bender and Nibbler'
 * sequencedDelimiterJoin(['Leela', 'Fry', 'Bender', 'Nibbler']);
 *
 * @param {Array} list The list of items to transform
 * @param {String} delimiter The delimiter to use for joining the first items
 * @param {String} finalDelimiter The delimiter to use for joining the final item to the list
 * @returns {String}
 */
export const sequencedDelimiterJoin = (list, delimiter = ', ', finalDelimiter = ' and ') => {
  const firstItems = list.slice(0, -1);
  const finalItem = list.slice(-1);

  return [firstItems.join(delimiter), finalItem].filter(Boolean).join(finalDelimiter);
};

/**
 * Converts an array into smaller chunks
 *
 * @example
 * // returns [[1, 2, 3], [4, 5, 6], [7, 8]]
 * chunk([1, 2, 3, 4, 5, 6, 7, 8], 3);
 *
 * @param {Array} array The list of items to transform
 * @param {Number} size The size of each chunk
 */
export const chunk = (array, size) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunkedArray[chunkedArray.length - 1];
    if (!last || last.length === size) {
      chunkedArray.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunkedArray;
};
