import { ChildType } from 'graphql/generated-types';
import { FC, MouseEventHandler } from 'react';

import { Button } from 'ui/Button';

interface IProfile {
  isRejected: boolean;
  childrenEligibleForBooking: ChildType[];
}

interface IBookingButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  profile: IProfile;
  className?: string;
  expand?: boolean;
}
export const BookingButton: FC<IBookingButtonProps> = ({
  onClick,
  profile,
  className,
  expand = true,
}) => {
  const disabled = profile.isRejected || profile.childrenEligibleForBooking.length === 0;

  return (
    <Button
      onClick={onClick}
      className={className}
      expand={expand}
      disabled={disabled}
      data-testid="home__book-extra-session"
    >
      Make a new booking
    </Button>
  );
};
