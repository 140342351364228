import { createContext } from 'react';

import { useProfileData } from 'app/profile';
import { Guardian } from 'models';
import { asyncNoOp } from 'utilities/functions';

export const ProfileContext = createContext({
  error: undefined,
  isInitializing: true,
  profile: Guardian(),
  refetch: asyncNoOp,
  requiresOnboarding: false,
});

export const ProfileProvider = ({ children }) => (
  <ProfileContext.Provider value={useProfileData()}>{children}</ProfileContext.Provider>
);
