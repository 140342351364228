import { IllustratedInfoPage } from 'layouts';
import { Button } from 'ui';
import { Link } from 'react-router-dom';
import illustration from './womanWithChild.png';
import styles from './OnboardingIntro.module.scss';
import { Fragment } from 'react';
import { usePageViewTracker } from 'hooks';

export const OnboardingIntroActivities = (props) => {
  const { history } = props;

  usePageViewTracker('onboarding_intro_activities');

  const handleNext = () => {
    history.push({ pathname: `/register`, search: history.location.search });
  };

  return (
    <IllustratedInfoPage
      illustration={
        <img src={illustration} className={styles.illustration} alt="Woman with child" />
      }
      footer={
        <Fragment>
          <Button expand onClick={handleNext} key="next">
            Register
          </Button>
          <div className={styles.center}>
            <Link to="/login">Login</Link>
          </div>
        </Fragment>
      }
    >
      <main className={styles.container}>
        <div className={styles.introText}>
          <h1 className={styles.title}>Instant book activities!</h1>

          <p>Register to access a world of kids activities here on Pebble!</p>
        </div>
      </main>
    </IllustratedInfoPage>
  );
};

OnboardingIntroActivities.buttonEvent = 'onboarding_intro';
