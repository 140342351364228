import { useWizard } from 'app/wizard';
import { DefaultAppPage } from 'layouts';
import { logButtonEvent } from 'app/amplitude';
import { ChildTile } from './components/ChildTile';
import styles from './ReviewChildrenScreen.module.scss';

export const ReviewChildrenScreen = ({
  footer,
  children,
  editScreenName,
  title,
  isExternallyManaged,
}) => {
  const { controls } = useWizard();
  const onEdit = () => {
    logButtonEvent('review_child_edit');
    controls.goToScreen(editScreenName);
  };
  return (
    <DefaultAppPage title={title} footer={footer} useHistoryBack>
      <main>
        <div className={styles.children}>
          {children.map((child, index) => (
            <ChildTile
              child={child}
              fullName={child.fullName}
              roomName={child.roomName}
              key={index}
              onEditClick={onEdit}
              isExternallyManaged={isExternallyManaged}
            />
          ))}
        </div>
        {isExternallyManaged && (
          <div className={styles.externallyManagedMessage}>
            If the information is incorrect, please contact Pebble for help
            <br />
            <a href="mailto:support@bookpebble.co.uk?subject=Child profile details incorrect">
              Contact us
            </a>
          </div>
        )}
      </main>
    </DefaultAppPage>
  );
};

ReviewChildrenScreen.defaultProps = {
  title: 'Review child details',
};
