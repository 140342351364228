import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconBaby = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0688 8.23333C13.8223 8.23333 15.2438 6.83795 15.2438 5.11667C15.2438 3.39538 13.8223 2 12.0688 2C10.3153 2 8.8938 3.39538 8.8938 5.11667C8.8938 6.83795 10.3153 8.23333 12.0688 8.23333Z"
      fill={color}
    />
    <path
      d="M18.9188 12.475L16.0688 9.59999C15.5438 9.07499 14.8188 8.77499 14.0688 8.77499H10.0938C9.3438 8.77499 8.6188 9.07499 8.0938 9.59999L5.1938 12.475C4.6688 13 4.6688 13.9 5.1938 14.4C5.7188 14.975 6.5938 14.95 7.1438 14.4L8.8938 12.65V20.625C8.8938 21.375 9.4938 22 10.2688 22C11.0188 22 11.6438 21.375 11.6438 20.625V17.625C11.6438 17.4 11.8438 17.2 12.0688 17.2C12.2938 17.2 12.4938 17.4 12.4938 17.625V20.625C12.4938 21.375 13.0938 22 13.8688 22C14.6188 22 15.2438 21.375 15.2438 20.625V12.675L16.9938 14.425C17.5188 14.95 18.3938 14.975 18.9438 14.425C19.4688 13.9 19.4688 13 18.9188 12.475Z"
      fill={color}
    />
  </svg>
);
