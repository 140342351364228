import {
  isBefore,
  isPast,
  isSameDay,
  isToday,
  isTomorrow,
  isWeekend,
  parse,
  parseJSON,
} from 'date-fns';

import {
  BookingRequestTypeEnum,
  BookingSessionTypeEnum,
  BookingStatusEnum,
} from 'graphql/constants';

const BOOKED_SESSION_TYPES = [BookingStatusEnum.PENDING, BookingStatusEnum.CONFIRMED];
const BOOKING_CUTOFF_MORNING = 7; // noon
const BOOKING_CUTOFF_AFTERNOON = 12; // 7am

export class CalendarDay {
  constructor({
    canInstantBook = false,
    dateStamp,
    availability = [],
    bookings = [],
    pricing = null,
    earliestInstantBookingSession,
    earliestBookingRequestSession,
  } = {}) {
    this.dateStamp = dateStamp;
    this.availability = availability;
    this.bookings = bookings;
    this.pricing = pricing;
    this.canInstantBook = canInstantBook;

    this.hasPricing = pricing !== null;

    // Generated properties
    this.date = parse(dateStamp, 'yyyy-MM-dd', new Date());
    this.meta = {
      isToday: isToday(this.date),
      isBeforeToday: isPast(this.date) && !isSameDay(this.date, new Date()),
      isTomorrow: isTomorrow(this.date),
      isWeekend: isWeekend(this.date),
    };

    this.earliestInstantBookingSession = earliestInstantBookingSession
      ? parseJSON(earliestInstantBookingSession)
      : new Date();
    this.earliestBookingRequestSession = earliestBookingRequestSession
      ? parseJSON(earliestBookingRequestSession)
      : new Date();
  }

  /**
   * Determines whether a sessionType is bookable
   *
   * @param {String} sessionType The booking sessionType (BookingSessionTypeEnum)
   * @returns Boolean
   */
  isSessionBookable(sessionType) {
    // Not bookable again if already booked
    if (this.isSessionAlreadyBooked(sessionType)) return false;
    // No sessions bookable is full day already booked
    if (this.isSessionAlreadyBooked(BookingSessionTypeEnum.FULL)) return false;
    // Can't book if past cutoff
    if (this.isSessionPastCutoff(sessionType)) return false;

    return true;
  }

  /**
   * Determines whether a sessionType is instantly bookable
   *
   * @param {String} sessionType The booking sessionType (BookingSessionTypeEnum)
   * @returns Boolean
   */
  isSessionInstantBookable(sessionType) {
    if (!this.canInstantBook) return false;

    if (this.isSessionPastCutoff(sessionType)) {
      return false;
    }

    switch (sessionType) {
      case BookingSessionTypeEnum.AM:
        return this.availability.amSessionsAvailable;
      case BookingSessionTypeEnum.PM:
        return this.availability.pmSessionsAvailable;
      case BookingSessionTypeEnum.FULL:
        return this.availability.amSessionsAvailable && this.availability.pmSessionsAvailable;
      default:
        return false;
    }
  }

  /**
   * Detects whether or not the passed session type has already been booked
   *
   * @param {String} sessionType The booking sessionType (BookingSessionTypeEnum)
   * @returns Boolean
   */
  isSessionAlreadyBooked(sessionType) {
    // For full day, return true if both AM and PM have already been booked separately
    if (sessionType === BookingSessionTypeEnum.FULL) {
      const isMorningAndAfternoonBooked =
        this.isSessionAlreadyBooked(BookingSessionTypeEnum.AM) &&
        this.isSessionAlreadyBooked(BookingSessionTypeEnum.PM);

      if (isMorningAndAfternoonBooked) return true;
    }

    return (
      this.bookings.some(
        (booking) =>
          (booking.sessionType === sessionType ||
            booking.sessionType === BookingSessionTypeEnum.FULL) &&
          BOOKED_SESSION_TYPES.includes(booking.status),
      ) ?? false
    );
  }

  /**
   * Detects whether or not the passed session type is already pending
   *
   * @param {String} sessionType The booking sessionType (BookingSessionTypeEnum)
   * @returns Boolean
   */
  isSessionPending(sessionType) {
    // For full day, return true if both AM and PM have already been booked separately
    if (sessionType === BookingSessionTypeEnum.FULL)
      return (
        this.isSessionPending(BookingSessionTypeEnum.AM) &&
        this.isSessionPending(BookingSessionTypeEnum.PM)
      );

    return this.bookings.some(
      (booking) =>
        booking.sessionType === sessionType && booking.status === BookingStatusEnum.PENDING,
    );
  }

  getCutOffTime(sessionType) {
    const availability =
      sessionType === BookingSessionTypeEnum.PM
        ? this.availability.pmSessionsAvailable
        : this.availability.amSessionsAvailable;

    const bookingType =
      this.canInstantBook && availability
        ? BookingRequestTypeEnum.INSTANT
        : BookingRequestTypeEnum.REQUEST;

    const cutoffTime =
      bookingType === BookingRequestTypeEnum.REQUEST
        ? this.earliestBookingRequestSession
        : this.earliestInstantBookingSession;

    return cutoffTime;
  }

  /**
   * Determine whether the session is past the cutoff time
   * @returns Boolean
   */
  isSessionPastCutoff(sessionType) {
    const sessionStartTime = new Date(this.date);
    sessionStartTime.setHours(
      sessionType === BookingSessionTypeEnum.PM ? BOOKING_CUTOFF_AFTERNOON : BOOKING_CUTOFF_MORNING,
    );

    const cutoffTime = this.getCutOffTime(sessionType);

    return isBefore(sessionStartTime, cutoffTime);
  }

  /**
   * Determine whether the session is on the same day as the cutoff time
   * @returns Boolean
   */
  isSessionOnSameDayOrAfterCutoff(sessionType) {
    const cutoffTime = this.getCutOffTime(sessionType);
    const isSame = isSameDay(new Date(this.date), cutoffTime);
    if (isSame) {
      return true;
    }
    return !this.isSessionPastCutoff(sessionType);
  }

  /**
   * Whether or not the day can be picked from UI
   *
   * If it's today, then check that the earliest bookable session is
   * today, if not then today isn't pickable
   */
  get isDayPickable() {
    if (this.meta.isBeforeToday || this.meta.isWeekend) {
      return false;
    }

    if (this.earliestBookingRequestSession)
      return (
        this.isSessionOnSameDayOrAfterCutoff(BookingSessionTypeEnum.AM) ||
        this.isSessionOnSameDayOrAfterCutoff(BookingSessionTypeEnum.PM)
      );
  }

  /**
   * Returns whether or not the current day is instant bookable
   *
   * @returns {Boolean}
   */
  get isDayInstantBookable() {
    return (
      this.canInstantBook &&
      (this.availability.amSessionsAvailable || this.availability.pmSessionsAvailable)
    );
  }

  /**
   * Returns an object with meta data for all sessions.
   */
  get sessions() {
    return [
      {
        label: 'Morning',
        type: BookingSessionTypeEnum.AM,
        isBookable: this.isSessionBookable(BookingSessionTypeEnum.AM),
        isInstantBookable: this.isSessionInstantBookable(BookingSessionTypeEnum.AM),
        price: this.pricing?.amPrice,
        isBooked: this.isSessionAlreadyBooked(BookingSessionTypeEnum.AM),
        isPending: this.isSessionPending(BookingSessionTypeEnum.AM),
      },
      {
        label: 'Afternoon',
        type: BookingSessionTypeEnum.PM,
        isBookable: this.isSessionBookable(BookingSessionTypeEnum.PM),
        isInstantBookable: this.isSessionInstantBookable(BookingSessionTypeEnum.PM),
        price: this.pricing?.pmPrice,
        isBooked: this.isSessionAlreadyBooked(BookingSessionTypeEnum.PM),
        isPending: this.isSessionPending(BookingSessionTypeEnum.PM),
      },
      {
        label: 'Full day',
        type: BookingSessionTypeEnum.FULL,
        isBookable: this.isSessionBookable(BookingSessionTypeEnum.FULL),
        isInstantBookable: this.isSessionInstantBookable(BookingSessionTypeEnum.FULL),
        price: this.pricing?.fullDayPrice,
        isBooked: this.isSessionAlreadyBooked(BookingSessionTypeEnum.FULL),
        isAltSessionBooked: this.isSessionAlreadyBooked(BookingSessionTypeEnum.AM)
          ? 'morning'
          : this.isSessionAlreadyBooked(BookingSessionTypeEnum.PM)
          ? 'afternoon'
          : false,
        isPending: this.isSessionPending(BookingSessionTypeEnum.FULL),
      },
    ];
  }
}
