import classNames from 'classnames';
import React, { forwardRef, InputHTMLAttributes, LegacyRef } from 'react';
import InputMask, { ReactInputMask } from 'react-input-mask';

import { ErrorHighlighter, Label } from 'ui';

import styles from './Input.module.scss';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isInvalid?: boolean;
  showAsValid?: boolean;
  className?: string;
  mask?: string;
  prefix?: string;
  suffix?: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'search' | 'tel';
  'data-testid'?: string;
}

export const Input = forwardRef((props: IInput, ref: React.Ref<HTMLInputElement>) => {
  const {
    className = '',
    isInvalid = false,
    label = '',
    mask = null,
    prefix = null,
    suffix = null,
    showAsValid = false,
    ...restOfProps
  } = props;

  const containerClass = classNames(
    styles.container,
    className,
    'fs-mask',
    mask && styles.hasInputMask,
  );

  const inputElement = mask ? (
    <InputMask
      ref={ref as LegacyRef<ReactInputMask>}
      {...restOfProps}
      mask={mask}
      className={styles.input}
    />
  ) : (
    <input ref={ref} {...restOfProps} className={styles.input} />
  );

  return (
    <Label className={containerClass}>
      {label && <div className={styles.label}>{label}</div>}
      <ErrorHighlighter isVisible={isInvalid} isValid={showAsValid}>
        <div className={styles.inputWrapper}>
          {prefix && <div className={styles.prefix}>{prefix}</div>}
          {inputElement}
          {suffix && <div className={styles.suffix}>{suffix}</div>}
        </div>
      </ErrorHighlighter>
    </Label>
  );
});
