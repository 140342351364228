import { FC, MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import { BackButton } from 'app/components';
import { useWindowHeight } from 'hooks';

import styles from './SettingsPage.module.scss';

interface ISettingsPageProps {
  className?: string;
  contentClass?: string;
  headerClass?: string;
  onBack?: (e: MouseEvent<HTMLButtonElement>) => void;
  title?: ReactNode;
  useHistoryBack?: boolean;
  useResponsiveCentered?: boolean;
}

export const SettingsPage: FC<ISettingsPageProps> = ({
  children,
  className,
  contentClass,
  headerClass,
  onBack,
  title,
  useHistoryBack = true,
  useResponsiveCentered = true,
}) => {
  const { boxStyle } = useWindowHeight();

  const hasBackButton = typeof onBack === 'function' || useHistoryBack;

  return (
    <main className={classNames(styles.container, className)} style={boxStyle}>
      <header className={classNames(styles.header, headerClass)}>
        <div className={classNames(styles.headerContent, styles.centered)}>
          {hasBackButton && (
            <BackButton
              useHistoryBack={useHistoryBack}
              onClick={onBack}
              className={styles.backButton}
            />
          )}

          <h1>{title}</h1>
        </div>
      </header>

      <div
        className={classNames(
          styles.content,
          useResponsiveCentered && styles.centered,
          contentClass,
        )}
        data-testid="settings__content"
      >
        {children}
      </div>
    </main>
  );
};
