import { FC, useState } from 'react';
import { useWindowHeight } from 'hooks';
import { logButtonEvent } from 'app/amplitude';
import styles from './ConfirmationPage.module.scss';
import { SessionDetails } from '../SessionDetails/SessionDetails';
import { PaymentStatus } from 'app/components/PaymentStatus';
import { PaymentInfoModal } from 'app/components/PaymentInfoModal';
import {
  CustomSessionTypeModelType,
  NurseryType,
  PaymentType,
  PromotionType,
  SessionBookingSessionType,
} from 'graphql/generated-types';
import { Maybe } from 'graphql/jsutils/Maybe';

type TPaymentProp = PaymentType & {
  statusLabel: string;
  formattedAmount: string;
};

type TPromoInfo = {
  finalAmount: Maybe<number> | undefined;
  originalAmount: Maybe<number> | undefined;
  discount: Maybe<number> | undefined;
  promotion: PromotionType;
};

type TNurseryAddress = Pick<NurseryType, 'addressLine1' | 'city' | 'postCode'>;

export interface IConfirmationPageProps {
  childsFirstName?: string;
  date: Date;
  footer: JSX.Element;
  illustration: JSX.Element;
  nursery: NurseryType;
  sessionType: SessionBookingSessionType;
  showCancellationPolicy: boolean;
  activityName?: string;
  isRequest: boolean;
  dateFormat: string;
  paymentInfo: TPaymentProp;
  promotionInfo?: TPromoInfo;
  isPaymentExempt: boolean;
  isActivity: boolean;
  customSessionDetails?: CustomSessionTypeModelType;
}

const getNurseryAddress = (nursery: NurseryType): TNurseryAddress => ({
  addressLine1: nursery?.addressLine1,
  city: nursery?.city,
  postCode: nursery?.postCode,
});

export const ConfirmationPage: FC<IConfirmationPageProps> = ({
  childsFirstName,
  date,
  footer,
  illustration,
  nursery,
  sessionType,
  showCancellationPolicy,
  activityName,
  isRequest,
  dateFormat = 'MMM do yyyy',
  paymentInfo,
  promotionInfo,
  isPaymentExempt,
  isActivity = false,
  customSessionDetails,
}) => {
  const { boxStyle } = useWindowHeight();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const actionPrefix = isActivity ? 'activity' : 'session';
  const headerCopy = isRequest ? 'We received your request' : 'Booking confirmed';
  const descriptionCopy = isRequest
    ? 'We will send you an update by SMS inside one business day.'
    : 'We will send you your booking confirmation via SMS shortly.';

  const onIconClick = () => {
    logButtonEvent(`${actionPrefix}_tool_tip_selection`);
    setShowInfoModal(!showInfoModal);
  };

  const showStatus = isActivity || nursery?.paymentsEnabled;

  return (
    <div className={styles.container} style={boxStyle}>
      <div className={styles.layoutTop}>
        <header className={styles.header}>
          <div className={styles.headerContent}>
            {illustration && <div className={styles.illustration}>{illustration}</div>}
          </div>
        </header>

        <div className={styles.confirmationDetailsWrapper}>
          <h1 className={styles.title}>{headerCopy}</h1>
          <p className={styles.description}>{descriptionCopy}</p>
          <div className={styles.confirmationDetails}>
            <SessionDetails
              activityName={activityName}
              date={date}
              sessionType={sessionType}
              nurseryAddress={getNurseryAddress(nursery)}
              dateFormat={dateFormat}
              customSessionDetails={customSessionDetails}
              showSessionTimes={nursery.showCustomSessionTimes}
            />
          </div>
        </div>

        {isRequest && (
          <section className={styles.section}>
            <h2>Booking request</h2>
            <p>
              We can't confirm your booking just yet. We'll do our best to accomodate your request
              and get back to you within the next business day.
            </p>
          </section>
        )}
        {showStatus && (
          <section className={styles.section}>
            <PaymentStatus
              payment={paymentInfo}
              onIconClick={onIconClick}
              promotion={promotionInfo}
              isPaymentExempt={isPaymentExempt}
              sessionLabel={childsFirstName ? `Extra session for ${childsFirstName}` : ''}
            />
          </section>
        )}

        {showCancellationPolicy && (
          <section className={styles.section}>
            <h2>Cancellation policy</h2>
            <p>
              If you cancel a confirmed booking you may not receive a refund. Please contact your
              provider for further information on their cancellation policy.
            </p>
          </section>
        )}
      </div>

      {footer && (
        <div className={styles.footerWrapper}>
          <div className={styles.layoutFooter}>{footer}</div>
        </div>
      )}
      <PaymentInfoModal
        isVisible={showInfoModal}
        isPaymentExempt={isPaymentExempt}
        onClose={() => setShowInfoModal(false)}
      />
    </div>
  );
};

ConfirmationPage.defaultProps = {
  showCancellationPolicy: true,
  isRequest: false,
};
