import { useMemo } from 'react';

import { useProfile } from 'app/profile';
import { useAvailabilityForNonPrimaryNursery, useChildPriceList } from 'graphql/hooks';
import { useNurseryCanAcceptPayments } from 'hooks';

/**
 * Fetches the price for a given session based on whether or not the nurseryId is the child's primary
 *
 * This hook either uses `useChildPriceList` if the child is within their primary nursery, or fetches
 * the price from `useAvailabilityForNonPrimaryNursery` otherwise.
 *
 * @returns Number
 */
export const useSessionPrice = ({
  childId,
  date,
  isPaymentExempt,
  nurseryId: nurseryIdFromArgs,
  sessionType,
}) => {
  const { profile } = useProfile();
  const child = profile.children.find((c) => c.id === childId);
  const nurseryId = nurseryIdFromArgs || child.primaryNursery.id;
  const isPrimaryNursery = child.primaryNursery.id === nurseryId;
  const canNurseryAcceptPayments = useNurseryCanAcceptPayments(nurseryId);
  const skipFetchingPriceList = !canNurseryAcceptPayments || isPaymentExempt || !isPrimaryNursery;

  const {
    priceList,
    priceListMissing,
    loading: priceListLoading,
  } = useChildPriceList({
    childId,
    date,
    skip: skipFetchingPriceList,
  });

  const { data: availability, loading: availabilityLoading } = useAvailabilityForNonPrimaryNursery({
    child,
    date,
    sessionType,
    skip: isPrimaryNursery,
  });

  const price = useMemo(() => {
    if (isPaymentExempt) return 0;

    if (isPrimaryNursery) {
      // Use the price list
      return priceList[sessionType];
    } else {
      // Find the original availability object by nursery ID
      const originalAvailability = availability.find((a) => a.nursery.id === nurseryId);

      return originalAvailability?.sessionPrice ?? 0;
    }
  }, [availability, isPaymentExempt, isPrimaryNursery, nurseryId, priceList, sessionType]);

  return {
    price,
    priceListMissing,
    loading: priceListLoading || availabilityLoading,
  };
};
