/* eslint jsx-a11y/anchor-is-valid: 0 */

import { usePageViewTracker } from 'hooks';
import styles from './CookiesPolicy.module.scss';

const showCookiePreferences = () => {
  window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
};
export const CookiesPolicy = () => {
  usePageViewTracker('cookies_policy');
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Cookies Policy</h1>

      <p>
        <span>Last updated: </span>
        <span>27/11/2020</span>
      </p>
      <p>
        <span>We use cookies on </span>
        <span>
          <a href="http://www.bookpebble.co.uk">www.bookpebble.co.uk</a>
        </span>
        <span>&nbsp;(our &ldquo;</span>
        <span>Site</span>
        <span>
          ). Cookies are small text files that uniquely identify your browser or device. The cookie
          file is stored on your browser on your computer or mobile device. When you return to that
          website (or visit websites that use the same cookies) these websites recognise the cookies
          and your browsing device. Cookies do many different jobs, like letting you navigate between
          pages efficiently, remembering your preferences, and generally improving the user experience
          or providing us information about how users navigate through our Site.
        </span>
      </p>
      <p>
        <span>
          When you visit our Site for the first time, we will provide you with a notice which lets you
          know how we use cookies, why we use them and how you can change which cookies you accept.
          You can opt out of cookies at any time by accessing our&nbsp;
          <a href="#" onClick={showCookiePreferences}>
            cookies preference center
          </a>
          . Please note that if you choose not to accept our cookies, we cannot guarantee that your
          experience on our Site will be as good as it could otherwise be.
        </span>
      </p>
      <ol start="1">
        <li>
          <span>What types of cookies do we use and how do we use them?</span>
        </li>
      </ol>
      <p>
        <span>
          The types of cookies used by us and our partners in connection with our Site are set out
          below.
        </span>
      </p>
      <table>
        <tbody>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Type of cookie</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>What it does</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Cookies necessary for essential Site purposes</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>These cookies are essential to provide you with </span>
                <span>our Site</span>
                <span>
                  &nbsp;and its features. Without these cookies, our Site would not function properly.
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Analytics Cookies</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>We use analytics cookies to analyse how </span>
                <span>our Site</span>
                <span>
                  &nbsp;is accessed, used or is performing in order to provide you with a better user
                  experience and to maintain, operate and continually improve{' '}
                </span>
                <span>our Site</span>
                <span>. </span>
              </p>
              <p>
                <span>
                  In particular, we use Google Analytics, which is a web analytics tool that helps us
                  understand how users engage with the Site. Like many services, Google Analytics uses
                  cookies to track user interactions, as in our case, where they are used to collect
                  information about how users use our site. This information is used to compile
                  reports and to help us improve our Site. The reports disclose website trends without
                  identifying individual visitors. For more information on Google Analytics, see{' '}
                </span>
                <span>
                  <a href="https://policies.google.com/technologies/partner-sites">here</a>
                </span>
                <span>
                  . &nbsp;You can opt out of Google Analytics without affecting how you visit our site
                  &ndash; for more information on opting out of being tracked by Google Analytics
                  across all websites you use, visit this Google page:{' '}
                </span>
                <span>
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                </span>
                <span>.</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Functionality Cookies</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>
                  Functionality cookies record information about choices you&#39;ve made and allow us
                  to tailor{' '}
                </span>
                <span>our Site</span>
                <span>
                  &nbsp;to you. These cookies mean that when you continue to use or come back to{' '}
                </span>
                <span>our Site</span>
                <span>
                  , we can provide you with our services as you have asked for them to be provided.
                </span>
              </p>
              <p>
                <span>
                  These cookies allow us to: (i) save your location preference if you have set your
                  location on your homepage; (ii) remember settings you have applied, such as layout,
                  text size, preferences, and colors; and (iii) store accessibility options.
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Advertising and Targeting Cookies</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>
                  We allow third party companies, including advertising companies, to place cookies on{' '}
                </span>
                <span>our Site</span>
                <span>. </span>
              </p>
              <p>
                <span>
                  These cookies enable such companies to track your activity across various sites
                  where they display ads and record your activities so they can show ads that they
                  consider relevant to you as you browse the Internet.
                </span>
              </p>
              <p>
                <span>
                  Cookies also allow us and third parties to know whether you have seen an ad or a
                  type of ad, and how long it has been since you&#39;ve last seen it. This information
                  is used for frequency capping purposes, to help tailor the ads you see, and to
                  measure the effectiveness of ads.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <ol start="2">
        <li>
          <span>How to change your cookies preferences </span>
        </li>
      </ol>
      <p>
        <span>
          We use cookies on this site to make sure you can use the site easily and enjoy the site.
          Some of the cookies are essential and we cannot provide our service without them, but there
          are others that are &nbsp;non-essential which you can choose to opt-in to or, if you have
          already opted-in, you can choose to turn it off.
        </span>
      </p>
      <p>
        <span>
          If you do not accept the use of any or all non-essential cookies, you can choose not to
          accept them,{' '}
        </span>
        <span>
          or if you have already enabled them, you may opt-out of them by accessing our&nbsp;
          <a href="#" onClick={showCookiePreferences}>
            cookies preference center
          </a>
          &nbsp;or you can opt out of all cookies by changing your browser settings so that cookies
          from the our Site cannot be placed on your computer or mobile device.
        </span>
      </p>
      <p>
        <span>
          You have the right to choose whether or not to accept these optional cookies and similar
          technologies but if you choose to refuse cookies, you may not be able to use the full
          functionality of &nbsp;our Site.
        </span>
        <span>&nbsp;We do not recommend turning all cookies off when using our Site as </span>
        <span>
          we cannot guarantee that your experience on our Site will be as good as it could otherwise
          be.
        </span>
      </p>
      <p>
        <span>
          You can also change your preferences by changing the settings in your browser. Most browsers
          will allow you to choose the level of privacy settings you want. &nbsp;This lets you control
          your cookie settings so that you can:
        </span>
      </p>
      <ul>
        <li>
          <span>
            see what cookies or other locally stored data you&rsquo;ve got and delete them on an
            individual basis;
          </span>
        </li>
        <li>
          <span>block third party cookies or similar technology;</span>
        </li>
        <li>
          <span>block cookies or similar technology from particular sites;</span>
        </li>
        <li>
          <span>block all cookies or similar technologies from being set; or</span>
        </li>
        <li>
          <span>delete all cookies or similar technologies when you close your browser.</span>
        </li>
      </ul>
      <p>
        <span>
          If you want to reduce your settings at any time (for example, if you accept all cookies, but
          later decide you do not want a certain type of cookie) you&rsquo;ll need to use your browser
          settings to remove any third party cookies dropped on your previous visit.
        </span>
      </p>
      <ol start="3">
        <li>
          <span>Changes to this policy</span>
        </li>
      </ol>
      <p>
        <span>
          We will occasionally update this policy to reflect changes in our practices and services.
          When we post changes to this policy, we will revise the &quot;Last Updated&quot; date at the
          top of this policy. If we make any material changes in the way we collect, use, and/or share
          information held in cookies, we will notify you by posting a notice of the changes on our
          Site. We recommend that you check this page from time to time to inform yourself of any
          changes in this policy or any of our other policies.
        </span>
      </p>
      <ol start="4">
        <li>
          <span>Contact us</span>
        </li>
      </ol>
      <p>
        <span>
          If you have any questions or comments about this policy, or privacy matters generally,
          please contact us via email&nbsp;
        </span>
        <span>
          <a href="mailto:support@bookpebble.co.uk">support@bookpebble.co.uk</a>
        </span>
        <span>.</span>
      </p>
    </div>
  )
}
