import { gql } from '@apollo/client';

import NURSERY_FIELDS_FRAGMENT from '../fragments/NurseryFieldsFragment';

const createGuardian = gql`
  ${NURSERY_FIELDS_FRAGMENT}

  mutation CreateGuardian(
    $fullName: String!
    $email: String!
    $children: [ChildrenInput]!
    $marketingOptIn: Boolean
    $organizationId: UUID
  ) {
    guardianCreate(
      fullName: $fullName
      email: $email
      children: $children
      marketingOptIn: $marketingOptIn
      organizationId: $organizationId
    ) {
      guardian {
        id
        fullName
        phoneNumber
        status
        email
        anyChildrenExternallyManaged
        externalSystemId
        canBookChildcare
        canBookMarketplaceActivities
        children {
          isRegisteredWithNursery
          isExternallyManaged
          id
          fullName
          status
          birthDate
          primaryNursery {
            ...NurseryFields
            isVisible
            isExternallyManaged
          }
          primaryNurseryRoom {
            id
            name
          }
        }
      }
      success
      errors {
        message
        code
      }
    }
  }
`;

export default createGuardian;
