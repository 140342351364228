import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { useWizard } from 'app/wizard';
import getNurseries from 'graphql/queries/getNurseries';
import { DefaultAppPage } from 'layouts';
import { Button, Pebbles, RadioButtonList } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import styles from './PickNurseryRoomScreen.module.scss';

export const PickNurseryRoomScreen = ({
  fullName,
  nurseryId,
  nurseryRoomsLoading,
  organizationId,
  primaryNurseryRoomId,
  onNextClick,
}) => {
  const { controls, onChange } = useWizard();

  const { data: nurseryData, loading } = useQuery(getNurseries, {
    variables: {
      organizationId,
    },
  });

  const nurseryRooms = useMemo(() => {
    if (!nurseryData || !nurseryData.nurseries) return;
    const nursery = nurseryData.nurseries.find((n) => n.id === nurseryId);

    return nursery?.nurseryRooms ?? [];
  }, [nurseryData, nurseryId]);

  const childFirstName = fullName.split(' ')[0];
  const roomOptions = useMemo(
    () => (nurseryRooms?.length > 0 ? mapRoomsToOptions(nurseryRooms) : []),
    [nurseryRooms],
  );

  const handleNextClick = () => {
    if (typeof onNextClick === 'function') onNextClick();
    logButtonEvent('room_selected_next');
    controls.goToNextScreen();
  };

  return (
    <DefaultAppPage
      title={`Which room is ${childFirstName} in?`}
      isLoading={nurseryRoomsLoading}
      footerActions={
        <Button onClick={handleNextClick} disabled={primaryNurseryRoomId === null} expand>
          Next
        </Button>
      }
      useHistoryBack
    >
      <main className={styles.container}>
        {loading && <Pebbles className={styles.loadingIndicator} fillSpace />}

        <RadioButtonList
          options={roomOptions}
          onChange={(id) => {
            const room = nurseryRooms.find((r) => r.id === id);
            const roomName = room?.name ?? `I’m not sure`;
            logButtonEvent('room_selected');
            onChange({ primaryNurseryRoomId: id, roomName });
          }}
          value={primaryNurseryRoomId}
          className={styles.options}
        />
      </main>
    </DefaultAppPage>
  );
};

/**
 * Converts a list of rooms from the server into the format required by `RadioButtonList`
 *
 * @param {Array} roomList The list of rooms to convert
 */
const mapRoomsToOptions = (roomList) => [
  ...roomList.map((room) => ({
    label: room.name,
    value: room.id,
  })),
  {
    value: '',
    label: "I'm not sure",
  },
];
