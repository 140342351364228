import { FC } from 'react';

import { BackButton } from 'app/components';
import { useWindowHeight } from 'hooks';
import { CloseButton } from 'ui';

import styles from './MarketplaceBookingDetails.module.scss';
import { MarketplaceSessionDetails } from '../MarketplaceSessionDetails';

type Address = {
  addressLine1?: string | null;
  city?: string | null;
  postCode?: string | null;
};

interface IMarketplaceBookingDetails {
  childName: string;
  activityName: string;
  dateString: string;
  footer: JSX.Element;
  illustration: JSX.Element;
  onBack?(): void;
  onClose(e: React.MouseEvent<HTMLElement>): Promise<void>;
  sessionTimeString: string;
  showCancellationPolicy: boolean;
  title: JSX.Element;
  useHistoryBack?: boolean;
  address?: Address | null;
  addOns?: string;
  children: React.ReactChild[];
  ticketName?: string | null;
  isSubscription?: boolean;
  subsOffDates?: string[];
  sessionWeekday?: string;
  isOnline?: boolean;
}

export const MarketplaceBookingDetails: FC<IMarketplaceBookingDetails> = ({
  children,
  activityName,
  dateString,
  footer,
  illustration,
  onBack,
  onClose,
  sessionTimeString,
  showCancellationPolicy,
  title,
  useHistoryBack,
  address,
  addOns,
  ticketName,
  isSubscription,
  subsOffDates,
  sessionWeekday,
  isOnline,
}) => {
  const { boxStyle } = useWindowHeight();
  const hasBackButton = typeof onBack === 'function' || useHistoryBack;

  return (
    <div className={styles.container} style={boxStyle}>
      <div className={styles.layoutTop}>
        <header className={styles.header}>
          <div className={styles.headerContent}>
            {illustration && <div className={styles.illustration}>{illustration}</div>}
            {title && <h1 className={styles.title}>{title}</h1>}

            {hasBackButton && (
              <BackButton
                className={styles.backButton}
                useHistoryBack={useHistoryBack}
                onClick={onBack}
              />
            )}

            {onClose && <CloseButton className={styles.closeButton} onClick={onClose} />}
          </div>
        </header>

        <div className={styles.sessionDetailsWrapper}>
          <MarketplaceSessionDetails
            activityName={activityName}
            dateString={dateString}
            address={address}
            addOns={addOns}
            sessionTimeString={sessionTimeString}
            ticketName={ticketName}
            isSubscription={isSubscription}
            subsOffDates={subsOffDates}
            sessionWeekday={sessionWeekday}
            isOnline={isOnline}
          />
        </div>

        {children && <div className={styles.content}>{children}</div>}

        {showCancellationPolicy && (
          <section className={styles.cancellationPolicy}>
            <h2>Cancellation policy</h2>
            {isSubscription ? (
              <p>
                If you do not wish to continue, then you will need to contact the provider to
                request cancellation. Cancel via provider anytime (subject to provider T&C’s).
              </p>
            ) : (
              <p>
                If you cancel a confirmed booking you may not receive a refund. Please contact your
                provider for further information on their cancellation policy.
              </p>
            )}
          </section>
        )}
      </div>

      {footer && (
        <div className={styles.layoutFooter}>
          <div className={styles.footerWrapper}>{footer}</div>
        </div>
      )}
    </div>
  );
};
