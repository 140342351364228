import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconChevronDown = ({
  className = null,
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 12 19"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.33301 2L7.33301 7.33333L12.333 2"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
