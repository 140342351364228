import { gql } from '@apollo/client';

const initiatePaidSessionBookingMutation = gql`
  mutation InitiatePaidSessionBookingMutation(
    $child: UUID!
    $date: Date!
    $promotion: UUID
    $room: UUID
    $customSessionType: UUID!
    $bookingToCancel: UUID
    $forceRequest: Boolean
  ) {
    initiatePaidSessionBooking(
      child: $child
      date: $date
      promotion: $promotion
      room: $room
      customSessionType: $customSessionType
      bookingToCancel: $bookingToCancel
      forceRequest: $forceRequest
    ) {
      sessionBookingStripeCheckout {
        url
        checkoutSessionId
      }
    }
  }
`;

export default initiatePaidSessionBookingMutation;
