import { useWizard } from 'app/wizard';
import { DefaultAppPage } from 'layouts';
import { Button, Label, RadioButtonList } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import styles from './ChildEstablishmentInfoScreen.module.scss';
import { StickyNav } from 'ui/StickyNav';

export const ChildEstablishmentInfoScreen = ({
  establishment,
  title,
  establishmentValue,
  setEstablishmentValue,
}) => {
  const { controls, onChange } = useWizard();

  const handleNextClick = (e) => {
    e.preventDefault();
    const actionPrefix = establishment === 'nursery' ? 'nursery' : 'activities';
    logButtonEvent(`add_child_${actionPrefix}_next_selected`);
    controls.goToScreen(establishment === 'nursery' ? 'pickOrganization' : 'childInfoEntry');
  };
  const onEstablishmentChange = (e) => {
    onChange('establishment', e);
    setEstablishmentValue(e);
    const actionPrefix = e === 'nursery' ? 'nursery' : 'activities';
    logButtonEvent(`add_child_${actionPrefix}_selected`);
  };

  return (
    <DefaultAppPage
      title={title}
      className={styles.container}
      footerActions={[
        <StickyNav key="next">
          <Button onClick={handleNextClick} disabled={!establishmentValue} expand key="next">
            Next
          </Button>
        </StickyNav>,
      ]}
      useHistoryBack
    >
      <main>
        <div className={styles.inputRow}>
          <Label className={styles.dropdownLabel}>What would you like to do?</Label>
          <RadioButtonList
            className={styles.radioButtons}
            options={[
              {
                value: 'activities',
                label: 'Book activities only',
              },
              {
                value: 'nursery',
                label: 'Check if my childcare provider is listed (book childcare & activities)',
                description:
                  "If your nursery, after school club or breakfast club is listed then you'll be able to register to book extra sessions.",
              },
            ]}
            value={establishmentValue}
            onChange={onEstablishmentChange}
          />
        </div>
      </main>
    </DefaultAppPage>
  );
};

ChildEstablishmentInfoScreen.defaultProps = {
  fullName: '',
  birthDate: '',
  title: 'Add a child',
};
