import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { BookingRequestTypeEnum } from 'graphql/constants';
import { ConfirmationPage } from 'layouts';
import { Button, Checkbox, FullScreen, Pebbles } from 'ui';
import { Child, Payment, RequestPayment } from 'models';
import { QUERY_PARAM_CHECKMARK } from 'utilities/string';
import getMarketingPreferences from 'graphql/queries/getMarketingPreferences';
import getBookingsByIds from 'graphql/queries/getBookingsByIds';
import getCheckoutSession from 'graphql/queries/getCheckoutSession';
import marketingOptInMutation from 'graphql/mutations/updateMarketingOptIn';
import { usePageViewTracker, useQueryParams } from 'hooks';
import { logButtonEvent } from 'app/amplitude';

import postItBooked from 'illustrations/postItBooked.png';
import postItRequested from 'illustrations/postItRequested.png';
import styles from './BookingConfirmation.module.scss';
import { useNurseryData } from 'graphql/hooks/useNurseryData';
import { PaymentStatus } from 'graphql/generated-types';

const getPromotionInfo = ({ finalAmount, originalAmount, discount, promotion }) => {
  return {
    finalAmount,
    originalAmount,
    discount,
    promotion,
  };
};

// Shape Stripe's metadata to our usual "Booking".
const metadataToBooking = (metadata, nursery, isInstantBook) => {
  return {
    ...metadata,
    nursery: nursery,
    child: {
      firstName: metadata.childFirstName || '',
    },
    customSessionType: {
      name: metadata.sessionName || '',
      startTime: metadata.sessionStartTime || '',
      endTime: metadata.sessionEndTime || '',
    },
    payment: {
      ...(isInstantBook
        ? {
            amount: Number(metadata.finalAmount) / 100,
            status: PaymentStatus.Succeeded,
            ...(metadata.promotionId && {
              originalAmount: Number(metadata.originalAmount) / 100,
              discount: metadata.discount ? Number(metadata.discount) / 100 : null,
              promotion: {
                code: metadata.promotionCode,
              },
            }),
          }
        : {
            finalAmount: Number(metadata.finalAmount) / 100,
            ...(metadata.promotionId && {
              originalAmount: Number(metadata.originalAmount) / 100,
              discount: metadata.discount ? Number(metadata.discount) / 100 : null,
              promotion: {
                code: metadata.promotionCode,
              },
            }),
            status: 'PENDING',
          }),
    },
    date: metadata.bookingDate,
  };
};

export const BookingConfirmation = () => {
  const history = useHistory();
  usePageViewTracker('booking/confirmation');
  const { bookingId, type, isPaymentExempt: exempt, sessionId } = useQueryParams();
  const [notifyMe, setNotifyMe] = useState(false);
  const [booking, setBooking] = useState(null);
  const isInstantBook = type === BookingRequestTypeEnum.INSTANT;
  let isPaymentExempt = exempt === QUERY_PARAM_CHECKMARK;
  const [saveMarketingPreferences, { loading: isSaving }] = useMutation(marketingOptInMutation);
  const { data: marketingPreferencesData, loading: loadingMarketingPreferences } =
    useQuery(getMarketingPreferences);

  const { loading: loadingSessionById } = useQuery(getCheckoutSession, {
    skip: !sessionId,
    variables: {
      checkoutSessionId: sessionId,
    },
    onCompleted: (data) => {
      if (data.getCheckoutSessionMetadata?.metadata) {
        const parsed = JSON.parse(data.getCheckoutSessionMetadata.metadata);
        setBooking(parsed);
      }
    },
  });
  const { loading: loadingBookingsByIds } = useQuery(getBookingsByIds, {
    skip: !bookingId,
    variables: {
      bookingIds: [bookingId],
    },
    onCompleted: (data) => {
      if (data.bookingsByIds) {
        setBooking(data.bookingsByIds[0]);
      }
    },
  });

  const nursery = useNurseryData(booking?.nurseryId);

  const bookingData = useMemo(() => {
    if (booking && bookingId) {
      return booking;
    }

    if (booking && sessionId && nursery) {
      return metadataToBooking(booking, nursery, isInstantBook);
    }

    return null;
  }, [booking, bookingId, sessionId, nursery, isInstantBook]);

  const handleDoneClick = async () => {
    logButtonEvent('session_confirmation_done_selection');
    if (marketingPreferencesData?.guardian?.marketingOptIn) {
      return history.push('/home');
    }
    try {
      await saveMarketingPreferences({
        variables: {
          guardianId: marketingPreferencesData.guardian.id,
          marketingOptIn: notifyMe,
        },
      });
    } catch (error) {
      // Agreed with PO to silently fail
      console.log(`error`, error);
    }
    history.push('/home');
  };

  if (loadingMarketingPreferences || loadingBookingsByIds || !bookingData || loadingSessionById) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  const imageProps = isInstantBook
    ? {
        src: postItBooked,
        alt: 'Post-it with the word booked written',
      }
    : {
        src: postItRequested,
        alt: 'Post-it with the word requested written',
      };

  const payment = !isInstantBook
    ? new RequestPayment(bookingData?.payment)
    : new Payment(bookingData?.payment);
  const child = bookingData ? Child(bookingData.child) : Child(null);
  const childsFirstName = child.firstName;

  return (
    <ConfirmationPage
      illustration={<img {...imageProps} />}
      date={new Date(bookingData.date)}
      sessionType={bookingData.sessionType}
      nursery={bookingData.nursery}
      childsFirstName={childsFirstName}
      isRequest={!isInstantBook}
      dateFormat="EEE, MMM do"
      paymentInfo={payment}
      promotionInfo={getPromotionInfo(bookingData?.payment || {})}
      isPaymentExempt={isPaymentExempt}
      isActivity={false}
      customSessionDetails={bookingData.customSessionType}
      footer={
        <>
          {!marketingPreferencesData?.guardian?.marketingOptIn && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                name="marketingOptIn"
                id="marketingOptIn"
                className={styles.checkbox}
                onChange={(e) => setNotifyMe(e.target.checked)}
                checked={notifyMe}
              />
              <label className={styles.agreementText} htmlFor="marketingOptIn">
                Notify me about special offers and updates
              </label>
            </div>
          )}
          <Button
            expand
            color="green"
            onClick={handleDoneClick}
            isWorking={isSaving}
            disabled={isSaving}
            data-testid="booking-confirmation__done-button"
          >
            Done
          </Button>
        </>
      }
    />
  );
};
