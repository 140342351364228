import { FC } from 'react';
import { Button, Dialog } from 'ui';
import { ModalBackdrop } from 'ui/ModalBackdrop';

import styles from './ExternalNurseryReferralDialog.module.scss';

interface IExternalNurseryReferralDialogProps {
  show: boolean;
  onGoBack: () => void;
}

export const ExternalNurseryReferralDialog: FC<IExternalNurseryReferralDialogProps> = ({
  show,
  onGoBack,
}) => {
  return (
    <>
      <Dialog
        isVisible={show}
        title="No details found"
        onClose={undefined}
        menu={
          <>
            <Button onClick={onGoBack} expand>
              Go back
            </Button>
          </>
        }
        zIndex="6"
      >
        <div className={styles.message}>
          Your name/email are not associated with a primary Busy Bees nursery account. Please ensure
          your details exactly match those found on your invoices. For further help please contact{' '}
          <a href="mailto:appsupport@busybees.com">appsupport@busybees.com</a>.
        </div>
      </Dialog>
      <ModalBackdrop isVisible={show} onClick={() => null} opacity={0.7} />
    </>
  );
};
