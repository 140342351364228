import { FC, ReactNode } from 'react';

import { ReactComponent as NoBookingsIcon } from './noBookings.svg';
import styles from './NoBookings.module.scss';

interface INoBookingsProps {
  message: ReactNode;
}

export const NoBookings: FC<INoBookingsProps> = ({ message }) => (
  <div className={styles.noBookings}>
    <div className={styles.message}>{message}</div>
    <NoBookingsIcon />
  </div>
);
