import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { MissingChildInfoDialog } from 'app/components';
import { useProfile } from 'app/profile';
import { usePageViewTracker, useWindowHeight } from 'hooks';
import { IconHelpCircle, IconUser } from 'icons';
import getAllBookings from 'graphql/queries/getAllBookings';
import { IllustratedInfoPage } from 'layouts/IllustratedInfoPage';
import { Emoji, Pebbles } from 'ui';
import { logButtonEvent } from 'app/amplitude';
import { getFlagValue } from 'app/featureFlags';

import { SessionList } from './components/SessionList';
import { BookingButton } from './components/BookingButton';
import { NoSessionMessage } from './components/NoSessionMessage';
import { Filters } from './components/Filters';
import illustration from '../../illustrations/PebbleIllustration_BikeRide.png';
import backInFive from './images/backInFive.png';
import styles from './Home.module.scss';
import { Error } from 'pages';
import { FullScreen } from 'ui/FullScreen';
import { StickyNav } from 'ui/StickyNav';

/**
 * Authenticated User Home Page
 */
export const Home = () => {
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [filter, setFilter] = useState('ALL');
  const history = useHistory();
  const { boxStyle } = useWindowHeight();
  const activitiesEnabled = getFlagValue('feature_flag_activities', true);

  const [showBirthDateError, setShowBirthDateError] = useState(false);
  const { profile, error: profileError } = useProfile();

  usePageViewTracker('home');

  // Check if there are any bookings
  const startDate = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);
  const sessions = useQuery(getAllBookings, {
    variables: {
      startDate: startDate,
      bookingFilter: 'ALL',
    },
  });

  const typesOfBooking = useMemo(() => {
    if (sessions?.data?.allBookingsForGuardian?.length) {
      return sessions?.data?.allBookingsForGuardian.reduce((types, booking) => {
        if (types.includes(booking.bookingType)) {
          return types;
        }
        return [...types, booking.bookingType];
      }, []);
    }

    return [];
  }, [sessions]);

  // Lazy query for filtering
  const [getBookings, { loading, error }] = useLazyQuery(getAllBookings, {
    onCompleted: (data) => {
      if (data.allBookingsForGuardian) {
        setFilteredSessions(data.allBookingsForGuardian);
      }
    },
  });

  // Initial load, fetch all bookings to display
  useEffect(() => {
    getBookings({
      variables: {
        startDate,
        bookingFilter: 'ALL',
      },
    });
  }, []);

  useEffect(() => {
    logButtonEvent('filter_selected');
    getBookings({
      variables: {
        startDate,
        bookingFilter: filter,
      },
    });
  }, [filter]);

  // Detect if the user has at least one approved child
  const hasNoBookableChildren = profile.childrenEligibleForBooking.length === 0;
  const hasMultipleChildren = profile.children.length > 1;

  /**
   * Handles what happens whent he user clicks the Request session button
   *
   * @param {MouseEvent} e The click event from the button
   */
  const handleRequestSessionClick = (e) => {
    e.preventDefault();

    if (hasNoBookableChildren) return;

    if (profile.children.length === 1 && !profile.children[0].hasBirthDate) {
      setShowBirthDateError(true);
      return;
    }

    logButtonEvent('make_a_booking_selected');

    if (!profile.canBookChildcare && profile.canBookMarketplaceActivities) {
      window.location.href = `https://activities.bookpebble.co.uk/`;
    }

    if (profile.canBookChildcare && profile.canBookMarketplaceActivities) {
      return history.push(`/session-or-marketplace`);
    }
  };

  // if network errors
  if (sessions?.error?.networkError || error?.networkError || profileError?.networkError) {
    return <Error title="400" messageText="Something went wrong" />;
  }

  if (loading) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }
  // if no session
  if (!loading && typesOfBooking.length === 0) {
    return (
      <IllustratedInfoPage
        illustration={<img src={illustration} alt="Pebble illustration learning to ride a bike" />}
        title={"You're all set!"}
        footer={
          <StickyNav>
            <BookingButton onClick={handleRequestSessionClick} profile={profile} />
          </StickyNav>
        }
        headerLink={
          <NavLink
            to="/profile"
            onClick={(e) => logButtonEvent('click_profile_icon')}
            className={styles.profileLink}
            aria-label="View my profile"
          >
            <IconUser size={14} />
          </NavLink>
        }
      >
        <NoSessionMessage />
        {profile.hasChildren && (
          <MissingChildInfoDialog
            childId={profile.children[0].id}
            onClose={() => setShowBirthDateError(false)}
            isVisible={showBirthDateError}
          />
        )}
      </IllustratedInfoPage>
    );
  }

  if (hasNoBookableChildren) {
    return (
      <IllustratedInfoPage
        illustration={
          <img
            src={backInFive}
            alt="Sign with `Back Soon` written on it"
            className={styles.backInFive}
          />
        }
        illustrationClass={styles.backSoonImage}
        title="Setting up your account…"
        footer={
          <StickyNav>
            <BookingButton onClick={handleRequestSessionClick} profile={profile} />
          </StickyNav>
        }
        headerLink={
          <NavLink to="/support" className={styles.supportLink}>
            Help
            <IconHelpCircle size={24} className={styles.supportIcon} />
          </NavLink>
        }
      >
        <div className={styles.emptyStateMessage}>
          <strong>Your account is not available yet on Pebble…</strong>
          <br />
          Please use the Help function above.
        </div>
      </IllustratedInfoPage>
    );
  }

  return (
    <div className={styles.container} style={boxStyle}>
      <div className={styles.pageContent}>
        <div className={styles.headerWrapper}>
          {profile.isRejected && (
            <aside className={styles.bannerMessages}>
              <div className={styles.blockedStatusBanner}>
                Your nursery has suspended all bookings. Please contact them for further info.
              </div>
            </aside>
          )}

          <header className={styles.header}>
            <div className={styles.introText}>
              <strong>
                Welcome back, {profile.firstName} <Emoji symbol="👋" label="Waving hand" />
              </strong>
            </div>

            <NavLink to="/profile" className={styles.profileLink}>
              <IconUser size={14} />
            </NavLink>
          </header>
        </div>

        <main className={styles.bookings}>
          {activitiesEnabled && typesOfBooking.length > 1 && (
            <Filters bookingTypes={typesOfBooking} filter={filter} setFilter={setFilter} />
          )}

          <SessionList
            data={filteredSessions}
            error={error}
            loading={loading}
            showChildNames={hasMultipleChildren}
          />
        </main>
      </div>

      <footer className={styles.footerActions}>
        <BookingButton onClick={handleRequestSessionClick} profile={profile} />
      </footer>

      {profile.hasChildren && (
        <MissingChildInfoDialog
          childId={profile.children[0].id}
          onClose={() => setShowBirthDateError(false)}
          isVisible={showBirthDateError}
        />
      )}
    </div>
  );
};
