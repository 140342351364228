import { usePageViewTracker } from 'hooks';
import { Route, Switch } from 'react-router-dom';

import * as pages from './pages';

export const CancelSession = () => {
  usePageViewTracker('cancel-session');
  return (
    <Switch>
      <Route exact path="/cancel-session/select-session" component={pages.SessionSelection} />
      <Route exact path="/cancel-session/:id" component={pages.CancellationWizard} />
    </Switch>
  )
};
