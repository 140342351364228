import { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useWindowHeight } from 'hooks';

const animationVariants = {
  show: { opacity: 1, scale: 1 },
  hide: { opacity: 0, scale: 0 },
};

export const AnimateExpandFromPoint = ({ children, className, coords, initial, isVisible }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const { boxStyle } = useWindowHeight();
  const modalStyle = useMemo(
    () => ({
      ...boxStyle, // contains `height`
      ...coords, // contains `originX` and `originY` (framer-motion properties)
    }),
    [boxStyle, coords],
  );

  return (
    <AnimatePresence initial={initial}>
      {isVisible && (
        <motion.div
          key="box"
          className={className}
          variants={animationVariants}
          initial="hide"
          animate="show"
          exit="hide"
          style={modalStyle}
          onAnimationStart={() => setIsAnimating(true)}
          onAnimationComplete={() => setIsAnimating(false)}
        >
          {!isAnimating && children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

AnimateExpandFromPoint.defaultProps = {
  coords: null, // when null or not provided animates from center of screen
  isVisible: false,
  initial: false, // whether to show the animation on initial page render
};
