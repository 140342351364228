import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';

import { AlternateAvailabilityGrid } from 'app/components';
import { Pebbles } from 'ui/Pebbles';
import { useProfile } from 'app/profile';
import { BookingRequestTypeEnum } from 'graphql/constants';
import { useNurseryCalendar } from 'graphql/hooks';
import getCustomSessionsByChild from 'graphql/queries/getCustomSessionsByChild';
import { useNurseryCanAcceptPayments, usePageViewTracker } from 'hooks';
import { DefaultAppPage } from 'layouts';
import { useSessionSelectionParams, useBookingParams } from 'pages/Booking';
import { QUERY_PARAM_CHECKMARK } from 'utilities/string';

import { SessionPicker } from './components';
import { CustomSessionPicker } from './components';
import illustration from './illustration.png';
import styles from './SessionSelection.module.scss';
import { logButtonEvent } from 'app/amplitude';

const getExistingCustomSessionBookings = (bookings) => {
  return bookings
    ?.filter((booking) => Boolean(booking.customSessionType))
    .map((booking) => ({
      status: booking.status,
      sessionId: booking.customSessionType.id,
    }));
};

export const SessionSelection = () => {
  const history = useHistory();
  const { profile } = useProfile();
  const { date, nursery, paymentExemptReason } = useBookingParams();
  const [sessionType, setSessionType] = useState(null);

  usePageViewTracker('booking/session_selection');

  const { allowInstantBookings, nurseryId, queryParams } = useSessionSelectionParams();
  const childIds = useMemo(() => queryParams.children.split(','), [queryParams]);
  const canNurseryAcceptPayments = useNurseryCanAcceptPayments(nurseryId);

  const { calendar: availabilityByDay, loading: calendarLoading } = useNurseryCalendar({
    canInstantBook: allowInstantBookings,
    childIds,
    fetchPricing: canNurseryAcceptPayments,
    startDate: date,
    endDate: date,
  });

  const availability = useMemo(() => {
    return availabilityByDay[queryParams.date];
  }, [availabilityByDay, queryParams.date]);

  const session = useMemo(() => {
    return availability?.sessions.find((s) => s.type === sessionType);
  }, [sessionType, availability]);

  const handleBookingButtonClick = () => {
    const type = session.isInstantBookable
      ? BookingRequestTypeEnum.INSTANT
      : BookingRequestTypeEnum.REQUEST;
    const actionPrefix = session.isInstantBookable ? 'instant' : 'request';
    logButtonEvent(`${actionPrefix}_book_primary_session_selection`);

    const bookingReviewQueryParams = {
      date: availability.dateStamp,
      sessionType,
      children: queryParams.children,
      type,
      paymentExemptReason,
    };

    if (paymentExemptReason) {
      bookingReviewQueryParams.isPaymentExempt = QUERY_PARAM_CHECKMARK;
    }

    history.push(`/booking/review?${queryString.stringify(bookingReviewQueryParams)}`);
  };

  const handleCustomSessionBookingClick = (sessionId, requestType) => {
    requestType === 'REQUEST'
      ? logButtonEvent('request_book_primary_custom_session_selection')
      : logButtonEvent('instant_book_primary_custom_session_selection');
    const bookingReviewQueryParams = {
      date: availability.dateStamp,
      children: queryParams.children,
      type: requestType,
      paymentExemptReason,
      sessionId,
    };

    if (paymentExemptReason) {
      bookingReviewQueryParams.isPaymentExempt = QUERY_PARAM_CHECKMARK;
    }

    history.push(`/booking/review?${queryString.stringify(bookingReviewQueryParams)}`);
  };

  const child = useMemo(
    () => profile.children.find((c) => c.id === queryParams.children),
    [profile, queryParams],
  );

  const showAlternateAvailability =
    session && !session.isInstantBookable && nursery?.isNonPrimaryBookingEnabled === true;

  const hasCustomSessions = nursery?.isCustomSessionBookingsEnabled;

  const showCustomSessionTimes = nursery?.showCustomSessionTimes;

  const { data: customSessionAvailability, loading: customSessionsLoading } = useQuery(
    getCustomSessionsByChild,
    {
      variables: {
        nursery: nurseryId,
        child: child?.id,
      },
      skip: !nurseryId && !child?.id,
    },
  );

  const isLoading = !nursery?.id;

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Pebbles className={styles.loadingIndicator} size={60} />
      </div>
    );
  }

  return (
    <DefaultAppPage
      title={`Hey ${profile.firstName},`}
      illustration={
        hasCustomSessions ? null : (
          <img src={illustration} className={styles.illustration} alt="Woman holding her child" />
        )
      }
      useHistoryBack
      onBack={() => logButtonEvent('back_session_selection')}
    >
      <main className={styles.container}>
        <p>Select the type of session you would like to book on {format(date, 'EEE, do MMMM')}:</p>

        {hasCustomSessions ? (
          <CustomSessionPicker
            sessions={customSessionAvailability?.customSessionTypesByChild}
            availability={availability?.availability}
            existingBookings={getExistingCustomSessionBookings(availability?.bookings)}
            nurseryOrg={nursery?.organization.name}
            nurseryName={nursery?.name}
            handleBookingButtonClick={handleCustomSessionBookingClick}
            isLoading={customSessionsLoading && calendarLoading}
            allowInstantBookings={allowInstantBookings}
            earliestBookingRequestSession={availability?.earliestBookingRequestSession}
            earliestInstantBookingSession={availability?.earliestInstantBookingSession}
            date={availability?.date}
            isNonPrimaryBookingEnabled={nursery?.isNonPrimaryBookingEnabled}
            nurseryId={nursery?.id}
            childId={child?.id}
            paymentsEnabled={nursery?.paymentsEnabled}
            showSessionTimes={showCustomSessionTimes}
          />
        ) : (
          <SessionPicker
            availability={availability}
            nursery={nursery}
            onChange={(v) => setSessionType(v.value)}
            session={session}
            handleBookingButtonClick={handleBookingButtonClick}
            isLoading={calendarLoading}
          />
        )}

        {showAlternateAvailability && (
          <>
            <AlternateAvailabilityGrid
              className={styles.alternateAvailability}
              child={child}
              date={date}
              sessionType={session?.type}
            />
            <div className={styles.disclaimer}>
              Bookings at nearby nurseries will be charged at the rate shown.
            </div>
          </>
        )}

        <div className={styles.disclaimer}>
          {!nursery.paymentsEnabled ? (
            <>
              Bookings at your home nursery will be charged at your current day rate. A session will
              be added to your monthly invoice.
            </>
          ) : (
            <>If you’re exempt of payment you can let us know on the next screen.</>
          )}
        </div>
      </main>
    </DefaultAppPage>
  );
};
