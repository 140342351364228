import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconInfoCircle = ({
  className = 'icon',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99935 1.66732C14.6017 1.66732 18.3327 5.39828 18.3327 10.0007C18.3327 14.603 14.6017 18.334 9.99935 18.334C5.39698 18.334 1.66602 14.603 1.66602 10.0007C1.66602 5.39828 5.39698 1.66732 9.99935 1.66732Z"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.334L10 9.16732"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.66797H10.0083"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
