import { useState } from 'react';
import emailValidator from 'email-validator';

import { DefaultAppPage } from 'layouts';
import { Button, Input } from 'ui';
import { StickyNav } from 'ui/StickyNav';

export const EditEmail = ({ guardian, initialValue, isSaving, onBack, postToApi }) => {
  const [newValue, setNewValue] = useState(initialValue);
  const isScreenValid = emailValidator.validate(newValue);

  const handleSubmitClick = (e) => {
    e.preventDefault();

    postToApi({ email: newValue });
  };

  return (
    <DefaultAppPage
      title="Email address"
      onBack={onBack}
      footerActions={
        <StickyNav>
          <Button
            expand
            onClick={handleSubmitClick}
            disabled={!isScreenValid || isSaving}
            isWorking={isSaving}
          >
            Update email address
          </Button>
        </StickyNav>
      }
    >
      <Input
        placeholder={guardian?.email ?? 'name@mail.com'}
        autoFocus
        value={newValue}
        onChange={(e) => setNewValue(e.target.value)}
        data-testid="editEmail__input"
      />
    </DefaultAppPage>
  );
};
